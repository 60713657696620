import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface OldShippingLineData {}
export interface ShippingLineData {}

export class ShippingLine extends BaseLine<OldShippingLineData & ShippingLineData> {
  kind = ConfigLineKinds.Shipping;
  category = 'Order';
  name = 'Shipping';
  order = 120;
  scrollToElementId = 'shipping-option-scroll-to';
  get valid() { return true; }

  deserialize(data?: OldShippingLineData & ShippingLineData) {
    this.data = data;
    return this.q.resolve();
  }

  refreshPrice() {
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.Shipping] = ShippingLine;
