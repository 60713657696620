import { PasswordChangeController } from './passwordChange.controller';
import lib from '~/ui/ui.module';
import angular from 'angular';

@lib.inject('$mdDialog').service
export class PasswordChangeService {

  constructor(
    private $mdDialog: ng.material.IDialogService
  ) { }

  showModal() {
    return this.$mdDialog.show({
      parent: angular.element('#fmtv-app'),
      templateUrl: 'ui/passwordChange/passwordChange.html',
      controller: PasswordChangeController,
      controllerAs: '$ctrl'
    });
  }

}
