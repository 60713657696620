
import app from '../../../app';
import { CartService } from '../../../cart/cart.service';
import { ShopifyHttpService } from '~/api-shopify/http.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import { DesignConfigurationService } from '../../../design/designConfiguration.service';
import { DesignConfiguration } from '~/configurator/design/designConfiguration';

@app.inject(
  '$state',
  '$scope',
  '$rootScope',
  CartService,
  LoadingIndicatorService,
  DesignConfigurationService,
  '$element'
).component({
  templateUrl: 'configurator/configuration/design/mini-cart/mini-cart.html',
})

export default class MiniCartComponent {
  private cartNote: string;
  private cartInfo: any;
  private cartItems: any;
  private configuration: DesignConfiguration;
  // call shopify to update total price
  private isDirty: boolean = false;
  private isVisible: boolean = false;
  private cartItemCounter: string = '0';
  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private cartService: CartService,
    private loadingIndicator: LoadingIndicatorService,
    private configurationService: DesignConfigurationService,
    private $element: ng.IRootElementService,
  ) {

    this.configuration = configurationService.designConfiguration;

    $rootScope.$on('toggle-mini-cart',  () => {
      this.toggleDisplay();
    });

    $scope.$watch(() => this.isDirty, (newValue, oldValue) => {
      if ( newValue !== oldValue ) {
          this.reload();
      }
    });
    $scope.$watch(() => this.isVisible, (newValue, oldValue) => {
      if ( newValue !== oldValue && this.isVisible === true) {
          this.reload();
      }
    });
  }

  $onInit() {
    this.loadingIndicator.show();
    this.cartService.getCartInformation().then( (cartInfo: any) => {
      this.cartItems = cartInfo.data.items;
    }).finally( () => {
      this.loadingIndicator.hide();
    });
  }

  toggleDisplay( ){
    // this.$element.parent().css({display: 'none'});
    this.isVisible = !this.isVisible;
  }
  incrementQuantity( itemIndex: number ){

    const shopifyIndex = itemIndex + 1; // shopify uses an index starting at 1, angular at 0
    // shopify recommends using items by index not id since ids can be repeated
    this.cartService.updateCartItemQuantity( shopifyIndex, this.cartInfo.items[itemIndex].quantity + 1 )
    .then( () => {
      this.isDirty = true;
    }).catch( (err: any ) => {
      // err.data.message is a title like 'cart error'
      if ( err.data && err.data.description ) {
        alert( err.data.description );
      } else {
        alert( "An unknown error occured while updating the quantity.  Please refer to the quantity after clicking 'checkout' ");
      }
    });
  }

  decrementQuantity (itemIndex: number){
    this.isDirty = true;
    const shopifyIndex = itemIndex + 1; // shopify uses an index starting at 1, angular at 0
    // shopify recommends using items by index not id since ids can be repeated
    if ( this.cartInfo.items[itemIndex].quantity > 0 ) {
      this.cartService.updateCartItemQuantity( shopifyIndex, this.cartInfo.items[itemIndex].quantity - 1 ).then(() => {
        this.isDirty = true;
      }).catch( (err: any ) => {
        // err.data.message is a title like 'cart error'
        if ( err.data && err.data.description ) {
          alert( err.data.description );
        } else {
          alert( "An unknown error occured while updating the quantity.  Please refer to the quantity after clicking 'checkout' ");
        }
      });
    }
  }
  goToCart(){
    this.cartService.openCart();
  }

  formatItemPrice( price: number ) {
    if ( !this.cartInfo || !this.cartInfo.items || this.cartInfo.items.length === 0 ) {
        return '$ 0';
    } else if ( !price ||  price === 0 ) {
      return '$ FREE';
    } else {
      return '$' + ( price / 100 ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  updateCartComment(){
    this.cartService.updateCartNote( this.cartNote ).then(() => {
      this.isDirty = true;
    }).catch( (err: any ) => {
      // err.data.message is a title like 'cart error'
      if ( err.data && err.data.description ) {
        alert( err.data.description );
      } else {
        alert( "An unknown error occured while updating the quantity.  Please refer to the quantity after clicking 'checkout' ");
      }
    });
  }
  close(){
    this.isVisible = false;
  }

  private reload() {
    this.loadingIndicator.show();
    this.cartService.getCartInformation().then( (cartInfo: any) => {
      this.cartInfo = cartInfo.data;
      this.cartNote = this.cartInfo.note;
      this.isDirty = false;
    }).finally( () => {
      this. loadingIndicator.hide();
    });
  }
}
