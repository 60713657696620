import isFinite from 'lodash/isFinite';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import lib from '~/ui/ui.module';

// tslint:disable-next-line:interface-name
export interface ICategory {
  label: string;
  thumbUrl: string;
  buttonLabel: string;
  anchor: string;
  scrollPos?: number;
  page?: string;
  items?: any[];
}

@lib.inject('$element', '$scope', '$timeout').controller
export class CategorizedScrollerController {

  categories: ICategory[];
  selection: string;
  categoriesPerLine: number;
  categorySelected: (options: { $category: ICategory }) => void;
  categoryEnter: (options: { $category: ICategory }) => void;
  categoryLeave: (options: { $category: ICategory }) => void;

  categoriesHeight: number;
  showTopBar: boolean;
  itemsContainer: ng.IAugmentedJQuery;
  currentCategory: string;
  tileWidth: number;

  // HACK: when scrolling, we set the active tab depending on the scroll position.
  // However, the material tabs will then auto trigger a click event. We use this flag
  // to know when to ignore that event
  private suppressClick: boolean;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope,
    $timeout: ng.ITimeoutService
  ) {
    $(window).on('resize', this.resized);

    this.tileWidth = Math.floor(9 / (this.categoriesPerLine || 3)) * 10;

    // this.itemsContainer = this.$element.find('.items-container');
    this.itemsContainer = (this.$element.find('.items-container') as ng.IAugmentedJQuery);
    this.itemsContainer.on('scroll', this.scrolled);

    $scope.$on('$destroy', () => {
      $(window).off('resize', this.resized);
      this.itemsContainer.off('scroll', this.scrolled);
    });

    // using timeout to ensure rendering happened
    $timeout(this.resized, 250);
  }

  $onInit() { }

  scrolled = () => {
    const scrollTop = this.itemsContainer.scrollTop();
    const showBar = scrollTop >= this.$element.find('#categories-container').height();
    const category = last(sortBy(filter(this.categories, c => isFinite(c.scrollPos) && c.scrollPos <= scrollTop - this.categoriesHeight), c => c.scrollPos));

    if (showBar !== this.showTopBar || (category && category.anchor !== this.currentCategory)) {
      this.$scope.$apply(() => {
        this.showTopBar = showBar;
        if (category) {
          this.currentCategory = category.anchor;
          this.suppressClick = true;
        } else {
          this.currentCategory = null;
        }
      });
    }
  }

  resized = () => {
    this.categoriesHeight = this.$element.height() - 48;
    this.scrolled();
  }

  tabClicked(category: ICategory) {
    if (this.suppressClick) {
      this.suppressClick = false;
      return;
    }
    this.clicked(category);
  }

  clicked(category: ICategory) {
    if (category.items && category.items.length > 1) {
      const pos = isFinite(category.scrollPos) ? category.scrollPos + this.$element.find('#categories-container').height() + 1 : 0;
      this.itemsContainer.scrollTop(pos);
    }
    this.categorySelected({ $category: category });
  }

  enter(category: ICategory) {
    this.categoryEnter({ $category: category });
  }

  leave(category: ICategory) {
    this.categoryLeave({ $category: category });
  }
}

// tslint:disable-next-line:max-classes-per-file
@lib.directive()
export class CategorizedScroller {
  definition() {
    return {
      controller: CategorizedScrollerController,
      controllerAs: '$directive',
      transclude: true,
      templateUrl: 'ui/categorizedScroller/categorizedScroller.html',
      bindToController: {
        categories: '<',
        selection: '=',
        collectionType: '@',
        categoriesPerLine: '=',
        categorySelected: '&',
        categoryEnter: '&',
        categoryLeave: '&'
      }
    };
  }
}
