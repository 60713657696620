import { FrameProduct } from '../../../../api-shopify/product-models/frame-product';
import app from '../../../app';
import find from 'lodash/find';
import FrameController from './frame.controller';

// tslint:disable-next-line:only-arrow-functions
/*
app.inject(ApiCategoriesService).filter(function frameCategory(
  categoriesService: ApiCategoriesService
) {
*/
app.filter( function frameCategory() {
  // let categories: VirtoCommerceCatalogModuleWebModelCategory[];
  // categoriesService.subCategories(CommonCategories.Frames).then(sc => categories = sc);
  let categories = this.categoryButtons;
  return (frame: FrameProduct) => {
    if (!frame || !frame.product) {
      return '';
    }
    // let category = find(categories, c => c.id === frame.product.categoryId);
    let category = find(categories, c => c.collectionId === frame.product.collectionId);
    // return category ? category.name : '';
    return category ? category.label : '';
  };
}, 'frameCategory');
