import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export class ArtCoverLine extends BaseLine<void> {
  kind = ConfigLineKinds.ArtCover;
  category = 'Design';
  name = 'Art Cover';
  description = 'Motorized Subframe';
  order = 35;
  editState = '';
  scrollToElementId = 'art-cover-option-scroll-to';
  get valid() { return true; }

  deserialize(data: any) {
    return this.q.resolve();
  }

  refreshPrice() {
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.ArtCover] = ArtCoverLine;
