import app from '../../app';
import { BrandingService } from '../../services/branding.service';
import { CaptureFormService } from './captureForm.service';
import { BrandingInfo } from '../../services/brandingInfo';

@app.inject(
  CaptureFormService,
  BrandingService,
  '$state'
).component({
  templateUrl: 'configurator/configuration/captureForm/captureForm.html'
})
export class CaptureForm {
  vendor: boolean;
  brandingInfo: BrandingInfo;

  constructor(
    public $ftvCaptureForm: CaptureFormService,
    $ftvBranding: BrandingService,
    private $state: ng.ui.IStateService
  ) {
    $ftvBranding.infoPromise.then(info => {
      this.vendor = info.id && info.id !== '0';
      this.brandingInfo = info;
    });
  }

  $onInit() { }

  cancel() {
    this.$ftvCaptureForm.visible = false;
    this.$ftvCaptureForm.hideCaptureForm = true;
    if (this.brandingInfo.settings.optionsTab) {
      this.$state.go('configuration.tab.options');
    }
    else {
      this.$state.go('configuration.tab.summary');
    }
  }
}
