import app from '../../../app';
import angular from 'angular';
import clone from 'lodash/clone';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import remove from 'lodash/remove';
import partition from 'lodash/partition';
import includes from 'lodash/includes';

import { AuthenticationService } from '../../../user/authentication.service';
import { InstallationLineData, InstallationVariant } from '../../../design/lines/installationLine';
import { InstallationMethodProduct } from '../../../../api-shopify/product-models/installation-method-product';
import { DesignConfiguration } from '../../../design/designConfiguration';
import RecessController from './recess.controller';
import { OptionsService } from '../options.service';
import { DesignTypes } from '~/configurator/design/designTypes';
import { ConfigLineKinds } from '~/configurator/design/lines';
import { SaveDesignTimerService } from '../../save-design/save-design-timer.service';

@app.inject(
  'designConfiguration',
  '$sce',
  '$mdDialog',
  AuthenticationService,
  '$scope',
  OptionsService,
  SaveDesignTimerService,
).controller
export default class OptionsInstallationController {

  installationVariants = {
    depth: [
      {
        id: 1,
        name: 'TV is mounted on surface of wall',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/Surface_Mount_Icon_TH-2.jpg'
      },
      {
        id: 2,
        name: 'TV is fully recessed inside the wall',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/Fully_Recessed_Icon_TH-2.jpg'
      }
    ],
    mount: [
      {
        id: 3,
        name: 'TV is on a swing arm wall mount',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/57_SwingArm.jpg'
      },
      {
        id: 4,
        name: 'TV is on a flat wall mount (customer supplied)',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/57_FlatWallMount.jpg'
      },
      {
        id: 5,
        name: 'TV is on a tilted wall mount',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/57_TiltWallMount.jpg'
      }
    ],
    wall: [
      {
        id: 6,
        name: 'TV is mounted to a stud wall',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/57_DrywallWall.jpg'
      },
      {
        id: 7,
        name: 'TV is mounted to an uneven surface',
        image: window.fmtvAssetsRoot + 'assets/configurator/installVariants/57StoneWall.jpg'
      }
    ]
  };

  // pages: Pages;
  installation: InstallationLineData;
  recommendedMethods: InstallationMethodProduct[];
  otherMethods: InstallationMethodProduct[];
  selectedButtonText: string = 'Added to My Design <br>  <md-icon md-svg-icon="check"></md-icon> +{{ item.price | price }}';
  private _installMethods: InstallationMethodProduct[];

  constructor(
    private configuration: DesignConfiguration,
    private $sce: ng.ISCEService,
    private $mdDialog: ng.material.IDialogService,
    private authenticationService: AuthenticationService,
    private $scope: ng.IScope,
    private optionsService: OptionsService,
    private saveDesignTimerService: SaveDesignTimerService,
  ) {
    InstallationMethodProduct.list().then(i => {
      this._installMethods = i;
      this.sortMethods();
    });

    this.installation = clone(this.configuration.installation.data || {});

    if (this.installation.type && !this.installation.depth) {
      this.installation.depth = find(this.installationVariants.depth,
        d => d.id === (this.installation.type === 'S' ? 1 : 2)
      );
    }

    this.sortMethods();

    $scope.$watch(() => this.installation, (installation, oldVal) => {
      if (!isEmpty(installation)) {
        if (this.configuration.installation.data && (this.configuration.installation.data.code === this.installation.code)) return;
        this.configuration.installation.update(clone(this.installation));
        if (installation.code && ( oldVal.code !== installation.code ) ) {
          this.optionsService.nextOption();
        }
      }
    }, true);

    $scope.$watch(() => this.configuration.type, (configType, oldVal) => {
      if (!isEmpty(configType)) {
        InstallationMethodProduct.list().then(i => {
          this._installMethods = i;
          this.sortMethods();
        });
      }
    }, true);

    $scope.$watch(() => this.configuration.tvModel.data.diagscreensize, (diagSize, oldVal) => {
      if (diagSize) {
        InstallationMethodProduct.list().then(i => {
          this._installMethods = i;
          this.sortMethods();
        });
      }
    }, true);

    $scope.$watch(() => this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku, (frameProduct) => {
      // need to update options if new frame is selected because it may be HW (removing L-bracket surface mount for everything but art coverings)
        this.sortMethods();
    }, true);
    this.saveDesignTimerService.pauseTimer();
  }

  $onInit() { }

  // arrow function is important here so the 'this' ref stays the same (it is used in a callback from another component)
  select = (installMethod: InstallationMethodProduct) => {
    if ( !installMethod) {
      this.installation.code = null;
      this.installation.name = null;
      return;
    }
    if ( this.installation.code !== installMethod.code ){
      this.installation.code = installMethod.code;
    }
    if ( this.installation.name !== installMethod.name ) {
      this.installation.name = installMethod.name;
    }
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder && this.configuration.dirty ) {
     // this.configuration.save();
    }
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
  }

  details(e: MouseEvent, installMethod: InstallationMethodProduct) {
    e.preventDefault();
    e.stopPropagation();
  }

  selectDepth(variant: InstallationVariant, e: MouseEvent) {
    this.select( null ); // user selected different installation options so remove any already selected product
    if ( variant.id ) {
      this.installation.type = variant.id === 1  ? 'S' : 'R';
    }
    if (this.installation.depth && this.installation.depth.id === variant.id) {
      this.installation.depth = null;
    }
    else {
      this.installation.depth = variant;
      if (variant.id === 2) {
        this.editRecess(e);
      }
    }
    this.sortMethods();
  }

  selectMount(variant: InstallationVariant) {
    this.select( null ); // user selected different installation options so remove any already selected product
    if (this.installation.mount && this.installation.mount.id === variant.id) {
      this.installation.mount = null;
    }
    else {
      this.installation.mount = variant;
    }
    this.sortMethods();
  }

  selectWall(variant: InstallationVariant) {
    this.select( null ); // user selected different installation options so remove any already selected product
    if (this.installation.wall && this.installation.wall.id === variant.id) {
      this.installation.wall = null;
    }
    else {
      this.installation.wall = variant;
    }
    this.sortMethods();
  }

  editRecess(e: MouseEvent) {
    this.$mdDialog.show({
      controller: RecessController,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/configuration/options/installation/recess.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: e,
      clickOutsideToClose: false,
      locals: {
        installation: this.installation
      }
    } as any);
  }

  hasRecess() {
    return isBoolean(this.installation.recess);
  }

  recessInfo() {
    if (this.installation.recess) {
      let t = 'The recess exists';
      if (this.installation.recessheight || this.installation.recesswidth || this.installation.recessdepth) {
        const d: string[] = [];
        if (this.installation.recesswidth) {
          d.push(this.installation.recesswidth + '" w');
        }
        if (this.installation.recessheight) {
          d.push(this.installation.recessheight + '" h');
        }
        if (this.installation.recessdepth) {
          d.push(this.installation.recessdepth + '" d');
        }
        t += ` (${d.join(', ')}).`;
      }
      return t;
    }
    else {
      return 'The recess does not exist.';
    }
  }

  showMethod(method: InstallationMethodProduct) {
    return method.code !== 'INSTALL-7' || this.authenticationService.isAdmin;
  }

  private sortMethods() {
    // installation methods is not initialized immediately - hack around
    if ( ! this._installMethods) {
      return;
    }
    this.recommendedMethods = clone(this._installMethods);
    this.otherMethods = [];

    const depthTable: { [k: string]: string } = {
      1: 'S',
      2: 'R'
    };

    const mountTable: { [k: string]: string } = {
      3: 'S',
      4: 'F',
      5: 'T'
    };

    const wallTable: { [k: string]: string } = {
      6: 'S',
      7: 'T'
    };

    if (this.configuration.tvModel.data && this.configuration.tvModel.data.diagscreensize > 46) {
      const straps = remove(this.recommendedMethods, m => m.code === 'INSTALL-1');
      const artStraps = remove(this.recommendedMethods, m => m.code === 'INSTALL-1-ART');
      if (this.configuration.type === DesignTypes.FrameWithArt || this.configuration.type === DesignTypes.MetroArt) {
        this.otherMethods = this.otherMethods.concat(artStraps);
      } else {
        this.otherMethods = this.otherMethods.concat(straps);
      }
    }

    // currently art related installation methods are
    // 'INSTALL-1-ART' Straps for art
    // 'INSTALL-2-ART' L-Bracket (Surface)
    // 'INSTALL-3-ART; Bracket Adapter Plate
    // 'INSTALL-6-ART'  L-Bracket (Recessed)
    // these skus end in -ART .  The non-art version does not have the -ART postfix
    const artSkus = this.recommendedMethods.filter(i => i.sku.endsWith('ART'));
    const tempNonArtSkus: string[] = artSkus.map(i => i.sku.replace('-ART', ''));
    const nonArtSkus = this.recommendedMethods.filter ( i => tempNonArtSkus.includes( i.sku ) );

    if (this.configuration.type === DesignTypes.FrameWithArt || this.configuration.type === DesignTypes.MetroArt) {

      this.recommendedMethods = this.recommendedMethods.filter(e => {
        if ( artSkus.includes(e) ) {
          return true;
        } else if ( nonArtSkus.includes(e) ) {
          return false;
        } else {
          return true;
        }
      });
    } else {
        this.recommendedMethods = this.recommendedMethods.filter(e => {
        if (artSkus.includes(e)) {
          return false;
        } else {
          return true;
        }
      });
    }

    let [keep, exclude] = partition(this.recommendedMethods,
      m => includes(m.productType, this.configuration.type as any)
    );

    this.recommendedMethods = keep;
    this.otherMethods = this.otherMethods.concat(exclude);

    if (this.installation.depth) {
      [keep, exclude] = partition(this.recommendedMethods,
        m => includes(m.mounting, depthTable[this.installation.depth.id.toString()])
      );
      this.recommendedMethods = keep;
      this.otherMethods = this.otherMethods.concat(exclude);
    }

    if (this.installation.mount) {
      [keep, exclude] = partition(this.recommendedMethods,
        m => includes(m.mountType, mountTable[this.installation.mount.id.toString()])
      );

      this.recommendedMethods = keep;
      this.otherMethods = this.otherMethods.concat(exclude);
    }

    if (this.installation.wall) {
      [keep, exclude] = partition(this.recommendedMethods,
        m => includes(m.wallType, wallTable[this.installation.wall.id.toString()])
      );
      this.recommendedMethods = keep;
      this.otherMethods = this.otherMethods.concat(exclude);
    }
    remove(this.recommendedMethods, m => !this.showMethod(m));
    remove(this.otherMethods, m => !this.showMethod(m));

    /// for ALL surface mounted Hardwood frames in all product categories  hide the L brackets installation method - except surface mounted HW TV art covers
    if (this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.isHardwood()) {
      if ( this.configuration.type !==  DesignTypes.FrameWithArt) {
        remove( this.recommendedMethods, m => m.sku === 'INSTALL-2');
      }
    }
  }
}
