import { ArtControlProduct } from '../../../api-shopify/product-models/art-control-products';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface OldArtControlLineData { }
export interface ArtControlLineData {
  code?: string;
  name?: string;
}

export class ArtControlLine extends BaseLine<OldArtControlLineData & ArtControlLineData> {
  kind = ConfigLineKinds.ArtControl;
  category = 'Options';
  name = 'Art Control';
  order = 70;
  editState = 'configuration.tab.options.art-control';
  scrollToElementId = 'art-control-option-scroll-to';

  private product: ArtControlProduct;

  get valid() { return !!this.product; }

  deserialize(data?: OldArtControlLineData & ArtControlLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return ArtControlProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    else {
      return this.q.resolve();
    }
  }

  setProduct(product: ArtControlProduct, noTrigger = false) {
    this.product = product;
    this.description = product.name;
    this.data = {
      code: product.code,
      name: product.name
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice() {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    this.price = null;
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.ArtControl] = ArtControlLine;
