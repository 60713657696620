import { MeasurementsService } from '../../configurator/services/measurements.service';
// import { CommonCategories } from '../apiCategories.service';
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import lib from '~/api-shopify/api-shopify.module';
let listPromise: ng.IPromise<MountingOptionProduct[]>;

export class MountingOptionProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = MountingOptionProduct.productsService.getAllMountingOptionProducts()
      .then(products =>  sortBy(products, p => p.sortPosition).map((p: any) => new MountingOptionProduct(p)));
  }

  static get(code: string) {
    return this.list().then( (products: any) => find(products, p => p.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  @lib.inject(MeasurementsService).property
  private static readonly measurementsService: MeasurementsService;

  // thumbnail moved to super
  // description moved to super
  private _manualPriceAdjustment: number  = 1.06; // this should be set in shopify but temporarily set here

  guaranteedPrice(): ng.IPromise<number> {
    let config = this.designService.designConfiguration;
    if (config && config.cutGlass && config.cutGlass.data && config.cutGlass.data.width) {
      let width = config.cutGlass.data.width;
      if (config.cutGlass.data.system === 'mm') {
        width = MountingOptionProduct.measurementsService.mmToInches(width);
      }
      return this.basePrice().then(p => Math.ceil(width) * p * this._manualPriceAdjustment);
    }
    return this.q.resolve(0);
  }
}
