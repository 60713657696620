import {app} from './app';

app
  .inject('$mdThemingProvider')
  .config((
    $mdThemingProvider: ng.material.IThemingProvider
  ) => {
    $mdThemingProvider.definePalette('fmtvPrimary', {
      '50': 'faede3',
      '100': 'f3d1b8',
      '200': 'ebb389',
      '300': 'e3955a',
      '400': 'dd7e36',
      '500': 'd76713',
      '600': 'd35f11',
      '700': 'cd540e',
      '800': 'c74a0b',
      '900': 'be3906',
      'A100': 'ffede7',
      'A200': 'ffc5b4',
      'A400': 'ff9d81',
      'A700': 'ff8968',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400', 'A700'],
      'contrastLightColors': ['500', '600', '700', '800', '900']
    });

    $mdThemingProvider.definePalette('fmtvAccent', {
      '50': 'faede5',
      '100': 'f2d2be',
      '200': 'eab492',
      '300': 'e29566',
      '400': 'db7f46',
      '500': 'd56825',
      '600': 'd06021',
      '700': 'ca551b',
      '800': 'c44b16',
      '900': 'ba3a0d',
      'A100': 'ffece7',
      'A200': 'E26617',
      'A400': 'E26617',
      'A700': 'AB510F',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': ['50', '100', '200', '300', '400', '500', 'A100', 'A200', 'A400', 'A700'],
      'contrastLightColors': ['600', '700', '800', '900']
    });

    $mdThemingProvider.definePalette('fmtvWarn', {
      '50': 'fcebe7',
      '100': 'f8cec4',
      '200': 'f3ad9d',
      '300': 'ee8c75',
      '400': 'eb7458',
      '500': 'ae510f',
      '600': 'ae510f',
      '700': 'e16619',
      '800': 'dd4025',
      '900': 'd72f18',
      'A100': 'ffffff',
      'A200': 'ffdad6',
      'A400': 'ffaca3',
      'A700': 'ff958a',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': ['50', '100', '200', '300', '400', '500', 'A100', 'A200', 'A400', 'A700'],
      'contrastLightColors': ['600', '700', '800', '900']
    });

    $mdThemingProvider.definePalette('fmtvBackground', {
      '50': 'eef0e5',
      '100': 'e6e8e0',
      '200': 'd6d9cc',
      '300': 'c5c9b8',
      '400': 'b8bea8',
      '500': 'acb299',
      '600': 'a5ab91',
      '700': '9ba286',
      '800': '92997c',
      '900': '828a6b',
      'A100': 'ffffff',
      'A200': 'f3fed1',
      'A400': 'e7ff9d',
      'A700': 'e1ff84',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'],
      'contrastLightColors': []
    });

    if (!location.search.match('store')) {
      $mdThemingProvider.theme('default')
        .primaryPalette('fmtvPrimary')
        .accentPalette('fmtvAccent')
        .warnPalette('fmtvWarn')
        .backgroundPalette('fmtvBackground');
    }
  });
