import app from '../../app';
import angular from 'angular';
import { SaveDesignStep2Service } from './save-design-step2.service';
import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
import { HubspotJSONFormService } from '~/configurator/services/hubspotJSONForm.service';
import { DesignConfiguration } from '~/configurator/design/designConfiguration';
import { Analytics } from '~/configurator/analytics';
import { SaveDesignService } from '../save-design/save-design.service';

@app.inject(
  SaveDesignStep2Service,
  SaveDesignService,
  '$scope',
  '$timeout',
  '$window',
  '$q',
  '$mdDialog',
  'HubspotJSONFormService',
  DesignConfigurationService,
  '$analytics',
  '$location',
  'clipboard',
  ProgressBarService,
).component({
  templateUrl: 'configurator/configuration/save-design-step2/save-design-step2.html',
  bindings: {
    firstName: '<',
    lastName: '<',
    email: '<',
  }
})
export class SaveDesignStep2 {

  isActive: boolean = false;
  configuration: DesignConfiguration;
  linkToDesign: string = 'Saving...';
  discussImageUrl: string = window.fmtvAssetsRoot + 'assets/configurator/save-design/schedule-call.jpg';

  private hubspotFormId: string = 'fc8d5da1-ce5c-426d-bff3-b4c5cc924e93';

  constructor(
    public saveDesignStep2Service: SaveDesignStep2Service,
    public saveDesignService: SaveDesignService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private $q: ng.IQService,
    private $mdDialog: ng.material.IDialogService,
    private hubspotJSONFormService: HubspotJSONFormService,
    private designConfigurationService: DesignConfigurationService,
    private $analytics: any,
    private $location: ng.ILocationService,
    private clipboard: any,
    private progressBarService: ProgressBarService,
    private firstname: string,
    private lastname: string,
    private email: string,

  ) {
    this.configuration = this.designConfigurationService.designConfiguration;

  }

  $onInit() {
    this.$scope.$watch(() => this.saveDesignStep2Service.visible, (newVal,oldVal) => {
      if ( newVal === true && oldVal === true) {
          this.saveDesignStep2Service.initCount += 1;
          if ( this.saveDesignStep2Service.initCount === 1  ) {
            this.start();
          }
      } else {
        this.finishAndHide();
      }
    });
  }
  start() {
    this.saveDesign();
    this.isActive = true;
  }
  finishAndHide() {
    this.isActive = false;

    this.saveDesignStep2Service.hide();
  }
  show() {
    return this.isActive;
  }
  saveDesign() {
    this.progressBarService.show();
    return this.designConfigurationService.save( this.saveDesignStep2Service.email, this.saveDesignStep2Service.firstname )
      .then(configId => {

        this.$timeout(() => {
          const fields = [
            { 'name': 'email', 'value': this.saveDesignStep2Service.email },
            { 'name': 'firstname', 'value': this.saveDesignStep2Service.firstname },
            // { 'name': 'lastname', 'value': this.saveDesignStep2Service.lastname },
            { 'name': 'saved_design_id', 'value': this.configuration.number },
            { 'name': 'saved_design_url', 'value': this.configuration.url + '/design' },
            { 'name': 'saved_design_amount', 'value': this.configuration.totalWithShipping },
            { 'name': 'saved_design_type', 'value': this.designType() }
          ];

          // after saving the url will include the link to the design
          const pageURI = this.$location.absUrl();
          const pageName = 'Design';
          this.hubspotJSONFormService.submitHubSpotForm(this.hubspotFormId, fields, pageURI, pageName).then(result => {
            console.log(result);
          });
          this.$analytics.eventTrack(Analytics.actions.formSubmitted, { category: Analytics.category, label: 'HubSpot Capture Form' });
        });
      })
      .then(() => {
        this.progressBarService.hide();
        this.linkToDesign = this.configuration.url + '/design';
        /*
        this.$mdDialog.show(
          this.$mdDialog.alert()
            .parent(angular.element('#fmtv-app'))
            .clickOutsideToClose(true)
            .title('Design Saved')
            .textContent('Your design has been saved, we have sent you a link to it by email. If you do not see it, please check your spam folder.')
            .ariaLabel('Design Saved')
            .ok('Ok'));
            */
      })
      .catch((err) => {
        this.progressBarService.hide();
      });
  }

  copyLink() {
    this.clipboard.copyText(this.configuration.url + '/design');
    this.$mdDialog.hide();
  }
  scheduleCall(){
    this.$window.open('https://app.hubspot.com/meetings/ben555', '_blank');
  }
  close() {
    $('ftv-save-design').css('display', 'static');
    $('ftv-save-design-component').css('display','none');
    $('.scroll-btn').css('display', 'block');
    this.saveDesignService.hide();
    this.saveDesignStep2Service.hide();
  }
  private designType() {
    switch (this.designConfigurationService.designConfiguration.type as any) {
      case 'frame with art':
        return 'Art';
      case 'frame with mirror':
      case 'metro mirror':
      case 'frameless mirror':
        return 'Mirror';
      case 'frame onl y':
        return 'Frame_Only';
    }
  }

}
