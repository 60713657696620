import app from '../../../app';

import some from 'lodash/some';

import { DesignConfiguration } from '../../../design/designConfiguration';
import { CutGlassAccessoryProduct } from '../../../../api-shopify/product-models/cut-glass-accessory-product';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', OptionsService).controller
export class OptionsCutGlassAccessoriesController {

  products: CutGlassAccessoryProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private optionsService: OptionsService
  ) {
    CutGlassAccessoryProduct.list().then(p => this.products = p);
  }

  $onInit() { }

  toggle(product: CutGlassAccessoryProduct) {
    this.configuration.cutGlassAccessories.toggleProduct(product);
    this.optionsService.nextOption();
  }

  selected(product: CutGlassAccessoryProduct) {
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
    //  this.configuration.save();
    }
    return some(this.configuration.cutGlassAccessories.products, p => p.code === product.code);
  }
}
