import includes from 'lodash/includes';
import app from '../../app';
import { DesignConfiguration } from '../../design/designConfiguration';
import { BrandingInfo } from '../../services/brandingInfo';
import { AuthenticationService } from '../../user/authentication.service';
import { CaptureFormService } from '../captureForm/captureForm.service';
import { TvService } from './tv/tv.service';
import { DesignTypes } from '~/configurator/design/designTypes';
import { FrameProduct } from '~/api-shopify/product-models/frame-product';
import { ArtProduct } from '~/api-shopify/product-models/art-product';
import { MirrorProduct } from '~/api-shopify/product-models/mirror-product';
import { freemem } from 'os';
import { BaseLine } from '~/configurator/design/lines';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
@app.inject(
  'designConfiguration',
  CaptureFormService,
  'brandingInfo',
  '$state',
  AuthenticationService,
  TvService,
  '$scope',
  DesignConfigurationService
).controller
export default class DesignTabController {

  constructor(
    public configuration: DesignConfiguration,
    private $ftvCaptureForm: CaptureFormService,
    private brandingInfo: BrandingInfo,
    private $state: ng.ui.IStateService,
    private authenticationService: AuthenticationService,
    private $ftvTv: TvService,
    private $scope: ng.IScope,
    private designConfigurationService: DesignConfigurationService
  ) {
    if (this.configuration.tvModel && !this.configuration.tvModel.data.diagscreensize) {
      window.scrollTo(0, 0);
      this.showTvOptions();
    }
    $scope.$watch( () => this.configuration.type, ( newDesignType: DesignTypes, oldDesignType: DesignTypes ) =>  {
      this.setDefaultsProductsPerDesignType(newDesignType, oldDesignType);
    });
  }

  $onInit() {
    // set default products per design type
    this.setDefaultsProductsPerDesignType();

  }

  setDefaultsProductsPerDesignType ( newDesignType: DesignTypes = null, oldDesignType: DesignTypes = null ) {
    // when the design type changes, use the existing selected frame unless the new design type
    // requires a metro or otherwise.
    let changeDefault = false;
    if ( newDesignType ) {
      let isNewMetro = ( newDesignType === DesignTypes.MetroArt || newDesignType === DesignTypes.MetroMirror );
      let isOldMetro =  ( oldDesignType === DesignTypes.MetroArt || oldDesignType === DesignTypes.MetroMirror );
      if ( isNewMetro && !isOldMetro || !isNewMetro && isOldMetro ) {
        // then the default selection needs to change
        changeDefault = true;
      }
    }

    if ( ( this.configuration.isNew && !this.configuration.dirty ) || changeDefault){
      const configType = this.configuration.type;
      // if frame
      if (configType === DesignTypes.FrameWithArt || configType === DesignTypes.FrameOnly || configType === DesignTypes.FrameWithMirror
          || configType === DesignTypes.FrameWithMirror || configType === DesignTypes.MetroMirror || configType === DesignTypes.MetroArt) {
        let defaultSku = 'FRAME-M1011';

        if ( configType === DesignTypes.MetroMirror || configType === DesignTypes.MetroArt ) {
          defaultSku = 'FRAME-M3012';
        }

        if (configType === DesignTypes.FrameOnly || configType === DesignTypes.FrameWithMirror ){
          defaultSku = 'FRAME-M4101';
        }
        FrameProduct.list().then ( frames => {
          const defaultFrame = frames.find( p => p.sku === defaultSku);
          if ( defaultFrame ) {
            this.configuration.frame.setFrameProduct( defaultFrame, null ); // .then( () => this.designConfigurationService.replace( this.configuration,) );
          }
        });
      }
      // if art
      if (configType === DesignTypes.FrameWithArt || configType === DesignTypes.MetroArt ) {
        ArtProduct.list().then ( frames => {
          const defaultArt = frames.find( p => p.sku === 'ART-8175');
          if ( defaultArt ) {
            this.configuration.artwork.setProduct(defaultArt);  // .then( () => this.designConfigurationService.designUpdated() );
          }
        });
      }
      // if mirror
      if (configType === DesignTypes.FrameWithMirror || configType === DesignTypes.FramelessMirror || configType === DesignTypes.MetroMirror ) {
        MirrorProduct.list().then ( mirrors => {
          const defaultMirror = mirrors.find( p => p.sku === 'MIRROR-OPTICLEAR-ULTRA');
          if ( defaultMirror ) {
            this.configuration.mirror.setProduct( defaultMirror );  // .then( () => this.designConfigurationService.designUpdated());
          }
        });
      }
    }
  }

  showPane(hideFrom: string[]) {
    return !includes(hideFrom, this.configuration.type as any);
  }

  showTvOptions(e?: MouseEvent) {
    this.$ftvTv.show(e);
  }

  saveAndContinue() {
    if (this.brandingInfo.settings.captureForm && this.configuration.isNew && !this.$ftvCaptureForm.hideCaptureForm) {
      this.$ftvCaptureForm.visible = true;
    }
    else {
      if (this.brandingInfo.settings.optionsTab) {
        this.$state.go('configuration.tab.options');
      }
      else {
        this.$state.go('configuration.tab.summary');
      }
    }
  }

  showContinue() {
    return false;
    /* return !this.authenticationService.restricted() ||
      (this.brandingInfo.settings.captureForm && this.configuration.isNew) ||
      this.brandingInfo.settings.summaryTab ||
      this.brandingInfo.settings.optionsTab; */
  }
}
