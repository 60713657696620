import app from '~/configurator/app';

@app.inject(
    '$q',
).service
export class  WebSocketService {
    public wsClient: WebSocket;
    constructor(
        private q: ng.IQService,
    ) {
        let rootUrl = window.shopifyCacheRoot.replace('https://','wss://');
        // const wsClient:WebSocket = new WebSocket('wss://' + rootUrl + '/');
        console.info( 'wss root' + rootUrl );
        const wsClient: WebSocket = new WebSocket(rootUrl + '/wss');
        wsClient.addEventListener('open', heartbeat);
        wsClient.addEventListener('ping', pinged);
        wsClient.addEventListener('close', wsClose );

        function wsClose( event: CloseEvent) {
          clearTimeout( this.pingTimeoutTimer);
        }

        function pinged() {
          heartbeat(null);
        }

        function heartbeat( event: Event ) {
          clearTimeout(this.pingTimeout);
          this.pingTimeout = setTimeout(() => {
            this.close();
          }, 30000 + 10000); // ping duration here should be larger than the server's
        }
    }

}
