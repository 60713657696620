import { FinishProduct } from '../../../api-shopify/product-models/finish-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import { FrameProduct } from '../../../api-shopify/product-models/frame-product';
import find from 'lodash/find';
import merge from 'lodash/merge';

export interface OldFrameLineData {
  style?: {
    caption?: string;
    category?: string;
    depthid?: string;
    facewidth?: string;
    id?: string;
    priority?: string;
    standardsidedepth?: string;
    stylebase?: string;
    stylefactor?: string;
    styleid?: string;
    stylename?: string;
    thumburl?: string;
    url?: string;
    width?: number;
    price?: number;
  };
  finish?: {
    colorbase?: string;
    colorfactor?: string;
    finishid?: string;
    finishname?: string;
    materialbase?: string;
    materialcostbasisbf?: string;
    materialdescription?: string;
    materialfactor?: string;
    styledescription?: string;
    stylename?: string;
    thumburl?: string;
    url?: string;
  };
}

export interface FrameLineData {
  style?: {
    code: string
  };
  finish?: {
    code: string
  };
}

export class FrameLine extends BaseLine<OldFrameLineData & FrameLineData> {
  kind = ConfigLineKinds.Frame;
  category = 'Design';
  name = 'Frame Style';
  order = 20;
  editState = 'configuration.tab.design.frame';

  frameProduct: FrameProduct;
  product: FrameProduct; // use to normalize the lightbox
  finishProduct: FinishProduct;
  scrollToElementId = 'frame-option-scroll-to';

  private framePrice = 0;
  private finishPrice = 0;

  get valid() {
    return !!this.frameProduct ||  ( !!this.frameProduct && (!!this.frameProduct.finishes && this.frameProduct.finishes.length > 0 && !!this.finishProduct)) ||
    ( this.frameProduct && ( !this.frameProduct.finishes || this.frameProduct.finishes.length === 0));
  }

  deserialize(data?: OldFrameLineData & FrameLineData) {
    this.data = data;
    if (this.data && this.data.style && this.data.style.code) {
      return FrameProduct.get(this.data.style.code).then(frame => {
        if (!frame.product) {
          throw new Error(`Frame with code [${this.data.style.code}] could not be found`);
        }
        if (this.data.finish && this.data.finish.code) {
          let variation = find(frame.finishes, f => f.code === this.data.finish.code);
          return FinishProduct.get(this.data.finish.code).then(finish => this.setFrameProduct(frame, finish, true));
        }
        else {
          return this.setFrameProduct(frame, null, true);
        }
      });
    }
    else {
      return this.q.resolve();
    }
  }

  refreshPrice() {
    let promises: ng.IPromise<any>[] = [];
    if (this.frameProduct) {
      promises.push(this.frameProduct.guaranteedPrice().then(p => {
        this.framePrice = p;
        this.price = this.framePrice + this.finishPrice;
      }));
    }
    if (this.finishProduct) {
      promises.push(this.finishProduct.guaranteedPrice().then(p => {
        this.finishPrice = p;
        this.price = this.framePrice + this.finishPrice;
      }));
    }
    return this.q.all(promises);
  }

  setFrameProduct(frame: FrameProduct, finish: FinishProduct, noTrigger = false) {
    this.product = this.frameProduct = frame;
    this.finishProduct = finish;
    this.data = {
      style: {
        code: frame.code,
        url: frame.previewUrl
      }
    };

    if (finish) {
      this.data = merge(this.data || {}, {
        finish: {
          code: finish.code,
          thumburl: finish.thumbnailUrl
        }
      });
    }

    if (frame) {
      this.description = frame.name;
      if (frame.finishes && frame.finishes.length && !finish) {
        this.description += `<span ui-sref="configuration.design.tab.frame"><md-icon md-svg-icon="alert"></md-icon> Please select a Finish</span>`;
      }
      if (finish) {
        this.description += ' in ' + finish.name;
      }
    }
    else {
      this.description = null;
    }

    return <ng.IPromise<void>>this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  serialize() {
    let line = super.serialize();

    line.data = {
      style: {
        code: this.data && this.data.style && this.data.style.code
      }
    };

    if (this.data && this.data.finish) {
      line.data.finish = {
        code: this.data.finish.code
      };
    }

    return line;
  }
}

linesTable[ConfigLineKinds.Frame] = FrameLine;
