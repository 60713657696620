// import { CustomerManagementModuleApi } from '../../virto/api/CustomerManagementModuleApi';
//  import { VirtoCommercePlatformApi } from '../../virto/api/VirtoCommercePlatformApi';
// import {
//    VirtoCommerceDomainCustomerModelContact,
//    VirtoCommercePlatformCoreSecurityApplicationUserExtended,
// } from '../../virto/model/models';
import app from '../app';
import map from 'lodash/map';

const settingsKey = 'Authentication:CurrentUser';
const anonCookie = 'vcf.anonymous-customer-id';
const userCookie = 'virto-javascript-shoppingcart-user-id';
const impersonartionCookie = 'virto-javascript-shoppingcart-impersonate-user-id';

interface Address {
  type: string;
  name: string;
  countryCode: string;
  countryName: string;
  city: string;
  postalCode: string;
  line1: string;
  line2: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

interface AccountResponse {
  userName: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  defaultShippingAddress: Address;
  addresses: Address[];
  dynamicProperties: string[];
  acceptsMarketing: false;
  isRegisteredUser: true;
  allowedStores: string[];
  userGroups: string[];
  id: string;
  operatorUserId: string;
}

export interface RegistrationInfo {
  email: string;
  password: string;
  phone?: string;
  firstName: string;
  lastName: string;
  company?: string;
}

interface UserInfo {
  email: string;
  firstName?: string;
  lastName?: string;
  id: string;
  admin: boolean;
  roles: string[];
}

export enum RegistrationErrors {
  emailInUse,
  other
}

@app.inject(
  // VirtoCommercePlatformApi,
  // CustomerManagementModuleApi,
  '$stateParams',
  '$q',
  '$rootScope',
  '$http',
  '$interval',
  '$cookies'
).service
export class AuthenticationService {

  private _userInfo: UserInfo = null;
  private _anonymousId: string;
  private _impersonatedId: string;
  private _impersonatedName: string;

  get capturedEmail() { return localStorage['userEmail']; }
  set capturedEmail(value: string) { localStorage['userEmail'] = value; }
  get capturedUserId() { return localStorage['userId']; }
  set capturedUserId(value: string) { localStorage['userId'] = value; }

  get loggedIn() { return this._userInfo !== null; }
  get isAdmin() { return this._userInfo && this._userInfo.admin; }
  get email() { return this._userInfo && this._userInfo.email; }
  get firstName() { return this._userInfo && this._userInfo.firstName; }
  get lastName() { return this._userInfo && this._userInfo.lastName; }
  get fullName() { return this._userInfo && `${this.firstName} ${this.lastName}`; }
  get userId() { return (this._userInfo && this._userInfo.id) || this.capturedUserId || this._anonymousId; }
  get userIdForCart() { return this._impersonatedId || this.userId; }
  get impersonatedName() { return this._impersonatedName; }
  get roles() { return this._userInfo ? this._userInfo.roles : []; }

  constructor(
   // private platformApi: VirtoCommercePlatformApi,
   //  private customerApi: CustomerManagementModuleApi,
    private $stateParams: ng.ui.IStateParamsService,
    private q: ng.IQService,
    private rootScope: ng.IRootScopeService,
    private $http: ng.IHttpService,
    $interval: ng.IIntervalService,
    private $cookies: ng.cookies.ICookiesService
  ) {
    // this.checkCookies();

    // if (location.port !== '9000') {
    //   // Check for login status every second
    //   $interval(this.checkCookies, 1000);
    // }
  }

  register(info: RegistrationInfo) {
    /*
    return this.$http.post<any>('/account/asyncregister', {
      email: info.email,
      firstName: info.firstName,
      lastName: info.lastName,
      password: info.password
    }).then(r => {
      if (r.data.succeeded) {
        return this.getUserDetails().then(() => {
          // Notify the frontend that the user has logged in.
          if (window.parent && window.parent.postEventMessage) {
            window.parent.postEventMessage({ message: 'loggedIn' });
          }
        });
      }

      if (r.data.errors && /already taken/.test(r.data.errors[0])) {
        return this.platformApi.securityGetUserByName(info.email).then(response => {
          if (!response.data) throw RegistrationErrors.other;

          this.capturedEmail = info.email;
          this.capturedUserId = response.data.id;
        });
      }

      throw RegistrationErrors.other;
    });
    */
  }

  login(email: string, password: string) {
    return this.$http.post<any>('/account/asynclogin', { password, email }).then(r => {
      if (r.data.status === 'Failure') {
        throw new Error('Login failed');
      }
      return this.getUserDetails();
    }).then(() => {
      // Notify the frontend that the user has logged in.
      if (window.parent && window.parent.postEventMessage) {
        window.parent.postEventMessage({ message: 'loggedIn' });
      }
    });
  }

  logout() {
    // Just "local" logout. The real thing is handled by the frontend
    this._userInfo = null;
    sessionStorage.removeItem(settingsKey);
    return this.$http.get<AccountResponse>('/account/logout');
  }

  impersonate(id: string) {
    this._impersonatedId = id;
    this._impersonatedName = null;

    /*
    if (id) {
      this.customerApi.customerModuleGetContactById(id)
        .then(response => {
          if (response.data) {
            this._impersonatedName = `${response.data.firstName} ${response.data.lastName}`;
          }
        });
    }
    */
  }

  changePassword(oldPassword: string, newPassword: string) {
    /*
    return  this.platformApi.securityChangePassword(this.email, {
      oldPassword,
      newPassword
    });
    */
  }

  /**
   * Some features are disabled on vendor sites for regular visitors
   */
  restricted() {
    // TODO: fix this
    return !!this.$stateParams['store'] && !this.isAdmin;

    // return !!this.$stateParams['store'] && (
    //   this.accountId() !== this.$stateParams['store'] &&
    //   ['Administrator', 'Webmaster', 'Editor',
    //     'FMT Manufacturing', 'FMT Administrative', 'FMT Executive'].indexOf(this.role()) < 0);
  }

  private checkCookies = () => {
    // Get all the cookies
    this._anonymousId = this.$cookies.get(anonCookie);
    let impersonatedId = this.$cookies.get(impersonartionCookie);

    if (this._anonymousId && this._userInfo) {
      // If previously logged in and getting an anon id, we've been logged out on the frontend
      this.logout();
    }
    else if (!this._anonymousId && !this._userInfo) {
      // If no anon id and not previously logged in, we must be logged in now so let's get the user details
      this.getUserDetails();
    }

    if (impersonatedId !== this._impersonatedId) {
      // When the impersonation cookie changes, update the status
      this.impersonate(impersonatedId);
    }
  }

  private getUserDetails() {
    return this.$http.get<AccountResponse>('/storefrontapi/account').then(r => {
      // First get the current user from the frontend API
      this._userInfo = {
        id: r.data.id,
        email: r.data.email,
        roles: [],
        firstName: r.data.firstName,
        lastName: r.data.lastName,
        admin: !!r.data.operatorUserId // If you could impersonate, you've got to be admin
      };

     // return this.platformApi.securityGetUserById(r.data.id);

      // the API returns more than the original model claims so we need to cast this.
      // return <ng.IHttpPromise<VirtoCommerceDomainCustomerModelContact>>this.customerApi.customerModuleGetMemberById(r.data.id);
    }).then((r) => {
      // Additional data needs to be retrieved from the backend API
      // this._userInfo.roles = map(r.data.roles, role => role.name);
     // this._userInfo.admin = this._userInfo.admin || r.data.isAdministrator;
      sessionStorage.setItem(settingsKey, JSON.stringify(this._userInfo));
    });
  }
}
