import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { BrandingInfo } from '../../services/brandingInfo';
import { LoginService } from '../../user/login/login.service';
import { AuthenticationService } from '../../user/authentication.service';
import app from '../../app';
import includes from 'lodash/includes';

@app.inject(
  '$mdSidenav',
  LoginService,
  AuthenticationService,
  '$mdToast',
  '$location',
  '$state',
  DesignConfigurationService
).component({
  templateUrl: 'configurator/layout/leftBar/leftBar.html',
  bindings: {
    brandingInfo: '<'
  }
}, 'leftBar')
export default class LeftBarController {

  user: any;
  brandingInfo: BrandingInfo;

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private loginService: LoginService,
    private authenticationService: AuthenticationService,
    private $mdToast: ng.material.IToastService,
    private $location: ng.ILocationService,
    private $state: ng.ui.IStateService,
    private designConfigurationService: DesignConfigurationService
  ) {
  }

  $onInit() { }

  close() {
    this.$mdSidenav('left').close();
  }

  login(ev: MouseEvent) {
    this.close();
    this.loginService.login(ev);
  }

  logout() {
    this.authenticationService.logout();
    this.close();
    this.$mdToast.showSimple('You have logged out');
  }

  changeDesign() {
    this.close();
    this.$state.go('configuration.frameType');
  }

  newDesign() {
    this.designConfigurationService.reset();
    this.close();
    this.$state.go('configuration.frameType', { configurationId: 'new' });
  }

  openConfiguration() {
    this.close();
    this.$state.go('configurationLibrary');
  }

  accountSettings() {
    this.close();
    this.$state.go('accountSettings');
  }

  isAuthenticated() {
    return this.authenticationService.loggedIn;
  }

  userName() {
    return this.authenticationService.fullName;
  }

  userRole() {
    // return this.$ftvUser.role();
  }

  showStoreAdmin() {
    return this.authenticationService.isAdmin ||
      includes(this.brandingInfo && this.brandingInfo.emails, this.authenticationService.email);
  }
}
