import { DesignConfiguration } from '../../../design/designConfiguration';
import app from '../../../app';
import angular from 'angular';
import clone from 'lodash/clone';
import { DesignTypes } from '~/configurator/design/designTypes';

export interface Speaker {
  hasspeakerbar?: boolean;
  makemodel?: string;
  mounted?: string;
  gap?: number;
  instructions?: string;
}

@app.inject('designConfiguration', '$state', '$scope').controller
export default class OptionsSpeakerController {

  speaker: Speaker;
  selected = false;
  deselected = false;

  speakerBarImage = window.fmtvAssetsRoot + 'assets/configurator/speakerbar.jpg';

  constructor(
    public configuration: DesignConfiguration,
    private $state: ng.ui.IStateService,
    $scope: ng.IScope
  ) {
    this.speaker = clone(configuration.speakerBar.data);
    if (this.speaker) {
      this.selected = this.speaker.hasspeakerbar;
      this.deselected = !this.speaker.hasspeakerbar;
    }

    $scope.$watch(() => this.selected || this.deselected, (val: boolean) => {
      if (val) {
        if (!this.speaker) {
          this.speaker = {};
        }
      }
      else {
        this.speaker = null;
      }
    });

    $scope.$on('$destroy', () => {
      if (!angular.equals(this.speaker, this.configuration.speakerBar.data)) {
        this.configuration.speakerBar.update(clone(this.speaker));
      }
    });
  }

  $onInit() { }

  // If they are choosing 86” on a TV Art Cover then the speaker bar accommodation
  //  section needs to be greyed out and warning text to say …
  // “This option is only available on 75” screen size and below.”
  showSizeLimit() {
      if ( this.configuration.type === DesignTypes.FrameWithArt ) {
        if (  this.configuration.tvModel.data.diagscreensize > 75 ) {
            return true;
        }
      }
      return false;
  }

  barSelected() {
    if (this.speaker) {
      this.speaker.hasspeakerbar = this.selected;
    }
    if (this.selected) {
      this.deselected = false;
    }
  }

  barDeselected() {
    if (this.speaker) {
      this.speaker.hasspeakerbar = !this.deselected;
    }
    if (this.deselected) {
      this.selected = false;
    }
  }

}
