
// import swiper from '~/angular-swiper/angular-swiper.module';
import app from '../../app';
import {SwiperContainer} from './swiper-container.directive';

@app.directive()
export class SwiperSlider {
definition( ): ng.IDirective {
    return {
        restrict: 'E',
        require: '^SwiperContainer',
        transclude: true,
        scope: {
          sliderCls: '@',
        },
        template: '<div class="swiper-slide {{sliderCls}}" ng-transclude></div>',
        replace: true
    };
  }
}
