import { LoadingIndicatorService } from '../loadingIndicator/loadingIndicator.service';
import { DesignConfigurationService } from '../design/designConfiguration.service';
import app from '../app';
import merge from 'lodash/merge';
import { DesignTypes } from '../design/designTypes';

const table: { [key: string]: any } = {
  frameonly: DesignTypes.FrameOnly as any,
  framewithmirror: DesignTypes.FrameWithMirror as any,
  framewithart: DesignTypes.FrameWithArt as any,
  framelessmirror: DesignTypes.FramelessMirror as any,
  metromirror: DesignTypes.MetroMirror as any,
  cutglass: DesignTypes.CutGlass as any
};

@app.inject('$state', '$stateParams', DesignConfigurationService, '$q', LoadingIndicatorService).controller
export class QuickStartController {
  constructor(
    $state: ng.ui.IStateService,
    $stateParams: ng.ui.IStateParamsService,
    designConfiguration: DesignConfigurationService,
    $q: ng.IQService,
    loadingIndicator: LoadingIndicatorService
  ) {
    if ($stateParams['type']) {
      const t: any = table[$stateParams['type'].toLowerCase()] || $stateParams['type'];

      loadingIndicator.show();
      designConfiguration
        .newDesign(t, $stateParams)
        .then(() => {
          loadingIndicator.hide();
          $state.go('configuration.tab.design', { configurationId: 'new' }, { reload: true });
        });
    }
    else {
      $state.go('configuration.frameType', merge({ configurationId: 'new' }, $stateParams), { reload: true });
    }
  }

  $onInit() { }
}
