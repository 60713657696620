import { BrandingInfo } from '../services/brandingInfo';
import app from '../app';

@app.inject('brandingInfo', '$mdMedia', '$scope').controller
export default class LayoutController {

  desktop: boolean;
  isMobile: boolean;
  vendor: boolean;

  constructor(
    public brandingInfo: BrandingInfo,
    $mdMedia: ng.material.IMedia,
    private $scope: ng.IScope,
  ) {
    this.desktop = $mdMedia('gt-sm');
    this.vendor = brandingInfo.id !== '0';
  }

   $onInit() {

   }

}
