// import * as Categories from '../apiCategories.service';
import { Product } from './product';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
import { ProductWithSamples } from './product-with-samples';
import { DesignTypes } from '../../configurator/design/designTypes';
import { SampleProduct } from './sample-product';

let listPromise: ng.IPromise<MirrorProduct[]>;

export class MirrorProduct extends ProductWithSamples {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = MirrorProduct.productsService.getAllMirrorProducts()
    .then(products =>  sortBy(products, p => p.sortPosition).map((p: any) => new MirrorProduct(p)));

  }

  static get(code: string) {
    return this.list().then(mirrors => find(mirrors, m => m.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  public layerUrl: string;

  //  ???????????????????
  public longDescription: string;
  private _factor: number;
  private _manualPriceAdjustment = 1.325; // adjustment to the shopify price .. should be done in shopify but done here for now
                                         // was 1.06 but now increased 25% more so

  constructor( data: any ){
    super(data);
    this._factor = data.factor;
    this.layerUrl = data.layerUrl;
    if ( data.mirrorSample ) {
      this.addSample(new SampleProduct(data.mirrorSample));
    }
  }
  get metro() {
    return this.category === 'M';
  }

  // thumbnailUrl moved to super

  get factor(): number {
    return this._factor;
  }

  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    let extra = 0;
    if (config.type === <any>DesignTypes.FramelessMirror) {
      // Special markups for floating mirrors
      tvSize += 10;
      extra = 400;
    }
    let factor: number = this._factor * 1;

    if (tvSize >= 75) {
      factor += 8;
    }
    else if (tvSize >= 52) {
      factor = factor + 4;
    }

    return this.basePrice().then(b => Math.ceil( (b + extra + factor * tvSize ) * this._manualPriceAdjustment));
  }
}
