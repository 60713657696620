import { DesignConfigurationService } from '../../../design/designConfiguration.service';
import { BrandingInfo } from '../../../services/brandingInfo';
import app from '../../../app';
import { BrandingService } from '../../../services/branding.service';
import { HubspotService } from '../../../services/hubspot.service';
import { AuthenticationService } from '../../../user/authentication.service';
import { CaptureFormService } from './../captureForm.service';
import { VendorLoginController } from './vendorLogin.controller';
import { DesignTypes } from '../../../design/designTypes';

@app.inject(
  '$timeout',
  '$state',
  HubspotService,
  DesignConfigurationService,
  CaptureFormService,
  '$mdDialog',
  '$mdMedia',
  AuthenticationService,
  BrandingService,
  '$scope'
).component({
  templateUrl: 'configurator/configuration/captureForm/vendor/vendorCaptureForm.html',
})
export class VendorCaptureForm {
  currentStep: number;

  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  needBy: string;
  question: string;
  referrer: string;
  referral: string;

  branding: BrandingInfo;

  continuing = false;

  private firstStepForm: ng.IAugmentedJQuery;
  private secondStepForm: ng.IAugmentedJQuery;

  constructor(
    private timeout: ng.ITimeoutService,
    private state: ng.ui.IStateService,
    private hubspot: HubspotService,
    private config: DesignConfigurationService,
    private captureFormService: CaptureFormService,
    private mdDialog: ng.material.IDialogService,
    private mdMedia: ng.material.IMedia,
    private auth: AuthenticationService,
    brandingService: BrandingService,
    $scope: ng.IScope
  ) {
    brandingService.infoPromise.then(i => this.branding = i);

    hubspot.createForm('8947992f-ecc4-455d-94d5-13e31da2254b').then(f => this.firstStepForm = f);
    hubspot.createForm('6f0d8a70-3a67-4e7f-9689-be92b374f6f8').then(f => this.secondStepForm = f);

    $scope.$watch(() => captureFormService.visible, visible => {
      if (visible) {
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phoneNumber = null;
        this.needBy = null;
        this.question = null;

        this.currentStep = 1;

        if (this.auth.loggedIn) {
          this.config.save().then(() => this.finish());
        }
      }
    });
  }

  $onInit() { }

  continueFirstStep() {

    if (this.email === this.branding.contactEmail) {
      this.continuing = true;
      this.mdDialog.show({
        templateUrl: 'configurator/configuration/captureForm/vendor/vendorLogin.html',
        controller: VendorLoginController,
        controllerAs: '$ctrl',
        locals: {
          userName: this.email
        },
        bindToController: true,
        fullscreen: this.mdMedia('xs')
      } as any).then(() => this.config.save())
        .then(() => this.finish())
        .catch(() => this.continuing = false);
    }
    else {
      this.config.designConfiguration.name = `Design for ${this.firstName} ${this.lastName}`;
      this.config.designConfiguration.adminNotes = `Phone: ${this.phoneNumber} - Email: ${this.email}`;

      this.config.save()
        .then(configId => {
          this.timeout(() => {
            this.hubspot.setValue(this.firstStepForm, 'firstname', this.firstName);
            this.hubspot.setValue(this.firstStepForm, 'lastname', this.lastName);
            this.hubspot.setValue(this.firstStepForm, 'phone', this.phoneNumber);
            this.hubspot.setValue(this.firstStepForm, 'email', this.email);
            this.hubspot.setValue(this.firstStepForm, 'saved_design_id', configId);
            this.hubspot.setValue(this.firstStepForm, 'saved_design_url', `https://framemytv.com/apps/designer/configuration/${configId}/design?store=${this.branding.id}`);
            this.hubspot.setValue(this.firstStepForm, 'hs_persona', 'persona_6');
            this.hubspot.setValue(this.firstStepForm, 'new_account', true);
            this.hubspot.setValue(this.firstStepForm, 'store_name', this.branding.title);
            this.hubspot.setValue(this.firstStepForm, 'store_contact', this.branding.contactName);
            this.hubspot.setValue(this.firstStepForm, 'store_email', this.branding.contactEmail);
            this.hubspot.setValue(this.firstStepForm, 'store_id', this.branding.id);
            this.hubspot.setValue(this.firstStepForm, 'store_street', this.branding.address1);
            this.hubspot.setValue(this.firstStepForm, 'store_city', this.branding.city);
            this.hubspot.setValue(this.firstStepForm, 'store_state', this.branding.state);
            this.hubspot.setValue(this.firstStepForm, 'store_color', this.branding.settings.storeColor);
            this.hubspot.setValue(this.firstStepForm, 'store_logo', this.branding.logo);
            this.hubspot.setValue(this.firstStepForm, 'store_phone', this.branding.phone);
            this.hubspot.setValue(this.firstStepForm, 'saved_design_amount', this.config.designConfiguration.totalWithShipping);
            this.hubspot.setValue(this.firstStepForm, 'saved_design_type', this.designType());
            this.firstStepForm.submit();
          });
        });
      this.currentStep = 2;
    }
  }

  continueSecondStep() {
    this.config.designConfiguration.adminNotes = this.config.designConfiguration.adminNotes + ` - Needed by: ${this.needBy} - Question: ${this.question}`;

    this.timeout(() => {
      this.config.save();
    });

    this.hubspot.setValue(this.secondStepForm, 'email', this.email);
    this.hubspot.setValue(this.secondStepForm, 'need_by', this.needBy);
    this.hubspot.setValue(this.secondStepForm, 'saved_design_message', this.question);
    this.hubspot.setValue(this.secondStepForm, 'referral_source', this.referrer);
    this.hubspot.setValue(this.secondStepForm, 'how_did_you_hear_about_us_', this.referral);
    setTimeout(() => {
      // Looks like a timeout is good for the submit, don't ask me why...
      this.secondStepForm.submit();
    });

    this.finish();
  }

  cancel() {
    this.captureFormService.visible = false;
  }

  private finish() {
    if (this.branding.settings.optionsTab) {
      this.captureFormService.visible = false;
      this.state.go('configuration.tab.options');
    }
    else if (this.branding.settings.summaryTab) {
      this.captureFormService.visible = false;
      this.state.go('configuration.tab.summary');
    }
    else {
      this.currentStep = 3;
    }
  }

  private designType() {
    switch (this.config.designConfiguration.type) {
      case DesignTypes.FrameWithArt:
        return 'Art';
      case DesignTypes.FrameWithMirror:
      case DesignTypes.MetroMirror:
      case DesignTypes.FramelessMirror:
        return 'Mirror';
      case DesignTypes.FrameOnly:
        return 'Frame_Only';
    }
  }
}
