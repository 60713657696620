import { InstallationMethodProduct } from '../../../api-shopify/product-models/installation-method-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import clone from 'lodash/clone';

export interface InstallationVariant {
  id?: number;
  name?: string;
  image?: string;
}

export interface InstallationLineData {
  type?: string;
  height?: number;
  width?: number;
  recess?: string;
  recessheight?: number;
  recesswidth?: number;
  recessdepth?: number;
  code?: string;
  name?: string;

  depth?: InstallationVariant;
  mount?: InstallationVariant;
  wall?: InstallationVariant;
  method?: any;
}

export class InstallationLine extends BaseLine<InstallationLineData> {
  kind = ConfigLineKinds.Installation;
  category = 'Options';
  name = 'Installation Method';
  order = 100;
  editState = 'configuration.tab.options.installation';
  product: InstallationMethodProduct;
  scrollToElementId = 'installation-method-option-scroll-to';

  get valid() { return !!this.data && !!this.data.code; }

  deserialize(data?: InstallationLineData) {
    this.data = data;
    if (this.data) {
      // Convert install method id from old configuration format
      if (!this.data.code && this.data.method && this.data.method.installmethodid) {
        this.data.code = 'INSTALL-' + this.data.method.installmethodid;
        this.data.name = this.data.method.name;
        this.data.type = this.data.method.mountType;
      }

      // Fix values when coming from old API
      this.data.depth = this.fixVariant(this.data.depth);
      this.data.mount = this.fixVariant(this.data.mount);
      this.data.wall = this.fixVariant(this.data.wall);
      this.description = this.setDescription(this.data);
      if (this.data && this.data.code) {
        InstallationMethodProduct.get(this.data.code).then( (installProduct: any) => this.product = installProduct);
      }

      return this.refreshPrice();
    }
    else {
      return this.q.resolve();
    }
  }

  update(data: InstallationLineData) {
    this.description = this.setDescription(data);
    this.refreshPrice();
    this.data = clone(data);
    this.triggerUpdate();
  }

  refreshPrice() {
    let code = this.data && this.data.code;
    if (code) {
      return InstallationMethodProduct.get(code)
        .then(pr => {
          let temp = pr.guaranteedPrice();
          return temp;
        })
        .then(p => {
          return this.price = p;
        });
    }
    return this.q.resolve();
  }

  private fixVariant(v: any): any {
    if (v === 'null') {
      return null;
    }

    if (v && v.id && typeof v.id === 'string') {
      v.id = parseInt(v.id);
    }
    return v;
  }

  private setDescription(install: InstallationLineData) {
    if (!install.code) {
      return '';
    }

    let t: string;

    if (install.depth) {
      t = install.depth.name;

      if (install.depth.id === 2) {
        if (install.recess) {
          t += '<br>The recess already exists';
          if (install.recesswidth) {
            t += `, width: ${install.recesswidth} in.`;
          }
          if (install.recessheight) {
            t += `, height: ${install.recessheight} in.`;
          }
          if (install.recessdepth) {
            t += `, depth: ${install.recessdepth} in.`;
          }
        }
      }
    }

    if (install.mount) {
      t += '<br>' + install.mount.name;
    }

    if (install.wall) {
      t += '<br>' + install.wall.name;
    }

    if (install.name) {
      t += `<br>Mounted with ${install.name}`;
    }

    return t;
  }
}

linesTable[ConfigLineKinds.Installation] = InstallationLine;
