import lib from '~/lambdas/module';
import { ProductInfo } from './product-info';
import { ProductForWishList } from './product-for-wishlist';
import angular from 'angular';
export interface CreateProductResult {
  id: number;
  sku: string;
  wishlist: ProductForWishList;
}

@lib.inject('$http').service
export class LambdasService {
  constructor(
    private $http: ng.IHttpService
  ) { }

  createProduct(product: ProductInfo,) {
    return this.$http.post<CreateProductResult>(
      window.shopifyCacheRoot + '/functions/create-product',
      product
    ).then(r => r.data).catch( err => {
      console.log( err );
    });
  }

  getProduct(sku: string) {
    // increase timeout because this call is proxied through to shopify and may take longer than expected
    try {
      return this.$http.get<any>(`${window.shopifyCacheRoot}/functions/get-product/sku/${sku}`, {timeout:15000})
      .then(r => {
        return r.data;
      });
    } catch ( err ) {
      console.error( JSON.stringify(err));
      throw err;
    }
  }

  getVariantDetails( variantId: number ) {
    return this.$http.get<any>(`${window.shopifyCacheRoot}/functions/get-variant-details/${variantId}`)
    .then(r => {
      return r.data;
    });
  }

  downloadPdfFile( product: any, showPrices: boolean ) {

    return this.$http.post<any>(`${window.shopifyCacheRoot}/functions/download-config-as-pdf?showPrices=${showPrices}`, product, {responseType: 'blob'})
      .then( response => {
          const blob = response.data;
          const blob2 = new Blob([response.data]);
          return blob;
      });

  }

  downloadPdf( product: any ) {
    return this.$http.post<any>(`${window.shopifyCacheRoot}/functions/download-config-as-pdf`, product, {responseType: 'blob'})
    .then( response => {
        const blob = response.data;
        this.startBlobDownload(blob, 'fmtv_design.pdf');
    });
  }

  private startBlobDownload( dataBlob: any, suggestedFileName: any ) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
       // for IE
       window.navigator.msSaveOrOpenBlob(dataBlob, suggestedFileName);
    } else {
       // for Non-IE (chrome, firefox etc.)
       const urlObject = URL.createObjectURL(dataBlob);

       const downloadLink = angular.element('<a>Download</a>');
       downloadLink.css('display','none');
       downloadLink.attr('href', urlObject);
       downloadLink.attr('download', suggestedFileName);
       angular.element(document.body).append(downloadLink);
       (downloadLink[0] as any).click();

       // cleanup
       downloadLink.remove();
       URL.revokeObjectURL(urlObject);
   }
 }

}
