import app from '../../../app';

import { EdgeFinishProduct } from '../../../../api-shopify/product-models/edge-finish-product';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', OptionsService).controller
export class OptionsEdgeFinishingController {

  products: EdgeFinishProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private optionsService: OptionsService
  ) {
    EdgeFinishProduct.list().then(p => this.products = p);
  }

  $onInit() { }

  select(product: EdgeFinishProduct) {
    this.configuration.edgeFinish.setProduct(product);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
   //   this.configuration.save();
    }
    this.optionsService.nextOption();
  }
}
