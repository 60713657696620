import { DesignConfiguration } from '../../../design/designConfiguration';
import { LinerProduct } from '../../../../api-shopify/product-models/liner-product';
import app from '../../../app';
import remove from 'lodash/remove';
import { DesignTypes } from '../../../design/designTypes';

@app.inject(
  '$state',
  '$scope',
).component({
  bindings: {
    configuration: '<',
},
    templateUrl: 'configurator/configuration/design/liner-bar/liner-bar.html'
})
export default class LinerBarComponent {
  liners: LinerProduct[];
  configuration: DesignConfiguration;
  showHardwoodNoLinerNotice: boolean = false;
  isMirrorForNoLinerMessage: boolean = false;

  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope
  ) {

  }

  $onInit() {

    this.$scope.$watch(() => this.configuration.frame.frameProduct, p => {
      // this is not currently used for liners but including it for future use if needed
      // const viewMore = new LinerProduct( {sku:"viewmore", layerUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', thumbnailUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', previewUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png'});
      LinerProduct.forCategory(this.configuration.frame.frameProduct.categoryCode())
      .then(liners => {
        this.liners = liners;
        this.showHardwoodNoLinerNotice = false;
        // this.liners.push( viewMore );
        // if switching to/or from metro and another frame type, need to set a default liner or try to
        // copy the same liner in the other style
        if (this.configuration.liner && this.configuration.liner.data && this.configuration.liner.data.code ){

          const selectedSku = this.configuration.liner.linerProduct.sku;
          let skuToFind: string = null;
          if (  this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.isHardwood()
                 && ( this.configuration.type === DesignTypes.FrameWithMirror || this.configuration.type === DesignTypes.FrameOnly )){
            this.showHardwoodNoLinerNotice = true;
            this.isMirrorForNoLinerMessage = (this.configuration.type === DesignTypes.FrameWithMirror );
          }

          if ( this.configuration.frame.frameProduct.isMetro() ) {
            if ( !this.configuration.liner.linerProduct.metro ) {
                skuToFind = selectedSku + '_METRO';
                // map old liner skus to they can switch color
                if ( selectedSku === 'LINER-1') skuToFind = 'LINER-6'; // linen
                if ( selectedSku === 'LINER-2') skuToFind = 'LINER-7'; // white
                if ( selectedSku === 'LINER-3') skuToFind = 'LINER-8'; // black
                if ( selectedSku === 'LINER-4') skuToFind = 'LINER-9'; // black
                // find the same liner color in metro or default to black if none found
            }
          }

          if ( !this.configuration.frame.frameProduct.isMetro() ) {
            if ( this.configuration.liner.linerProduct.metro ) {
              skuToFind = selectedSku.replace( '_METRO', '');
              // map old liner skus to they can switch color
              if ( selectedSku === 'LINER-6') skuToFind = 'LINER-1'; // linen
              if ( selectedSku === 'LINER-7') skuToFind = 'LINER-2'; // white
              if ( selectedSku === 'LINER-8') skuToFind = 'LINER-3'; // black
              if ( selectedSku === 'LINER-9') skuToFind = 'LINER-4'; // black
            }
            if ( this.configuration.liner.linerProduct.hardwood && this.configuration.type === DesignTypes.FrameWithMirror) {
              if ( !this.configuration.liner.linerProduct ) { // if liner is already selected don't auto-set no-liner
              this.select( this.liners.find( ( l: LinerProduct) => l.sku === 'LINER-5' ) );
              }
            }
          }
          if ( skuToFind ) {
            let sameColorLiner = liners.find( ( l: LinerProduct) => l.sku === skuToFind );
            if ( sameColorLiner ) {
              this.select( sameColorLiner );
            } else {
              if ( this.configuration.frame.frameProduct.isMetro() ) {
                // metro black
                this.select( this.liners.find( ( l: LinerProduct) => l.sku === 'LINER-8' ) );
              } else {
                // non-metro black
                this.select( this.liners.find( ( l: LinerProduct) => l.sku === 'LINER-3' ) );
              }
            }
          }
        }
        // if no liner selected set a default
        if ( !this.configuration.liner.linerProduct ) {
          if ( this.configuration.frame.frameProduct.isMetro() ) {
            // metro black
            this.select( this.liners.find( ( l: LinerProduct) => l.sku === 'LINER-8' ) );
          } else {
            // non-metro black
            this.select( this.liners.find( ( l: LinerProduct) => l.sku === 'LINER-3' ) );
          }
        }

        if (this.configuration.type !== DesignTypes.FrameOnly && this.configuration.type !== DesignTypes.FrameWithMirror) {
          remove(this.liners, l => l.product.code === 'LINER-5');
        }
        // frame only has 'no liner' only when the frame type is hardwood.  This is adjusting the filter to  remove 'no liner' from metro which is getting served server side.
        if ( this.configuration.type === DesignTypes.FrameOnly && this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.isMetro()) {
          remove(this.liners, l => l.product.code === 'LINER-5');
        }

        // if the config has 'no liner' make sure it is the first in the list
        let noLinerIdx = this.liners.findIndex( l => l.product.code === 'LINER-5' );
        if ( noLinerIdx > 0 ) {
          let noLiner = this.liners.splice( noLinerIdx,1 );
          this.liners.unshift( noLiner[0] );
        }
      });
    });
  }

  price(liner: LinerProduct) {
    // return this.configuration.linerPrice(liner);
  }

  select(liner: LinerProduct) {
    this.configuration.liner.setProduct(liner);
  }

  // formats the price on a thumbnail item
  formatItemPrice( item: LinerProduct ) {
    if ( !item.price ) return '';
    return '$' + item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
