import app from '../app';

@app.inject('$state').controller
export default class ErrorController {

  message: string;// route param
  configurationId: string; // route param
  configToSearch: string;

  constructor(
    private $state: ng.ui.IStateService,
  ) {
    this.message = $state.params['message'];
    this.configurationId = $state.params['configurationId'];
  }
  openConfigId(){
    if ( this.configToSearch ) {
      const url =  this.$state.href('configuration', { configurationId: this.configToSearch });
      window.location.href = url + '/design?t=' + new Date().getTime();
    }
  }
  reloadFailedConfig(){
    if ( this.configurationId ) {
      const url =  this.$state.href('configuration', { configurationId: this.configurationId });
      window.location.href = url + '/design?t=' + new Date().getTime();
    }
  }
  $onInit() { }
}
