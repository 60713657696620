import angular from 'angular';
import defaults from 'lodash/defaults';
import { LambdasService } from '~/lambdas/lambdas.service';
import app from '../app';
import { LoadingIndicatorService } from '../loadingIndicator/loadingIndicator.service';
import { BrandingService } from '../services/branding.service';
import { BrandingInfo } from '../services/brandingInfo';
import { AuthenticationService } from '../user/authentication.service';
import { LoginService } from '../user/login/login.service';
import { CopyLinkController } from './copy-link/copyLink.controller';
import { DesignConfiguration } from './designConfiguration';
import { SaveDialogController } from './saveDialog.controller';
import { SaveDesignStep2 } from '../configuration/save-design-step2/save-design-step2.component';
import { SaveDesignStep2Service } from '../configuration/save-design-step2/save-design-step2.service';
import { SaveDesignService } from '../configuration/save-design/save-design.service';
import { BaseLine } from './lines';

const settingsKey = 'designConfiguration';

@app.inject(
  '$q',
  '$timeout',
  '$state',
  LoadingIndicatorService,
  '$mdDialog',
  '$mdMedia',
  AuthenticationService,
  LoginService,
  BrandingService,
  'clipboard',
  LambdasService,
  SaveDesignService,
  SaveDesignStep2Service,
  '$rootScope',
  '$cookies'
).service
export class DesignConfigurationService {

  get designConfiguration() {
    return this._designConfiguration;
  }

  private _designConfiguration: DesignConfiguration;
  private branding: BrandingInfo;

  constructor(
    private q: ng.IQService,
    private timeout: ng.ITimeoutService,
    private state: ng.ui.IStateService,
    private loadingIndicator: LoadingIndicatorService,
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    brandingService: BrandingService,
    private clipboard: any,
    private lambdaService: LambdasService,
    private saveDesignService: SaveDesignService,
    private saveDesignStep2Service: SaveDesignStep2Service,
    private $rootScope: ng.IRootScopeService,
    private $cookies: ng.cookies.ICookiesService

  ) {
    brandingService.infoPromise.then(b => this.branding = b);
  }

  reset() {
    this.replace(new DesignConfiguration());
  }

  newDesign(type: string, params?: any) {
    return this.replace(new DesignConfiguration(type as any))
      .then(() => {
        if (params) {
          let q = this.q.resolve();

          if (params['size']) {
            q = q.then(() => this.designConfiguration.tvModel.deserialize({ diagscreensize: parseInt(params['size']), speakerlayout: 'M' }));
          }

          if (params['frame']) {
            q = q.then(() => this.designConfiguration.frame.deserialize({ style: { code: params['frame'] } } as any));
          }

          if (params['art']) {
            q = q.then(() => this.designConfiguration.artwork.deserialize({ code: params['art'] }));
          }

          if (params['mirror']) {
            q = q.then(() => this.designConfiguration.mirror.deserialize({ code: params['mirror'] }));
          }

          if (params['liner']) {
            q = q.then(() => this.designConfiguration.liner.deserialize({ code: params['liner'] }));
          }

          return q.then(() => this.designConfiguration.refresh(false));
        }
      });
  }

  /**
   * Figures out where to load a config from based on the id
   */
  load(id: string) {
    if (this._designConfiguration && this._designConfiguration.number === id) {
      // If we already have the config loaded, nothing to do!
      return this.q.resolve(this._designConfiguration);
    }
   // if loading an existing config, the id will be set.. want to load it from shopify
   // rather than local storage, hence why order is changed since last commit .. this now allows the link to optionally update the config.
    if (id && id !== 'new') {
      // If there is still no match and the id is not "new", we load from the API
      this.loadingIndicator.show();
      try {
        return this.lambdaService.getProduct(id)
        .then(c => DesignConfiguration.deserialize(c))
        .then(c => {
          this.replace(c);
          this.loadingIndicator.hide();
          return c;
        }, () => {
          this.loadingIndicator.hide();
          this.timeout(() => {
            this.state.go('error', { message: `The requested configuration (id: ${id}) could not be found or loaded. If you just saved a configuration, please try loading it again in a minute.  We have been notified of the error.`, configurationId: id });
            window.trackJs.addMetadata('configurationId', id);
            throw new Error('Failed to load product configuration');
          });
        });
      } catch (err ) {
        console.log( JSON.stringify(err));
        throw err;
      }
    }

    // this loads the config from localstorage - i.e. has not been saved yet
    const jsonData = localStorage.getItem(settingsKey);
    if (jsonData !== null) {
      // Then we check the cached copy in localStorage
      const data = JSON.parse(jsonData);
      if (data.number === id || (id === 'new' && !data.number)) {
        // For simplicity, the ids have to be compared before the async process is started
        this.loadingIndicator.show();

        return DesignConfiguration.deserialize(data, true).then(designConfiguration => {
          this.loadingIndicator.hide();

          return this.replace(designConfiguration, false).then( () => {
            return this.q.resolve(designConfiguration);
          });
        });
      }
    }

    // Default to new config, send user to select the type
    this.replace(new DesignConfiguration());
    return this.q.resolve();
  }

  save( email: string ='', firstname: string ='') {
    const hutk = this.$cookies.get('hubspotutk');

    return this.designConfiguration.save( email, firstname, hutk ).then(result => {
      this.designUpdated();
      return this.state.transitionTo(
        this.state.current.name,
        defaults({ configurationId: this.designConfiguration.number }, this.state.params),
        { notify: false, reload: false }
      ).then(() => result.id);
    })
    .catch(() => {
      window.alert('There was an error saving your design. Please contact us if the problem persists.');
      throw Error('Save failed');
    });
  }

  showLink(e?: MouseEvent) {
    this.$mdDialog.show({
      parent: angular.element('#fmtv-app'),
      templateUrl: 'configurator/design/copy-link/copyLink.html',
      controller: CopyLinkController,
      controllerAs: '$ctrl',
      clickOutsideToClose: true,
      locals: {
        configurationUrl: this.designConfiguration.url,
        configurationId: this.designConfiguration.number
      }
    });
  }

  showSaveDialog(e?: MouseEvent) {
    if (!this.designConfiguration.dirty && !this.designConfiguration.isNew) {
      this.$mdDialog.show(
        this.$mdDialog.alert()
          .parent(angular.element('#fmtv-app'))
          .clickOutsideToClose(true)
          .title('Nothing to save')
          .textContent("Your configuration hasn't changed since you last saved it.")
          .ariaLabel('Already saved configuration')
          .ok('Ok')
          .targetEvent(e)
      );
      return;
    }

    let q = this.q.resolve<any>(null);
    if (this.authenticationService.loggedIn || this.authenticationService.capturedEmail) {
      q = q
        .then(() => this.loadingIndicator.show())
        .then(() => this.save());
    }
    else {
      const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
      q = q.then(() => this.$mdDialog.show({
        templateUrl: 'configurator/design/saveDialog.html',
        controller: SaveDialogController,
        controllerAs: '$ctrl',
        parent: angular.element('#fmtv-app'),
        targetEvent: e,
        clickOutsideToClose: true,
        fullscreen: useFullScreen
      }));
    }

    q.then(() => {
      const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
      this.$mdDialog.show({
        controller: 'SavedController',
        controllerAs: '$ctrl',
        templateUrl: 'configurator/design/saved/saved.html',
        parent: angular.element('#fmtv-app'),
        targetEvent: e,
        clickOutsideToClose: true,
        fullscreen: useFullScreen
      });
    });

    q.finally(() => this.loadingIndicator.hide());
  }

  email(configurationId: string) {
    location.href = `mailto:?subject=FrameMyTV.com%20Custom%20Frame%20Configuration%20${configurationId}&body=Configuration%20ID:%20${configurationId}%0D%0AView/%20Edit%20/%20Purchase:%20https://framemytv.com/app/configuration/${configurationId}/summary`;
  }

  emptyCache() {
    this.replace(null);
    localStorage.removeItem(settingsKey);
  }

  // this should be private but need access to it for the print.controller.js
  public designUpdated = () => {
    let stringConfig = JSON.stringify(this._designConfiguration.serialize());
    localStorage.setItem(settingsKey, stringConfig );
    this.$rootScope.$emit( 'design-changed');
  }

  public replace(design: DesignConfiguration, updateLastSaved = true) {
    if (this._designConfiguration) {
      this._designConfiguration.onUpdate(null);
    }

    this._designConfiguration = design;

    if (design) {
      return design.refresh(updateLastSaved).then(() => {
        this._designConfiguration.onUpdate(this.designUpdated);
        this.designUpdated();
        return this.q.resolve();
      });
    }
    else {
      return this.q.resolve();
    }
  }
}
