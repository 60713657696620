import app from '../app';

@app.directive('evalAttrAsExpr')
export class EvalAttrAsExpr {
  /*
   * This directive is a workaround for the md-component-id attribute of the
   * mdSidenav directive.
   *
   * The mdSidenav directive, in its controller function, registers the element
   * using the md-component-id attribute. If we need this value to be an
   * expression to be evaluated in the scope, we can't do
   *
   * <md-sidenav md-component-id="{{ expr }}" [...]>
   *
   * because the curly braces are replaced in a subsequent stage. To work around
   * this, this directive replace the value of md-component-id with the value of
   * that expression in the scope. So the previous example becomes
   *
   * <md-sidenav md-component-id="expr" eval-attr-as-expr="mdComponentId" [...]>
   */
  definition() {
    return {
      restrict: 'A',
      controller: ['$scope', '$element', '$attrs', (
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes
      ) => {
        let attrToEval = $attrs['evalAttrAsExpr'];
        $attrs[attrToEval] = $scope.$eval($attrs[attrToEval]);
      }],
      priority: 9999
    };
  }
}
