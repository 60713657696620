import { AuthenticationService } from '../user/authentication.service';
import { DesignConfigurationService } from '../design/designConfiguration.service';
import app from '../app';

@app.inject(DesignConfigurationService, AuthenticationService, '$rootScope').service
export class FrameCommunicationService {
  constructor(
    designConfigurationService: DesignConfigurationService,
    authenticationService: AuthenticationService,
    rootScope: ng.IRootScopeService
  ) {
    window.addEventListener('message', event => {
      switch (event.data.message) {
        case 'getConfigId':
          let data = event.data;
          if (designConfigurationService.designConfiguration) {
            data.id = designConfigurationService.designConfiguration.number;
          }
          //  ???????????????????
          event.source.postMessage(data, '*');
      }
    });

    rootScope.$on('auth:userChanged', (event, args) => {
      this.loggedIn(args);
    });
  }

  openCart() {
    if (window.top !== window.self) {
      window.parent.postEventMessage({ message: 'openCart' });
    }
    else {
      location.href = `//${location.hostname}/?action=cart`;
    }
  }

  private loggedIn(id: string) {
    if (window.parent && window.parent.postEventMessage) {
      window.parent.postEventMessage({ message: 'loggedIn' });
    }
  }
}
