import { LinerProduct } from '../../../api-shopify/product-models/liner-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import find from 'lodash/find';

export interface OldLinerLineData {
  base?: string;
  caption?: string;
  category?: string;
  factor?: string;
  linerid?: string;
  previewimage?: string;
  thumburl?: string;
  url?: string;
}
export interface LinerLineData {
  code: string;
}

export class LinerLine extends BaseLine<OldLinerLineData & LinerLineData> {
  kind = ConfigLineKinds.Liner;
  category = 'Design';
  name = 'Liner Color';
  order = 40;
  editState = 'configuration.tab.design.liner';
  scrollToElementId = 'liner-option-scroll-to';

  linerProduct: LinerProduct;
  product: LinerProduct;

  get valid() { return !!this.linerProduct; }

  deserialize(data?: OldLinerLineData & LinerLineData) {
    this.data = data;

    if (this.data && this.data.code) {
      return LinerProduct.get(this.data.code).then(l => this.setProduct(l, true));
    }
    else {
      return this.q.resolve();
    }
  }

  refreshPrice() {
    if (this.linerProduct) {
      return this.linerProduct.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }

  setProduct(product: LinerProduct, noTrigger = false) {
    this.product = this.linerProduct = product;
    this.data = {
      code: product.code,
      thumburl: product.thumbnailUrl,
      caption: product.name
    };

    // let preview = find(product.product.images, i => i.name.indexOf('FMT') === 0);
    let preview = product.layerUrl;
    if (preview === ''){
      // angular is not removing the image when the url is '' (i.e. no liner).  The following is a workaround
      preview = '//:0';
    }
    this.preview = {
      // image: preview && preview.url,
      image: preview,
      index: 3
    };

    this.description = product.name;
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  serialize() {
    let line = super.serialize();
    line.data = {
      code: this.data && this.data.code
    };
    return line;
  }
}

linesTable[ConfigLineKinds.Liner] = LinerLine;
