// import { ApiProductsService } from '~/api/apiProducts.service';
import app from '~/configurator/app';
import { DesignConfiguration } from '~/configurator/design/designConfiguration';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import { FrameCommunicationService } from '~/configurator/services/frameCommunication.service';
import { AuthenticationService } from '~/configurator/user/authentication.service';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';
import { LambdasService } from '~/lambdas/lambdas.service';
import { CssAnimationService } from '../services/css-animation.service';
// import { ShoppingCartModuleApi } from '~/virto/api/ShoppingCartModuleApi';

// Using string because rollup is having a hard time ordering things today
@app
  .inject(
    'FrameCommunicationService',
    // ShoppingCartModuleApi,
    AuthenticationService,
    // ApiProductsService,
    LoadingIndicatorService,
    DesignConfigurationService,
    '$http',
    '$q',
    LambdasService,
    CssAnimationService,
    '$rootScope'
  )
  .service
export class CartService {

  constructor(
    private frameCommunicationService: FrameCommunicationService,
    // private cartApi: ShoppingCartModuleApi,
    private authenticationService: AuthenticationService,
    // apiProductsService: ApiProductsService,
    private loadingIndicator: LoadingIndicatorService,
    private designConfigurationService: DesignConfigurationService,
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private lambdaService: LambdasService,
    private $cssAnimationService: CssAnimationService,
    private $rootScope: ng.IRootScopeService,
  ) {
  }

  // /storefrontapi/cart/additem
  addSampleProduct(sample: SampleProduct, redirectToCart: boolean = false, showLoader: boolean = true) {
    if (showLoader )  {
      this.loadingIndicator.show();
    }

    let q = this.$q.resolve() as ng.IPromise<any>;
    return this.getItemInventoryQuantity( sample.id).then( quantity => {
      if ( showLoader ) {
        this.loadingIndicator.hide();
      }
      if ( quantity === 0 ){
        return Promise.reject( sample.name + ' ' + sample.sku + ' is out of inventory.  The item was not added to your cart.');
      } else {
        return Promise.resolve();
      }
    }).then( () => this.$http.post('/cart/add.js', {
        quantity: 1,
        id: sample.id
      })).then(( result: any) => {
        if ( redirectToCart ) {
          window.location.href = '/cart';
        }
      }).finally( ( ) => {
        this.loadingIndicator.hide();
      });
  }

  addSampleProducts(samples: SampleProduct[], redirectToCart: boolean = false) {
    this.loadingIndicator.show();

    // shopify add to cart must be called syncronously or results are mixed ..i.e. don't use promise.all
    return samples.reduce( ( previousPromise: any, nextSample: SampleProduct ) => {
      return previousPromise.then(() => {
        return this.addSampleToCart(nextSample);
      });
    }, Promise.resolve()).then( () => {
      this.loadingIndicator.hide();
      if ( redirectToCart ) {
        window.location.href = '/cart';
      }
    }).finally( () => {
      this.loadingIndicator.hide();
    });
  }

  addConfiguration(config: DesignConfiguration, redirectToCart: boolean = true) {
    this.loadingIndicator.show();

    let q = this.$q.resolve() as ng.IPromise<any>;
    if (config.dirty) {
      q = this.designConfigurationService.save();
    }

    return q
      .then(response => this.$http.post('/cart/add.js', {
        quantity: 1,
        id: config.id
      }))
      .then(() => {
        this.loadingIndicator.hide();
        if ( false ) {
          window.location.href = '/cart';
        } else {
          let images = $('.preview-image-container .image img').toArray();
          let container = $('.preview-image-container');
          let top: number = container.offset().top + (container.height() / 2) ;
          let left = container.offset().left + ( container.width() / 2);
          for ( let i of images ) {
            this.$cssAnimationService.addElementToCart($(i), this.$rootScope.$emit('force-cart-count-update'), top, left );
          }
        }
      }, (e: any) => {
        this.loadingIndicator.hide();
        console.log( e );
        window.alert('There was an error adding your design to your cart. Please contact us if the problem persists.');
      }).catch( ( e: any ) => {
        console.log( e );
      }).finally( () => {
        this.loadingIndicator.hide();
      });
  }

  show() {
    if (window.top !== window.self) {
      this.frameCommunicationService.openCart();
    }
    else {
      location.href = `//${location.hostname}/?action=openCart`;
    }
  }

  openCart() {
      location.href = `//${location.hostname}/cart`;
  }

  getCartCount() {
    return this.getCartInformation().then( (result: any) => {
      return result.data.item_count;
    }).catch( (err: any) => {
      return '?';
    });
  }
  getItemInventoryQuantity( variantId: number ) {
    return this.lambdaService.getVariantDetails( variantId ).then( (info: any) => {
      // if inventory is controlled by the shopify setting return the actual number
      // otherwise return a large number so that it appears it is in stock
      if ( info.inventory_management === 'shopify' ) { // shopify is tracking inventory quantity
        if ( info.inventory_policy === 'continue') { // Continue selling when out of stock checked
            // item may or may not have stock but allowed to sell if out of stock, so return a large number allowing the sale
            return 99990;
        } else if (info.inventory_policy === 'deny') { // Continue selling when out of stock is _NOT_ checked
            return info.inventory_quantity;
        }
      } else {
        // inventory track not set -- assume not for sale ??
        return -999;
      }

    });
  }
  getCartInformation(){
    return this.$http.get('/cart.js');
  }
  updateCartItemQuantity( lineNumber: number, quantity: number) {
    /** A cart can have multiple line items with same product id so shopify recommends using line number
     *  The value of line is the 1-based index position of the item in the cart.
     * https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#update-cart
     */
    return this.$http.post('/cart/change.js', {line: lineNumber, quantity });
  }
  updateCartNote( note: string ) {
    /** A cart can have multiple line items with same product id so shopify recommends using line number
     *  The value of line is the 1-based index position of the item in the cart.
     * https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#update-cart
     */
    return this.$http.post('/cart/update.js', {note});
  }
  private addSampleToCart( sample: SampleProduct) {
    return this.$http.post( '/cart/add.js', {
      quantity: 1,
      id: (sample as any).sample.id
    });
  }
}
