import find from 'lodash/find';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import lib from '~/api-shopify/api-shopify.module';
import {ProductsApi} from '~/api-shopify/products-api';
import { Product } from '~/api-shopify/product-models/product';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import { ProductWithSamples } from './product-with-samples';
import { SampleProduct } from './sample-product';
import { TvModelLine } from '~/configurator/design/lines';

let listPromise: ng.IPromise<LinerProduct[]>;

@lib.inject(ProductsApi).service
export class LinerProduct extends ProductWithSamples {

  static get(code: string) {
    return LinerProduct.productsApi.getLinerBySku(code).then(p => {
      return new LinerProduct(p);
    });
  }

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = LinerProduct.productsApi.getAllLiners().then(products => {
      return sortBy(products, p => p.sortPosition).map(p => new LinerProduct(p));
    });
  }

  static metro() {
    return LinerProduct.list().then(liners => liners.filter( m => m.metro));
  }

  static standard() {
    return LinerProduct.list().then(liners => liners.filter( m => !m.metro));
  }

  static forCategory(category: string) {
    return LinerProduct.list().then(
      liners => liners.filter(
        l => includes(l.category.split('.'), category.slice(category.length - 1))
      )
    );
  }

  @lib.inject(ProductsApi).property
  private static readonly productsApi: ProductsApi;

  public factor: number;
  public product: LinerProduct;
  public layerUrl: string;

  //  ???????????????????
  public category: string;
  private _manualPriceAdjustment: number  = 1.113; // this should be set in shopify but temporarily set here
                                                  // was 1.06 but now 5% more

  constructor(data: any) {
    super(data);
    this.factor = data.factor;
    this.layerUrl = data.layerUrl || '';
    this.product = this;
    // reduce size of thumbnail image from CDN.. currently too large
    const lastPeriod = this.thumbnailUrl.lastIndexOf('.');
    if ( lastPeriod > -1 ) {
      this.thumbnailUrl = this.thumbnailUrl.substring(0,  lastPeriod) + '_75x75' + this.thumbnailUrl.substring(lastPeriod);
    }
    if ( data.linerSample ) {
      this.addSample(new SampleProduct(data.linerSample));
    }
  }

  get metro() {
    return includes( this.category.split('.'), 'M' );
  }
  get hardwood() {
    return includes( this.category.split('.'), 'H' );
  }
  /* one liner product is used to represent 'no liner' selection */
  get isNoLiner() {
    return this.code === 'LINER-5';
  }

  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    let tv = config.tvModel;
    if ( tv && (tv as TvModelLine).isCustomSize  ) {
      let tempPromise = this.basePrice();
      return this.basePrice().then(b => b ? Math.ceil( (b + this.factor * tvSize ) * this._manualPriceAdjustment * (tv as TvModelLine).customTVSizePriceAdjustmentOnNonFixedPricedItems) : 0);
    } else {
      return this.basePrice().then(b => b ? Math.ceil( (b + this.factor * tvSize ) * this._manualPriceAdjustment) : 0);
    }
  }
}
