import { AuthenticationService } from '../../user/authentication.service';
import { BrandingInfo } from '../../services/brandingInfo';
import app from '../../app';

@app.inject('$mdSidenav', AuthenticationService).component({
  templateUrl: 'configurator/layout/navBar/navBar.html',
  bindings: {
    brandingInfo: '<'
  }
}, 'navBar')
export default class NavBarController {

  brandingInfo: BrandingInfo;
  isFullscreen = false;

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private authenticationService: AuthenticationService,
  ) {
  }

  $onInit() { }

  toggleSidenav() {
    this.$mdSidenav('left').toggle();
  }

  userName() {
    return this.authenticationService.email;
  }

  impersonatedName() {
    return this.authenticationService.impersonatedName;
  }
}
