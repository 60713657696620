import app from '../../../app';

@app.component({
  templateUrl: 'configurator/configuration/frameType/tiles/frameTypeTile.html',
  bindings: {
    title: '@',
    description: '@',
    design: '&',
    discover: '&',
    pricedFrom: '<',
    pricedFromUnits: '<',
    productionTime: '<'
  }
})
export class FrameTypeTile {
  pricedFrom: number[];
  productionTime: number;
  pricedFromUnits: string;
  getPricedFrom(){
    let priceString = '';
    priceString = '$' + this.pricedFrom[0];

    if ( this.pricedFrom.length > 1 ) {
      priceString += ' - $' + this.pricedFrom[1];
    }
    return priceString;
  }

  getProductionTime( productionTime: number ){
    if ( productionTime > 0 ) {
      return this.productionTime + ( productionTime === 1 ? ' week' : ' weeks' );
    } else {
      return 'Ships immediately';
    }
  }

  shipDateSpan() {
    const monthsString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // 14-20 weeks
    const now = new Date();
    //                          14 weeks in minutes
    const ms14 = now.getTime() + (141120 * 60 * 1000);
                      // 20 weeeks in minutes
    const ms20 = now.getTime() + (201600 * 60 * 1000);
    const startingIdx =  new Date(ms14 ).getMonth();
    const endingIdx = new Date(ms20).getMonth();
    const dateString  = monthsString[startingIdx] + '-' + monthsString[endingIdx];
    return dateString;
  }
}
