import { AuthenticationService } from './authentication.service';
import app from '../app';
import includes from 'lodash/includes';

// tslint:disable-next-line:only-arrow-functions
@app.inject('$compile', AuthenticationService).directive()
export class ShowTo {
  definition(
    $compile: ng.ICompileService,
    authenticationService: AuthenticationService
  ) {
    return {
      restrict: 'A',
      replace: false,
      terminal: true, // Don't process lower priority directives
      priority: 990,  // ng-repeat is at 1000 and ng-if is at 600
      compile: (element: JQuery, attrs: ng.IAttributes) => {
        let showTo = attrs['ftvShowTo'];

        // Only do anything if we're actually
        if (showTo && showTo.length) {
          // Grab any existing ng-if condition
          let condition = element.attr('ng-if');
          if (condition) {
            // Add any preexisting condition
            element.attr('ng-if', `$userAuthorized(${showTo}) && (${condition})`);
          }
          else {
            // Stick ng-if in there
            element.attr('ng-if', `$userAuthorized(${showTo})`);
          }

          // Remove this directive and ng-repeat so they don't get recompiled.
          // Any other directive that is higher priority should also be removed here.
          element.removeAttr('ftv-show-to');
          element.removeAttr('ng-repeat');
        }

        return {
          post: (scope: any, el: ng.IAugmentedJQuery) => {

            scope.$userAuthorized = (expr: any) => {
              return includes(expr, authenticationService.roles);
            };

            // Recompile and process remaining directives
            $compile(el)(scope);
          }
        };
      }
    };
  }
}
