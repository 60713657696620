import { DesignTypes } from '../../design/designTypes';

export interface Tile {
  kind: string;
  quote?: string;
  author?: string;
  frameType?: DesignTypes | string;
  backgroundColor?: string;
  backgroundImage?: string;
  title?: string;
  description?: string;
  features?: string[];
  externalLink?: string;
  pricedFrom?: number[];
  pricedFromUnits?: string; // defaults to fixed price (i.e. nothing)
  productionTime?: number; // weeks
  discontinued?: boolean;
}

export let tiles: Tile[] = [
  {
    kind: 'help',
    backgroundColor: '#354555'
  },
  {
    kind: 'frameType',
    features: ['frame', 'art', 'liner'],
    frameType: DesignTypes.FrameWithArt,
    title: 'TV ART Cover',
    description: 'A framed canvas artwork that automatically rolls up with the press of a button to reveal your TV.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_1.jpg)`,
    pricedFrom: [4999],
    productionTime: 6,
    discontinued: false
  },
  {
    kind: 'frameType',
    features: ['frame', 'mirror', 'liner'],
    frameType: DesignTypes.FrameWithMirror,
    title: 'FRAMED tv MIRROR',
    description: 'An elegant decorative mirror when the TV is OFF which instantly becomes clear when the TV is ON.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_3.jpg)`,
    pricedFrom: [ 1849 ],
    productionTime: 4,
    discontinued: true
  },
  {
    kind: 'externalLink',
    features: ['samsungDeco'],
    frameType: 'samsungDeco',
    title: 'For Samsung "The Frame"',
    description: 'Economical magnetic frame only solutions for Samsung The Frame televisions.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/deco.jpg)`,
    externalLink:  'http://framemytv.com/deco',
    pricedFrom: [ 299 , 749],
    productionTime: 0 // immediate
  },
 {
    kind: 'frameType',
    features: ['mirror'],
    frameType: DesignTypes.FramelessMirror,
    title: 'FLOATING tv MIRROR',
    description: 'A Sleek and contemporary frameless mirror when the TV is OFF instantly becomes clear when the TV is ON.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_9.jpg)`,
    pricedFrom: [ 1350, 2650],
    productionTime: 4,
    discontinued: true
  },

  {
    kind: 'frameType',
    features: ['frame', 'liner'],
    frameType: DesignTypes.FrameOnly,
    title: 'tv FRAME',
    description: 'A decorative premium quality frame to dress up any TV to complement a room’s decor.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_2.jpg)`,
    pricedFrom: [ 849 ],
    productionTime: 4,
    discontinued: true
  },
  {
    kind: 'frameType',
    features: ['frame', 'mirror'],
    frameType: DesignTypes.MetroMirror,
    title: 'METRO tv MIRROR',
    description: 'A slim frame with clean lines shows as a mirror when the TV is OFF and becomes clear when the TV is ON.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_7.jpg)`,
    pricedFrom: [ 1849 ],
    productionTime: 4,
    discontinued: true
  },

  {
    kind: 'frameType',
    frameType: DesignTypes.CutGlass,
    title: 'tv MIRROR glass',
    description: 'Cut-To-Size TV mirror glass for your DIY project.',
    backgroundImage: `url(${window.fmtvAssetsRoot}assets/configurator/frameTypes/image_8.jpg)`,
    pricedFrom: [ 65, 125], // todo "per foot"
    pricedFromUnits: 'per foot',
    productionTime: 1,
    discontinued: true,
  },
  /*
  {
    kind: 'quote',
    quote: '“The quality and construction of the frames is excellent as well. I highly recommend FrameMyTV ”.',
    author: 'Lowell Kaps, Owner of Lowell/Edwards in Hackensack, NJ',
    backgroundColor: '#9F8D5C'
  },
   */
  {
    kind: 'quote',
    quote: '“The retractable painting works perfectly each time. And every time someone comes through our home, THIS is the talking point. ”.',
    author: 'The Hermann Family, Dallas, TX',
    backgroundColor: '#9F8D5C'
  },
];
