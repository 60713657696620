import app from '../../app';
import { SaveDesignService } from '../save-design/save-design.service';

@app.inject(
  '$scope',
  '$rootScope',
  '$timeout',
  SaveDesignService
).component({
  templateUrl: 'configurator/configuration/save-design-footer/save-design-footer-component.html',
})

export default class SaveDesignFooterComponent {

  showClosedFooter: boolean;
  showSaveMessage: boolean;
  showControl: boolean ;

  constructor(
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService,
    private saveDesignService: SaveDesignService
  ) {
    this.showClosedFooter = false;
    this.showSaveMessage = false;
    this.showControl = false;
  }

  $onInit() {
    this.$rootScope.$on( 'saveDesignFooterService', (evt, show ) => {
      if ( show ) {
        this.openControl();
      } else {

      }
    });
  }
  closeSaveDesignFooter(){
    this.showSaveMessage = false;
    this.showClosedFooter = true;
  }

  reopenSaveDesignMessage(){
    this.showClosedFooter = false;
    this.openControl();
  }

  displayControl() {
    return this.showControl;
  }
  displayCloseFooter(){
    return this.showClosedFooter;
  }
  displaySaveMessage(){
    return this.showSaveMessage;
  }

  showSaveDesignDialog(){
    this.saveDesignService.show();
    this.$timeout( () => {
      this.showClosedFooter = true;
      this.showSaveMessage = false;
      this.showControl = false;
    });
  }

  openControl() {
    this.$timeout( () => {
      this.showClosedFooter = false;
      this.showSaveMessage = true;
      this.showControl = true;
    });

  }
}
