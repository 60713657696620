import app from '../../app';
import angular from 'angular';

@app.service
export class SaveDesignService {

  visible = false;
  initCount = 0;

  show( ) {
      this.visible = true;
  }

  hide() {
    this.initCount = 0;
    this.visible = false;
  }

}
