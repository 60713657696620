import app from '../../../app';
import { AuthenticationService } from '../../../user/authentication.service';

@app.inject('$mdDialog', AuthenticationService).controller
export class VendorLoginController {

  userName: string;
  password: string;
  error: string;

  constructor(
    private mdDialog: ng.material.IDialogService,
    private auth: AuthenticationService
  ) { }

  $onInit() {}

  cancel() {
    this.mdDialog.cancel();
  }

  login() {
    this.auth.login(this.userName, this.password).then(() => {
      this.mdDialog.hide();
    }, () => {
      this.error = 'The email or password provided is invalid. Please verify your credentials and try again.';
    });
  }
}
