import { MeasurementsService } from '../../../services/measurements.service';
import { CutGlassProduct } from '../../../../api-shopify/product-models/cut-glass-product';
import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';
import some from 'lodash/some';

@app.inject(
  '$mdSidenav',
  '$state',
  '$scope',
  '$timeout',
  '$mdMedia',
  'designConfiguration',
  MeasurementsService
).controller
export class CutGlassController {

  product: CutGlassProduct;
  products: CutGlassProduct[];
  loaded = false;

  private basePrice = 0;

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $mdMedia: ng.material.IMedia,
    public designConfiguration: DesignConfiguration,
    private measurementsService: MeasurementsService
  ) {
    CutGlassProduct.list().then(p => {
      this.products = p;
      this.loaded = true;
    });

    $timeout(this.init, 100);
  }

  $onInit() {}

  close() {
    this.$mdSidenav('cut-glass').close();
  }

  select(product: CutGlassProduct) {
    if (this.$mdMedia('xs') || !product) {
      product = product || this.product;
      this.designConfiguration.cutGlass.setProduct(product);
      this.close();
    }
    else {
      this.product = product;
    }
  }

  selected(mirror: CutGlassProduct) {
    if (this.product) {
      return this.product.code === mirror.code;
    }
    else if (this.designConfiguration.cutGlass.data) {
      return this.designConfiguration.cutGlass.data.code === mirror.code;
    }
    return false;
  }

  canSelect(product: CutGlassProduct) {
    let d = this.designConfiguration.cutGlass.data;

    let width = d.width;
    let height = d.height;

    if (d.system === 'mm') {
      width = this.measurementsService.mmToInches(width);
      height = this.measurementsService.mmToInches(height);
    }

    return !((some(product.maxOversize, s => s < height) && some(product.maxOversize, s => s < width)) ||
      some([width, height], dim => dim > Math.max(product.maxOversize[0], product.maxOversize[1])));
  }

  private init = () => {
    let sideNav = this.$mdSidenav('cut-glass');
    sideNav.open().then(() => {
      this.$scope.$watch(() => sideNav.isOpen(), (open: boolean) => {
        if (!open) {
          this.$timeout(() => this.$state.go('^'), 300);
        }
      });
    });
  }
}
