import lib from '~/ui/ui.module';

@lib.inject('$mdDialog').controller
export class PasswordChangeController {

  constructor(
    private $mdDialog: ng.material.IDialogService
  ) { }

  $onInit() { }

  setPassword(password: string) {
    this.$mdDialog.hide(password);
  }

}
