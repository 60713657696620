import { MountingOptionProduct } from '../../../api-shopify/product-models/mounting-option-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
export interface MountingOptionLineData {
  code?: string;
}

export class MountingOptionLine extends BaseLine<MountingOptionLineData>{
  kind = ConfigLineKinds.MountingOption;
  category = 'Options';
  name = 'Mounting Options';
  order = 30;
  editState = 'configuration.tab.options.mounting-options';
  product: MountingOptionProduct;
  scrollToElementId = 'mounting-option-scroll-to';

  get valid() { return !!this.product; }

  deserialize(data?: MountingOptionLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return MountingOptionProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: MountingOptionProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.MountingOption] = MountingOptionLine;
