import { SampleProduct } from './sample-product';
import { Product } from './product';

export abstract class ProductWithSamples extends Product {

  private _samples: SampleProduct[] = [];

  private _samplesPromise: ng.IPromise<SampleProduct[]>;

  private _category: string;
  private _previewUrl: string;
  private _productId: number; // the ID of the parent product (that has the variants) in shopify - required for swym wishlist
  constructor( data: any ) {
    super(data);
    // TODO not sure if category should be here or pushed intro product
    this._category = data.category;
    this._previewUrl = data.previewUrl;
    this._productId = data.productId;
  }

  addSample(sample: SampleProduct) {
    this._samples.push(sample);
  }

  get samples() {
    return this._samples;
  }
  get category() {
    return this._category;
  }
  get previewUrl(){
    return this._previewUrl;
  }
  get productId(){
    return this._productId;
  }

  samplesPromise() {
    return this.q.resolve(this._samples);
    /*
    if (!this._samplesPromise) {
      let ids = filter(this.product.associations, a => a.type === 'Sample').map(a => a.associatedObjectId);
      if (!ids.length) {
        return this.q.resolve([]);
      }

      this._samplesFetched = true;
      this._samplesPromise = this.catalogApi
        .catalogModuleProductsGetProductByIds(ids)
        .then(r => {
          r.data
            .filter(p => p.isBuyable)
            .forEach(p => {
              let sample = new SampleProduct(p);
              sample.sku = `${sample.product.code}-${this.product.code}`;
              if (p.isBuyable) {
                this._samples.push(sample);
              }
            });
          return this._samples;
        });
    }

    return this._samplesPromise;
    */
  }
}
