// import { CommonCategories } from '../apiCategories.service';
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
import sortBy from 'lodash/sortBy';

let listPromise: ng.IPromise<CutGlassAccessoryProduct[]>;

@lib.inject(ProductsApi).service
export class CutGlassAccessoryProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = CutGlassAccessoryProduct.productsService.getAllCutGlassAccessoryProduct()
      .then( (products: any ) => sortBy(products, p => p.sortPosition).map((p: any) => new CutGlassAccessoryProduct(p)) );
  }

  static get(code: string) {
    return this.list().then((products: any) => find(products, p => p.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  // thumbnail moved to super
  // description moved to super

  guaranteedPrice() {
    return this.basePrice();
  }
}
