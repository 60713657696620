import { MeasurementsService } from '../services/measurements.service';
import { DesignConfigurationService } from '../design/designConfiguration.service';
import app from '../app';
import { DesignConfiguration } from '../design/designConfiguration';
import { BrandingInfo } from '../services/brandingInfo';
import { AuthenticationService } from '../user/authentication.service';
import { CaptureFormService } from './captureForm/captureForm.service';
import startsWith from 'lodash/startsWith';
import { DesignTypes } from '../design/designTypes';
import angular from 'angular';
import RushController from './summary/rush.controller';
import jquery from 'jquery';
import { SaveDesignService } from './save-design/save-design.service';
import { ConfigLineKinds } from '../design/lines';
import Swiper from 'swiper';

@app
  .inject(
    'designConfiguration',
    '$mdMedia',
    AuthenticationService,
    CaptureFormService,
    '$state',
    'brandingInfo',
    '$scope',
    DesignConfigurationService,
    MeasurementsService,
    '$mdDialog',
    '$rootScope',
    '$element',
    '$timeout',
    SaveDesignService
  )
  .controller
export default class ConfigurationController {

  zoomPreview = false;
  showPrices: boolean;
  largeTabs: boolean;
  showPriceDetails = false;
  showWallColorSelector = false;
  wallColors = ['#788690',
    '#A1A89C',
    '#7E373D',
    '#D2D4CF',
    '#6E7769', // olive green
    '#324350', // navy blue
    '#CDCABF', // medium grey
    '#E3E1D9', // light grey
    '#877D77', // dark grey
    '#EDDBB1', // eggshell
  ];
  isMobile: boolean;
  isMobileXs: boolean;
  paintBrushIcon = window.fmtvAssetsRoot + '/assets/configurator/paint-brush.png';
  isOpenChangingFromToXS: boolean = false;
  showSamples: boolean = false;
  swiperContent: {title: string, url: string} [] = [];
  _isSwiperFullScreen: boolean = false;
  constructor(
    public configuration: DesignConfiguration,
    private $mdMedia: ng.material.IMedia,
    private authenticationService: AuthenticationService,
    private $ftvCaptureForm: CaptureFormService,
    public $state: ng.ui.IStateService,
    public brandingInfo: BrandingInfo,
    private $scope: ng.IScope,
    private configurationService: DesignConfigurationService,
    private measurementsService: MeasurementsService,
    private $mdDialog: ng.material.IDialogService,
    private $rootScope: ng.IRootScopeService,
    private $element: ng.IAugmentedJQuery,
    private $timeout: ng.ITimeoutService,
    public saveDesignService: SaveDesignService,
  ) {
    this.showPrices = brandingInfo.settings.showPrices;
    // swiper
    (this.$scope as any).swiper = {};
    (this.$scope as any).onReadySwiper = (swiper: Swiper) => {
      (this.$scope as any).swiper = swiper;
      console.log('onReadySwiper');
    };
    // end swiper section
    if (!$state.params['configurationId']) {
      $state.go('configuration.frameType', { configurationId: 'new' });
    }
    $scope.$on('zoom-preview', () => {
      this.toggleZoom();
    });
    this.$scope.$watch(() => $state.includes('configuration.tab.design'), design => this.largeTabs = !design);
    this.$scope.$watch(() => $state.includes('configuration.tab.design'), design => this.largeTabs = !design);
    this.$rootScope.$watch(() => (this.$rootScope as any).samplesOnPreviewComponentIsOpen, isOpen =>
      this.isOpenChangingFromToXS = isOpen
    );
    this.getSwiperImageContent();
    this.$scope.$watch(() => this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku, () => this.getSwiperImageContent());
    this.$scope.$watch(() => this.configuration.artwork && this.configuration.artwork.product && this.configuration.artwork.product.sku, () => this.getSwiperImageContent());
    this.$scope.$watch(() => this.configuration.liner && this.configuration.liner.product && this.configuration.liner.product.sku, () => this.getSwiperImageContent());

    this.$scope.$watch(() => this.$mdMedia('xs') || this.$mdMedia('sm'), mobile => {
      this.isMobile = mobile;
    });
    this.$scope.$watch(() => this.$mdMedia('xs'), mobile => {
      this.showSamples = (this.$rootScope as any).samplesOnPreviewComponentIsOpen;
      this.isMobileXs = mobile;
    });
    this.$timeout( () => {
        // when the config column is scrolled on the mobile, hide the secondary mobile menu with /new design and /view designs
      const configScrollColumn = document.getElementById('right-scroll-column');
      const secondaryMobileMenu = $('#secondary-mobile-menu');
      angular.element(configScrollColumn).bind('scroll', () => {
        if (this.isMobile ) {
          if ( configScrollColumn.scrollTop > 30 ) {
            secondaryMobileMenu.hide( 'slow' );
           } else {
            secondaryMobileMenu.show( 'slow' );
           }
        }
      });
     });
  }

  changeWallColor(color: string) {
    this.configuration.wallColor = color;
  }

  toggleWallColorSelector() {
    return this.showWallColorSelector = !this.showWallColorSelector;
  }

  firstTabLabel() {
    // This is in a method because the config type can be changed
    if (this.configuration && this.configuration.type === DesignTypes.CutGlass) {
      return 'Glass';
    }
    return 'Design';
  }

  restricted() {
    return this.authenticationService.restricted();
  }

  getSwiperImageContent(){
    // will want an image for each frame piece, art perhaps and something with samples at the end
    const frameImageUrls: {title: string, url: string}[] = [];
    const artImageUrls: {title: string, url: string}[] = [];
    const linerImagesUrls: {title: string, url: string}[] = [];

    if ( this.configuration.type !== DesignTypes.CutGlass ) {
      if (  this.configuration.frame && this.configuration.frame.product ) {
        let numbImages = 4;
        if ( this.configuration.type === DesignTypes.MetroArt || this.configuration.type === DesignTypes.MetroMirror ) {
          numbImages = 3;
        }
        let count = 0;
        for ( let image of this.configuration.frame.product.images ){
          if ( count < numbImages) {
            frameImageUrls.push( { title: 'Frame' , url: image } );
            count ++;
          } else {
            break;
          }
        }
      }
      if ( this.configuration.artwork && this.configuration.artwork.product ) {
        artImageUrls.push ( { title: 'Artwork', url: this.configuration.artwork.product.previewUrl });
      }
      if ( this.configuration.liner && this.configuration.liner.product ) {
        linerImagesUrls.push ( { title: 'Liner', url: this.configuration.liner.product.previewUrl } );
      }
      // frameImageUrls.push( this.configuration.frame.product.previewUrl );
      // frameImageUrls.push( this.configuration.frame.product.thumbnailUrl );
      // frameImageUrls.push( this.configuration.frame.product.samples[0].imagesForDetails );
      // frameImageUrls.push( this.configuration.frame.product.samples[0].imgSrc );
      // frameImageUrls.push( this.configuration.frame.product.samples[0].thumbnailUrl );

    }

    let allUrls: {title: string, url: string}[] = [];
    // the sliders first position (0) is the preview image so add an empty place holder
    allUrls.push( {title:'', url:''} );
    allUrls = allUrls.concat( frameImageUrls ).concat( artImageUrls ).concat(linerImagesUrls);
    // add an empty place holder at the end for the samples preview if not on mobile
    const isMobile = this.$mdMedia('xs') || this.$mdMedia('sm');
    if ( !isMobile ) {
      allUrls.push( {title:'', url:''} );
    }
    this.swiperContent = allUrls;
  }

  showSliderImage( first: boolean, last: boolean) {
    let show = false;
    if (first) {
      show = false;
    } else if ( !first && !last ) {
      show = true;
    } else if ( last && this.isMobile ) {
      show = true;
    } else {
      show = false;
    }
    return show;

  }
  saveAndContinue() {
    if (this.$state.includes('configuration.tab.design')) {
      if (this.brandingInfo.settings.captureForm && this.configuration.isNew && !this.$ftvCaptureForm.hideCaptureForm) {
        this.$ftvCaptureForm.visible = true;
      }
      else {
        if (this.brandingInfo.settings.optionsTab) {
          this.$state.go('configuration.tab.options');
        }
        else {
          this.$state.go('configuration.tab.summary');
        }
      }
    }
    else {
      this.$state.go('configuration.tab.summary');
    }
  }

  back() {
    if (this.$state.includes('configuration.tab.options')) {
      this.$state.go('configuration.tab.design');
    }
    else if (this.$state.includes('configuration.tab.summary')) {
      if (this.brandingInfo.settings.optionsTab) {
        this.$state.go('configuration.tab.options');
      }
      else {
        this.$state.go('configuration.tab.design');
      }
    }
  }

  disableBack() {
    return true;
    /* return this.$state.includes('configuration.tab.design'); */
  }

  disableContinue() {
    return true;
    /* return (this.$state.includes('configuration.tab.design') && !this.configuration.designComplete) ||
      (this.$state.includes('configuration.tab.options') && !this.configuration.optionsComplete) ||
      this.$state.includes('configuration.tab.summary'); */
  }

  showContinue() {
    return false;
    /* return !this.authenticationService.restricted() ||
      (this.brandingInfo.settings.captureForm && !this.configuration.isNew) ||
      this.brandingInfo.settings.summaryTab ||
      this.brandingInfo.settings.optionsTab; */
  }

  showSummaryTab() {
    return this.brandingInfo.settings.summaryTab || !this.authenticationService.restricted();
  }

  showOptionsTab() {
    // return this.brandingInfo.settings.optionsTab || !this.authenticationService.restricted();
    return true;
  }

  showSaveDesign() {
    this.saveDesignService.show();
  }

  link(e: MouseEvent) {
    if (!this.configuration.isNew) {
      if (this.configuration.dirty) {
        this.$mdDialog.show(
          this.$mdDialog.confirm()
            .parent(angular.element('#fmtv-app'))
            .title('Save Changes')
            .textContent(`Do you wish to save the changes before copying the link? If you answer 'No' the link will load the last saved configuration.`)
            .ok('Yes')
            .cancel('No')
        ).then(() => {
          this.configurationService.save().then(() => {
            this.configurationService.showLink(e);
          });
        }, () => {
          this.configurationService.showLink(e);
        });
      } else {
        this.configurationService.showLink(e);
      }
    }
    else {
      this.configurationService.save().then(() => {
        this.configurationService.showLink(e);
      });
    }
  }

  save(e: MouseEvent) {
    this.configurationService.showSaveDialog(e);
  }

  tabSelected(state: string) {
    if (!startsWith(this.$state.current.name, state)) {
      this.$state.go(state);
    }
  }

  toggleZoom() {
    if (!this.$mdMedia('xs')) {
      this.zoomPreview = false;
      return;
    }
    this.zoomPreview = !this.zoomPreview;
  }

  priceVisible() {
    switch (this.configuration.type) {
      case DesignTypes.FrameWithArt:
        return !!this.configuration.frame.frameProduct && !!this.configuration.artwork.product;
      case DesignTypes.FrameWithMirror:
      case DesignTypes.MetroMirror:
        return !!this.configuration.frame.frameProduct && !!this.configuration.mirror.mirrorProduct;
      case DesignTypes.FramelessMirror:
        return !!this.configuration.mirror.mirrorProduct;
      case DesignTypes.FrameOnly:
        return !!this.configuration.frame.frameProduct;
      case DesignTypes.CutGlass:
        return this.configuration.cutGlass.price > 0;
    }
  }

  toggleDetails(e: ng.IAngularEvent) {
    this.showPriceDetails = !this.showPriceDetails;
    e.stopPropagation();
    e.preventDefault();
  }

  getSamples() {
    this.showSamples = !this.showSamples;
    this.$rootScope.$broadcast('show-get-sample-modal', this.showSamples);
  }

  toggleFullScreen(){
    this._isSwiperFullScreen = !this._isSwiperFullScreen;
    if (this._isSwiperFullScreen) {
      jquery('.mini-cart-icon').hide();
      jquery('ftv-art-bar-component').hide();
      jquery('ftv-liner-bar-component').hide();
      jquery('.sample-body.hidesamples').hide();
      if ( this.isMobile ) {
        jquery('.add-to-cart-wrapper.preview-details-mobile').css( 'z-index', -1);
      }
      jquery('ftv-frame-bar-component').hide();
    } else {
      jquery('.mini-cart-icon').show();
      jquery('ftv-art-bar-component').show();
      jquery('ftv-liner-bar-component').show();
      jquery('.sample-body.hidesamples').show();
      if ( this.isMobile ) {
        jquery('.add-to-cart-wrapper.preview-details-mobile').css( 'z-index', 0);
      }
      jquery('ftv-frame-bar-component').show();
    }
    (this.$scope as any).swiper.update();
    this.$timeout( () => {
      (this.$scope as any).swiper.update();
    }, 500);
  }
  isSwiperFullScreen (){
    return this._isSwiperFullScreen;
  }
}
