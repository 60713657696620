import { FrameCommunicationService } from './services/frameCommunication.service';
import app from './app';
import defaults from 'lodash/defaults';
import { ArtProduct } from '../api-shopify/product-models/art-product';
import { FrameProduct } from '../api-shopify/product-models/frame-product';
import { LinerProduct } from '~/api-shopify/product-models/liner-product';
import { IPromise } from 'angular';
import { getDiffieHellman } from 'crypto';
import { error } from 'jquery';
import { WebworkerService } from './web-worker/web-worker.service';
import { WebSocketService } from './web-worker/web-socket.service';

const lastStateKey = 'init:lastStateKey';
const lastStateParams = 'init:lastStateParams';
let sessionLengthTimer = 0;
let pingTimeoutTimer: any;

app
  .inject(
    '$rootScope',
    FrameCommunicationService,
    '$state',
    '$timeout',
    '$q',
    WebworkerService,
    '$http',
    '$window',
    WebSocketService
  )
  .run((
    rootScope: ng.IRootScopeService,
    frameService: FrameCommunicationService, // just including it so it gets instanciated
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    $q: ng.IQService,
    webworkerService: WebworkerService,
    $http: ng.IHttpService,
    $window: ng.IWindowService,
    webSocketService: WebSocketService,
  ) => {
    /*
    // ping that someone started the quote builder
    $http.get(`${window.shopifyCacheRoot}/stats/qb-opened/` );

    // record length of session for stats and record when browser or tab is closed
    setInterval( () => {
      sessionLengthTimer += 1;
    } , 1000 );

    const timerID = setInterval( () => {
      navigator.sendBeacon(`${window.shopifyCacheRoot}/stats/qb-closed/`, sessionLengthTimer.toString());
    }, 3 * 60 * 1000); // every 3 minutes

    pingTimeoutTimer = setTimeout(() => {
      this.terminate();
    }, 30000 + 1000);
    */

    (rootScope as any).samplesOnPreviewComponentIsOpen = false;

    rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, err) => {
      console.warn(err);
    });
    rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
      // Whenever there is a successful state change, the state name and params are saved to
      // session storage. This is how we can keep track of where the user was before the
      // browser is refreshed.
      if (toState.name !== 'initial-load') {
        localStorage.setItem(lastStateKey, toState.name);
        localStorage.setItem(lastStateParams, JSON.stringify(toParams));
      }
    });
    function loadImage(src: string) {
      return $q((resolve: any, reject: any) => {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.onerror = (e) => {
          // reject(e);
          resolve(image);
        };
        image.src = src;
      });
    }
    // load the default art and frames for startup then start to load the
    // full list afterewards
    const artDefaults = ArtProduct.loadDefaults().then(art => {
      // console.log('art defaults loaded');
      let count = art.length;
      let loaded = 0;
      const promises: any = [];
      art.forEach((a) => {
        promises.push(loadImage(a.thumbnailUrl));
      });
      let q: ng.IPromise<any>;
      return $q.all(promises).then((results) => {
        return results;
      });
    });

    const frameDefaults = FrameProduct.loadDefaults().then(frame => {
      /*frame.map(f => {
        const image = new Image();
        image.src = f.thumbnailUrl;
      });*/
      let count = frame.length;
      let loaded = 0;
      const promises: any = [];
      frame.forEach((a) => {
        promises.push(loadImage(a.thumbnailUrl));
      });
      let q: ng.IPromise<any>;
      return $q.all(promises).then((results) => {
        return results;
      });
    });

    const linerDefaults = LinerProduct.list().then(liner => {
      let count = liner.length;
      let loaded = 0;
      const promises: any = [];
      liner.forEach((a) => {
        promises.push(loadImage(a.thumbnailUrl));
      });
      let q: ng.IPromise<any>;
      return $q.all(promises).then((results) => {
      });
    });

    $q.all([artDefaults, frameDefaults, linerDefaults]).then((results) => {
      const frameUrls = FrameProduct.list().then((frames) => frames.map(f => f.thumbnailUrl));
      const artUrls = ArtProduct.list().then((art) => art.map(a => a.thumbnailUrl));
      $q.all([frameUrls, artUrls]).then(artFrameUrls => {
        const imageUrls = artFrameUrls[0].concat(artFrameUrls[1]);

        /* tslint:disable */
        const worker = webworkerService.newWorker(() => {
          var self = this;
          var curState = 'resume_worker';
          // handler for a batch of urls to precache
          onmessage = (e) =>  {

            if ( e.data === 'pause_worker' || e.data === 'resume_worker') {
              (e.currentTarget as any).postMessage( e.data );
              curState = e.data;
            } else {
              curState = 'processing batch';
              // console.log( 'processing batch'  );
              // data has urls to precache images
              let urls = []
              try {
                urls = JSON.parse( e.data );
              } catch ( err ) {
                console.log ( err );
                console.log ( 'data :' + e.data );
                throw err;
              }
                let done = urls.length;

                for ( let url of urls )  {
                  // console log in firefox only for debugging
                  // self.console.log(" URL "  + url);

                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (pe: ProgressEvent) => {
                      if (--done === 0) {
                        (e.currentTarget as any).postMessage('batch_done');
                        // w.terminate();
                        // (self as any).close();
                      }
                    };

                    (xhr as any).onerror = (e: ErrorEvent)  => {
                      if (--done === 0) {
                        // this image (and posssibly others) has errored out but still finished the batch
                        (e.currentTarget as any).postMessage('batch_done');
                      }
                    };;

                    xhr.open('GET', url, true);
                    xhr.send();
                }

            }
          };
        });


        // send urls to download 10 at a time then wait and send another 10
        let i,j,batchArray,chunk = 30; // roughly 10kb per image * 30 = 300kb per batch
        let batches: any[] = [];
        for (i=0 , j = imageUrls.length; i < j; i += chunk) {
          // chunk into 50 images/ requests at a time
            batchArray = imageUrls.slice(i,i+chunk);
            batches.push ( batchArray );
        }
        let curBatch = 0;
        let curState = 'resume_worker';
        // load first batch
        worker.postMessage(  JSON.stringify(batches[curBatch] ));
        // after first batch loaded, wait for a message to start processing
        worker.onmessage = function(e) {
          if ( e.data === 'pause_worker' ){
            curState = e.data;
            // don't process batch - do nothing for now until
          } else if ( e.data === 'resume_worker' || e.data === 'batch_done' ) {
            if ( curState === 'pause_worker' && e.data != 'resume_worker' ) {
              console.log( 'skipping next batch while paused')
              // do nothing
            } else {
              curBatch += 1;
              if ( curBatch < batches.length ) {
                // can only post strings as params
                worker.postMessage( JSON.stringify( batches[curBatch] ));
              }
              curState = e.data;
            }
          }
        }

      });

      // We use a timeout to give everything time to be bootstrapped before trying to load a state
      $timeout(() => {
        //  if (document.URL.endsWith( '/summary')) {
        //    $state.go('configuration.tab.summary' );
        //    return;
        //  }

        if (location.pathname !== '/apps/designer/') {
          // ignore any URL that's not the default
          return;
        }

        let state = localStorage.getItem(lastStateKey);
        if (state && state !== 'initial-load') {
          // If there is a saved state and it's not 'initial-load', we load that state
          let params = JSON.parse(localStorage.getItem(lastStateParams) || 'null');
          $state.go(state, params);
        }
        else {
          // Otherwise, we go to the default state
          $state.go('configuration.frameType', defaults({ configurationId: 'new' }, $state.params));
        }
      });
    });
  });

app.module.constant('basePath', '');
