import angular from 'angular';
import lib from '~/ui/ui.module';

interface LazySrcScope extends ng.IScope {
  ftvLazySrc: any;
  animateSpeed: string;
}

@lib.inject('$window', '$document', '$timeout').directive()
export class LazySrc {
  definition(
    $window: ng.IWindowService,
    $document: ng.IDocumentService,
    $timeout: ng.ITimeoutService
  ): ng.IDirective {
    let doc = $document[0];
    let body = doc.body;
    let win = $window;
    let $win = angular.element(win);
    let uid = 0;
    let elements: { [key: string]: { iElement: ng.IAugmentedJQuery, $scope: ng.IScope } } = {};

    function getUid(el: ng.IAugmentedJQuery) {
      let uniqueId = el.data('__uid');
      if (!uniqueId) {
        el.data('__uid', (uniqueId = '' + ++uid));
      }
      return uniqueId;
    }

    function getWindowOffset() {
      let t: any;
      // tslint:disable-next-line:no-conditional-assignment
      let pageXOffset = (typeof win.pageXOffset === 'number') ? win.pageXOffset : (((t = doc.documentElement) || (t = body.parentNode)) && typeof t.scrollLeft === 'number' ? t : body).scrollLeft;
      // tslint:disable-next-line:no-conditional-assignment
      let pageYOffset = (typeof win.pageYOffset === 'number') ? win.pageYOffset : (((t = doc.documentElement) || (t = body.parentNode)) && typeof t.scrollTop === 'number' ? t : body).scrollTop;
      return {
        offsetX: pageXOffset,
        offsetY: pageYOffset
      };
    }

    function isVisible(iElement: ng.IAugmentedJQuery) {
      let elem = iElement[0];
      let elemRect = elem.getBoundingClientRect();
      let windowOffset = getWindowOffset();
      let winOffsetX = windowOffset.offsetX;
      let winOffsetY = windowOffset.offsetY;
      let elemWidth = elemRect.width;
      let elemHeight = elemRect.height;
      let elemOffsetX = elemRect.left + winOffsetX;
      let elemOffsetY = elemRect.top + winOffsetY;
      let viewWidth = Math.max(doc.documentElement.clientWidth, win.innerWidth || 0);
      let viewHeight = Math.max(doc.documentElement.clientHeight, win.innerHeight || 0);
      let xVisible;
      let yVisible;

      if (elemOffsetY <= winOffsetY) {
        if (elemOffsetY + elemHeight >= winOffsetY) {
          yVisible = true;
        }
      } else if (elemOffsetY >= winOffsetY) {
        if (elemOffsetY <= winOffsetY + viewHeight) {
          yVisible = true;
        }
      }

      if (elemOffsetX <= winOffsetX) {
        if (elemOffsetX + elemWidth >= winOffsetX) {
          xVisible = true;
        }
      } else if (elemOffsetX >= winOffsetX) {
        if (elemOffsetX <= winOffsetX + viewWidth) {
          xVisible = true;
        }
      }

      return xVisible && yVisible;
    }

    function checkImage() {
      angular.forEach(elements, (obj, key) => {
        let iElement = obj.iElement;
        let $scope = obj.$scope as LazySrcScope ;
        if (isVisible(iElement)) {
          iElement.attr('src', $scope.ftvLazySrc);
        }
      });
    }

    $win.bind('scroll', checkImage);
    $win.bind('resize', checkImage);
    setInterval(checkImage, 500);

    function onLoad() {
      let $el = angular.element(this);
      let uniqueId = getUid($el as ng.IAugmentedJQuery );

      $el.css('opacity', 1);

      if (elements.hasOwnProperty(uniqueId)) {
        delete elements[uniqueId];
      }
    }

    return {
      restrict: 'A',
      scope: {
        ftvLazySrc: '@',
        animateVisible: '@',
        animateSpeed: '@'
      },
      link: ($scope: LazySrcScope, iElement) => {
        iElement.bind('load', onLoad);

        $scope.$watch('ftvLazySrc', () => {
          let speed = '1s';
          if ($scope.animateSpeed != null) {
            speed = $scope.animateSpeed;
          }

          let uniqueId = getUid(iElement);
          iElement.css({
            'opacity': 0,
            '-webkit-transition': 'opacity ' + speed,
            'transition': 'opacity ' + speed
          });
          elements[uniqueId] = { iElement, $scope };
        });

        $scope.$on('$destroy', () => {
          iElement.unbind('load');
          let uniqueId = getUid(iElement);
          if (elements.hasOwnProperty(uniqueId)) {
            delete elements[uniqueId];
          }
        });
      }
    };
  }
}
