import lib from '~/api-shopify/api-shopify.module';
import {ProductQueryParams, ProductQuery } from '~/api-shopify/product-query-params';
import { IHttpPromise, IPromise } from 'angular';
import { WebworkerService } from '~/configurator/web-worker/web-worker.service';

export interface CreateProductResult {
  id: number;
  sku: string;
}

// back end is using mongo and its not designed to query across collections so need to include the
// the productType in this api which then corresponds to the collection in mongo
@lib.inject('$http', WebworkerService ).service
export class ShopifyHttpService {
  constructor(
    private $http: ng.IHttpService,
    private webworkerService: WebworkerService,
  ) {
    // user gzip compression on gets to reduce large initial json transfer
   // $http.defaults.headers.get['Accept-Encoding'] = "gzip,deflate";
  }

  public getAllCollections( productType: string ){
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/collection/` ) );
  }

  public getCollections(collectionIds: Array<string>, productType: string, fields?: string) {
    const ids= collectionIds.join(',');
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/collection/?collection_ids=${ids}`) );
  }
  public  getCollection(collectionId: string, productType: string) {
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/collection/${collectionId}/collection`) );
  }

  public getProductById( productId: string, productType: string, fields?: string) {
    let params = '';
    if (fields) {
      params += `&fields=${fields}`;
    }
    return this.pauseImagePreCaching(  this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/product/${productId}/product`) );
  }
  public getProductBySku( sku: string, productType: string, fields?: string) {
    let params = '';
    if (fields) {
      params += `&fields=${fields}`;
    }
    // this is being hijacked for sku temporarily until all art products come from shopify
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/product/${sku}/sku`) );
  }

  // retrieves a list of products in a collection based on a list of skus
  public getProductsBySkuList( skus: string[], productType: string, ) {
    let skuString = skus.toString();
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/${productType}/product/list/skus/?skus=${skuString}`) );
  }

  public getProducts( params: ProductQueryParams ) {
    const paramString = ProductQuery.getQuery( params );
    return this.pauseImagePreCaching(   this.$http.get<any>(`${window.shopifyCacheRoot}/api/art/product/?productId=${paramString}`) );
  }

  /*
   * See: https://help.shopify.com/en/api/reference/metafield
   *  for possible metafield types - have not included all here
   */
  getGetMetafields( id: string, type: string){
    return this.pauseImagePreCaching( this.$http.get<any>(`${window.shopifyCacheRoot}/api/products/get-metafields?id=${id}&type=${type}`) );
  }

  private unpauseImagePreCaching(){
    if ( this.webworkerService.self ) {
      this.webworkerService.self.postMessage( 'resume_worker' );
    }
  }

  private pauseImagePreCaching ( webCall: IHttpPromise<any> ) {
    // do something to pause caching
    if ( this.webworkerService.self ) {
      this.webworkerService.self.postMessage( 'pause_worker' );
    }
    return webCall.then ( ( result ) => {
      this.unpauseImagePreCaching();
      return result.data;
    });
  }
}
