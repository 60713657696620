
/**
 * A generic class to construct queries based on shopify product api
 * This is not a part of the shopify api but a helper function
 */
export interface ProductQueryParams{
 ids?: string;
 collectionId?: string;
 limit?: string;
 page?: string;
 sinceId?: string;
 title?: string;
 vendor?: string;
 handle?: string;
 productType?: string;
 fields?: string;
 createdAtMin?: string;
 createdAtMax?: string;
 updatedAtMin?: string;
 updatedAtMax?: string;
 publishedAtMin?: string;
 publishedAtMax?: string;
 publishedStatus?: string;
}

export class ProductQuery{

public static getQuery(params: ProductQueryParams) {
 const ids = [ 'ids', params.ids];
 const collectionId = [ 'collection_id', params.collectionId];
 const limit  = [ 'limit', params.limit];
 const page  = [ 'page', params.page ];
 const sinceId  = [ 'since_id', params.sinceId];
 const title  = [ 'title', params.title];
 const vendor  = [ 'vendor', params.vendor];
 const handle  = [ 'handle', params.handle];
 const productType  = [ 'product_type', params.productType];
 const fields  = [ 'fields', params.fields]; // add specific product fields to be returned
 const createdAtMin  = [ 'created_at_min', params.createdAtMin];
 const createdAtMax  = [ 'created_at_max', params.createdAtMax];
 const updatedAtMin  = [ 'updated_at_min', params.updatedAtMin];
 const updatedAtMax  = [ 'updated_at_max', params.updatedAtMax];
 const publishedAtMin  = [ 'published_at_min', params.publishedAtMin];
 const publishedAtMax  = [ 'published_at_max', params.publishedAtMax];
 const publishedStatus  = [ 'published_status', params.publishedStatus];

 const queryParams =  [ ids, collectionId, limit, page, sinceId, title, vendor, handle, productType, fields,
                    createdAtMin, createdAtMax, updatedAtMin, updatedAtMax, publishedAtMin,
                    publishedAtMax, publishedStatus].filter(val => val[1]).map( val => `${val[0]}=${encodeURIComponent(val[1])}`).join('&');

 return queryParams;

}
}
