import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { BrandingInfo } from '../../services/brandingInfo';
import { AuthenticationService } from '../../user/authentication.service';
import { DesignConfiguration } from '../../design/designConfiguration';
import app from '../../app';
import { optionPanes } from './options.routes';
import includes from 'lodash/includes';
import each from 'lodash/each';
import find from 'lodash/find';
import angular from 'angular';

@app.inject(
  'designConfiguration',
  AuthenticationService,
  '$mdDialog',
  DesignConfigurationService,
  '$state',
  'brandingInfo',
  '$scope',
  '$timeout'
).controller
export default class OptionsTabController {
  accordionPanes = optionPanes;

  constructor(
    public configuration: DesignConfiguration,
    private authenticationService: AuthenticationService,
    private $mdDialog: ng.material.IDialogService,
    private configurationService: DesignConfigurationService,
    public $state: ng.ui.IStateService,
    public brandingInfo: BrandingInfo,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService
  ) {
    // if ($state.current.name !== 'configuration.tab.options') {
    if ($state.current.name !== 'configuration.tab.design') {
      this.expandFromState($state.current);
    }

    $scope.$on('$stateChangeSuccess', (event: ng.IAngularEvent, toState: ng.ui.IState) => {
      this.expandFromState(toState);
    });
  }

  $onInit() { }

  showPane(pane: any) {
     return !includes(pane.hide, this.configuration.type);
  }

  toggle(pane: any) {
     const paneState = `configuration.tab.options.${pane.id}`;
     if (this.$state.includes(paneState)) {
      pane.expanded = false;
      this.$state.go('configuration.tab.design');
    }
    else {
      // makes the accordions for options only one open pane by toggling the others closed
      this.accordionPanes.forEach((p: any) => p.expanded = false);
      pane.expanded = true;
      this.$state.go(paneState);
    }
  }

  continue(e: MouseEvent) {
    if (this.brandingInfo.id === '0' || this.brandingInfo.settings.summaryTab || !this.authenticationService.restricted()) {
      this.$state.go('configuration.tab.summary');
    }
    else {
      this.configurationService.save().then(() => {
        this.$mdDialog.show(
          this.$mdDialog.alert()
            .parent(angular.element('#fmtv-app'))
            .clickOutsideToClose(true)
            .title('Design Updated')
            .textContent('Your saved design has been updated. Thanks for designing with us!')
            .ariaLabel('Design Updated')
            .ok('Ok')
            .targetEvent(e));
      });
    }
  }

  private expandFromState(state: ng.ui.IState) {
    const paneId = state.name.split('.')[3];
    each(this.accordionPanes, (p: any) => { p.expanded = false; });
    const pane: any = find(this.accordionPanes, { id: paneId });
    if (pane) {
      pane.expanded = true;
      setTimeout(() => {
        // not happy using jquery but it's a quick way to do the job
        const container = $('#config-options');
        container.scrollTop($(`#header-${paneId}`).offset().top - (container.offset().top + 20));
      }, 400);
    }
  }
}
