import app from '../../app';
import { AuthenticationService } from '../../user/authentication.service';
import { CartService } from '../cart.service';

@app.inject(CartService, AuthenticationService).component({ templateUrl: 'configurator/cart/button/cartButton.html' })
export class CartButton {
  constructor(
    private cartService: CartService,
    private authenticationService: AuthenticationService
  ) { }

  $onInit() { }

  show() {
    this.cartService.show();
  }

  count() {
    // return this.cartService.items.length;
  }

  restricted() {
    return this.authenticationService.restricted();
  }
}
