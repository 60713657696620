import app from '../../../app';

import { RightBarService } from '../../../rightBar/rightBar.service';
import { ArtWarrantyProduct } from '../../../../api-shopify/product-models/art-warranty-product';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', RightBarService, OptionsService).controller
export class OptionsArtWarrantyController {

  products: ArtWarrantyProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private rightBar: RightBarService,
    private optionsService: OptionsService
  ) {
    /* filter out lifetime warranty since it is no longer offered */
    ArtWarrantyProduct.list().then(p => this.products = p.filter( i => i.sku !== 'CONFIG-WRNTY-ART-DLX' ));
  }

  $onInit() { }

  select(product: ArtWarrantyProduct) {
    this.configuration.artWarranty.setProduct(product);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
    //  this.configuration.save();
    }
    this.optionsService.nextOption();
  }

  details(e: MouseEvent, product: ArtWarrantyProduct) {
    e.preventDefault();
    e.stopPropagation();

    this.rightBar.show({
      templateUrl: 'configurator/configuration/options/art-warranty/details.html',
      id: 'artWarrantyDetails',
      backButton: true,
      title: product.name,
      data: product.longDescription
    });
  }
}
