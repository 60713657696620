import { DesignConfigurationService } from '../../design/designConfiguration.service';
import app from '../../app';
import { DesignTypes } from '~/configurator/design/designTypes';
import isEmpty from 'lodash/isEmpty';

@app.inject('$mdDialog', DesignConfigurationService, '$rootScope').controller
export default class CustomLineController {

  name: string;
  description: string;
  price: number;
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private designConfigurationService: DesignConfigurationService
  ){ }

  $onInit(){ }

  add() {
    this.designConfigurationService.designConfiguration.addCustomLine(this.name, this.description, this.price);
    this.$mdDialog.hide();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
