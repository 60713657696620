import { DesignConfiguration } from '../../../design/designConfiguration';
import { ArtProduct } from '../../../../api-shopify/product-models/art-product';
import app from '../../../app';
import remove from 'lodash/remove';
import { DesignTypes } from '../../../design/designTypes';
import { SwymWishListService } from '../../../services/swymWishList.service';
import { Product } from '~/api-shopify/product-models/product';

@app.inject(
  '$scope',
  SwymWishListService,
  '$rootScope',
  '$location',
  '$element',
  '$state'
).component({
  bindings: {
    configuration: '<',
  },
  templateUrl: 'configurator/configuration/design/art-bar/art-bar.html'
})
export default class ArtBarComponent {
  artList: ArtProduct[];
  configuration: DesignConfiguration;
  constructor(
    private $scope: ng.IScope,
    private swymWishListService: SwymWishListService,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $element: ng.IAugmentedJQuery,
    private $state: ng.ui.IStateService,
  ) {
    $scope.$watch(() => this.configuration.artwork && this.configuration.artwork.product && this.configuration.artwork.product.sku, () => this.addNewSelection());
    $rootScope.$on('wishlistIsDirty', (e, newSkuList) => {
      if ( newSkuList ) {
        this.updateEntries(newSkuList);
      }
    });
  }

  $onInit() {
    this.$scope.$on('wishlistUpdate', function (e, time) {
      this.updateEntries();
    });
    this.updateEntries();
  }

  updateEntries(newSkuList: string[] = null) {

    // const viewMore = new ArtProduct( {sku:'viewmore', layerUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', thumbnailUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', previewUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png'});
    const getDefaultArt = ArtProduct.list()
      .then(art => {
        this.artList = art.filter(artItem => ArtProduct.defaultSkus.includes(artItem.sku));
        if (newSkuList) {
          let artItems = newSkuList.filter((sku: any) => sku.startsWith('ART-'));
          let artWish = art.filter(a => artItems.includes(a.sku));
          this.artList = artWish.concat(this.artList);
        //  this.artList.push( viewMore );
          this.addNewSelection();
        } else {
          // load from internet because nothing is yet loaded locally
          this.swymWishListService.getWishListItems().then((items) => {
            let artItems = (items as any).filter((i: any) => {
              if (i.cprops.sku.startsWith('ART-')) {
                return i;
              }
            }).map((i: any) => {
              return i.cprops.sku;
            });
            let artWish = art.filter(a => {
              return artItems.includes(a.sku);
            });
            const merged = artWish.concat(this.artList);
            // remove any duplicates
            this.artList = merged.filter((item,index) => {
              return (merged.indexOf(item) === index);
           });
           // this.artList.push( viewMore );
            this.addNewSelection();
          });
        }
      });
  }

  addNewSelection() {
    if ( this.configuration && this.configuration.artwork && this.configuration.artwork.product && this.configuration.artwork.product.sku ) {
      if (this.artList) {
        const x = this.artList.find(a => a.sku === this.configuration.artwork.product.sku);
        if (!x) {
          this.artList.unshift(this.configuration.artwork.product);
        }
      }
    }
  }
  price(art: ArtProduct) {
    // return this.configuration.linerPrice(liner);
  }

  select(art: ArtProduct) {
  //  if ( art.sku === 'viewmore') {
  //    this.$state.go('configuration.tab.design.art');
  //  } else {
      this.configuration.artwork.setProduct(art);
  //  }
  }
  formatItemPrice( item: ArtProduct ) {
    if ( !item.price ) return '';
    return '$' + item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
