
import app from '../app';
import jquery from 'jquery';

@app.inject(
  '$rootScope'
).service

export class CssAnimationService {

  constructor(
    private $rootScope: ng.IRootScopeService,
  ) { }

  public addElementToCart(elementToAddToCart: JQuery, finishedCallBack: any = null, offsetTop: number = null, offsetLeft: number = null, sizeMultiplier: number = null) {
    const cart = $('.mini-cart-icon');
    const elementToAnimate = $(elementToAddToCart);
    // const elementToAnimate = $(self).parent();  // need 'something' to animate and don't have an image at present

    if (elementToAnimate) {
      const imgclone = elementToAnimate.clone()
        .offset({
          top: offsetTop || elementToAnimate.offset().top,
          left: offsetLeft || elementToAnimate.offset().left
         })
        .css({
          //  'opacity': '0.5',
          'position': 'absolute',
          'height': (sizeMultiplier * 150) + 'px',
          'width': (sizeMultiplier * 150) + 'px',
          'z-index': '199900'
        })
        .appendTo($('body'));

      imgclone.animate({
        'top': cart.offset().top + 10,
        'left': cart.offset().left + 10,
        'width': 75,
        'height': 75
      }, 1000, 'linear', () => {
        imgclone.animate({
          'width': 0,
          'height': 0
        }, 'linear', () => {
          imgclone.detach();
          return '';
        });
      }); // 'easeInOutExpo' <== requires jquery-ui so not using // linear || swing included
      setTimeout(() => {
        cart.addClass('heartbeat');
        setTimeout(() => {
          cart.removeClass('heartbeat');
          if ( finishedCallBack ) {
            // the callback should at least call (or be)`this.$rootScope.$emit('force-cart-count-update');` to force the cart counter to update
            finishedCallBack();
          }
        }, 2000);
      }, 1500);
    }

  }
}
