import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { ArtControlProduct } from '../../../../api-shopify/product-models/art-control-products';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', OptionsService).controller
export default class OptionsArtControlController {
  artControls: ArtControlProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private optionsService: OptionsService
  ) {
    ArtControlProduct.list().then(c => {
      this.artControls = c;
    });
  }

  $onInit() { }

  select(artControl: ArtControlProduct) {
    this.configuration.artControl.setProduct(artControl);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
    //  this.configuration.save();
    }
    this.optionsService.nextOption();
  }
}
