import { ConfigLineKinds } from '../../design/lines';
import app from '../../app';
import './tv/tv.controller';
import './speaker/speaker.controller';
import './installation/installation.controller';
import './depth/depth.controller';
import './art-control/artControl.controller';
import './edge-finishing/edgeFinishing.controller';
import './cut-glass-accessories/cutGlassAccessories.controller';
import './backing-options/backingOptions.controller';
import './mounting-options/mountingOptions.controller';
import './art-warranty/artWarranty.controller';
import './mirror-warranty/mirrorWarranty.controller';
import each from 'lodash/each';
import camelCase from 'lodash/camelCase';
import { DesignTypes } from '../../design/designTypes';
import { PasswordChangeController } from '~/ui/passwordChange/passwordChange.controller';

export const optionPanes = [
  {
    label: 'TV Control',
    id: 'tv',
    lineKind: ConfigLineKinds.TvControl,
    hide: [DesignTypes.CutGlass],
    scrollToElementId: 'header-tv' // this is duplicated in the line object and should match
  },
  {
    label: 'Art Control',
    id: 'art-control',
    lineKind: ConfigLineKinds.ArtControl,
    hide: [DesignTypes.CutGlass, DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror],
    scrollToElementId: 'art-control-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Side Depth',
    id: 'depth',
    lineKind: ConfigLineKinds.SideDepth,
    hide: [DesignTypes.CutGlass],
    scrollToElementId: 'side-depth-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'External Speaker Bar Accomodation',
    shortLabel: 'Speaker Bar',
    id: 'speaker',
    lineKind: ConfigLineKinds.SpeakerBar,
    hide: [DesignTypes.CutGlass, DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror],
    scrollToElementId: 'speaker-bar-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Warranty',
    id: 'art-warranty',
    lineKind: ConfigLineKinds.ArtWarranty,
    hide: [DesignTypes.CutGlass, DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror],
    scrollToElementId: 'art-warranty-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Warranty',
    id: 'mirror-warranty',
    lineKind: ConfigLineKinds.MirrorWarranty,
    hide: [DesignTypes.CutGlass, DesignTypes.FrameOnly, DesignTypes.FrameWithArt, DesignTypes.MetroArt],
    scrollToElementId: 'mirror-warrant-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Installation Method',
    id: 'installation',
    lineKind: ConfigLineKinds.Installation,
    hide: [DesignTypes.CutGlass],
    scrollToElementId: 'installation-method-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Edge Finishing',
    id: 'edge-finishing',
    lineKind: ConfigLineKinds.EdgeFinish,
    hide: [DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror, DesignTypes.FrameWithArt, DesignTypes.MetroArt],
    scrollToElementId: 'edge-finish-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Backing Options',
    id: 'backing-options',
    lineKind: ConfigLineKinds.BackingOption,
    hide: [DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror, DesignTypes.FrameWithArt, DesignTypes.MetroArt],
    scrollToElementId: 'backing-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Mounting Options',
    id: 'mounting-options',
    lineKind: ConfigLineKinds.MountingOption,
    hide: [DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror, DesignTypes.FrameWithArt, DesignTypes.MetroArt],
    scrollToElementId: 'mounting-option-scroll-to' // this is duplicated in the line object and should match
  },
  {
    label: 'Accessories',
    id: 'cut-glass-accessories',
    lineKind: ConfigLineKinds.CutGlassAccessory,
    hide: [DesignTypes.FrameOnly, DesignTypes.FrameWithMirror, DesignTypes.FramelessMirror, DesignTypes.MetroMirror, DesignTypes.FrameWithArt, DesignTypes.MetroArt],
    scrollToElementId: 'cut-glass-accessory-option-scroll-to' // this is duplicated in the line object and should match
  }
];

app.inject('$stateProvider').config((
  $stateProvider: ng.ui.IStateProvider
) => {

  function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  each(optionPanes, pane => {
    const state: any = {
      url: window.fmtvApp.standalone && `/${pane.id}`,
      views: {},
      parent: 'configuration.tab.design',
      resolve: {
        $title: () => pane.label
      }
    };
    state.views[pane.id] = {
      templateUrl: `configurator/configuration/options/${pane.id}/${camelCase(pane.id)}.html`,
      controller: `Options${capitalize(camelCase(pane.id))}Controller`,
      controllerAs: '$ctrl',
      scrollToElementId: pane.scrollToElementId
    };
    $stateProvider.state(`configuration.tab.options.${pane.id}`, state);
  });
});
