import angular from 'angular';
import { Analytics } from '~/configurator/analytics';
import app from '~/configurator/app';
import { CaptureFormService } from '~/configurator/configuration/captureForm/captureForm.service';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import { HubspotService } from '~/configurator/services/hubspot.service';
import { AuthenticationService } from '~/configurator/user/authentication.service';

@app.inject(
  HubspotService,
  '$timeout',
  '$mdDialog',
  CaptureFormService,
  '$state',
  '$scope',
  DesignConfigurationService,
  '$analytics',
  AuthenticationService,
  LoadingIndicatorService
).component({
  templateUrl: 'configurator/configuration/captureForm/unbranded/unbrandedCaptureForm.html',
  bindings: {
    inDialog: '<'
  }
})
export class UnbrandedCaptureForm {

  inDialog: boolean;
  skipSave: boolean;
  continuing = false;
  error: string;

  firstName: string;
  email: string;
  persona: string;

  private newAccount: boolean;
  private defaultPassword = 'FTVuser';
  private firstStepForm: ng.IAugmentedJQuery;

  constructor(
    private hubspot: HubspotService,
    private timeout: ng.ITimeoutService,
    private $mdDialog: ng.material.IDialogService,
    private captureFormService: CaptureFormService,
    private state: ng.ui.IStateService,
    $scope: ng.IScope,
    private designConfigurationService: DesignConfigurationService,
    private $analytics: any,
    private authentication: AuthenticationService,
    private loadingIndicator: LoadingIndicatorService
  ) {
    hubspot.createForm('fc8d5da1-ce5c-426d-bff3-b4c5cc924e93').then(f => this.firstStepForm = f);

    $scope.$watch(() => captureFormService.visible, visible => {
      if (visible) {
        this.firstName = null;
        this.email = null;
        this.skipSave = null;
        this.newAccount = null;
        this.persona = null;
      }
    });
  }

  $onInit() { }

  continue() {
    this.captureFormService.hideCaptureForm = true;

    if (this.skipSave) {
      this.captureFormService.visible = false;
      this.$mdDialog.hide();
      this.state.go('configuration.tab.options');
      return;
    }

    this.authentication.capturedEmail = this.email;

    this.save();
  }

  private save() {
    this.loadingIndicator.show();
    this.designConfigurationService.save()
      .then(configId => {
        this.timeout(() => {
          this.hubspot.setValue(this.firstStepForm, 'firstname', this.firstName);
          this.hubspot.setValue(this.firstStepForm, 'email', this.email);
          this.hubspot.setValue(this.firstStepForm, 'saved_design_id', this.designConfigurationService.designConfiguration.number);
          this.hubspot.setValue(this.firstStepForm, 'saved_design_url', this.designConfigurationService.designConfiguration.url + '/design');
          this.hubspot.setValue(this.firstStepForm, 'hs_persona', this.persona);
          this.hubspot.setValue(this.firstStepForm, 'new_account', this.newAccount);
          // this.hubspot.setValue(this.firstStepForm, 'saved_design_amount', this.config.current.priceWithShipping);
          this.hubspot.setValue(this.firstStepForm, 'saved_design_type', this.designType());
          this.firstStepForm.submit();
          this.$analytics.eventTrack(Analytics.actions.formSubmitted, { category: Analytics.category, label: 'HubSpot Capture Form' });
        });
      })
      .then(() => this.loadingIndicator.hide())
      .then(() => {
        this.captureFormService.visible = false;
        this.$mdDialog.hide();
        if (!this.inDialog) {
          this.state.go('configuration.tab.options').then(() => {
            this.$mdDialog.show(
              this.$mdDialog.alert()
                .parent(angular.element('#fmtv-app'))
                .clickOutsideToClose(true)
                .title('Design Saved')
                .textContent('Your design has been saved, we have sent you a link to it by email. If you do not see it, please check your spam folder.')
                .ariaLabel('Design Saved')
                .ok('Ok'));
          });
        }
      })
      .catch(() => this.loadingIndicator.hide());
  }

  private designType() {
    switch (this.designConfigurationService.designConfiguration.type as any) {
      case 'frame with art':
        return 'Art';
      case 'frame with mirror':
      case 'metro mirror':
      case 'frameless mirror':
        return 'Mirror';
      case 'frame only':
        return 'Frame_Only';
    }
  }
}
