import app from './app';
import ConfigurationLibraryController from './configurationLibrary/configurationLibrary.controller';
import ErrorController from './error/error.controller';
import AccountSettingsController from './accountSettings/accountSettings.controller';
import LayoutController from './layout/layout.controller';
import { BrandingService } from './services/branding.service';

app
  .inject(
  '$urlRouterProvider',
  '$stateProvider',
  '$locationProvider'
  )
  .config((
    $urlRouterProvider: ng.ui.IUrlRouterProvider,
    $stateProvider: ng.ui.IStateProvider,
    $locationProvider: ng.ILocationProvider
  ) => {

    if (window.fmtvApp.standalone) {
      $locationProvider.html5Mode({ enabled: true, requireBase: false, rewriteLinks: false });
      // summary route was removed in latest changes so redirect to design
      $urlRouterProvider.when('/configuration/:configurationId/summary', '/configuration/:configurationId/design');
      // this is forcing the url back to the base address of the app
      $urlRouterProvider.otherwise(() => '/' + window.location.search);
    }
    else {
      $locationProvider.html5Mode({ enabled: true, requireBase: false, rewriteLinks: false });
    }

    $stateProvider
      .state('layout', {
        abstract: true,
        templateUrl: 'configurator/layout/layout.html',
        controller: LayoutController,
        controllerAs: '$ctrl',
        url: window.fmtvApp.standalone && '?store',
        params: {
          store: undefined
        },
        resolve: {
          $title: () => 'TV Frame Designer',
          brandingInfo: [
            BrandingService.name, '$stateParams',
            ($ftvBranding: BrandingService, $stateParams: ng.ui.IStateParamsService) => {
              return $ftvBranding.setStore($stateParams['store']);
            }
          ]
        }
      })
      .state('configurationLibrary', {
        url: window.fmtvApp.standalone && '/configurations',
        parent: 'layout',
        views: {
          'main@layout': {
            templateUrl: 'configurator/configurationLibrary/configurationLibrary.html',
            controller: ConfigurationLibraryController,
            controllerAs: '$ctrl'
          }
        },
        resolve: {
          $title: () => 'Saved Configurations'
        }
      })
      .state('error', {
        url: window.fmtvApp.standalone && '/error?message',
        params: {
          message: undefined,
          configurationId: undefined
        },
        parent: 'layout',
        views: {
          'main@layout': {
            templateUrl: 'configurator/error/error.html',
            controller: ErrorController,
            controllerAs: '$ctrl'
          }
        },
        resolve: {
          $title: () => 'Unexpected Error'
        }
      })
      .state('accountSettings', {
        url: window.fmtvApp.standalone && '/account',
        parent: 'layout',
        views: {
          'main@layout': {
            templateUrl: 'configurator/accountSettings/accountSettings.html',
            controller: AccountSettingsController,
            controllerAs: '$ctrl'
          }
        }
      })
      .state('initial-load', {
        url: window.fmtvApp.standalone && '/?store',
        params: {
          store: undefined
        }
      });
  });
