import { RushProduct } from '../../../api-shopify/product-models/rush-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import moment from 'moment';
import find from 'lodash/find';

export interface RushLineData {
  ship_id: string;
  sku?: string;
}

export class RushLine extends BaseLine<RushLineData> {
  kind = ConfigLineKinds.Rush;
  category = 'Order';
  name = 'Production Time';
  order = 110;
  product: RushProduct;
  scrollToElementId = 'rush-line-option-scroll-to';

  get valid() { return true; }

  deserialize(data?: RushLineData) {
    this.data = data || { ship_id: '1' };
    if (this.data && this.data.ship_id) {
      return RushProduct.get(parseInt(this.data.ship_id).toString()).then(p => this.setProduct(p));
    }
    return this.q.resolve();
  }

  refresh() {
    if (this.data && this.data.ship_id) {
      return RushProduct.list()
        .then(products => {
          let found = find(products, p => p.code === this.data.ship_id) || find(products, p => p.code === '1');
          return found;
          })
        .then(p => this.setProduct(p, true)) // we don't want to notify of the update
        .then(() => this.refreshPrice());
    }
    else {
      return this.q.resolve();
    }
  }

  setProduct(product: RushProduct, noTrigger = false) {
    if (!product) {
      return;
    }
    this.product = product;
    this.data = {
      ship_id: product.code,
      sku: (<any>product).sku
    };

    let p = <any>product;

    let t: string;
    if (p.minDay) {
      t = ` ${p.minDay} to ${p.maxDay} days`;
    }
    else {
      t = ` ${p.maxDay} days`;
    }

    t += ` - ${p.name}`;
    this.description = t;

    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.Rush] = RushLine;
