import lib from '~/api-shopify/api-shopify.module';
// import { ApiProductsService } from '~/api-shopify/apiProducts.service';
import find from 'lodash/find';
import isFinite from 'lodash/isFinite';
import pick from 'lodash/pick';
import map from 'lodash/map';
import { PricingService } from '~/configurator/services/pricing.service';

// tslint:disable-next-line:interface-name
export interface IDesignConfiguration {
  designConfiguration: {
    type: string;
    tvModel: {
      sizeForPrice: number,
      data: {
        diagscreensize: number
      }
    },
    total: number;
    artwork: any;
    frame: {
      frameProduct: {
        isArtisan: () => ng.IPromise<boolean>
        isHardwood: () => boolean
        isHardwoodTraditional: () => boolean
        isHardwoodBaroque: () => boolean
      }
    };
    cutGlass: {
      surface: number;
      data: {
        width?: number;
        height?: number;
        system?: 'inches' | 'mm';
      }
    }
  };
}

export abstract class Product {

  protected static strip(model: any) {
    let stripped: any = pick(model, ['code', 'name', 'associations', 'id', 'images', 'categoryId', 'variations']);
    stripped.properties = map(model.properties, (p: any) => ({
      name: p.name,
      values: [{
        value: p.values[0].value,
        valueType: p.values[0].valueType
      }]
    }));
    stripped.reviews = map(model.reviews, (r: any) => ({
      content: r.content,
      reviewType: r.reviewType
    }));
    return stripped;
  }
  public _id: number;
  @lib.inject('$rootScope').property
  protected rootScope: ng.IRootScopeService;

  // @lib.inject(ApiProductsService).property
  // protected products: ApiProductsService;

  @lib.inject('$q').property
  protected q: ng.IQService;

  @lib.inject('DesignConfigurationService').property
  protected designService: IDesignConfiguration;

  protected _price: number = null; // price is used to cache the calculated value and should not be assigned to directly
  protected _basePrice: number; // base price is the product price used in the price calculation
  protected basePricePromise: ng.IPromise<number>;
  protected _sizeOnPriceCheck: number;
  protected _longDescription: string;
  protected _shortDescription: string;
  protected _keywords: string;
  protected _name: string;
  protected _sku: string;
  protected  _thumbnailUrl: string;
  protected _collectionId: string;
  protected _sortPosition: string;

  get id(){
    return this._id;
  }
  set id( value: number){
    this._id = value;
  }
  get price() {
    let tvSize: number = null;
    let config = this.designService.designConfiguration;
    let tv = config.tvModel;
    if (config) {
      if (config.tvModel && config.tvModel.sizeForPrice) {
        tvSize = config.tvModel.sizeForPrice;
      }
      else if (config.cutGlass && config.cutGlass.data &&
        isFinite(config.cutGlass.data.width) && isFinite(config.cutGlass.data.height)) {
        tvSize = config.cutGlass.data.width * config.cutGlass.data.height;
      }
    }
    if (this._sizeOnPriceCheck !== tvSize) {
      this._sizeOnPriceCheck = tvSize;
      this.guaranteedPrice().then(p => this._price = p);
    }
    return this._price;
  }

  get shortDescription() {
    return this._shortDescription;
  }

  get longDescription() {
    return this._longDescription;
  }

  get sortPosition(){
    return this._sortPosition;
  }

  constructor( productData: any  ) {
    this._id = productData.id;
    this._basePrice = 0;
    if ( productData.price ) {
      this._basePrice = parseInt( productData.price) ;
    }
    this._keywords = productData.keywords || '';
    this._name = productData.name === 'Default Title' ? productData.shortDescription : productData.name;
    this._sku = productData.sku;
    this._thumbnailUrl = productData.thumbnailUrl ? productData.thumbnailUrl : '';
    this._collectionId  = productData.collectionId;
    this._longDescription = productData.longDescription;
    this._shortDescription = productData.shortDescription;
    this._sortPosition = productData.sortPosition;

  }

  getKeywords(){
    return this._keywords;
  }

  getKeywordsForDisplay() {
    if ( !this._keywords) {
      return '';
    }
    const keywordArray = this._keywords.split(',');
    let temp = keywordArray.filter( e => !e.includes('^'));
    return temp.join(',');

  }
  abstract guaranteedPrice(): ng.IPromise<number>;

  getProperty(propertyName: string): any {
   return "shouldn't have to use";
  }

  getReview(reviewName: string) {
    // let review = find(this.product.reviews, r => r.reviewType === reviewName);
    // return review ? review.content : '';
    return '';
  }

  public get code() {
    return this._sku;
  }

  public get sku() {
    return this._sku;
  }

  public get name(){
    return this._name;
  }
  public get collectionId(){
    return this._collectionId;
  }
  public get thumbnailUrl(){
    return this._thumbnailUrl;
  }
  // this should be readonly but adding a set to adjust image urls for size client side
  public set thumbnailUrl( value: string){
    this._thumbnailUrl = value;
  }

  /**
   * Returns the 'base price' whatever that may be in the context of given product
   * This is typically overridden per product type
   */
  protected basePrice() {
    if (!this.basePricePromise) {
      // this.basePricePromise = this.products.getPrice(this.productData).then(p => p ? p.list : 0);
      this.basePricePromise = this.q.resolve(this._basePrice);
    }
    return this.basePricePromise;
  }

  private convertValue(value: string, type: string): any {
    switch (type) {
      case 'Number':
        return parseFloat(value);
      case 'ShortText':
      case 'LongText':
        return value;
      default:
        return value;
    }
  }
}
