import { DesignConfiguration } from '../../../design/designConfiguration';
import { FrameProduct } from '../../../../api-shopify/product-models/frame-product';
import app from '../../../app';
import remove from 'lodash/remove';
import { DesignTypes } from '../../../design/designTypes';
import { SwymWishListService } from '../../../services/swymWishList.service';
import { RushProduct } from '~/api-shopify/product-models/rush-product';

@app.inject(
  '$state',
  '$scope',
  SwymWishListService,
  '$rootScope',
  '$timeout'
).component({
  bindings: {
    configuration: '<',
  },
  templateUrl: 'configurator/configuration/design/frame-bar/frame-bar.html'
})
export default class FrameBarComponent {
  frames: FrameProduct[];
  configuration: DesignConfiguration;
  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private swymWishListService: SwymWishListService,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService,
  ) {

    $rootScope.$on('wishlistIsDirty', (e, newSkuList) => {
      if (newSkuList) {
        this.updateEntries(this.configuration.type, newSkuList);
      }
    });
    this.$timeout(() => {
      $scope.$watch(() => this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku, () =>
        this.addNewSelection()
      );
    });

  }

  $onInit() {
    this.$scope.$on('wishlistUpdate', function (e, time) {
      this.updateEntries();
    });

    this.$scope.$watch(() => this.configuration.type, (type: DesignTypes) => {
      this.updateEntries(type);
    });
    this.updateEntries(this.configuration.type);
  }
  updateEntries(designType: DesignTypes, newSkuList: string[] = null) {
        let defaults = FrameProduct.defaults;
        if (designType === DesignTypes.MetroArt || designType === DesignTypes.MetroMirror) {
          defaults = FrameProduct.defaultsMetro;
        }

        // const viewMore = new FrameProduct( {sku:'viewmor', layerUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', thumbnailUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png', previewUrl: window.fmtvAssetsRoot + 'assets/configurator/view-more.png'});
        const getDefaultFrame = FrameProduct.list()
          .then( frameList => {
            this.frames = frameList.filter(frameItem => defaults.includes(frameItem.sku));
            if (newSkuList) {
              let frameItems = newSkuList.filter((sku: any) => sku.startsWith('FRAME-'));
              let frameWish = frameList.filter(a => frameItems.includes(a.sku));
              this.frames = frameWish.concat(this.frames);
              // this.frames.push( viewMore );
              this.addNewSelection();
            } else {
              // load from internet because nothing is yet loaded locally
              this.swymWishListService.getWishListItems().then((items) => {
                let frameItems = (items as any).filter((i: any) => {
                  if (i.cprops.sku.startsWith('FRAME-')) {
                    return i;
                  }
                }).map((i: any) => {
                  return i.cprops.sku;
                });

                let frameWish = frameList.filter(a => {
                  return frameItems.includes(a.sku);
                });

                let merged = frameWish.concat(this.frames);
                // remove any duplicates
                this.frames = merged.filter((item,index) => {
                  return (merged.indexOf(item) === index);
               });

                // this.frames.push( viewMore );
                this.addNewSelection();
              });
            }
        });
  }

  addNewSelection() {
    if (this.frames && this.configuration && this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku) {
      const x = this.frames.find(a => a.sku === this.configuration.frame.frameProduct.sku);
      if (!x) {
        this.frames.unshift(this.configuration.frame.frameProduct);
      }
    }
  }
  price(frame: FrameProduct) {
    // return this.configuration.linerPrice(liner);
  }

  select(frame: FrameProduct) {
    // hack here
    // if an artisan frame is selected, the only rush option is 'standard'
    // and this is enforced in the expediate options (when selecting)
    // however when a non-artisan frame is selected AND a non-standard rush is picked
    // changing to an artisan frame needs to reset the selection back to 'standard'
    // Doing that here since there is no easy place to catch frame type changes
    // ALSO SEE SELECT() IN THE FRAME.CONTROLLER.TS
    if (frame && frame.isArtisanNonPromise()) {
      RushProduct.list().then(options => {
        this.configuration.rush.setProduct(options[0]);
      });
    }

    // if ( frame.sku === 'viewmore') {
    //   this.$state.go('configuration.tab.design.frame');
    // } else {
    this.configuration.frame.setFrameProduct(frame, null);
    // }
  }
  // formats the price on a thumbnail item
  formatItemPrice(item: FrameProduct) {
    if (!item.price) return '';
    return '$' + item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
