import { SampleProduct } from '../../api-shopify/product-models/sample-product';
import lib from '../ui.module';

/** this is virto not shopify */
@lib.inject('$http').service
export class SamplesService {
  constructor(
    private $http: ng.IHttpService,
  ) { }

  addToCart(sample: SampleProduct, name?: string) {

    sample.guaranteedPrice()
      .then(price => this.$http.post('/storefrontapi/cart/additem', {
        name: name || sample.name,
        quantity: 1,
        currency: 'USD',
        sku: sample.sku,
        listPrice: price,
        salePrice: price,
        salePriceWithTax: price,
        productId: sample.id,
        catalogId: sample.catalogId,
        imageUrl: sample.imgSrc
      })).then(
      () => { this.openCart(); },
      e => console.error(e)
      );
  }

  openCart() {
    if (window.top !== window.self) {
      window.parent.postEventMessage({ message: 'openCart' });
    }
    else if (window.postEventMessage) {
      window.postEventMessage({ message: 'openCart' });
    }
    else {
      location.href = `//${location.hostname}/?action=openCart`;
    }
  }
}
