import app from '../app';

@app.service
export class MiniLoadingIndicatorService {
  visible = false;
  messageLines = ['Updating Preview...'];

  private visibleCount = 0;
  show( msg?: string[]) {
    if ( msg ) {
      this.messageLines = msg;
    }
    this.visibleCount++;
    this.visible = true;
  }

  hide() {
    this.visibleCount--;
    if (this.visibleCount < 0) {
      this.visibleCount = 0;
    }
    this.visible = false;
  }
}
