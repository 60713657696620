import app from '../../app';

@app.inject('$mdDialog', 'clipboard', 'configurationUrl', 'configurationId').controller
export class CopyLinkController {
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private clipboard: any,
    public configurationUrl: string,
    public configurationId: number,
  ) {
  }

  $onInit() {}

  cancel() {
    this.$mdDialog.hide();
  }

  copy() {
    const link = this.configurationUrl + '/design';
    this.clipboard.copyText(link);
    this.$mdDialog.hide();
  }
}
