import { ArtWarrantyProduct } from '../../../api-shopify/product-models/art-warranty-product';
import { BaseLine, linesTable, ConfigLineKinds } from './baseLine';

export interface ArtWarrantyLineData {
  code?: string;
}

export class ArtWarrantyLine extends BaseLine<ArtWarrantyLineData> {
  kind = ConfigLineKinds.ArtWarranty;
  category = 'Options';
  name = 'Warranty';
  order = 120;
  editState = 'configuration.tab.options.art-warranty';
  product: ArtWarrantyProduct;
  scrollToElementId = 'art-warranty-option-scroll-to';
  get valid() { return !!this.product; }

  deserialize(data?: ArtWarrantyLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return ArtWarrantyProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: ArtWarrantyProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.ArtWarranty] = ArtWarrantyLine;
