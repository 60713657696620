import angular from 'angular';
import lib from '~/ui/ui.module';

interface OnImageLoadScope extends ng.IScope {
    ftvOnImageLoad: any;
}

@(lib.inject('$window', '$document').directive())
export class OnImageLoad {

    definition(
        $window: ng.IWindowService,
        $document: ng.IDocumentService
    ): ng.IDirective {
        let doc = $document[0];
        let body = doc.body;
        let win = $window;
        let $win = angular.element(win);
        let elements: any = [];

        return {
            restrict: 'A',
            scope: {
                ftvOnImageLoad: '&'
            },
            link: ($scope: OnImageLoadScope, iElement, attrs, ctrlr) => {
                // iElement.bind('load', onLoad);
                // track elements to be loaded so that if they are loaded more than once we don't pause longer than needed
                let load = true;
                if ( iElement[0].hasOwnProperty( 'currentSrc') && (iElement[0] as any).currentSrc === '//:0' ) {
                    load = false;
                }
                if ( load ) {

                elements.push( { kind: attrs['kind'], element: iElement, localScope: $scope });
// ng-on-loadstart="$ctrl.imageOnLoadStart(line)" ng-on-loadstart="$ctrl.imageLoaded(line)"  ng-on-loadend="$ctrl.imageLoadEnd(line)" ng-on-onerror="$ctrl.imageLoaderror()"
                iElement.bind('load', (e) => {
                    // call the function when image is loaded.
                    // scope.updateFn()("Directive Args");
                    const lineKind = attrs['kind'];
                    $scope.ftvOnImageLoad()(lineKind);
                });
                iElement.bind('loadstart', (e) => {
                    // alert( 'loadstart ' );
                });
                iElement.bind('error', (e) => {
                    // alert( 'error ' + JSON.stringify(e) );
                    console.error(
                        'could not load image ' + e.target.attributes.getNamedItem('kind')
                    );
                });
                $scope.$on('$destroy', () => {
                    iElement.unbind('error');
                    iElement.unbind('load');
                });
            }

                /*
                async function loadImage(url, elem) {
  return new Promise((resolve, reject) => {
    elem.onload = () => resolve(elem);
    elem.onerror = reject;
    elem.src = url;
  });
}
*/
            }
        };
    }
}
