import app from '../../../app';

import { DesignConfiguration } from '../../../design/designConfiguration';
import { BackingOptionProduct } from '../../../../api-shopify/product-models/backing-option-product';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', OptionsService).controller
export class OptionsBackingOptionsController {

  products: BackingOptionProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private optionsService: OptionsService
  ) {
    BackingOptionProduct.list().then(p => {
      this.products = p;
    });
  }

  $onInit() { }

  select(product: BackingOptionProduct) {
    this.configuration.backingOption.setProduct(product);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
    //  this.configuration.save();
    }
    this.optionsService.nextOption();
  }
}
