import lib from '~/ui/ui.module';

@lib.inject('$location').component({
  templateUrl: 'ui/branch-button/branch-button.component.html'
})
export class BranchButton {
  ref = localStorage['ref'];

  constructor(private $location: ng.ILocationService) {}

  reset() {
    localStorage['ref'] = 'prod';
    this.$location.search('ref', null);
    location.reload();
  }
}
