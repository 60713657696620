import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
// import { CommonCategories } from '../apiCategories.service';
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import sortBy from 'lodash/sortBy';

let listPromise: ng.IPromise<EdgeFinishProduct[]>;

export class EdgeFinishProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = EdgeFinishProduct.productsService.getAllEdgeFinishProduct()
      .then(products => sortBy(products, p => p.sortPosition).map((p: any) => new EdgeFinishProduct(p)));
  }

  static get(code: string) {
    return this.list().then(products => find(products, p => p.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  private _maxDimension: number;
  private _minPrice: number;
  private _manualPriceAdjustment = 1.06; // adjustment to the shopify price .. should be done in shopify but done here for now

  constructor(data: any) {
    super(data);
    this._maxDimension = data.maximumDimension;
    this._minPrice = data.minimumPrice;
  }
  // thumbnail is now in super
  // description is now in super

  get maxDimension() {
    return this._maxDimension || Number.MAX_VALUE;
  }

  get minPrice() {
    return this._minPrice || 0;
  }

  guaranteedPrice(): ng.IPromise<number> {
    let config = this.designService.designConfiguration;
    if (config && config.cutGlass && config.cutGlass.surface) {
      return this.basePrice().then(p => {
        return Math.max( this.minPrice * this._manualPriceAdjustment, ( config.cutGlass.surface * p ) * this._manualPriceAdjustment);
       });
    }
    return this.q.resolve(0);
  }
}
