import app from '../app';

// tslint:disable-next-line:only-arrow-functions
app.inject('$filter').filter(function money(
  $filter: ng.IFilterService
) {
  return (input: string | number): string => {
    if (input === '') {
      return '';
    }
    return $filter('currency')(<number>input);
  };
});
