import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import lib from '~/api-shopify/api-shopify.module';

let listPromise: ng.IPromise<MirrorWarrantyProduct[]>;

@lib.inject(ProductsApi).service
export class MirrorWarrantyProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = MirrorWarrantyProduct.productsService.getAllMirrorWarrantyProducts().then(
      (products: MirrorWarrantyProduct[]) =>  sortBy(products, p => p.sortPosition).map(p => new MirrorWarrantyProduct(p)));
  }

  static get(code: string) {
    return this.list().then( (warranties: MirrorWarrantyProduct[]) => find(warranties, w => w.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;
  // thumbnailUrl moved to super
  // description moved to super
  // longDescription moved to super

  guaranteedPrice() {
    return this.basePrice();
  }
}
