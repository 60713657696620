import app from '../app';
import { RightBarService, RightBar } from './rightBar.service';

@app.inject('$mdSidenav', RightBarService).component({
  templateUrl: 'configurator/rightBar/rightBar.html',
}, 'rightBar')
export class RightBarController {

  bars: RightBar[];

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    $ftvRightBar: RightBarService
  ) {
    this.bars = $ftvRightBar.bars;
  }

  $onInit() { }

  close(id: string) {
    this.$mdSidenav(id).close();
  }

}
