import app from '../../../app';

@app.component({
  templateUrl: 'configurator/configuration/frameType/tiles/externalLinkTypeTile.html',
  bindings: {
    title: '@',
    description: '@',
    design: '&',
    discover: '&',
    pricedFrom: '<',
    pricedFromUnits: '<',
    productionTime: '<'
  }
})
export class ExternalLinkTypeTile {
  pricedFrom: number[];
  productionTime: number;
  pricedFromUnits: string;
  getPricedFrom(){
    let priceString = '';
    priceString = '$' + this.pricedFrom[0];

    if ( this.pricedFrom.length > 1 ) {
      priceString += ' - $' + this.pricedFrom[1];
    }
    return priceString;
  }

  getProductionTime( productionTime: number ){
    if ( productionTime > 0 ) {
      return this.productionTime + ( productionTime === 1 ? ' week' : ' weeks' );
    } else {
      return 'Ships immediately';
    }
  }
}
