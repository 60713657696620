import app from '../../../app';
import { Product } from '~/api-shopify/product-models/product';

@app.component({
  templateUrl: 'configurator/configuration/options/panes/pane-header.component.html',
  bindings: {
    sectionName: '@',
    description: '<',
    price: '<',
    completed: '<',
    product: '<'
  }
})
export class PaneHeader {}
