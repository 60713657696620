import { CartService } from '../../cart/cart.service';
import { DesignConfiguration } from '../designConfiguration';
import { DesignConfigurationService } from '../designConfiguration.service';
import app from '../../app';

@app.inject(DesignConfigurationService, CartService, '$mdDialog', '$scope', '$state').controller
export class SavedController {

  configuration: DesignConfiguration;
  shadowImage = window.fmtvAssetsRoot + 'assets/configurator/shadow.png';

  constructor(
    private designConfigurationService: DesignConfigurationService,
    private cartService: CartService,
    private $mdDialog: ng.material.IDialogService,
    scope: ng.IScope,
    private $state: ng.ui.IStateService
  ) {
    this.configuration = designConfigurationService.designConfiguration;

    scope.$on('$stateChangeStart', () => {
      $mdDialog.hide();
    });
  }

  $onInit() { }

  addToCart() {
    this.cartService.addConfiguration(this.configuration);
  }

  email() {
    this.$mdDialog.hide();
    this.designConfigurationService.email(this.configuration.number);
  }

  link(e: MouseEvent) {
    this.designConfigurationService.showLink(e);
  }

  close() {
    this.$mdDialog.hide();
    this.$state.go('configuration.tab.summary');
  }
}
