import app from '../../app';
import angular from 'angular';
import { DesignConfiguration } from '../../design/designConfiguration';
import { FrameLine, ArtLine, LinerLine } from '~/configurator/design/lines';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';
import { FrameProduct } from '~/api-shopify/product-models/frame-product';
import { DesignTypes } from '~/configurator/design/designTypes';
import { CartService } from '~/configurator/cart/cart.service';
import { CssAnimationService } from '~/configurator/services/css-animation.service';
import { MirrorProduct } from '~/api-shopify/product-models/mirror-product';

@app.inject(
    '$q',
    '$scope',
    '$rootScope',
    CartService,
    CssAnimationService,
).component({
    bindings: {
        configuration: '=',
    },
    templateUrl: 'configurator/configuration/get-samples/get-samples.html'
})

export class GetSamples {
    totalPrice: number;
    frameCornerSample: SampleProduct;
    frameCornerSampleUrl: string;
    artSampleOnCanvas: SampleProduct;
    artSampleOnCanvasUrl: string;
    frame: FrameProduct;
    linerSample: SampleProduct;
    linerSampleUrl: string;
    selectedSamples: any[] = [];

    constructor(
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
        private cartService: CartService,
        private cssAnimationService: CssAnimationService,
        public configuration: DesignConfiguration,
    ) {
    }

    $onInit() {
        const configType = this.configuration.type;
        // if configuration type changes, reset the samples
        // this.$scope.$watch(() => this.configuration.type, (type) => {

        this.selectedSamples = [];
        let samplePromises = [];
        if (configType === DesignTypes.FrameOnly || configType === DesignTypes.FrameWithArt || configType === DesignTypes.FrameWithMirror || configType === DesignTypes.MetroArt || configType === DesignTypes.MetroMirror) {
            samplePromises.push( this.getFrameSample() );
        }
        if (configType === DesignTypes.FrameWithArt || configType === DesignTypes.MetroArt) {
            samplePromises.push( this.getArtSample() );
        }
        // this is almost instant.. no promise
        this.getLinerSample();

        if (configType === DesignTypes.CutGlass || configType === DesignTypes.FrameWithMirror || configType === DesignTypes.FramelessMirror || configType === DesignTypes.MetroMirror) {
            samplePromises.push( this.getMirrorSample() );
        }

        this.totalPrice = this.calcTotalPrice();

        return this.$q.all(samplePromises).then( results => {
            return results;
        });
    }

    getFrameSample() {
        if (this.configuration.frame && this.configuration.frame.frameProduct) {
            const sampleFound = this.configuration.frame.frameProduct.samples.filter((s: SampleProduct) => s.sku.indexOf('-CRNR-') > -1 || s.sku.endsWith('-CR'));
            if (sampleFound) {
                return sampleFound[0].guaranteedPrice().then(price => {
                    return this.cartService.getItemInventoryQuantity(sampleFound[0].id).then(quantity => {
                        if (quantity > 0) {
                            // remove any existing frame sample
                            this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'frame');
                            const sampleVariantId = sampleFound[0].id;
                            this.selectedSamples.push({ sample: sampleFound[0], type: 'frame', description: this.configuration.frame.frameProduct.name + ' Corner Sample', 'image': this.configuration.frame.frameProduct.thumbnailUrl, 'price': price, 'priority': 1, 'selected': true });
                            this.totalPrice = this.calcTotalPrice();

                            return this.$q.resolve();
                        } else {
                            // suggest a color chip sample if no corner sample as per kevin
                            const chipSample = this.configuration.frame.frameProduct.samples.filter((s: SampleProduct) => s.sku.endsWith('-CH'));
                            if (chipSample) {
                                return this.cartService.getItemInventoryQuantity( chipSample[0].id).then( chipQuantity => {
                                    if (chipQuantity > 0) {
                                        chipSample[0].guaranteedPrice().then( chipPrice => {
                                            this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'frame');
                                            const sampleVariantId = sampleFound[0].id;
                                            let sampleURL = chipSample[0].thumbnailUrl.replace('.jpg', '_x100.jpg');
                                            // using the general image as the sample image
                                            this.selectedSamples.push({ sample: chipSample[0], type: 'frame', description: this.configuration.frame.frameProduct.name + ' Color Sample', 'image': this.configuration.frame.frameProduct.thumbnailUrl, 'price': chipPrice, 'priority': 1, 'selected': true });
                                            this.totalPrice = this.calcTotalPrice();
                                            return this.$q.resolve();
                                        });
                                    } else {
                                        sampleFound[0].guaranteedPrice().then( chipPrice => {
                                            this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'frame');
                                            const sampleVariantId = sampleFound[0].id;
                                            this.selectedSamples.push({ sample: sampleFound[0], type: 'frame', description: this.configuration.frame.frameProduct.name + ' Corner Sample', 'image': this.configuration.frame.frameProduct.thumbnailUrl, 'price': price, 'priority': 1, 'selected': false, 'outOfStock': true });
                                            this.totalPrice = this.calcTotalPrice();
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
            }
        }
    }

    getArtSample() {
        if (this.configuration.artwork.product && this.configuration.artwork.product.samples) {
            // not offering art samples on card to keep it simple
            const sampleFound = this.configuration.artwork.product.samples.filter((s: SampleProduct) => s.name === 'Sample on Canvas');
            if (sampleFound) {
                return sampleFound[0].guaranteedPrice().then(price => {
                    // the Art samples we do not inventory  -- will allow here rather than updating shopify setting properly for now
                    // this.cartService.getItemInventoryQuantity(sampleFound[0].id).then(quantity => {
                    //     if (quantity > 0) {
                    this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'art');
                    this.selectedSamples.push({ sample: sampleFound[0], type: 'art', description: 'Art Sample', 'image': this.configuration.artwork.product.thumbnailUrl, 'price': price, 'priority': 3, 'selected': true });
                    this.totalPrice = this.calcTotalPrice();
                    return this.$q.resolve();
                    //     }
                    // });
                });
            }
        }
    }

    getLinerSample() {
        if (this.configuration.liner && this.configuration.liner.linerProduct && this.configuration.liner.linerProduct.samples.length > 0) {
            this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'liner');
            let linerSample = this.configuration.liner.linerProduct.samples[0]; // only one sample for a liner not multiple
            // liner samples inventory is not tracked
            // this.cartService.getItemInventoryQuantity(linerSample.id).then(quantity => {
            // if (quantity > 0) {
            this.selectedSamples.push({ sample: linerSample, type: 'liner', description: 'Liner Sample', 'image': this.configuration.liner.linerProduct.thumbnailUrl, 'price': 0, 'priority': 4, 'selected': true });
            this.totalPrice = this.calcTotalPrice();
            //  }
            // })
        }
    }

    getMirrorSample() {
        // mirror only has 'one sample'
        const mirrorSamples = [];
        return MirrorProduct.list().then((mirrors: MirrorProduct[]) => {

            const samplesPromises = [];
            // prices are resolved as a promise due to legacy vitro
            for (const m of mirrors) {
                if (m.samples && m.samples.length > 0) {
                    const sample = m.samples[0];
                    samplesPromises.push(sample.guaranteedPrice().then((price) => {
                        return { mirror: m, sample, price };
                    }));
                }
            }
            return Promise.all(samplesPromises).then((samplesWithPrices) => {
                this.selectedSamples = this.selectedSamples.filter(s => s.type !== 'mirror');
                for (const sample of samplesWithPrices) {
                    if (this.configuration.type === DesignTypes.CutGlass || this.configuration.type === DesignTypes.FramelessMirror) {
                        this.selectedSamples.push({ sample: sample.sample, type: 'mirror', description: sample.mirror.name + ' Mirror Sample', 'image': sample.mirror.thumbnailUrl, 'price': sample.price, 'priority': 2, 'selected': true });
                    } else { // other design with mirror
                        if (sample.mirror.name === this.configuration.mirror.product.name) {
                            this.selectedSamples.push({ sample: sample.sample, type: 'mirror', description: sample.mirror.name + ' Mirror Sample', 'image': sample.mirror.thumbnailUrl, 'price': sample.price, 'priority': 2, 'selected': true });
                        }
                    }
                }

                this.totalPrice = this.calcTotalPrice();
                return this.$q.resolve();
            });
        });
    }
    calcTotalPrice() {
        return this.selectedSamples.reduce((sum, entry) => {
            const x = sum + (entry.selected && entry.price) || 0;
            return x;
        }, 0);
    }

    selectSample(item: any) {
        item.selected = !item.selected;
        this.totalPrice = this.calcTotalPrice();
    }

    addSamplesToCart() {
        const selectedSamples = this.selectedSamples.filter(sample => sample.selected);
        this.cartService.addSampleProducts(selectedSamples, false).then( () => {
            this.$scope.$apply();
            // need to animate each selected item
            const selectedImages = $( '.sample-images:not(.disabled)').toArray();
            for ( let i = 0; i < selectedSamples.length; i ++ ) {
                if ( i === selectedSamples.length - 1 ) {
                    this.cssAnimationService.addElementToCart( $(selectedImages[i]), null );
                } else {
                this.cssAnimationService.addElementToCart( $(selectedImages[i]) );
                }
            }

        }).catch( (err: any) => {
            alert( err );
        });
        this.$rootScope.$emit('force-cart-count-update');
    }

    getRefundMessage() {
        // the max we will refund is $50 ..... so can you make that disclaimer line say ....
        // $34 for samples will be refunded with your frame purchase.
        // then if the amount is over $50 ... then just say
        // Up to $50 worth of samples will be refunded with your frame purchase.    ....   for the the mirror glass only   it should say   ..... with your mirror purchase
        let message = '';
        if (this.configuration.type === DesignTypes.CutGlass || this.configuration.type === DesignTypes.FramelessMirror) {
            if (this.totalPrice < 50) {
                message = '$' + this.totalPrice + ' will be refunded with your mirror purchase.';
            } else {
                message = 'Up to $50 for samples will be refunded with your mirror purchase.';
            }
        } else {
            if (this.totalPrice < 50) {
                message = '$' + this.totalPrice + ' will be refunded with your frame purchase';
            } else {
                message = 'Up to $50 for samples will be refunded with your frame purchase.';
            }
        }
        return message;
    }
}
