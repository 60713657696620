import app from '../../app';

@app.inject('$state', '$scope').component({
  templateUrl: 'configurator/configuration/flow-bar/flow-bar.html'
})
export class FlowBar {

  step = 0;

  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope
  ){}

  $onInit() {
    this.setStep(this.$state.current.name);
    this.$scope.$on('$stateChangeSuccess', (event, toState) => {
      this.setStep(toState.name);
    });
  }

  private setStep(state: string) {
    this.step = 0;
    if (/tab.design/.test(state)) {
      this.step = 1;
    }
    else if (/tab.options/.test(state)) {
      this.step = 2;
    }
    else if (/tab.summary/.test(state)) {
      this.step = 3;
    }
  }
}
