import app from '../app';

@app.inject(
  '$window',
  '$timeout',
  '$q',
  '$rootScope'
).service

export class SwymWishListService {
  // see https://api-docs.swym.it/
  // going to need to expose the images somewhere
  // with a url convention
  public ART_COLLECTION = 'art_collection';
  public FRAME_COLLECTION = 'frame_collection';
  public DESIGN_COLLECTION = 'design_collection';
  readonly baseProductUrl: string = 'https://framemytv.com/products/';
  readonly baseImageUrl: string = 'https://framemytv.com/products/';

  constructor(
    private $window: ng.IWindowService,
    private timeout: ng.ITimeoutService,
    private q: ng.IQService,
    private $rootScope: ng.IRootScopeService
  ) { }

  broadcastWishlistIsDirty(newSkuList: string[] = null) {
    this.$rootScope.$broadcast('wishlistIsDirty', newSkuList);
  }

  addCollection(collectionName: string, variantId: number, ): ng.IPromise<ng.IAugmentedJQuery> {

    let defer = this.q.defer<ng.IAugmentedJQuery>();
    try {
      this.$window._swat.addProductsToCollection(
        collectionName,
        [variantId]
        , (r: any) => {
          defer.resolve(r);
        });
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }
  getItemsForCollection(collectionName: string) {
    let defer = this.q.defer<any>();
    try {
      this.$window._swat.fetchWishlistWRTHashtag((r: any) => { defer.resolve(r); }, collectionName);
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }
  addToWishList(
    productId: number,
    variantId: number,
    imageUrl: string,
    productUrl: string,
    price: number,
    properties: any,
    variants: any,
  ): ng.IPromise<ng.IAugmentedJQuery> {

    let defer = this.q.defer<ng.IAugmentedJQuery>();
    try {
      this.$window._swat.addToWishList({
        'epi': variantId,
        'du': productUrl,
        'empi': productId,
        'iu': imageUrl,
        'pr': price,
        'cprops': properties,
        'variants': variants
      }, (r: any) => {
        defer.resolve(r);
      });
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }
  /* to remove from the wishlist, keep the items returned from 'getWishListItems' and pass them to this function to remove */
  removeFromWishList(wishListItem: any) {
    let defer = this.q.defer<ng.IAugmentedJQuery>();

    try {
      this.$window._swat.removeFromWishList({
        'epi': wishListItem.epi,
        'du': wishListItem.du,
        'empi': wishListItem.empi,
        'iu': wishListItem.iu,
        'pr': wishListItem.pr,
        //    "stk": wishListItem.stk, this is shown in the example call in the docs but not explained
        //     and including it appears to break the API
        'variants': wishListItem.variants
      }, (r: any) => {
        defer.resolve(r);
      });
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }

  /** Gets a list of all items in the user's wish list.  The list return includes variant and product ids that can be used to determine if an item is selected */
  getWishListItems() {
    let defer = this.q.defer<ng.IAugmentedJQuery>();
    try {
      this.$window._swat.fetch((r: any) => {
        defer.resolve(r);
      });
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }

  getDesignsCount() {
    return this.getWishListItems().then((items: any) => {
      const designs = items.filter((i: any) => i.dt === 'Design' || i.dt === 'My Design');
      return designs.length;
    });
  }
  getWishlistCount() {
    let defer = this.q.defer<string>();
    try {
      this.$window._swat.wishlistCount((count: any) => {
        defer.resolve(count);
      });
    } catch (e) {
      defer.reject();
    }
    return defer.promise;
  }

  showFavoritesPopup() {
    this.$window._swat.notepad.openNotepad();
  }
}
