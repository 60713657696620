import app from '../../app';
import { BaseLine } from '~/configurator/design/lines';
import { SaveDesignFooterService } from '../save-design-footer/save-design-footer.service';
import { GetSamplesOnPreviewService } from '../get-samples/get-samples-on-preview.service';

@app.inject(SaveDesignFooterService, '$mdMedia', '$rootScope', GetSamplesOnPreviewService ).service

export class SaveDesignTimerService {
    private timeOut: number = 15000;
    private timer: any;
    private openFunc: any;
    private closeFunc: any;

    constructor(
        private saveDesignFooterService: SaveDesignFooterService,
        private $mdMedia: ng.material.IMedia,
        private $rootScope: ng.IRootScopeService,
        private getSamplesOnPreviewService: GetSamplesOnPreviewService,
    ) {
    }

    setOpenCallBack( func: any) {
        this.openFunc = func;
    }
    setCloseCallBack( func: any) {
        this.closeFunc = func;
    }
    starTimer(){
        this.stopTimer();
        this.timer = setInterval(() => this.openCallback(), this.timeOut);
    }

    // used when the user opens a dialog (like frame selection) so save design is not shown while in another screen/activity
    pauseTimer(){
        this.stopTimer();
    }

    // resumes the timer (resets) when the user closes a dialog/activity that took them away from the main screen
    unpauseTimerOnDialogClose(){
        if ( !this.timer ) {
            clearInterval( this.timer);
            this.timer = setInterval( () => this.openCallback(), this.timeOut);
        }
    }
    stopTimer() {
        clearInterval( this.timer);
        this.timer = null;
    }

    resetOpenTimer() {
        clearInterval( this.timer);
        this.timer = setInterval( () => this.openCallback(), this.timeOut);
    }
    stopTimerAndClose(){
        this.stopTimer();
        if ( this.closeFunc ) {
            this.closeFunc();
        }
    }
    openCallback( ) {
        this.stopTimer();
        if ( this.openFunc ) {
            this.getSamplesOnPreviewService.show();
            // the save design is triggered once and once shown is not triggered again regardless if the user entered their data
            // the use can use the manual 'save design' after the fact
            if ( BaseLine.saveDesignWasShown === false ) {
                BaseLine.saveDesignWasShown = true;
                // if mobile,
                if ( this.$mdMedia('xs') || this.$mdMedia('sm') ) {
                    this.$rootScope.$broadcast( 'saveDesignFooterService', true);
                    // this.saveDesignFooterService.show();
                } else {

                // if desktop
                    this.openFunc();
                }
            }
        }
    }

    closeCallBack(){
        this.starTimer();
        if ( this.closeFunc ) {
            this.$rootScope.$broadcast( 'saveDesignFooterService', false);
            // this.saveDesignFooterService.hide();
            this.closeFunc();
        }
    }

}
