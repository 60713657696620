// import swiper from '~/angular-swiper/angular-swiper.module';
import {Swiper} from 'swiper';
import app from '../../app';
import angular from 'angular';

export interface SwiperContainerScope extends ng.IScope {
    swiper: any;
    onReady: any;
    swiper_uuid: string;
    slidesPerView: number;
    slidesPerColumn: number;
    spaceBetween: number;
    direction: string;
    loop: boolean;
    initialSlide: number;
    autoplay: boolean;
    paginationIsActive: boolean;
    paginationClickable: boolean;
    overrideParameters: boolean;
    showNavButtons: boolean;
    showScrollBar: boolean;
    pagination: ''; // the element where to put the pagination dots
}
@app.inject('$scope', '$element', '$timeout').controller
export class SwiperContainerController {
    constructor(
        $scope: SwiperContainerScope,
        $element: ng.IAugmentedJQuery,
        $timeout: ng.ITimeoutService
      ) {
        const uuid = this.createUUID();

        $scope.swiper_uuid = uuid;

        // directive defaults
        let params = {
            slidesPerView: $scope.slidesPerView || 1,
            slidesPerColumn: $scope.slidesPerColumn || 1,
            spaceBetween: $scope.spaceBetween || 0,
            direction: $scope.direction || 'horizontal',
            loop: $scope.loop || false,
            initialSlide: $scope.initialSlide || 0,
            showNavButtons: true,
            nextButton: '',
            prevButton: '',
            scrollbar: '',
            pagination: {
                type: 'bullets',
            },
        };

        if (!angular.isUndefined($scope.autoplay) && typeof $scope.autoplay === 'number') {
            params = angular.extend({}, params, {
                autoplay: $scope.autoplay
            });
        }

        if ($scope.paginationIsActive === true) {
            params = angular.extend({}, params, {
                paginationClickable: $scope.paginationClickable || true,
                pagination: '#paginator-' + $scope.swiper_uuid
            });
        }

        if ($scope.showNavButtons === true) {
            params.nextButton = '#nextButton-' + $scope.swiper_uuid;
            params.prevButton = '#prevButton-' + $scope.swiper_uuid;
        }

        if ($scope.showScrollBar === true) {
            params.scrollbar = '#scrollBar-' + $scope.swiper_uuid;
        }

        if ($scope.overrideParameters) {
            params = angular.extend({}, params, $scope.overrideParameters);
        }

        $timeout(function() {
            let swiperInstance = null;

            if (angular.isObject($scope.swiper)) {
                $scope.swiper = new Swiper( this.$element[0].firstChild, this.params);
                swiperInstance = $scope.swiper;
            } else {
                swiperInstance = new Swiper(this.$element[0].firstChild, this.params);
            }

            // If specified, calls this function when the swiper object is available
            if (!angular.isUndefined($scope.onReady)) {
                $scope.onReady({
                    swiperInstance // json short for swiper: swiper
                });
            }
        });

  }

  createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        const s: string []= [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        const x = parseInt( s[19] );
        /* tslint:disable:no-bitwise */
        s[19] = hexDigits.substr(( x & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }
}
