import lib from '~/ui/ui.module';

@lib.inject('$templateCache').directive()
export class Svg {
  definition(
    templateCache: ng.ITemplateCacheService
  ): ng.IDirective {
    return {
      restrict: 'AE',
      terminal: true,
      link: (scope, element, attrs) => {
        let src = attrs['ftvSvg'] || attrs['src'];
        scope.$watch(() => src, (source: string) => {
          let cacheKey = scope.$eval(source);
          if (cacheKey) {
            element.html(<string>templateCache.get(cacheKey));
          }
          else {
            element.html('');
          }
        });
      }
    };
  }
}
