import app from '../../app';
import angular from 'angular';
import { ProgressBarService } from './progress-bar.service';

@app.inject(
  ProgressBarService,
  '$scope',
  '$rootScope',
  '$window',
  '$timeout',
  '$element',
).component({
  templateUrl: 'configurator/configuration/progress-bar/progress-bar.html',
  bindings: {
    totalSeconds: '<'
  }
})
export class ProgressBar {

  timer: any;
  percentComplete: number;
  curMessage: string;
  width: number;
  curstep: number = 0;
  isActive: boolean = false;
  elapsedTime: number = 0;
  // wrap window object as jQuery object
  steps = [
    { prcnt: 0, msg: 'Your design is being saved!' },
    { prcnt: 10, msg: 'Preparing to Save' },
    { prcnt: 25, msg: 'Confirming details' },
    { prcnt: 50, msg: 'Preparing Preview image' },
    { prcnt: 75, msg: 'Flattening Shareable preview image' },
    { prcnt: 80, msg: 'Generating Shareable Link' },
    { prcnt: 90, msg: 'Almost there!' },
    { prcnt:100, msg: 'Completed!'}
  ];

  constructor(
    public progressBarService: ProgressBarService,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $window: ng.IWindowService,
    private $timeout: ng.ITimeoutService,
    private $element: ng.IAugmentedJQuery,
    private totalSeconds: any,
  ) {
    let $win = angular.element($window);

    // will listen for a completion message and if one is found, increase the speed of the bar so it finishes asap.
    // $scope.on( 'force-progress-bar-closed', ()=>{

    // })
    $scope.$watch( () => {
      ($scope as any).widthStyle = { 'width': this.percentComplete + '%' };
    });

  }

  $onInit() {
    this.$scope.$watch( () => this.progressBarService.visible, v => {
      if ( v ) {
        this.start();
      } else {
        this.finishAndHide();
      }
    });
  }
  start(){
    this.isActive = true;
    this.curMessage = this.steps[0].msg;
    // want about 15 seconds to make sure server has time to create an image/complete the cycle
    // 8 steps = 15/8 = 1875
    this.timer = setInterval(this.advanceBar, 1875);
  }
  finishAndHide(){
    this.isActive = false;
    this.progressBarService.hide();

  }
  show() {
    return this.isActive && this.percentComplete !== 100;
  }
  advanceBar = () => {
    if ( this.totalSeconds <= this.elapsedTime * 2) {
      clearInterval(this.timer);
      this.elapsedTime = 0;
    } else {
      this.elapsedTime ++;
      // turn total seconds into a percentage of elapsed time
      this.percentComplete = Math.ceil(this.elapsedTime * 2 / this.totalSeconds * 100);

      const curStep = this.steps.find( ( step: any ) => step.prcnt <= this.percentComplete && step.prcnt >= this.percentComplete);
      let nextIdx = null;
      let nextStep = null;
      if ( curStep ) {
        this.curMessage = curStep.msg;
        nextIdx = this.steps.findIndex ( step => step === curStep) + 1;
        if ( nextIdx > this.steps.length - 1){
          nextIdx = this.steps.length - 1;
        }
        nextStep = this.steps[nextIdx];
      }

      if ( curStep === nextStep ){
        clearInterval(this.timer);
        this.finishAndHide();
      }

      this.width = this.percentComplete;
      this.$scope.$apply();
    }
  }
}
