import app from '~/configurator/app';

@app.inject(
    '$q',
).service
export class  WebworkerService {
    public self: Worker;
    constructor(
        private q: ng.IQService,
    ) {

    }
/* tslint:disable */
    newWorker = ( funcObj: any ) => {
        // Build a worker from an anonymous function body
        const blobURL = URL.createObjectURL( new Blob(['(',

        funcObj.toString(),
            ')()'], {
            type: 'application/javascript'
        })),
        worker = new Worker(blobURL);
        // Won't be needing this anymore
        URL.revokeObjectURL(blobURL);
        this.self = worker;
        return worker;
    }
}
