import app from '../../app';
import { DesignConfiguration } from '../../design/designConfiguration';
import { DesignTypes } from '~/configurator/design/designTypes';
import { HubspotJSONFormService } from '~/configurator/services/hubspotJSONForm.service';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import { Analytics } from '~/configurator/analytics';
import angular from 'angular';
import jquery from 'jquery';
import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { SaveDesignStep2 } from '../save-design-step2/save-design-step2.component';
import { SaveDesignStep2Service } from '../save-design-step2/save-design-step2.service';
import { SaveDesignService } from './save-design.service';
import { SaveDesignTimerService } from './save-design-timer.service';
import { BaseLine } from '~/configurator/design/lines';
import { GetSamplesOnPreviewService } from '../get-samples/get-samples-on-preview.service';

@app.inject(
    '$scope',
    '$mdMedia',
    '$http',
    SaveDesignStep2Service,
    SaveDesignService,
    SaveDesignTimerService,
    GetSamplesOnPreviewService

).component({
    templateUrl: 'configurator/configuration/save-design/save-design.html',
    bindings: {
        configuration: '<',
    },
})

export class SaveDesignComponent {
    /* form bindings */
    email: string;
    firstname: string;
    lastname: string;

    wasManuallyClosed: boolean = false;
    forceOpen: boolean = false;
    showSaveDesignStep2: boolean = false;
    // keeps the design save window open when the config is saved until the user manually closes it
    justSaved: boolean = false;

    // stats to see how often user manually closes the dialog without saving.
    sessionId: string = this.guid();

    constructor(
        private $scope: ng.IScope,
        private $mdMedia: ng.material.IMedia,
        private $http: ng.IHttpService,
        private saveDesignStep2Service: SaveDesignStep2Service,
        private saveDesignService: SaveDesignService,
        private saveDesignTimerService: SaveDesignTimerService,
        private configuration: DesignConfiguration,
        private getSamplesOnPreviewService: GetSamplesOnPreviewService,

    ) {

        saveDesignTimerService.setOpenCallBack( () => {
            this.open();
        });
        saveDesignTimerService.setCloseCallBack( () => this.triggerClose() );
    }

    guid() {
        let d = new Date().getTime();
        let d2 = ( performance && performance.now && ( performance.now() * 1000 ) ) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16;
            if ( d > 0 ){
                /* tslint:disable:no-bitwise */
                r = (d + r) % 16 | 0;
                d = Math.floor( d / 16 );
            } else {
                /* tslint:disable:no-bitwise */
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor( d2 / 16);
            }
            return ( c === 'x' ? r : ( r & 0x3 | 0x8) ).toString(16);
        });
    }

    /* this component take the values for hubspot and passes them
       to the saveDesignStep2 for saving */

    $onInit() {
        this.$scope.$watch(() => this.$mdMedia('gt-xs'), mobile => {
            if (!this.saveDesignService.visible  && !this.saveDesignStep2Service.visible) {
                // $('ftv-save-design').css('display', 'static');
                $('ftv-save-design-component').css('display','none');
            } else {
                $('ftv-save-design-component').css('display', 'block');
            }
        });
        /*
        this.$scope.$watch(() => this.$mdMedia('gt-sm'), mobile => {
            if (!this.saveDesignService.visible) {
                // $('ftv-save-design').css('display', 'static');
                $('ftv-save-design-component').css('display','none');
            } else {
                $('ftv-save-design-component').css('display', 'block');
            }
        });
        */
        // if the save design was initiated then don't want to hide it after
        this.$scope.$watch(() => this.saveDesignService.visible, (newVal, oldVal) => {
            if (newVal === true && oldVal === false) {
                this.saveDesignService.initCount += 1;
                if (this.saveDesignService.initCount === 1) {
                    $('.scroll-btn').css('display', 'none');
                    this.showDisplayDesignParentWrapper();
                }
            } else {

                this.saveDesignService.hide();
                if ( !this.saveDesignStep2Service.visible ) {
                    this.hideDisplayDesignParentWrapper();
                    $('.scroll-btn').css('display', 'block');
                }
            }
        });
        /*
        this.$scope.$watch(() => this.configuration.dirty, (newVal, oldVal) => {
            if (newVal === true && oldVal === false) {
                this.saveDesignService.show();
            } else if (newVal === false) {
                this.saveDesignService.hide();
                this.$scope.$apply();
            }
        });
        */

    }

    safeConfiguration() {
        this.justSaved = true;
        this.saveDesignService.hide();

        if (this.email) {
            this.saveDesignStep2Service.show(this.email, this.firstname, this.lastname);
            // this.$http.post(`${window.shopifyCacheRoot}/stats/save-dialog/`, { sessionId: this.sessionId, event: 'saved-config' } );
        }
    }

    open(){
        // check for dirty here because after saving there are queued events that still trigger the open
        if ( ( !this.saveDesignService.visible && !this.saveDesignStep2Service.visible ) && this.configuration.dirty) {
            this.saveDesignService.show();
            this.showDisplayDesignParentWrapper();
            // this.$http.post(`${window.shopifyCacheRoot}/stats/save-dialog/`, { sessionId: this.sessionId, event: 'open' } );
        }
    }
    hideDisplayDesignParentWrapper(){
        // to fill mobile view the wrapper must fill the screen (absolute position) but need to be 'shown/hidden' or it will block the screen.
        if ( this.$mdMedia('xs') || this.$mdMedia('sm') ) {
                $('ftv-save-design').css('display', 'static');
                $('ftv-save-design-component').css('display','none');
            }
    }
    showDisplayDesignParentWrapper(){
        if ( this.$mdMedia('xs') || this.$mdMedia('sm') ) {
            $('ftv-save-design-component').css('display', 'block');
        } else {
            $('ftv-save-design-component').css('display', 'block');
        }
    }
    // close is triggered by the timer
    triggerClose(){
        // closed by a design change or reverting to a previously saved design.
        // this.$http.post(`${window.shopifyCacheRoot}/stats/save-dialog/`, { sessionId: this.sessionId, event: 'closed-automatically' } );
        this.hideDisplayDesignParentWrapper();
        this.saveDesignService.hide();
    }
    manuallyClose(){
        // this.$http.post(`${window.shopifyCacheRoot}/stats/save-dialog/`, { sessionId: this.sessionId, event: 'closed-manually' } );
        this.hideDisplayDesignParentWrapper();
        this.saveDesignTimerService.stopTimerAndClose();
    }

}
