import app from '../app';
import { LightBoxInfo } from '~/api-shopify/product-models/light-box-info';

@app.inject(
'$mdDialog',
'product',
'lightboxInfo',
'addSelection',
).controller
export class LightBoxModalController {
  public addSelectionCallback: any;
  private curIdx: any;
  private curImage: any;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private product: any,
    private lightBoxInfo: LightBoxInfo,
    public addSelection: any
  ) {
    this.curIdx = 0;
    this.curImage = this.lightBoxInfo.carousel[0];
    this.addSelectionCallback = addSelection;
  }

  $onInit() {

    if ( this.lightBoxInfo.scripts) {
      for ( let src of this.lightBoxInfo.scripts) {
        let sTag= document.createElement('script');
        sTag.type = 'text/javascript';
        sTag.src = src;
        document.body.appendChild(sTag);
      }
    }
  }

  hasMoreThanOneEntry(){
    return this.lightBoxInfo.carousel.length > 1;
  }
  closeModal(){
    this.$mdDialog.hide();
  }
  swipePrevious(){
    this.curIdx = this.curIdx > 0 ? this.curIdx - 1 : this.lightBoxInfo.carousel.length - 1;
    this.curImage = this.lightBoxInfo.carousel[this.curIdx];
  }
  swipeNext(){
    this.curIdx = this.curIdx < this.lightBoxInfo.carousel.length - 1 ? this.curIdx + 1 : 0;
    this.curImage = this.lightBoxInfo.carousel[this.curIdx];
  }
  specify() {
    this.$mdDialog.hide(true);
  }

  later() {
    this.$mdDialog.hide(false);
  }
  getProductPrice(){
    return this.product.price;
  }
  cancel() {
    this.$mdDialog.cancel();
  }

  addSelectionClick(){
    if ( this.addSelection) {
      this.addSelection(this.product);
      this.$mdDialog.cancel();
    }
  }
}
