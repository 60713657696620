import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import lib from '~/api-shopify/api-shopify.module';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

let listPromise: ng.IPromise<CutGlassProduct[]>;

let sizes: { [key: string]: { maxSize: number[], maxOversize: number[], order: number } } = {
  'PROD-53': {
    maxSize: [60, 72],
    maxOversize: [72, 120],
    order: 2
  },
  'PROD-54': {
    maxSize: [60, 72],
    maxOversize: [71, 88],
    order: 3
  },
  'PROD-55': {
    maxSize: [60, 72],
    maxOversize: [72, 120],
    order: 4
  },
  'PROD-91': {
    maxSize: [60, 72],
    maxOversize: [60, 72],
    order: 1
  }
};

export class CutGlassProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = CutGlassProduct.productsService.getAllCutGlassProduct()
      // .then(products => filter(products, p => includes(keys(sizes), p.code)).map(p => new CutGlassProduct(p)));
      // product here is being sorted per shopify however the display is reversing the sorted order so '.reverse' it
      .then(products => sortBy(products, p => p.sortPosition).map( (p: any) => new CutGlassProduct(p)));
  }

  static get(code: string) {
    return this.list().then(mirrors => find(mirrors, m => m.code === code));
  }

  static maxSize() {
    let maxArea = 0;
    let max: number[];
    forEach(sizes, s => {
      const area = s.maxOversize[0] * s.maxOversize[1];
      if (area > maxArea) {
        maxArea = area;
        max = s.maxOversize;
      }
    });
    return max;
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  public readonly previewUrl: string;

  private _manualPriceAdjustment: number  = 1.2;

  constructor(productData: any){
    super( productData );
    this.previewUrl = productData.previewUrl;
  }
  get maxSize() {
    if (sizes[this.code]) {
      return sizes[this.code].maxSize;
    }
  }

  get maxOversize() {
    if (sizes[this.code]) {
      return sizes[this.code].maxOversize;
    }
  }

  get order() {
    return sizes[this.code].order;
  }

  get price() {
    if (!this._price) {
      this.guaranteedPrice().then(p => this._price = p);
    }
    return this._price;
  }

  guaranteedPrice() {
    return this.basePrice().then( p => {
      return p * this._manualPriceAdjustment;
    });
  }
}
