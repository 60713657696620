import { DesignConfiguration } from '../../../design/designConfiguration';
import { LinerProduct } from '../../../../api-shopify/product-models/liner-product';
import app from '../../../app';
import remove from 'lodash/remove';
import { DesignTypes } from '../../../design/designTypes';

@app.inject(
  'designConfiguration',
  '$mdSidenav',
  '$state',
  '$scope',
  '$timeout'
).controller
export default class LinerController {
  liners: LinerProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private $mdSidenav: ng.material.ISidenavService,
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService
  ) {
    if (configuration.frame.frameProduct) {
        // configuration.frame.frameProduct.categoryCode()
        // .then(c => LinerProduct.forCategory(c))
        LinerProduct.forCategory(configuration.frame.frameProduct.categoryCode())
        .then(liners => {
          this.liners = liners;
          if (configuration.type !== DesignTypes.FrameOnly && configuration.type !== DesignTypes.FrameWithMirror) {
            remove(this.liners, l => l.product.code === 'LINER-5');
          }
        });
    }
    $timeout(this.init, 100);
  }

  $onInit() { }

  price(liner: LinerProduct) {
    // return this.configuration.linerPrice(liner);
  }

  close() {
    this.$mdSidenav('liner').close();
  }

  select(liner: LinerProduct) {
    this.configuration.liner.setProduct(liner);
    this.close();
  }

  private init = () => {
    const sideNav = this.$mdSidenav('liner');
    sideNav.open().then(() => {
      this.$scope.$watch(() => sideNav.isOpen(), (open: boolean) => {
        if (!open) {
          this.$timeout(() => this.$state.go('^'), 300);
        }
      });
    });
  }
}
