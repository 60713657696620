import angular from 'angular';
import lib from '~/ui/ui.module';

interface LazyLoadSrcScope extends ng.IScope {
  ftvLazyLoadSrc: any;
}

@lib.inject('$window', '$document', '$timeout').directive()
export class LazyLoadSrc {
  definition(
    $window: ng.IWindowService,
    $document: ng.IDocumentService,
    $timeout: ng.ITimeoutService
  ): ng.IDirective {
    let doc = $document[0];
    let body = doc.body;
    let win = $window;
    let $win = angular.element(win);
    let uid = 0;
    let elements: Array< { iElement: ng.IAugmentedJQuery, $scope: ng.IScope }> = [];

    function getUid(el: ng.IAugmentedJQuery) {
      let uniqueId = el.data('__uid');
      if (!uniqueId) {
        el.data('__uid', (uniqueId = '' + ++uid));
      }
      return uniqueId;
    }

    function checkImage() {
      angular.forEach(elements, (obj, key) => {
        let iElement = obj.iElement;
        let $scope = obj.$scope as LazyLoadSrcScope ;
        iElement.attr('src', $scope.ftvLazyLoadSrc);
      });
    }

    setInterval(checkImage, 500);
   // setTimeout(checkImage, 500);

    function onLoad() {
      let $el = angular.element(this);
      let $scope = this.$scope as LazyLoadSrcScope ;
      this.attr('src', $scope.ftvLazyLoadSrc);
    }

    return {
      restrict: 'A',
      scope: {
        ftvLazyLoadSrc: '@',
      },
      link: ($scope: LazyLoadSrcScope, iElement) => {
       // iElement.bind('load', onLoad);

        $scope.$watch('ftvLazyLoadSrc', () => {
          // let uniqueId = getUid(iElement);
          // elements[uniqueId] = { iElement, $scope };
          elements.push({ iElement, $scope });
        });

        $scope.$on('$destroy', () => {
          iElement.unbind('load');
          let uniqueId = getUid(iElement);
          if (elements.hasOwnProperty(uniqueId)) {
            delete elements[uniqueId];
          }
        });

      }
    };
  }
}
