import app from '../app';

declare var hbspt: any;

@app.inject('$timeout', '$q').service
export class HubspotService {

  constructor(
    private timeout: ng.ITimeoutService,
    private q: ng.IQService
  ) {
    $(document.body).append('<div class="ng-hide" id="hubspot-form"><iframe name="hubspot-iframe" id="hubspot-iframe"></iframe></div>');
  }

  createForm(id: string): ng.IPromise<ng.IAugmentedJQuery> {
    let defer = this.q.defer<ng.IAugmentedJQuery>();

    this.timeout(() => {
      hbspt.forms.create({
        portalId: '2302681',
        formId: id,
        onFormReady: (form: ng.IAugmentedJQuery) => {
          form.attr('target', 'hubspot-iframe');
          defer.resolve(form);
        }
      });
    });

    return defer.promise;
  }

  setValue(form: ng.IAugmentedJQuery, field: string, value: any) {
    form.find(`[name="${field}"]`).val(value);
  }
}
