import _ from 'lodash';
import app from '~/configurator/app';
import { DesignConfiguration } from '~/configurator/design/designConfiguration';
import * as Lines from '~/configurator/design/lines';
import clone from 'lodash/clone';
import { DesignTypes } from '~/configurator/design/designTypes';
import { BaseLine } from '~/configurator/design/lines';
import { SaveDesignTimerService } from '../../save-design/save-design-timer.service';

@app.inject(
  'configuration',
  '$mdDialog',
  SaveDesignTimerService,
  '$scope'
).controller
export default class TvController {

  // removing sizes 40, 48, 60, 70, 90 => [32, 40, 43, 48, 50, 55, 60, 65, 70, 75, 84, 90];
  // 32", 43", 50", 55", 65", 75", 85",

  // !! this list here is copied to tvModelLine.ts  It should be centralized somewhere but not worth the effort at this point
  // keep the two aligned manually for now
  standardSizes = [32, 43, 50, 55, 65, 75, 85];

  showOtherSize = false;
  maxScreenSizeForConfigType = 86;
  customSize: number;
  selectedSize: number;
  model: string;
  provideModelLater: boolean = false;
  provideModelLasterPlaceholder = 'Samsung Model XYZ';

  constructor(
    public configuration: DesignConfiguration,
    private $mdDialog: ng.material.IDialogService,
    private saveDesignTimerService: SaveDesignTimerService,
    $scope: ng.IScope,
  ) {
    this.refresh();
    saveDesignTimerService.pauseTimer();
    $scope.$on('$stateChangeStart', () => {
      $mdDialog.cancel();
    });

    $scope.$watch(() => this.customSize, size => {
      if (_.isFinite(size)) {
        this.selectedSize = null;
      }
    });
  }

  $onInit() {
      // limit the size of all framed mirrors to 80" maximum screen size, and all Metro and Floating mirrors to 75
      if ( this.configuration && this.configuration.type ===  DesignTypes.FrameWithMirror) {
        // this.standardSizes = [32, 43, 48, 50, 55, 60, 65, 70, 75];
        this.standardSizes = [32, 43, 50, 55, 65, 75];
        this.maxScreenSizeForConfigType = 80;
      } else if ( this.configuration && ( this.configuration.type ===  DesignTypes.MetroMirror || this.configuration.type ===  DesignTypes.FramelessMirror )) {
        // this.standardSizes = [32, 40, 43, 48, 50, 55, 60, 65, 70, 75];
        this.standardSizes = [32, 43, 50, 55, 65, 75];
        this.maxScreenSizeForConfigType = 75;
      }

      if ( this.configuration && this.configuration.tvModel && this.configuration.tvModel.data) {
          if (this.configuration.tvModel.data.diagscreensize ) {
              let size = this.standardSizes.findIndex ( e => e === this.configuration.tvModel.data.diagscreensize );
              if ( size === -1 ) {
                this.customSize = this.configuration.tvModel.data.diagscreensize as number;
              } else {
                this.selectedSize = size;
              }
          }
          if (this.configuration.tvModel.data.makemodel ) {
            this.model = this.configuration.tvModel.data.makemodel;
        }
      }
   }

  selectStandardSize(index: number) {
    this.selectedSize = index;
    this.customSize = null;
    this.showOtherSize = false;
  }
  save() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.configuration.tvModel.update(this.getModel());
    this.$mdDialog.hide();
  }
  isvalid(){
    let model = this.getModel();
    return ( model && model.diagscreensize );
  }
  changeModelLater(){
    if ( this.provideModelLater){
      this.provideModelLasterPlaceholder = 'to be provided later';
      this.model = null;
    } else {
      this.provideModelLasterPlaceholder = 'Samsung Model XYZ';
    }
  }
  enteringTvModel(){
    if ( this.model && this.model.length > 0 ){
      this.provideModelLater = false;
    }
  }
  private getModel(): Lines.TvModelLineData {

    if (this.customSize) {
      return {
        speakerlayout: 'M',
        diagscreensize: this.customSize,
        makemodel: this.model
      };
    }
    if (this.selectedSize === null || !isFinite(this.selectedSize)) {
      return;
    }
    return {
      speakerlayout: 'M',
      diagscreensize: this.standardSizes[this.selectedSize],
      makemodel: this.model
    };
  }

  /**
   * Uses the TV data present in the configuration to determine the
   * state of the UI controls
   */
  private refresh() {
    this.customSize = null;
    this.selectedSize = null;
    this.showOtherSize = false;

    if (!this.configuration.tvModel || !this.configuration.tvModel.data) {
      return;
    }

    if (this.configuration.tvModel.data.diagscreensize) {
      this.setDiagonal();
    }
  }

  private setDiagonal() {
    const index = _.indexOf(this.standardSizes, this.configuration.tvModel.data.diagscreensize);
    if (index >= 0) {
      this.selectedSize = index;
    }
    else {
      this.customSize = this.configuration.tvModel.data.diagscreensize;
      this.showOtherSize = true;
    }
  }
}
