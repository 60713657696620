import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from '~/api-shopify/product-models/product';
import sortBy from 'lodash/sortBy';

let listPromise: ng.IPromise<ArtControlProduct[]>;

@lib.inject(ProductsApi).service
export class ArtControlProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = ArtControlProduct.productsService.getArtControlCollection()
      .then( (products: any) => sortBy(products, p => p.sortPosition).map( (p: any) => new ArtControlProduct(p)));
  }

  static get(code: string) {
    return ArtControlProduct.productsService.getArtControlBySku(code).then( (p: any) => new ArtControlProduct(p));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  public product: ArtControlProduct;
  private _factor: number;
  private _description: string;
  // private _manualPriceAdjustment: number = 1.06; // manual adjument to the shopify price .. should be set in shopify but doing it here for now
  constructor(data: any) {
    super(data);
    this._factor = data.factor ? data.factor : 0;
    this._description = data.description;
    this.product = this;
  }

  get factor() {
    return this._factor;
  }

  get description() {
    return this._description;
  }

  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    // return this.basePrice().then(b => Math.ceil((b + this.factor * tvSize) * this._manualPriceAdjustment));
    return this.basePrice().then(b => Math.ceil( b + this.factor * tvSize ));
  }
}
