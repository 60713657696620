import app from '../app';

@app.inject('$mdDialog').controller
export class SaveDialogController {

  constructor(
    private $mdDialog: ng.material.IDialogService
  ) { }

  $onInit() { }

  cancel() {
    this.$mdDialog.cancel();
  }
}
