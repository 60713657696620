import { SamplesService } from './samples.service';
import lib from '~/ui/ui.module';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';

@lib.inject(SamplesService).component({
  templateUrl: 'ui/samples/samples.html',
  bindings: {
    samples: '<',
    name: '<',
    addedToCart: '&?'
  }
})
export class Samples {
  samples: SampleProduct[];
  name: string;
  addedToCart: () => void;

  constructor(
    private sampleService: SamplesService
  ) { }

  $onInit() {}

  addToCart(sample: SampleProduct) {
    this.sampleService.addToCart(sample, `${sample.name} for ${this.name}`);
    if (this.addedToCart) {
      this.addedToCart();
    }
  }
}
