import app from '../../../app';

export enum SaveChoices {
  Update,
  New
}

@app.inject('$mdDialog').controller
export class LoaderTimeoutController {
  constructor(
    private $mdDialog: ng.material.IDialogService
  ) { }

  $onInit() { }

  cancel() {
    this.$mdDialog.cancel();
  }

  update() {
    this.$mdDialog.hide(SaveChoices.Update);
  }

  saveNew() {
    this.$mdDialog.hide(SaveChoices.New);
  }
}
