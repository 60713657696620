import app from '../app';
import { DesignConfigurationService } from '../design/designConfiguration.service';
import { DesignConfiguration } from '../design/designConfiguration';
import { SwymWishListService } from '../services/swymWishList.service';
import { CartService } from '../cart/cart.service';

@app.inject(
  '$mdMedia',
  '$scope',
  '$rootScope',
  '$state',
  DesignConfigurationService,
  'SwymWishListService',
  CartService,
  '$filter'
).component({
  templateUrl: 'configurator/mobile-menu/mobileMenu.html'
})
export class MobileMenu {
  show = false;
  rootHref: string;
  cartHref: string;
  configuration: DesignConfiguration;
  cartItemCounter: string = '0';
  designsCounter: string = '0';
  constructor(
    private $mdMedia: ng.material.IMedia,
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService,
    private configurationService: DesignConfigurationService,
    private swymWishListService: SwymWishListService,
    private cartService: CartService,
    private $filter: ng.IFilterService,
  ) {
    this.rootHref = `//${location.hostname}/`;
    this.cartHref = `//${location.hostname}/?action=cart`;

    this.forceCartCountUpdate();
    this.getDesignsCount();
    // forces a count update when the app triggers an add to count
    $rootScope.$on('force-cart-count-update',  () => {
      this.forceCartCountUpdate();
    });

}

  $onInit() {
    this.configuration = this.configurationService.designConfiguration;
    this.$scope.$watch(() => this.$mdMedia('xs') || this.$mdMedia('sm'), mobile => {
      this.show = mobile && (window.top === window.self);
    });
  }
  getDateEndOfCurMonth(){
    const now = new Date();
    const endOfMonth =  new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    const endOfMonthDate = new Date(now.getFullYear(), now.getMonth(), endOfMonth);
    // const dateString  = this.$filter('date')(endOfMonthDate, 'MMM dd yyyy');
    const dateString  = this.$filter('date')(endOfMonthDate, 'MMM dd yyyy');
    return dateString;
  }
  // this is copied from summary-bar.component.ts
  newDesign() {
    this.configurationService.reset();
    this.$state.go('configuration.frameType', { configurationId: 'new' }, { reload: true });
  }

  showSaveDesign(){
    this.$rootScope.$broadcast('show-save-design');
  }

  displaySaveDesign(){
    if ( this.configuration.dirty ){
      return true;
    } else {
      return false;
    }
  }

  showMyDesigns(){
    this.swymWishListService.showFavoritesPopup();
  }

  openMiniCart(){
    this.$rootScope.$broadcast('toggle-mini-cart');
  }
  getDesignsCount(){
    this.swymWishListService.getDesignsCount().then( count => {
      this.designsCounter = count;
    });
  }
  private forceCartCountUpdate(){
    this.cartService.getCartCount().then( (count: string) => {
      this.cartItemCounter = count;
    });
  }
}
