import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface OldCustomLineData { }
export interface CustomLineData { }

export class CustomLine extends BaseLine<OldCustomLineData & CustomLineData> {
  kind = ConfigLineKinds.Custom;
  category = 'Options';
  order = 150;

  get valid() { return true; }

  deserialize() {
    return this.q.resolve();
  }

  refreshPrice() {
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.Custom] = CustomLine;
