import { Product } from './product';
import { ProductsApi } from '~/api-shopify/products-api';
import lib from '~/api-shopify/api-shopify.module';
import sortBy from 'lodash/sortBy';

let listPromise: ng.IPromise<TvControlProduct[]>;

@lib.inject(ProductsApi).service
export class TvControlProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    listPromise = TvControlProduct.productsService.getAllTVControlProducts()
      .then((products: any) => sortBy(products, p => p.sortPosition).map(p => new TvControlProduct(p)));
    return listPromise;
  }

  static get(code: string) {
    return TvControlProduct.productsService.getTVControlBySku(code).then((p: any) => new TvControlProduct(p));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;
  // thumbnail in super
  // description in super

  guaranteedPrice(): ng.IPromise<number> {
    // trello #95 removing the -99 for no control, so set to 0 as the min price for all options
    return this.basePrice().then(b => Math.max(0, Math.ceil(b) - 99));
  }
}
