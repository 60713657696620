import { DesignConfiguration } from '../../../design/designConfiguration';
import { AuthenticationService } from '../../../user/authentication.service';
import app from '../../../app';
import { CartService } from '../../../cart/cart.service';
import { ConfirmTvModel } from './confirmTvModel';
import { TvService } from '../../design/tv/tv.service';
import angular from 'angular';

@app.inject(
  '$mdDialog',
  '$mdMedia',
  CartService,
  AuthenticationService,
  TvService,
  '$state',
  '$scope',
  '$location',
  '$anchorScroll',
  '$mdToast'
).component({
  templateUrl: 'configurator/configuration/directives/addToCart/addToCart.html',
  bindings: {
    configuration: '=',
    smallLabel: '<',
    hideIcon: '<',
    showInlineMessage: '<',
  }
}, 'addToCart')
export class AddToCartController {

  configuration: DesignConfiguration;
  smallLabel: boolean;
  hideIcon: boolean;
  showInlineMessage: boolean;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private $ftvTv: TvService,
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
    private $anchorScroll: ng.IAnchorScrollService,
    private $mdToast: ng.material.IToastService
  ) {
  }

  $onInit() {

   }

  restricted() {
    return this.authenticationService.restricted();
  }

  addToCart() {
    if (!this.configuration.canOrder) {
      this.$mdToast.show({
        template: '<md-toast class="md-toast"><div class="md-toast-content missing-option"><div>All options (with <img style="display:inline-block;position:relative;top:3px;" src="'
          + window.fmtvAssetsRoot + '/assets/configurator/alert-black.png' + '">) must be selected before the design can be added to the cart.</div></md-toast>',
        hideDelay: 5000,
        position: 'top right'
      });

      const problemLines = this.configuration.lines.filter(line => !line.valid);
      const element = angular.element(document.getElementById(problemLines[0].scrollToElementId));
      // element.css('animation', 'none');
      this.scrollTo(problemLines[0].scrollToElementId);
      element.addClass('heartbeat');
      setTimeout( () => {
        element.removeClass('heartbeat');
      }, 3000); // animation length

      return;
    }
    if (
      this.configuration.tvModel &&
      !this.configuration.tvModel.data.code &&
      !this.configuration.tvModel.data.makemodel &&
      !this.configuration.tvModel.data.modelNotListed
    ) {
      let useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
      this.$mdDialog.show({
        templateUrl: 'configurator/configuration/directives/addToCart/confirmTvModel.html',
        controller: ConfirmTvModel,
        controllerAs: '$ctrl',
        fullscreen: useFullScreen,
        clickOutsideToClose: true,
        parent: angular.element('#fmtv-app')
      }).then((configureTv: boolean) => {
        if (configureTv) {
          this.$ftvTv.show(null).then(() => this.doAdd());
        }
        else {
          this.doAdd();
        }
      });
    }
    else {
      this.doAdd();
    }
  }

  scrollTo(id: string) {
    this.$location.hash(id);
    this.$anchorScroll.yOffset = 600;
    this.$anchorScroll();
  }

  smallButton(){
    return '';
  }

  showAlertMessage(){
    if (  this.configuration.canOrder) {
      $('.message-overlay').removeClass('text-blur-out');
    }
    return !this.configuration.canOrder;
  }

  private doAdd() {
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if (this.configuration.canOrder && (this.configuration.isNew || this.configuration.dirty) && Number.isNaN(this.configuration.id)) {
      this.configuration.save().then(() => {
        this.cartService.addConfiguration(this.configuration); // .then(() => this.cartService.show());
      });
    } else {
      this.cartService.addConfiguration(this.configuration); // .then(() => this.cartService.show());
    }
  }

}
