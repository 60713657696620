import { BackingOptionProduct } from '../../../api-shopify/product-models/backing-option-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
export interface BackingOptionLineData {
  code?: string;
}

export class BackingOptionLine extends BaseLine<BackingOptionLineData>{
  kind = ConfigLineKinds.BackingOption;
  category = 'Options';
  name = 'Backing Options';
  order = 20;
  editState = 'configuration.tab.options.backing-options';
  product: BackingOptionProduct;
  scrollToElementId = 'backing-option-scroll-to';

  get valid() { return !!this.product; }

  deserialize(data?: BackingOptionLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return BackingOptionProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: BackingOptionProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.BackingOption] = BackingOptionLine;
