import app from '../../app';
@app.inject('$mdMedia', '$rootScope').service

export class GetSamplesOnPreviewService {
    visible = false;
    private timeOut: number = 20000;
    private timer: any;
    private wasShownAlready = false;
    private visibleCount = 0;

    constructor(
        private $mdMedia: ng.material.IMedia,
        private $rootScope: ng.IRootScopeService,
    ) {
    }

    show() {
      this.visibleCount++;
      if ( this.wasShownAlready === false ) {
        this.stopTimer();
        this.timer = setInterval(() => this.visible = true, this.timeOut);
        this.wasShownAlready = true;
      }
    }

    stopTimer() {
        clearInterval( this.timer);
        this.timer = null;
    }

}
