import { months } from 'moment';
import { CutGlassAccessoryProduct } from '../../../api-shopify/product-models/cut-glass-accessory-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import remove from 'lodash/remove';
import map from 'lodash/map';

export interface CutGlassAccessoriesLineData {
  codes?: string[];
}

export class CutGlassAccessoriesLine extends BaseLine<CutGlassAccessoriesLineData>{
  kind = ConfigLineKinds.CutGlassAccessory;
  category = 'Options';
  name = 'Accesories';
  order = 40;
  editState = 'configuration.tab.options.cut-glass-accessories';
  products: CutGlassAccessoryProduct[] = [];
  scrollToElementId = 'cut-glass-accessory-option-scroll-to';
  get valid() { return true; }

  constructor() {
    super();
    this.setDescription();
  }

  deserialize(data?: CutGlassAccessoriesLineData): ng.IPromise<any> {
    this.data = data;
    if (this.data && this.data.codes && this.data.codes.length) {
      let q: Array<ng.IPromise<void>> = [];
      forEach(this.data.codes, c => {
        q.push(CutGlassAccessoryProduct.get(c).then(p => this.toggleProduct(p, true)));
      });
      return this.q.all(q);
    }
    this.setDescription();
    return this.q.resolve();
  }

  toggleProduct(product: CutGlassAccessoryProduct, noTrigger = false) {
    if (some(this.products, p => p.code === product.code)) {
      remove(this.products, p => p.code === product.code);
    }
    else {
      this.products.push(product);
    }

    this.data = {
      codes: map(this.products, p => p.code)
    };

    this.setDescription();

    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    let q: Array<ng.IPromise<any>> = [];
    this.price = 0;

    forEach(this.products, p => {
      q.push(p.guaranteedPrice().then(price => this.price += price));
    });

    return this.q.all(q);
  }

  private setDescription() {
    if (this.products.length) {
      this.description = map(this.products, p => p.name).join('<br>');
    }
    else {
      this.description = 'None';
    }
  }
}

linesTable[ConfigLineKinds.CutGlassAccessory] = CutGlassAccessoriesLine;
