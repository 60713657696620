import app from '../app';
import angular from 'angular';

@app.inject('$window').directive('fixToTop')
export class FixToTop {
  definition(
    $window: ng.IWindowService
  ) {
    let $win = angular.element($window); // wrap window object as jQuery object

    return {
      restrict: 'A',
      link: (scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) => {
        let topClass = attrs['fixToTop'];     // get CSS class from directive's attribute value
        let parent = attrs['parent'] ? angular.element(element.closest(attrs['parent'])) : angular.element($window);
        let offsetTop: number;

        parent.on('scroll', (e) => {
          if (!offsetTop) {
            offsetTop = element.offset().top; // get element's offset top relative to document
          }
          if (parent.offset().top + parent.scrollTop() >= offsetTop) {
            element.addClass(topClass);
          } else {
            element.removeClass(topClass);
          }
        });
      }
    };
  }
}
