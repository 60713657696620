import app from '../app';
import { RightBarService } from './rightBar.service';
import find from 'lodash/find';
import remove from 'lodash/remove';

interface SidenavScope extends ng.IScope {
  $$mdHandle: string;
}

/*
  Plugs into the angular-material sidenav to track when they are opened and closed
*/
app.inject('$provide').config((
  $provide: ng.auto.IProvideService
) => {
  $provide.decorator('mdSidenavDirective', [
    '$delegate',
    '$controller',
    RightBarService.name,
    '$mdSidenav',
    '$timeout',
    (
      $delegate: any,
      $controller: ng.IControllerService,
      $ftvRightBar: RightBarService,
      $mdSidenav: ng.material.ISidenavService,
      $timeout: ng.ITimeoutService
    ) => {
      let directive = $delegate[0];

      let compile = directive.compile;

      // tslint:disable-next-line:only-arrow-functions
      directive.compile = function (tElement: any, tAttrs: any) {
        let link = compile.apply(this, arguments);
        // tslint:disable-next-line:only-arrow-functions
        return function (
          scope: SidenavScope,
          elem: ng.IAugmentedJQuery,
          attrs: ng.IAttributes
        ) {
          scope.$watch('isOpen', val => {
            let bar = find($ftvRightBar.bars, { id: attrs['mdComponentId'] });
            if (bar) {
              if (val === false) {
                $timeout(() => {
                  remove($ftvRightBar.bars, (bar as any));
                  if ( (bar as any).closed) {
                    (bar as any).closed();
                  }
                }, 300);
              }
              else if (typeof val === 'undefined') {
                $timeout(() => {
                  $mdSidenav( ( bar as any).id).open();
                });
              }
            }
          });

          link.apply(this, arguments);
        };
      };

      return $delegate;
    }]);
});
