import { IDesignConfiguration, Product } from './product';
import lib from '~/api-shopify/api-shopify.module';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import find from 'lodash/find';
import { DesignTypes } from '~/configurator/design/designTypes';

let depths = [
  {
    code: '1133',
    name: 'Recessed',
    imgSrc: window.fmtvAssetsRoot + 'assets/configurator/depths/sidedepth0.jpg',
    description: 'Please call Customer Service at 866-612-1895 for additional information about types of back boxes and when one is needed.',
    hide: []
  },
  {
    code: '0',
    name: '1.5-inch Universal',
    imgSrc: window.fmtvAssetsRoot + 'assets/configurator/depths/sidedepth1.jpg',
    description: 'Please call Customer Service at 866-612-1895 for additional information about types of back boxes and when one is needed.',
    hide: ['frame with art', 'metro art']
  },
  {
    code: '3',
    name: '3-inch Universal',
    imgSrc: window.fmtvAssetsRoot + 'assets/configurator/depths/sidedepth3.jpg',
    description: 'Please call Customer Service at 866-612-1895 for additional information about types of back boxes and when one is needed.',
    hide: []
  },
  {
    code: 'standard',
    name: 'Standard',
    imgSrc: window.fmtvAssetsRoot + 'assets/configurator/depths/sidedepth3.jpg',
    description: 'Please call Customer Service at 866-612-1895 for additional information about types of back boxes and when one is needed.',
    hide: []
  }
  ,
  {
    code: 'deep',
    name: 'Deep',
    imgSrc: window.fmtvAssetsRoot + 'assets/configurator/depths/sidedepth3.jpg',
    description: 'Please call Customer Service at 866-612-1895 for additional information about types of back boxes and when one is needed.',
    hide: []
  }
];

export class SideDepthProduct extends Product {

  static list() {
    return SideDepthProduct.q.resolve(filter(depths, d => {

      const config = (SideDepthProduct as any).designService.designConfiguration;

      if ( config.frame && config.frame.frameProduct && config.frame.frameProduct.isHardwood()) {
        if ( config.frame.frameProduct.isHardwoodTraditional() || config.frame.frameProduct.isHardwoodBaroque() ) {
          // there is not a deep option for Baroque or Tradituonal ... those two profiles come only in a standard depth
          return d.code === 'standard';
        } else {
          // otherwise return standard and deep for hardwood
          return  (d.code === 'standard' || d.code === 'deep');
        }
      } else {
        // always remove standard and deep if its not hardwood
        return ( !includes(d.hide, (SideDepthProduct as any).designService.designConfiguration.type) && !(d.code === 'standard' || d.code === 'deep'));
      }
    }).map(d => new SideDepthProduct(d)));
  }

  static get(code: string) {
    return SideDepthProduct.q.resolve(new SideDepthProduct(find(depths, d => d.code === code)));
  }

  @lib.inject('$q').property
  private static readonly q: ng.IQService;

  @lib.inject('DesignConfigurationService').property
  private static readonly designService: IDesignConfiguration;

  public hide: string[];
  private _code: string;
  private _manualPriceAdjustment: number  = 1.06; // this should be set in shopify but temporarily set here

  constructor(data: any){
    super(data);
    this._thumbnailUrl = data.imgSrc;
    this._shortDescription = data.description;
    this._name = data.name;
    this.hide = data.hide;
    this._code = data.code;
  }
  get code(){
    return this._code;
  }
  get thumbnailUrl() {
    return this._thumbnailUrl;
  }

  get description() {
    return this._shortDescription;
  }
/*
 * There should be code for this in there already somehwere, but I think it may have become lost along the way when andrew initially switched us from Virto a year ago.
 *
 *   The formula for the 3" side depth should be:
 *   A) If a TV Art Cover: $0 because the price is already included in the motorized subframe which is 3"
 *   B) for Metro, Artisan, Gallery collection the 1.5" is always $0.00 ... and the 3" universal is price = [screen size]2.5
 *   C) for hardwoods the avaialble depths vary by profile unfortunately but basically all but the traditional and baroque styles (they only have a standard) have an option for
 *   - Standard price = $0
 *   - Deep - price = [screen size]4
 *
 */
  guaranteedPrice(): ng.IPromise<number> {
    const config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }

    const tvSize = config.tvModel.sizeForPrice;
    if ( config.artwork && ( config.frame.frameProduct && !config.frame.frameProduct.isHardwood()) ){
      // A) If a TV Art Cover: $0 because the price is already included in the motorized subframe which is 3"
      // -- however if hardwood is included, then standard/deep rules apply and are not included
      return this.q.resolve(0);
    }

    if ( config.frame){
      if (  config.frame.frameProduct && config.frame.frameProduct.isHardwood() ) {
        // C)  for hardwoods the avaialble depths vary by profile unfortunately but
        // basically all but the traditional and baroque styles (they only have a standard) have an option for
        // - Standard price = $0
        // - Deep - price = [screen size]4
        if ( config.frame.frameProduct.isHardwoodTraditional() || config.frame.frameProduct.isHardwoodBaroque() ) {
          // there is not a deep option for Baroque or Tradituonal ... those two profiles come only in a standard depth
          return this.q.resolve(0);
        } else {
          if (this.code === 'deep') {
            return this.q.resolve(Math.ceil(tvSize * 4 * this._manualPriceAdjustment));
          } else {
            return this.q.resolve(0);
          }
        }
      } else {
        // B) for Metro, Artisan, Gallery collection the 1.5" is always $0.00 ... and the 3" universal is price = [screen size]2.5
        if (this.code === '3') {
          return this.q.resolve(Math.ceil(tvSize * 2.5 * this._manualPriceAdjustment));
        } else {
          return this.q.resolve(0);
        }
      }
    }
    return this.q.resolve(0);
  }
/*
    let tvSize = config.tvModel.sizeForPrice;

    if (this.code === '3' && !config.frame) {
      return this.q.resolve(Math.ceil(tvSize * 2.5));
    }
*/
  //  return this.q.resolve(0);

}
