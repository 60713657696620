import Module from '@rightscale/ui-angular-decorators';

import '../ui.images';
import '../ui.templates';

const lib = new Module('ftv.ui', ['ngMaterial', 'ui.images', 'ui.templates'], { prefix: 'ftv' });

lib.inject('$mdIconProvider').config((
  $mdIconProvider: ng.material.IIconProvider
) => {
  $mdIconProvider.icon('add-to-cart', 'ui/samples/add.svg');
});

export default lib;
