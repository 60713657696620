import app from '../../../app';

@app.component({
  templateUrl: 'configurator/configuration/frameType/tiles/quoteTile.html',
  bindings: {
    quote: '@',
    author: '@',
    background: '@'
  }
})
export class QuoteTile { }
