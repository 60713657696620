
export enum DesignTypes {
  FrameOnly = <any>'frame only',
  FrameWithMirror = <any>'frame with mirror',
  FrameWithArt = <any>'frame with art',
  FramelessMirror = <any>'frameless mirror',
  MetroMirror = <any>'metro mirror',
  MetroArt = <any>'metro art',
  CutGlass = <any>'cut glass',
}
