import { QuickStartController } from './quickStart.controller';
import app from '../app';
import { FrameTypeController } from './frameType/frameType.controller';
import ConfigurationController from './configuration.controller';
import DesignTabController from './design/design.controller';
import OptionsTabController from './options/options.controller';
import SummaryController from './summary/summary.controller';
import PrintController from './print/print.controller';
import { DesignConfigurationService } from '../design/designConfiguration.service';

app
  .inject('$stateProvider')
  .config((
    $stateProvider: ng.ui.IStateProvider
  ) => {
    $stateProvider
      .state('quick-start', {
        url: window.fmtvApp.standalone && '/quick-start?type&frame&art&mirror&liner&size',
        params: {
          type: undefined,
          frame: undefined,
          art: undefined,
          mirror: undefined,
          liner: undefined,
          size: undefined
        },
        controller: QuickStartController
      })
      .state('configuration', {
        url: window.fmtvApp.standalone && '/configuration/:configurationId',
        params: {
          configurationId: undefined
        },
        abstract: true,
        parent: 'layout',
        views: {
          'main@layout': {
            template: '<ftv-summary-bar hide-xs hide-sm></ftv-summary-bar><ui-view class="content-wrapper" flex layout="column"></ui-view>'
          }
        },
        resolve: {
          designConfiguration: [
            DesignConfigurationService.name, '$stateParams',
            (config: DesignConfigurationService, params: ng.ui.IStateParamsService) => config.load(params['configurationId'])
          ]
        }
      })
      .state('configuration.frameType', {
        url: window.fmtvApp.standalone && '/frame-type?frame&art&mirror&liner&size',
        params: {
          frame: undefined,
          art: undefined,
          mirror: undefined,
          liner: undefined,
          size: undefined
        },
        templateUrl: 'configurator/configuration/frameType/frameType.html',
        controller: FrameTypeController,
        controllerAs: '$ctrl',
        resolve: {
          $title: () => 'Frame Types'
        }
      })
      .state('configuration.print', {
        url: window.fmtvApp.standalone && '/print',
        views: {
          'main@layout': {
            templateUrl: 'configurator/configuration/print/print.html',
            controller: PrintController,
            controllerAs: '$ctrl'
          }
        },
        resolve: {
          $title: () => 'Print',
          PreviousState: ['$state', ($state: ng.ui.IStateService) => {
            const currentStateData = {
              name: $state.current.name,
              params: $state.params
            };
            return currentStateData;
          }]
        }
      })
      .state('configuration.tab', {
        abstract: true,
        templateUrl: 'configurator/configuration/configuration.html',
        controller: ConfigurationController,
        controllerAs: '$ctrl'
      })
      .state('configuration.tab.design', {
        url: window.fmtvApp.standalone && '/design',
        views: {
          design: {
            templateUrl: 'configurator/configuration/design/design.html',
            controller: DesignTabController,
            controllerAs: '$ctrl'
          },
          options: {
            templateUrl: 'configurator/configuration/options/options.html',
            controller: OptionsTabController,
            controllerAs: '$ctrl'
          },
          summary: {
            templateUrl: 'configurator/configuration/summary/summary.html',
            controller: SummaryController,
            controllerAs: '$ctrl'
          },
        },
        resolve: {
          $title: () => 'Design'
        }
      })
  .state('configuration.tab.options', {
    url: window.fmtvApp.standalone && '/options',
    views: {
      options: {
        template: '<div ui-view></div>',
        controller: OptionsTabController,
        controllerAs: '$ctrl'
      }
    }
  });
        /*
      .state('configuration.tab.options', {
        parent:'configuration.tab.design',
        url: window.fmtvApp.standalone && '/options',
        views: {
          options: {
            templateUrl: 'configurator/configuration/options/options.html',
            controller: OptionsTabController,
            controllerAs: '$ctrl'
          }
        },
        resolve: {
          $title: () => 'Options'
        }
      })
      .state('configuration.tab.summary', {
        abstract: true,
        url: window.fmtvApp.standalone && '/summary',
        views: {
          summary: {
            templateUrl: 'configurator/configuration/summary/summary.html',
            controller: SummaryController,
            controllerAs: '$ctrl'
          }
        },
        resolve: {
          $title: () => 'Summary'
        }
      });
      */
  });
