import app from '../../../app';

import { RightBarService } from '../../../rightBar/rightBar.service';
import { MirrorWarrantyProduct } from '../../../../api-shopify/product-models/mirror-warranty-product';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', RightBarService, OptionsService).controller
export class OptionsMirrorWarrantyController {

  products: MirrorWarrantyProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private rightBar: RightBarService,
    private optionsService: OptionsService
  ) {
    MirrorWarrantyProduct.list().then(p => this.products = p);
  }

  $onInit() { }

  select(product: MirrorWarrantyProduct) {
    this.configuration.mirrorWarranty.setProduct(product);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder && this.configuration.id) {
      // this.configuration.save();
    }
    this.optionsService.nextOption();
  }

  details(e: MouseEvent, product: MirrorWarrantyProduct) {
    e.preventDefault();
    e.stopPropagation();

    this.rightBar.show({
      templateUrl: 'configurator/configuration/options/mirror-warranty/details.html',
      id: 'mirrorWarrantyDetails',
      backButton: true,
      title: product.name,
      data: product.longDescription
    });
  }
}
