import map from 'lodash/map';
import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
import { TvModelLine } from '~/configurator/design/lines';
import { ProductWithSamples } from './product-with-samples';
import { SampleProduct } from './sample-product';

let listPromise: ng.IPromise<ArtProduct[]>;
// used to load images doesn't need angular promise
let defaultsPromise: ng.IPromise<ArtProduct[]>;

@lib.inject(ProductsApi).service
export class ArtProduct extends ProductWithSamples {

  public static readonly defaultSkus = ['ART-7045', 'ART-7070', 'ART-8175', 'ART-8001', 'ART-8023'];

  static get(code: string) {
    return ArtProduct.productsApi.getArtBySku(code).then((p: any) => {
      return new ArtProduct(p);
    });
  }

  static list() {
    if (listPromise) {
      return listPromise;
    }

    // key here is 'cache key' and not a product key/id itself -jp

    /*  REMOVING CACHING
    const cachedData = sessionStorage.getItem(ArtProduct.key);
    let q: ng.IPromise<any>;

    if (cachedData && cachedData != null) {
      q = ArtProduct.$q.resolve(JSON.parse(cachedData));
    }
    else {
      q = ArtProduct.productsApi.getArtCollections().then(p => {
        const stripped = p.map( (prod: any ) => prod ); // ArtProduct.strip(prod));
        try {
          // key here is a 'cache key' and not a 'product id'
          sessionStorage.setItem(ArtProduct.key, JSON.stringify(stripped));
        }
        catch {}
        return stripped;
      });
    } */

    let q: ng.IPromise<any> = ArtProduct.productsApi.getArtCollections();

    // return listPromise = q.then(products => map(products, p => new ArtProduct(p)));
    return listPromise = q.then(products => map(products, p => new ArtProduct(p)));
  }

  // for defaults we don't know what design type the user will use so best to load defaults for all
  // *but* load the initial loading screen items first
  static loadDefaults() {
    if ( defaultsPromise) {
      return defaultsPromise;
    }
    // return listPromise = q.then(products => map(products, p => new ArtProduct(p)));
    return defaultsPromise = ArtProduct.productsApi.getArtBySkuList( ArtProduct.defaultSkus ).then(products => map(products, p => new ArtProduct(p)));
  }

  // @lib.inject(ApiShopifyService).property
  // private static readonly apiShopifyService: ApiShopifyService;

  @lib.inject(ProductsApi).property
  private static readonly productsApi: ProductsApi;

  private static key = 'art-cache-shopify';

  @lib.inject('$q').property
  private static readonly $q: ng.IQService;

  // public id: string;
  public artistRoyaltyPrice: string;
  public artist: string;
  public motorizedArtworkSubframePrice: string;
  public layerUrl: string;
  private _pricePromise: ng.IPromise<number>;
  private _manualPriceAdjustment: number  =  1.16607; // this should be set in shopify but temporarily set here - was 1.06 but want additional 10%

  constructor(data: any) {
    // super(data);
    super(data);
    this.artistRoyaltyPrice = data.artistRoyaltyPrice;
    super._basePrice = parseInt(data.artistRoyaltyPrice);
    this.artist = data.artist;
    this.motorizedArtworkSubframePrice = '';
    this.layerUrl = data.layerUrl;
    try {
    // no longer offering card samples
    // this.addSample(new SampleProduct(data.cardSample));
    this.addSample(new SampleProduct(data.canvasSample));
    } catch (e){
     // console.log( `missing sample data in ${this.sku}`);
    }
  }

  public get product() {
    return this;
  }

  /* this does not appear to be in use - JP
  static forCategory(category: VirtoCommerceCatalogModuleWebModelCategory) {
    return ArtProduct.productsService.list(category.code).then(products => products.map(p => new ArtProduct(p)));
  }
*/

  /**
   * URLs of images to be shown in detail views
   *
   * @readonly
   *
   * @memberof FrameProduct
   */
  get imagesForDetails() {
    return this.previewUrl;
  }

  get subFramePrice() {
    if (!this.designService.designConfiguration) {
      return null;
    }

    let tv = this.designService.designConfiguration.tvModel;

    if (!tv || !tv.sizeForPrice) {
      return null;
    }

    let artBase = 1400;
    let factor = 30;
    if (tv.sizeForPrice >= 75) {
      factor += 12;
    }
    else if (tv.sizeForPrice >= 52) {
      factor += 8;
    }

    if ( (tv as TvModelLine).isCustomSize ) {
      return Math.ceil( ((artBase + (factor * tv.sizeForPrice)) * this._manualPriceAdjustment ) * (tv as TvModelLine).customTVSizePriceAdjustmentOnNonFixedPricedItems );
    } else {
      return Math.ceil( (artBase + (factor * tv.sizeForPrice)) * this._manualPriceAdjustment);
    }
  }

  guaranteedPrice() {
    if (!this._pricePromise) {
      this._pricePromise = this.q.resolve(this.basePrice());
      // this.q.resolve( Math.ceil(2 * this.price / 10.0));
    }
    return this._pricePromise;
  }

}
