import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import clone from 'lodash/clone';

export interface TvModelLineData {
  diagscreensize?: number;
  height?: number;
  width?: number;
  id?: string;
  makemodel?: string;
  previewImage?: string;
  speakerlayout?: string;
  modelNotListed?: boolean;
  provideLater?: boolean;
  code?: string;
}

export class TvModelLine extends BaseLine<TvModelLineData> {

  public static tvBackgroundImage = 'https://cdn.shopify.com/s/files/1/0011/6208/4407/products/TVM01F_blacked_out_2000x.png?v=1597359591';

  kind = ConfigLineKinds.TvModel;
  category = 'Design';
  name = 'TV Size';
  order = 10;
  editState = 'configuration.tab.design';
  scrollToElementId = 'tv-model-option-scroll-to';
  // this list here is copied from tv.controller.ts.  It should be centralized somewhere but not worth the effort at this point
  // keep the two aligned manually for now
  standardSizes = [32, 43, 50, 55, 65, 75, 85];

  customTVSizePriceAdjustmentOnNonFixedPricedItems = 1.35;

  get valid() { return true; }

  get isCustomSize( ){
    if (!this.data || ( this.data && !this.data.diagscreensize)) {
      return false;
    } else  if ( this.standardSizes.indexOf( this.data.diagscreensize  ) > -1 ) {
      return false; // size in the standard size list
    } else {
      return true; // has a size not in the custom size list;
    }

  }
  get sizeForPrice() {
    return (this.data && this.data.diagscreensize) ? Math.max(this.data.diagscreensize, 40) : null;
  }

  constructor() {
    super();
    this.data = {};
  }

  deserialize(data?: TvModelLineData) {
    this.data = data || {};
    this.setPreview();
    this.setDescription(this.data);
    return this.q.resolve();
  }

  update(model: TvModelLineData) {
    this.setPreview();
    this.setDescription(model);
    this.data = clone(model);
    this.triggerUpdate();
  }

  refreshPrice() {
    return this.q.resolve();
  }

  private setPreview() {
    this.preview = {
      image: TvModelLine.tvBackgroundImage,
      index: 0
    };
  }

  private setDescription(model: TvModelLineData) {
    if (model && model.diagscreensize) {
      let t: string;

      if (!model.makemodel && model.diagscreensize) {
        t = `${model.diagscreensize}-in. diagonal screen size`;
      }
      else if (model.makemodel) {
        t = `${model.makemodel} ${model.diagscreensize}-in. diag`;
      }

      if (!model.makemodel) {
        t += '<br>Model number to be provided at a later time.';
      }

      t += '<br><b>Note:</b> TV specified and supplied by client.';
      this.description = t;
    } else {
      // added for case when changing screen size but current screen size is out of bounds
      // so need to reset description (see design configuration switchType())
      this.description = '';
    }
  }
}

linesTable[ConfigLineKinds.TvModel] = TvModelLine;
