import app from '../app';
import angular from 'angular';
import some from 'lodash/some';

export interface RightBar {
  id: string;
  title: string;
  templateUrl: string;
  controller?: () => void;
  closed?: () => void;
  className?: string;
  closeButton?: boolean;
  backButton?: boolean;
  data?: any;
}

@app.inject('$mdSidenav', '$timeout').service
export class RightBarService {

  bars: RightBar[] = [];

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private $timeout: ng.ITimeoutService
  ) { }

  show(bar: RightBar) {
    if (some(this.bars, { id: bar.id })) {
      throw new Error(`RightBar with id "${bar.id}" is already present`);
    }
    if (!bar.controller) {
      bar.controller = angular.noop;
    }
    this.bars.push(bar);
    // this.$timeout(() => this.$mdSidenav(bar.id).open());
  }
}
