import app from '../app';
import { LightBoxModalController } from './lightBoxModal.controller';
import angular from 'angular';

export interface LightBox {
  data?: any;
}

@app.inject(
  '$mdDialog',
  '$mdMedia',
).component({
  templateUrl: 'configurator/lightBox/lightBox.html',
  bindings: {
    product: '=',
    addSelection: '<',
    showInfoIcon: '<'
  }
})
export class LightBoxComponent {

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private product: any,
    private addSelection: any,
    private showInfoIcon: boolean, // show as an info icon (!) instead of as a button
  ) {
  }

  $onInit() {
        this.showInfoIcon = this.showInfoIcon || false;
  }

    // this control has dual purposes.  It can open the lightbox from a button (i.e. one for each product in the list).
  // and can be opened via an info icon (!) beside a selected product.  If it is set to show the info-icon, it will only display the icon if there is info for the lightbox info
  displayAsIcon(){
    return this.showInfoIcon;
  }

  hasLightBoxInfo() {
    return this.product && this.product.lightBoxInfo;
  }

  setLightboxClass(){
    angular.element('.md-dialog-container').addClass('lightbox-dialog');
  }

  showModal() {
    this.$mdDialog.show({
      locals: { product: this.product, lightboxInfo: this.product.lightBoxInfo, addSelection: this.addSelection },
      templateUrl: 'configurator/lightBox/lightBoxModal.html',
      controller: LightBoxModalController,
      controllerAs: '$ctrl',
      title: 'here',
      fullscreen: true,
      clickOutsideToClose: true,
      onComplete: this.setLightboxClass,
      parent: angular.element('#fmtv-app'),
    });
  }

  close() {

  }
}
