import app from './app';

app
  .inject(
  '$provide',
  '$mdIconProvider',
  '$locationProvider',
  '$analyticsProvider'
  )
  .config((
    $provide: ng.auto.IProvideService,
    $mdIconProvider: ng.material.IIconProvider,
    $locationProvider: ng.ILocationProvider,
    $analyticsProvider: any
  ) => {
    // Tells Angulartics to use the /app base when reporting the URL
    $analyticsProvider.withAutoBase(true);

    // Hijacks the Angular exception handler to rethrow exceptions that work with source maps on Chrome
    $provide.decorator('$exceptionHandler', ['$delegate', ($delegate: any) => {
      return (exception: any, cause: any) => {
        setTimeout(() => {
          throw exception;
        });
      };
    }]);

    $mdIconProvider
      .icon('info', 'configurator/icons/info.svg')
      .icon('alert', 'configurator/icons/alert.svg')
      .icon('arrowr', 'configurator/icons/arrow-right.svg')
      .icon('arrowl', 'configurator/icons/arrow-left.svg')
      .icon('cart', 'configurator/icons/cart.svg')
      .icon('cheveronL', 'configurator/icons/chevron-left.svg')
      .icon('cheveronLeftLarge', 'configurator/icons/chevron-left-large.svg')
      .icon('cheveronR', 'configurator/icons/chevron-right2.svg')
      .icon('cheveronD', 'configurator/icons/chevron-down.svg')
      .icon('check', 'configurator/icons/check.svg')
      .icon('edit', 'configurator/icons/pencil.svg')
      .icon('fullscreen', 'configurator/icons/fullscreen.svg')
      .icon('fullscreen2', 'configurator/icons/fullscreen2.svg')
      .icon('fullscreenexit', 'configurator/icons/fullscreen-exit.svg')
      .icon('logout', 'configurator/icons/logout.svg')
      .icon('mail', 'configurator/icons/email.svg')
      .icon('menu', 'configurator/icons/menu.svg')
      .icon('phone', 'configurator/icons/phone.svg')
      .icon('print', 'configurator/icons/printer.svg')
      .icon('save', 'configurator/icons/content-save.svg')
      .icon('close', 'configurator/icons/window-close.svg')
      .icon('pdf', 'configurator/icons/pdf.svg')
      .icon('delete', 'configurator/icons/delete.svg')
      .icon('check2', 'configurator/icons/checkbox-marked-circle.svg', 20)
      .icon('link', 'configurator/icons/link.svg')
      .icon('download', 'configurator/icons/download-file.svg')
      .icon('arrow-down', 'configurator/icons/arrow-down.svg');
  });
