import app from '../../app';

import angular from 'angular';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { Line } from '../../design/lines';
import { DesignConfiguration } from '../../design/designConfiguration';
import { BrandingInfo } from '../../services/brandingInfo';
import { AuthenticationService } from '../../user/authentication.service';
import CustomLineController from './customLine.controller';
import RushController from './rush.controller';
import isEmpty from 'lodash/isEmpty';
import { DesignTypes } from '~/configurator/design/designTypes';

@app.inject(
  '$scope',
  '$mdDialog',
  '$mdMedia',
  AuthenticationService,
  '$stateParams',
  '$mdToast',
  'brandingInfo',
  'designConfiguration',
  DesignConfigurationService,
  '$rootScope'
).controller
export default class SummaryController {

  lines: { [key: string]: Line[] };

  editingComment = false;
  newComment: string;
  private _showExpedite: boolean;
  constructor(
    private $scope: ng.IScope,
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private authentication: AuthenticationService,
    private $stateParams: ng.ui.IStateParamsService,
    private $mdToast: ng.material.IToastService,
    public brandingInfo: BrandingInfo,
    public configuration: DesignConfiguration,
    private designConfigurationService: DesignConfigurationService,
    private $rootScope: ng.IRootScopeService,
  ) {
    $scope.$watchCollection(() => map(this.configuration.lines, l => l.id), () => {
      const lines = this.configuration.lines; // this.brandingInfo.settings.estimateShipping ? this.configuration.linesWithShipping : this.configuration.data.lines;
      this.lines = groupBy(sortBy(lines, l => l.order), l => l.category);
    });
    $rootScope.$watch(() => this.designConfigurationService.designConfiguration.type, (configType, oldVal) => {
      if (!isEmpty(configType)) {
        this._showExpedite = this.designConfigurationService.designConfiguration.type !== DesignTypes.CutGlass;
      }
    }, true);
  }

  $onInit() { }

  addCustomLine(ev: MouseEvent) {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    this.$mdDialog.show({
      controller: CustomLineController,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/configuration/summary/customLine.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen
    });
  }
  shipDateSpan() {
    const monthsString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // 14-20 weeks
    const now = new Date();
    //                          14 weeks in minutes
    const ms14 = now.getTime() + (141120 * 60 * 1000);
                      // 20 weeeks in minutes
    const ms20 = now.getTime() + (201600 * 60 * 1000);
    const startingIdx =  new Date(ms14 ).getMonth();
    const endingIdx = new Date(ms20).getMonth();
    const dateString  = monthsString[startingIdx] + '-' + monthsString[endingIdx];
    return dateString;
  }
  removeLine(id: string) {
    this.configuration.removeLine(id);
  }

  save(e: MouseEvent) {
    if (this.brandingInfo.id === '0') {
      this.designConfigurationService.showSaveDialog(e);
    }
    else {
      this.designConfigurationService.save().then(() => {
        this.$mdDialog.show(
          this.$mdDialog.alert()
            .parent(angular.element('#fmtv-app'))
            .clickOutsideToClose(true)
            .title('Design Updated')
            .textContent('Your saved design has been updated. Thanks for designing with us!')
            .ariaLabel('Design Updated')
            .ok('Ok')
            .targetEvent(e));
      });
    }
  }

  setRush(ev: MouseEvent) {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    this.$mdDialog.show({
      controller: RushController,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/configuration/summary/rush.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen
    });
  }

  showExpedite(){
    // hide for cut glass
    return this._showExpedite;
  }

  showWarning() {
    return !this.configuration.canOrder && (!this.$stateParams['store'] || this.authentication.loggedIn);
  }

  notifyCopy() {
    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent('A link to your configuration was copied to the clipboard')
        .hideDelay(3000)
        .position('bottom right')
    );
  }

  configurationLink() {
    return this.configuration.url + '/summary';
  }

  admin() {
    return this.authentication.isAdmin;
  }

  // this is what calculates missing selection per 'section' ( ie. 'Design`, `Options` (see html for usage))
  missingItemsCount(section: string) {
    return this.lines[section].filter(i => !i.description).length;
  }

  editComment() {
    this.editingComment = true;
    this.newComment = this.configuration.comment;
  }

  cancelCommentEdit() {
    this.editingComment = false;
  }

  saveComment() {
    this.editingComment = false;
    this.configuration.comment = this.newComment;
  }
  getSamples() {
    this.$rootScope.$broadcast('show-get-sample-modal', true);
  }
}
