import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import sortBy from 'lodash/sortBy';
let listPromise: ng.IPromise<BackingOptionProduct[]>;

export class BackingOptionProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = BackingOptionProduct.productsService.getAllBackingOptionProduct()
      .then(products => sortBy(products, p => p.sortPosition).map((p: any) => new BackingOptionProduct(p)));
  }

  static get(code: string) {
    return this.list().then(products => find(products, p => p.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;
  private _maxDimension: number;
  private _minPrice: number;
  private _manualPriceAdjustment: number  = 1.06; // this should be set in shopify but temporarily set here

  constructor( data: any ){
    super(data);
    this._maxDimension = data.maxDimension;
    this._minPrice = data.minPrice;
  }
  get maxDimension() {
    return this._maxDimension || Number.MAX_VALUE;
  }

  get minPrice() {
    return this._minPrice || 0;
  }

  guaranteedPrice(): ng.IPromise<number> {
    let config = this.designService.designConfiguration;

    if (config && config.cutGlass && config.cutGlass.surface) {
      if ( this.sku === 'BLACKOUT-BACK-PAINTING') {
        const additionalPriceAdjument = 1.2;
        return this.basePrice().then(p => Math.max(this.minPrice * (this._manualPriceAdjustment * additionalPriceAdjument) , config.cutGlass.surface * p * (this._manualPriceAdjustment  * additionalPriceAdjument)));
      }
      return this.basePrice().then(p => Math.max(this.minPrice * this._manualPriceAdjustment, config.cutGlass.surface * p * this._manualPriceAdjustment));
    }
    return this.q.resolve(0);
  }
}
