import lib from '~/api-shopify/api-shopify.module';
import { ShopifyHttpService } from '~/api-shopify/http.service';
import { ProductQueryParams, ProductQuery } from '~/api-shopify/product-query-params';
import { ArtProduct } from './product-models/art-product';

@lib.inject(ShopifyHttpService).service
export class ProductsApi {
  constructor(
    private shopifyHttpService: ShopifyHttpService
  ){}

  // by convention the route names are not plural
  getAllMountingOptionProducts(){
    return this.shopifyHttpService.getAllCollections('mounting-option');
  }
  getAllMirrorWarrantyProducts(){
    return this.shopifyHttpService.getAllCollections('mirror-warranty');
  }

  getAllMirrorProducts(){
    return this.shopifyHttpService.getAllCollections('mirror');
  }
  getAllInstallationMethodProduct() {
    return this.shopifyHttpService.getAllCollections('installation-method');
  }
  getInstallationMethodBySku(sku: string) {
    return this.shopifyHttpService.getProductBySku( sku, 'installation-method');
  }

  getAllEdgeFinishProduct(){
    return this.shopifyHttpService.getAllCollections('edge-finishing');
  }
  getAllCutGlassProduct(){
    return this.shopifyHttpService.getAllCollections('cut-glass');
  }
  getAllCutGlassAccessoryProduct(){
    return this.shopifyHttpService.getAllCollections('cut-glass-accessory');
  }
  getAllBackingOptionProduct(){
    return this.shopifyHttpService.getAllCollections('backing-option');
  }
  getAllTVControlProducts(){
    return this.shopifyHttpService.getAllCollections('tv-control');
  }
  getTVControlBySku( sku: string ){
    return this.shopifyHttpService.getProductBySku(sku, 'tv-control');
  }

  getAllArtWarrantyProducts(){
    return this.shopifyHttpService.getAllCollections('art-warranty');
  }

  getArtControlCollection(){
    return this.shopifyHttpService.getAllCollections('art-control');
  }

  getArtControlBySku(sku: string){
    return this.shopifyHttpService.getProductBySku(sku, 'art-control').then( r => r );
  }
  // TODO ... SHOULD THIS BE 'PER PRODUCT TYPE' OR JUST GENERALIZED TO BASIC getCollection getSku, etc and the product classes send their
  // product type??
   /****  ART   *****/
   getArtCollections(){
     return this.shopifyHttpService.getAllCollections('art');
    // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/art/collection`).then(r => r );
   }

   getArtProducts( products: ArtProduct[] ){
    return this.shopifyHttpService.getAllCollections('art');
   // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/art/collection`).then(r => r );
  }

  getArtBySku(sku: string){
    return this.shopifyHttpService.getProductBySku( sku, 'art').then( r => r );
  }

  getArtBySkuList(skus: string[]){
    return this.shopifyHttpService.getProductsBySkuList( skus, 'art').then( r => r );
  }
  /****  FRAMES   *****/
  getFrameCollections(){
    return this.shopifyHttpService.getAllCollections('frame');
    // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/frames/collection`).then(r => r );
  }

  getFrameCollectionById( collectionId: string){
    return this.shopifyHttpService.getCollection( collectionId, 'frame').then( r => r );
    // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/frames/collection/${collectionId}/collection`).then(r => r );
  }

  getFrameBySku(sku: string){
    return this.shopifyHttpService.getProductBySku( sku, 'frame').then( r => r );
    // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/frames/product/${sku}/sku`).then(r => r );
  }

  getFramesBySkuList( skus: string[]) {
    return this.shopifyHttpService.getProductsBySkuList( skus, 'frame' ).then( r => r);
  }
  // TODO not sure yet what lookup to use here is the frame will have a product id
  // and inside the product has a variant of a frame with id
  getFrameById(id: string){
    return this.shopifyHttpService.getProductById( id, 'frame').then( r => r );
    // return this.$http.get<any>(`${window.shopifyCacheRoot}/api/frames/product/${id}/product`).then(r => r );
  }

  // in this call the frame and finish now both have the same sku
  getFrameFinishBySku( sku: string) {
    return this.shopifyHttpService.getProductBySku( sku, 'finish').then( r => r );
  }

  getFrameFinishesByFrameBaseSku( frameBaseSku: string) {
    return this.shopifyHttpService.getProductBySku( frameBaseSku, 'finishes').then( r => r );
  }

  getLinerBySku( sku: string ) {
    return this.shopifyHttpService.getProductBySku( sku, 'liner').then( r => r );
  }

  getAllLiners(){
    return this.shopifyHttpService.getAllCollections('liner').then( r => r );
  }
  /**
   * SEE: https://help.shopify.com/en/api/reference/products/product#show
   *      GET /admin/products/#{product_id}.json
   * @param productId the product to be retrieved
   * @param fields a list of fields to filter the result (none for everything)
   */
  getProductById( productId: string, fields?: string) {
    return this.shopifyHttpService.getProductById( productId, fields );
  }

  /**
   * SEE: https://help.shopify.com/en/api/reference/products/product#show
   * GET /admin/products.json
   * @param params the params for querying (see reference above)
   */
  getProducts( params: ProductQueryParams ) {
    return this.shopifyHttpService.getProducts( params );
  }

}
