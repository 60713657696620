import _ from 'lodash';
import { LambdasService, ProductInfo } from '~/lambdas';
import {
  FrameCategories,
  FrameProduct
} from '../../api-shopify/product-models/frame-product';
import { LinerProduct } from '../../api-shopify/product-models/liner-product';
import { SideDepthProduct } from '../../api-shopify/product-models/side-depth-product';
import { Analytics } from '../analytics';
import app from '../app';
import { BrandingService } from '../services/branding.service';
import { BrandingInfo } from '../services/brandingInfo';
import { AuthenticationService } from '../user/authentication.service';
import { DesignTypes } from './designTypes';
import * as Lines from './lines';
import { ConfigLineKinds, BaseLine } from './lines/baseLine';
// import * as Models from '../../api-virto-models/models';
import { TvControlProduct } from '~/api-shopify/product-models/tv-control-product';
import { SwymWishListService } from '../services/swymWishList.service';
import { freemem } from 'os';
import { SaveDesignTimerService } from '../configuration/save-design/save-design-timer.service';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';
import { MiniLoadingIndicatorService } from '../../configurator/mini-loading-indicator/mini-loading-indicator.service';

let linesByCategory: { [key: string]: ConfigLineKinds[] } = {};
linesByCategory[DesignTypes.FrameOnly] = [
  ConfigLineKinds.TvModel,
  ConfigLineKinds.TvControl,
  ConfigLineKinds.SideDepth,
  ConfigLineKinds.Installation,
  ConfigLineKinds.Rush,
  ConfigLineKinds.Frame,
  ConfigLineKinds.Liner
];

linesByCategory[DesignTypes.FramelessMirror] = [
  ConfigLineKinds.TvModel,
  ConfigLineKinds.TvControl,
  ConfigLineKinds.SideDepth,
  ConfigLineKinds.Installation,
  ConfigLineKinds.Rush,
  ConfigLineKinds.Mirror,
  ConfigLineKinds.MirrorWarranty
];

linesByCategory[DesignTypes.FrameWithArt] = [
  ConfigLineKinds.TvModel,
  ConfigLineKinds.TvControl,
  ConfigLineKinds.SideDepth,
  ConfigLineKinds.Installation,
  ConfigLineKinds.Rush,
  ConfigLineKinds.Frame,
  ConfigLineKinds.Artwork,
  ConfigLineKinds.Liner,
  ConfigLineKinds.ArtControl,
  ConfigLineKinds.ArtCover,
  ConfigLineKinds.SpeakerBar,
  ConfigLineKinds.ArtWarranty
];

linesByCategory[DesignTypes.FrameWithMirror] = [
  ConfigLineKinds.TvModel,
  ConfigLineKinds.TvControl,
  ConfigLineKinds.SideDepth,
  ConfigLineKinds.Installation,
  ConfigLineKinds.Rush,
  ConfigLineKinds.Frame,
  ConfigLineKinds.Liner,
  ConfigLineKinds.Mirror,
  ConfigLineKinds.MirrorWarranty
];

linesByCategory[DesignTypes.MetroMirror] = [
  ConfigLineKinds.TvModel,
  ConfigLineKinds.TvControl,
  ConfigLineKinds.SideDepth,
  ConfigLineKinds.Installation,
  ConfigLineKinds.Rush,
  ConfigLineKinds.Frame,
  ConfigLineKinds.Mirror,
  ConfigLineKinds.MirrorWarranty
];

linesByCategory[DesignTypes.CutGlass] = [
  ConfigLineKinds.CutGlass,
  ConfigLineKinds.EdgeFinish,
  ConfigLineKinds.MountingOption,
  ConfigLineKinds.BackingOption,
  ConfigLineKinds.CutGlassAccessory,
  ConfigLineKinds.Rush
];

const lastSavedKey = 'lastSavedConfiguration';

export class DesignConfiguration {

  static deserialize(serializedConfiguration: any, loadLastVersion = false) {
    let designConfig = new DesignConfiguration();
    return designConfig.deserialize(serializedConfiguration, loadLastVersion);
  }

  lines: Array<Lines.BaseLine<any>> = [];
  id: number;
  number = 'new';

  overallFrameHeight: number;
  overallFrameWidth: number;

  private _adminNotes: string;
  get adminNotes() {
    return this._adminNotes;
  }
  set adminNotes(value: string) {
    this._adminNotes = value;
    if (this.updated) {
      this.updated();
    }
  }

  private _name = 'My Design';
  get name() {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    if (this.updated) {
      this.updated();
    }
  }

  // used to reference the kinds per config type externally
  get lineTypesForCategory(  ) {
    return linesByCategory;
  }
  // TODO: tags really have nothing to do with design configuration and are more of a shopify concern
  // and should be handled elsewhere but for lack of better place to put them for now, they are here.
  private _tags: string[] = [];
  get tags() {
    // add config types tags
    this._tags = [];
    if (this.type) {
      if (
        this.type === DesignTypes.FramelessMirror ||
        this.type === DesignTypes.FrameWithMirror ||
        this.type === DesignTypes.MetroMirror
      ) {
        this._tags.push('mirror_config');
        // add warranty tag
        if (
          this.mirrorWarranty &&
          this.mirrorWarranty.data &&
          this.mirrorWarranty.data.code
        ) {
          if (this.mirrorWarranty.data.code === 'CONFIG-WRNTY-MIR-BASIC') {
            this._tags.push('warranty_mirror_standard');
          } else if (this.mirrorWarranty.data.code === 'CONFIG-WRNTY-MIR-EXT') {
            this._tags.push('warranty_mirror_extended');
          } else if (
            this.mirrorWarranty.data.code === 'CONFIG-WRNTY-MIR-EXTPLUS'
          ) {
            this._tags.push('warranty_mirror_extended_plus');
          } else if (this.mirrorWarranty.data.code === 'CONFIG-WRNTY-MIR-DLX') {
            this._tags.push('warranty_mirror_lifetime');
          } else {
            console.error('Error: art_config has missing warranty mapping');
          }
        }
      } else if (this.type === DesignTypes.CutGlass) {
        this._tags.push('glass_config');
        this._tags.push('warranty_glassonly_standard');
      } else if (
        this.type === DesignTypes.FrameWithArt ||
        this.type === DesignTypes.MetroArt
      ) {
        this._tags.push('art_config');
        // add warranty tag
        if (
          this.artWarranty &&
          this.artWarranty.data &&
          this.artWarranty.data.code
        ) {
          if (this.artWarranty.data.code === 'CONFIG-WRNTY-ART-BASIC') {
            this._tags.push('warranty_art_standard');
          } else if (this.artWarranty.data.code === 'CONFIG-WRNTY-ART-EXT') {
            this._tags.push('warranty_art_extended');
          } else if (
            this.artWarranty.data.code === 'CONFIG-WRNTY-ART-EXTPLUS'
          ) {
            this._tags.push('warranty_art_extended_plus');
          } else if (this.artWarranty.data.code === 'CONFIG-WRNTY-ART-DLX') {
            this._tags.push('warranty_art_lifetime');
          } else {
            console.error('Error: glass_config has missing warranty mapping');
          }
        }
      } else if (this.type === DesignTypes.FrameOnly) {
        this._tags.push('frame_config');
        this._tags.push('warranty_frame_standard');
      } else {
        console.error('Error: product.type has no tag mapping');
      }
      // add art control tags
      if (
        this.artControl &&
        this.artControl.data &&
        this.artControl.data.code
      ) {
        const artControlCode = this.artControl.data.code;
        if (artControlCode === 'ARTCTRL-R' || artControlCode === 'ARTCTRL-W') {
          this._tags.push('control_RTSremote');
        } else if (
          artControlCode === 'ARTCTRL-D' ||
          artControlCode === 'ARTCTRL-DM' ||
          artControlCode === 'ARTCTRL-485'
        ) {
          this._tags.push('control_DCT');
        } else if (artControlCode === `ARTCTRL-UR`) {
          this._tags.push('control_URI');
        } else {
          console.error('Errror artcontrol.data.code has no tag mapping');
        }
      }

      // add speakerbar tag
      if (
        this.speakerBar &&
        this.speakerBar.data &&
        this.speakerBar.data.hasspeakerbar
      ) {
        this._tags.push('speakerbar');
      }
    } // end of it this.type
    return this._tags;
  }

  private _comment = '';
  get comment() {
    return this._comment;
  }
  set comment(value: string) {
    this._comment = value;
    if (this.updated) {
      this.updated();
    }
  }

  private _wallColor = '#788579';
  get wallColor() {
    return this._wallColor;
  }
  set wallColor(color: string) {
    this._wallColor = color;
    if (this.updated) {
      this.updated();
    }
  }

  private _total: number;
  get total() {
    return this._total;
  }

  private _totalWithShipping: number;
  get totalWithShipping() {
    return this._totalWithShipping;
  }

  private customerId: string;

  get tvModel() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.TvModel
    ) as Lines.TvModelLine;
  }
  get frame() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Frame
    ) as Lines.FrameLine;
  }
  get artwork() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Artwork
    ) as Lines.ArtLine;
  }
  get liner() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Liner
    ) as Lines.LinerLine;
  }
  get mirror() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Mirror
    ) as Lines.MirrorLine;
  }
  get tvControl() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.TvControl
    ) as Lines.TvControlLine;
  }
  get artControl() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.ArtControl
    ) as Lines.ArtControlLine;
  }
  get sideDepth() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.SideDepth
    ) as Lines.SideDepthLine;
  }
  get installation() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Installation
    ) as Lines.InstallationLine;
  }
  get rush() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.Rush
    ) as Lines.RushLine;
  }
  get speakerBar() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.SpeakerBar
    ) as Lines.SpeakerBarLine;
  } // no associated product
  get artCover() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.ArtCover
    ) as Lines.ArtCoverLine;
  } // no associated product
  get cutGlass() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.CutGlass
    ) as Lines.CutGlassLine;
  }
  get edgeFinish() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.EdgeFinish
    ) as Lines.EdgeFinishLine;
  }
  get backingOption() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.BackingOption
    ) as Lines.BackingOptionLine;
  }
  get cutGlassAccessories() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.CutGlassAccessory
    ) as Lines.CutGlassAccessoriesLine; // this has multiple products
  }
  get mountingOption() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.MountingOption
    ) as Lines.MountingOptionLine;
  }
  get artWarranty() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.ArtWarranty
    ) as Lines.ArtWarrantyLine;
  }
  get mirrorWarranty() {
    return _.find(
      this.lines,
      l => l.kind === Lines.ConfigLineKinds.MirrorWarranty
    ) as Lines.MirrorWarrantyLine;
  }

  // get isNew() { return this.number === 'new' || (this.customerId && this.customerId !== this.customerIdForSave); }
  get isNew() {
    return this.number === 'new';
  }

  get designComplete() {
    return (
      _.filter(this.lines, l => l.category === 'Design' && !l.valid).length ===
      0
    );
  }
  get optionsComplete() {
    return (
      _.filter(this.lines, l => l.category === 'Options' && !l.valid).length ===
      0
    );
  }

  get dirty() {
    const equal = localStorage.getItem('designConfiguration') !== this.lastSavedVersion;
    const returnVal = !!this.lastSavedVersion && equal;
    return returnVal;
  }

  get canOrder() {
    return this.type && _.every(this.lines, l => l.valid);
  }

  get customerIdForSave() {
    return (
      (this.brandingInfo && <any>this.brandingInfo.contactId) ||
      this.authService.userIdForCart
    );
  }

  get url() {
    return `https://framemytv.com/apps/designer/configuration/${this.number}`;
  }

  private lastSavedVersion: string;

  private updated: () => void;

  @(app.inject('$q').property)
  private q: ng.IQService;

  @(app.inject(AuthenticationService).property)
  private authService: AuthenticationService;

  @(app.inject(BrandingService).property)
  private branding: BrandingService;

  @(app.inject('$timeout').property)
  private $timeout: ng.ITimeoutService;

  @(app.inject('$analytics').property)
  private $analytics: any;

  @(app.inject(LambdasService).property)
  private lambdasService: LambdasService;

  @(app.inject(SwymWishListService).property)
  private swymWishListService: SwymWishListService;

  @(app.inject(SaveDesignTimerService).property)
  private saveDesignTimerService: SaveDesignTimerService;

  @(app.inject(MiniLoadingIndicatorService).property)
  private miniLoadingIndicatorService: MiniLoadingIndicatorService;

  private brandingInfo: BrandingInfo;

  constructor(
    public type?: DesignTypes
  ) {
    this.switchType(type);
    this.branding.infoPromise.then(b => (this.brandingInfo = b));
  }

  serialize() {
    let lines = _.map(this.lines, l => {
      let line = l.serialize();
      if (line.id.indexOf('TMP') === 0) {
        line.id = null;
      }
      return line;
    });
    this.estimateOverallFrameDimension();
    let hasSpeakerBar = false;
    if (this.speakerBar && this.speakerBar.data && this.speakerBar.data.hasspeakerbar) {
      hasSpeakerBar = this.speakerBar.data.hasspeakerbar;
    }
    return {
      id: this.isNew ? null : this.id,
      number: this.isNew ? null : this.number,
      storeId: 'framemytv',
      isAnonymous: !this.authService.loggedIn,
      customerId:
        (this.brandingInfo && <any>this.brandingInfo.contactId) ||
        this.authService.userIdForCart,
      customerName: this.authService.fullName,
      jobName: this.name,
      comment: this.comment,
      innerComment: this._adminNotes,
      currency: 'USD',
      canOrder: this.canOrder,
      tags: this.tags,
      overallFrameWidth: this.overallFrameWidth,
      overallFrameHeight: this.overallFrameHeight,
      hasSpeakerBar,
      productConfiguration: {
        wallColor: this.wallColor,
        type: <any>this.type,
        lineItems: lines
      }
    };
  }

  isDistressedFrame() {
    const frameLine = this.lines.find( line => line.kind === ConfigLineKinds.Frame );
    if ( frameLine ) {
      const frameProduct: FrameProduct = (frameLine as any).product;
      return frameProduct && frameProduct.isDistressedFrame();
    } else {
      return false;
    }
  }

  buildImageUrls() {
    return this.lines.filter(l => l.preview && l.preview.image && l.preview.image !== '//:0').map( item => {
      return { order: item.preview.index, url: item.preview.image };
    }).sort( (a, b) => a.order - b.order );
    /*
        const promises = _(this.lines)
          // "//:0" is used when an image doesn't exist (instead of '') to work around an angular issue
          // where it won't properly recognize '' as a non-existent (breaks the rendering)
          // some configs may have a missing image (like no liner)
          .filter(l => l.preview && l.preview.image && l.preview.image !== '//:0' )
          .map(l => {
            const q = this.q.defer<{ order: number; image: HTMLImageElement }>();
            const image = document.createElement('img');
            image.crossOrigin = 'Anonymous';
            image.onload = () =>
              q.resolve({ order: (l as any).preview.index, image });
            image.src = (l as any).preview.image;
            return q.promise;
          })
          .value();

        const canvas = document.createElement('canvas');
        canvas.width = 2700;
        canvas.height = 2400;
        const context = canvas.getContext('2d');
        context.fillStyle = this.wallColor;
        context.fillRect(0, 0, 2700, 2400);

        return this.q.all(promises).then(images => {
          _(images)
            .sortBy('order')
            .forEach(i => {
              context.drawImage(i.image, 0, 0, 2700, 2400);
            });

          // return canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
          return canvas
            .toDataURL('image/jpeg', 0.95)
            .replace('data:image/jpeg;base64,', '');
        });
    */
  }

  onUpdate(callback: () => void) {
    this.updated = callback;
  }

  /**
   * Saves or updates the configuration if needed
   *
   * @returns the id (not number) of the configuration
   *
   * @memberOf DesignConfiguration
   */
  save( email: string = '', username: string='', hubspotUTK: string ='' ) {
    const isNew = this.isNew;
    if (isNew) this.number = null;
    const pi = this.toProductInfo();
    // if ( email ) pi.email = email;
    // if ( username ) pi.username = username;
    // if ( hubspotUTK ) pi.hubspotutk = hubspotUTK;

    return this.lambdasService.createProduct(pi)
      .then((result: any) => {
        this.number = result.sku;
        this.id = result.id;
        try {
          // do not let a wishlist failure break the product save.
          // can only save when config is new/changed so no need to check for duplicate
          // this.swymWishListService.addCollection(this.swymWishListService.DESIGN_COLLECTION,  result.wishlist.productId).then(() => {
          this.swymWishListService.addToWishList(
            result.wishlist.productId,
            result.wishlist.variantId,
            result.wishlist.imageUrl,
            result.wishlist.productUrl,
            result.wishlist.price,
            result.wishlist.customProperties,
            result.wishlist.variants
          );
          this.swymWishListService.broadcastWishlistIsDirty();
          // });
        } catch (e) {
          console.log('could not save to wishlist');
        }
        this.$analytics.eventTrack(Analytics.actions.designSaved, {
          category: Analytics.category,
          label: `Saved ${isNew ? 'New' : 'Updated'} Design`
        });
        this.setLastSavedVersion();
        return result;
      });
  }

  refresh(updateLastSaved = false) {
    return this.ensureDefaultLiner()
      .then(() => this.setMirrorPreview())
      .then(() => this.setFramePreview())
      .then(() => this.ensureDefaultSideDepth())
      .then(() => this.ensureDefaultTVControl())
      .then(() => this.setArtCoverPrice())
      .then(() => this.refreshPrices())
      .then(() => this.refreshRush())
      .then(() => {
        if (updateLastSaved) {
          // So many things have to be done asynchronously that when we load a configuration, it's only ready
          // at this point for dirty checks.
          this.setLastSavedVersion();
        }
      });
  }

  addCustomLine(name: string, description: string, price: number) {
    let line = new Lines.CustomLine();
    line.name = name;
    line.description = description;
    line.price = price;
    this.lines.push(line);
    this.lineUpdated();
  }

  removeLine(id: string) {
    _.remove(this.lines, l => l.id === id);
    this.lineUpdated();
  }

  /**
   * Replaces the lines of the current configuration with the lines needed by the given type and initializes them
   */
  switchType(type: any) {
    if (!type) {
      return;
    }

    this.type = type;

    if ( type ===  DesignTypes.FrameWithMirror) {
      // this.maxScreenSizeForConfigType = 80;
      const tvModelLine = this.tvModel;
      if ( tvModelLine && tvModelLine.data && tvModelLine.data.diagscreensize ) {
        if (  tvModelLine.data.diagscreensize > 80 ) {
          // see newDesign in the designConfigurationService .. this appears to be necessary for the default
          tvModelLine.update({speakerlayout: 'M'});
        }
      }
    } else if ( type ===  DesignTypes.MetroMirror || type ===  DesignTypes.FramelessMirror ) {
      // this.maxScreenSizeForConfigType = 75;
      const tvModelLine = this.tvModel;
      if ( tvModelLine && tvModelLine.data && tvModelLine.data.diagscreensize ) {
        if (  tvModelLine.data.diagscreensize > 75 ) {
          tvModelLine.update({speakerlayout: 'M'});
        }
      }
    }

    // remove any existing line not in the new type
    _.remove(this.lines, l => !_.includes(linesByCategory[type], l.kind));

    // keep track of lines kept
    let linesKept = _.map(this.lines, l => l.kind);
    // add any line not already present
    _.forEach(linesByCategory[type], l => {
      if (!_.includes(linesKept, l)) {
        let line = new Lines.linesTable[l]();
        this.lines.push(line);
        line.onUpdate(this.lineUpdated);
      }
    });

    // make a list of lines just added
    let newLines = _.difference(linesByCategory[type], linesKept);

    // then go through them and initialize them if needed
    _.forEach(newLines, l => {
      switch (l) {
        case ConfigLineKinds.TvControl:
          this.tvControl.deserialize({ code: 'IR-2' });
          break;
        case ConfigLineKinds.SpeakerBar:
          this.speakerBar.deserialize({ hasspeakerbar: false, gap: 0 });
          break;
        case ConfigLineKinds.ArtControl:
          this.artControl.deserialize({ code: 'ARTCTRL-R' });
          break;
        case ConfigLineKinds.EdgeFinish:
          this.edgeFinish.deserialize({ code: 'MIRROR-CUT-FINISHED-NONE' });
          break;
        case ConfigLineKinds.MountingOption:
          this.mountingOption.deserialize({ code: 'MIRROR-CUT-NO-MOUNTING' });
          break;
        case ConfigLineKinds.BackingOption:
          this.backingOption.deserialize({ code: 'NO-BACKING' });
          break;
        case ConfigLineKinds.Rush:
          this.$timeout(() => {
            // This has to be done after the other lines are initialized because shipping dates depend on
            // whether there is art or not in the config.
            this.rush.deserialize({ ship_id: '1' });
          });

          break;
      }
    });

    this.$timeout(() => {
      this.miniLoadingIndicatorService.hide();
      this.refreshPrices().then(() => {
        if (_.includes(linesKept, ConfigLineKinds.Rush)) {
          this.rush.refresh();
        }
      });
    });
  }

  toProductInfo() {
    const temp = ({
      name: this.name,
      sku: this.number,
      price: this.totalWithShipping,
      imageUrls: this.buildImageUrls(),
      tags: this.tags,
      configuration: this.serialize(),
      userId: window.fmtvApp.customer && window.fmtvApp.customer.id,
      dev: localStorage.getItem('ref') === 'dev',
      isDistressedFrame: this.isDistressedFrame()
    } as ProductInfo);
    return temp;
  }

  private setLastSavedVersion() {
    this.lastSavedVersion = JSON.stringify(this.serialize());
    window.sessionStorage.setItem(lastSavedKey, this.lastSavedVersion);
  }

  /** this is triggered any time a user changes a config option in the UI */
  private lineUpdated = () => {
    this.refresh().then(() => {
      if (this.updated) {
        this.updated();
        /*  this is too broad to be useful
        if ( this.dirty ) {
            this.saveDesignTimerService.resetOpenTimer();
        } else {
          // user reverted to a saved item
          this.saveDesignTimerService.stopTimerAndClose();
        }
        */
      }
    });
  }

  private ensureDefaultLiner() {
    if (this.frame && this.frame.frameProduct) {
      //  this.frame.frameProduct.categoryCode()
      // .then(c => {
      // LinerProduct.forCategory(c)
      const c = this.frame.frameProduct.categoryCode();
      return LinerProduct.forCategory(c).then(liners => {
        if (c === <any>FrameCategories.Metro) {
          if (this.mirror) {
            _.remove(this.lines, l => l.kind === Lines.ConfigLineKinds.Liner);
          }
        } else {
          if (!this.liner) {
            this.lines.push(new Lines.LinerLine());
          }
        }

        if (
          this.liner &&
          (!this.liner.linerProduct ||
            !_.find(
              liners,
              l => l.product.code === this.liner.linerProduct.product.code
            ))
        ) {
          // this was setting the default liner for a new config
          // logic was moved into the liner-bar-component
          // this.liner.setProduct( _.find( liners,l => l.product.code === 'LINER-3' || l.product.code === 'LINER-8'));
        }
      });
      // });
    }

    return this.q.resolve();
  }

  private setMirrorPreview() {
    if (this.mirror && this.mirror.mirrorProduct && this.tvModel.data) {
      if (this.frame && this.frame.frameProduct) {
        const m = this.frame.frameProduct.isMetro();
        // return this.frame.frameProduct.isMetro().then(m => {
        let name = `${m ? 'Metro' : ''}Mirror${
          this.tvModel.data.speakerlayout
          }0600`;
        let image = this.mirror.mirrorProduct.layerUrl || '';
        this.mirror.preview = {
          image,
          index: 2
        };
        // });
      } else {
        let name = `Mirror${this.tvModel.data.speakerlayout}0600`;
        let image = this.mirror.mirrorProduct.layerUrl || '';
        this.mirror.preview = {
          image,
          index: 2
        };
      }

      // Hide the preview when there's a mirror because it sicks out otherwise
      this.tvModel.preview = null;
    }

    return this.q.resolve();
  }

  private setFramePreview() {
    if ( this.type && this.type !== DesignTypes.FramelessMirror ) {
    if (this.frame && this.frame.frameProduct) {
      // let image: VirtoCommerceCatalogModuleWebModelImage;
      let image = '';
      // Forcing the preview because we don't have previews to match other speaker layouts
      let imageName = 'FRM42M'; // `FRM42${this.tvModel.data.speakerlayout}`;

      if (this.frame.finishProduct) {
        this.frame.preview = {
          image:
            this.liner && this.liner.preview
              ? this.frame.finishProduct.previewWithLiner
              : this.frame.finishProduct.previewWithoutLiner,
          index: 4
        };
      } else {
        // image = _.find(this.frame.frameProduct.product.images, i => i.name.indexOf(imageName) === 0);
        image = this.frame.frameProduct.layerUrl;
        if (image) {
          this.frame.preview = {
            // image: image.url,
            image,
            index: 4
          };
        }
      }
    } else {
      console.log( 'setFramePreview called before frameproduct set. cant load preview' );
    }
  }

    return this.q.resolve();
  }

  private ensureDefaultTVControl() {
    let tvControl = this.tvControl;
    if (
      tvControl &&
      (!tvControl.data || (tvControl.data && !tvControl.data.code))
    ) {
      if (!this.cutGlass) {
        return TvControlProduct.get('IR-2').then(p =>
          this.tvControl.setProduct(p)
        );
      }
    }

    return this.q.resolve();
  }

  private ensureDefaultSideDepth() {
    let sideDepth = this.sideDepth;
    if (sideDepth && !sideDepth.data) {
      if (this.artwork) {
        return SideDepthProduct.get('3').then(p =>
          this.sideDepth.setProduct(p)
        );
      } else {
        return SideDepthProduct.get('0').then(p =>
          this.sideDepth.setProduct(p)
        );
      }
    }

    return this.q.resolve();
  }

  private refreshPrices() {
    let q = this.q.resolve({
      total: 0,
      withShipping: 0
    });
    _.forEach(this.lines, l => {
      if (l.kind !== Lines.ConfigLineKinds.Rush) {
        q = q.then(v =>
          l.refreshPrice().then(() => {
            if (_.isNumber(l.price)) {
              v.withShipping += l.price;
              if (l.kind !== Lines.ConfigLineKinds.Shipping) {
                v.total += l.price;
              }
            }
            return v;
          })
        );
      }
    });

    return q.then(v => {
      this._total = v.total;
      this._totalWithShipping = v.withShipping;
    });
  }

  private refreshRush() {
    if (!this.rush) {
      return this.q.resolve();
    }
    // Refreshes the rush price after the other prices because it depends on the total
    return this.rush.refresh().then(() => {
      this._totalWithShipping += this.rush.price;
    });
  }

  private setArtCoverPrice() {
    let art = this.artwork;
    if (art && art.product) {
      let artCover = this.artCover;
      artCover.price = art.product.subFramePrice;
    }
    return this.q.resolve();
  }

  private calcWidth(diaganol: number, height: number, width: number) {
    return (diaganol * width) / Math.sqrt(width * width + height * height);
  }

  private calcHeight(diaganol: number, height: number, width: number) {
    return (diaganol * height) / Math.sqrt(width * width + height * height);
  }

  private estimateOverallFrameDimension() {
    // have the diagnol measurement in the design but no
    // width/height. The properties are defined but there
    // is no data related to them

    // TODO refactor this into the tv after??
    if (this.tvModel && this.tvModel.data && this.tvModel.data.diagscreensize) {
      const diaganol = this.tvModel.data.diagscreensize;
      const widthRatio = 16;
      const heightRation = 9;
      const tvWidth = this.calcWidth(diaganol, heightRation, widthRatio);
      const tvHeight = this.calcHeight(diaganol, heightRation, widthRatio);
      let calculatedWidth = -1;
      let calculatedHeight = -1;

      if (
        this.type === DesignTypes.FrameOnly || // tv frame - no art/mirror
        this.type === DesignTypes.FrameWithMirror ||
        this.type === DesignTypes.MetroMirror
      ) {
        // need to verify 'molding' = 'frame width'
        const moldingWidth = Number.parseFloat(this.frame.frameProduct.width);

        if (
          this.frame.frameProduct.category ===
          FrameCategories.Artisan.toString() ||
          this.frame.frameProduct.category === FrameCategories.Standard.toString()
        ) {
          calculatedWidth = tvWidth + 2 * (1.5 + moldingWidth);
          calculatedHeight = tvHeight + 2 * (1.5 + moldingWidth);
        } else if (
          this.frame.frameProduct.category === FrameCategories.Metro.toString()
        ) {
          calculatedWidth = tvWidth + 8;
          calculatedHeight = tvHeight + 8;

          // removing HW
      //  } else if (
     //     this.frame.frameProduct.category === FrameCategories.Hardwood.toString()
      //  )

          // use 'tv frame' w/ hardwood to test this selection
          if (!this.liner.linerProduct.isNoLiner) {
            calculatedWidth = tvWidth + 2 * (1.5 + moldingWidth);
            calculatedHeight = tvHeight + 2 * (1.5 + moldingWidth);
          } else {
            calculatedWidth = tvWidth + 2 * moldingWidth;
            calculatedHeight = tvHeight + 2 * moldingWidth;
          }
        }
      } else if (this.type === DesignTypes.FrameWithArt) {
        // need to verify 'molding' = 'frame width'
        const moldingWidth = Number.parseFloat(this.frame.frameProduct.width);
        calculatedWidth = tvWidth + 2 * (1.5 + moldingWidth + 1.5);
        calculatedHeight = tvHeight + 2 * (1.5 + moldingWidth + 1.5);
      } else if (this.type === DesignTypes.MetroArt) {
        calculatedWidth = tvWidth + 13;
        calculatedHeight = tvHeight + 13;
      }

      this.overallFrameHeight = Number.parseFloat(calculatedHeight.toFixed(1));
      this.overallFrameWidth = Number.parseFloat(calculatedWidth.toFixed(1));
    }
  }

  private deserialize(serializedConfiguration: any, loadLastVersion = false) {
    _.forEach(this.lines, l => l.onUpdate(null));
    this.lines = [];
    this._name = serializedConfiguration.jobName;
    this._comment = serializedConfiguration.comment;
    this.wallColor = serializedConfiguration.productConfiguration.wallColor;
    this.id = _.isFinite(serializedConfiguration.id)
      ? (serializedConfiguration.id as any)
      : parseInt(serializedConfiguration.id);
    this.number = serializedConfiguration.number || 'new';
    this.type = <any>serializedConfiguration.productConfiguration.type;
    this.customerId = serializedConfiguration.customerId;
    let promises: Array<ng.IPromise<void>> = [];
    this.lines = _.map(
      serializedConfiguration.productConfiguration.lineItems,
      l => {
        let line: Lines.BaseLine<any> = new Lines.linesTable[l.kind]();

        // This will deserialize custom lines
        line.name = l.name;
        line.description = l.description === 'null' ? null : l.description;
        line.price = l.price;

        if (l.kind === ConfigLineKinds.Rush) {
          this.$timeout(() => {
            line.deserialize(l.data).then(() => this.setLastSavedVersion());
          }, 3000);
        } else {
          promises.push(line.deserialize(l.data));
        }
        if (l.id) {
          line.id = l.id;
        }
        line.onUpdate(this.lineUpdated);
        // console.log( JSON.stringify( line ) );
        return line;
      }
    );

    return this.q.all(promises).then(() => {
      if (!this.rush) {
        // hack for configs that may not have the rush line
        this.lines.push(new Lines.RushLine());
        this.rush.deserialize({ ship_id: '1' });
      }
      // calculate dimension after all lines are loaded
      this.estimateOverallFrameDimension();
      if (loadLastVersion) {
        let lastVersion = sessionStorage.getItem(lastSavedKey);
        if (lastVersion) {
          this.lastSavedVersion = lastVersion;
        }
      } else {
        sessionStorage.removeItem(lastSavedKey);
      }
      return this;
    });
  }
}
