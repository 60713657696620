import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
import sortBy from 'lodash/sortBy';
import { items } from '~/sizing-widget/widget/data';

let listPromise: ng.IPromise<ArtWarrantyProduct[]>;

@lib.inject(ProductsApi).service
export class ArtWarrantyProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = ArtWarrantyProduct.productsService.getAllArtWarrantyProducts().then(
      (products: ArtWarrantyProduct[]) => sortBy(products, p => p.sortPosition).map(p => new ArtWarrantyProduct(p)));
  }

  static get(code: string) {
    return this.list().then((warranties: ArtWarrantyProduct[]) => find(warranties, w => w.code === code));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  guaranteedPrice() {
    return this.basePrice();
  }
}
