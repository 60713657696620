import app from '../../app';

@app.inject('$state').component({
  bindings: {
    show: '<',
    message: '@',
    state: '@'
  },
  templateUrl: 'configurator/configuration/directives/notConfigured.html'
})
export class NotConfigured {

  show: boolean;
  message: string;
  state: string;

  constructor(
    private $state: ng.ui.IStateService
  ) {
  }

  $onInit() { }

  clicked(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.$state.go(this.state);
  }
}
