import { BrandingInfo } from '../../services/brandingInfo';
import angular from 'angular';
import app from '../../app';

@app.inject('$mdDialog', '$mdMedia').service
export class LoginService {
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia
  ) {
  }

  login(ev?: MouseEvent, message: string = null) {
    let useFullScreen = (this.$mdMedia('sm') || this.$mdMedia('xs'));
    return this.$mdDialog.show({
      controller: 'LoginController',
      controllerAs: '$ctrl',
      templateUrl: 'configurator/user/login/login.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen,
      locals: {
        message
      }
    });
  }
}
