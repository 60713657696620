
import { ProductsApi } from '~/api-shopify/products-api';
import { Product } from './product';
import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
import { FrameProduct } from './frame-product';

export class FinishProduct extends Product {

  static get(code: string) {
    return FinishProduct.productsService.getFrameFinishBySku(code).then( (p: any) => new FinishProduct(p) );
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  private _priceCheckSize: number;
  private _pricePromise: ng.IPromise<number>;

  get previewWithLiner() {
    return this._previewWithLinerUrl;
  }

  get previewWithoutLiner() {
    return this._previewWithoutLinerUrl;
  }

  get factor() {
    return this._factor;
  }
  get finish(){
    return this._finish;
  }
  get style(){
    return this._style;
  }

  private _previewWithLinerUrl: string;
  private _previewWithoutLinerUrl: string;
  private _factor: number;
  private _finish: string;
  private _style: string;
  private _manualPriceAdjustment = 1.06; // adjustment to the shopify price .. should be done in shopify but done here for now

  constructor(
    data: any
  ) {
    super(data);
    this._previewWithLinerUrl = data.layerUrl;
    this._previewWithoutLinerUrl = data.previewWithoutLiner;
    this._factor = data.finishFactor;
    this._finish = data.finish;
    this._basePrice = 0;
    const tempStyle = data.baseSkuHardwood.substring( data.baseSkuHardwood.indexOf('-') + 1 ).toLowerCase();
    // upper case first char -- fames only have one word names for now
    this._style = tempStyle.charAt(0).toUpperCase() + tempStyle.slice(1);
  }

  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    if (!this._pricePromise || this._priceCheckSize !== tvSize) {
      this._priceCheckSize = tvSize;
      this._pricePromise = this.basePrice().then(b => Math.ceil( (b + this.factor * tvSize ) * this._manualPriceAdjustment));
    }
    return this._pricePromise;
  }

}
