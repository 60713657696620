import app from '../../app';
import { BaseLine } from '~/configurator/design/lines';

@app.service
export class SaveDesignFooterService {
    public visible = false;

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }
}
