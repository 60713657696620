import { LoadingIndicatorService } from './loadingIndicator.service';
import app from '../app';

@app.inject(LoadingIndicatorService).component({
  templateUrl: 'configurator/loadingIndicator/loadingIndicator.html',
})
export class LoadingIndicator {
  constructor(
    public loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  $onInit() { }
}
