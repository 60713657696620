import app from '../../../app';
import TvController from './tv.controller';
import angular from 'angular';
import { DesignConfigurationService } from '../../../design/designConfiguration.service';

@app.inject('$mdDialog', '$mdMedia', DesignConfigurationService).service
export class TvService {
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private config: DesignConfigurationService
  ) { }

  show(e?: MouseEvent) {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    return this.$mdDialog.show({
      controller: TvController,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/configuration/design/tv/tv.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: e,
      clickOutsideToClose: false,
      fullscreen: useFullScreen,
      locals: {
        configuration: this.config.designConfiguration
      }
    });
  }
}
