import { MirrorWarrantyProduct } from '../../../api-shopify/product-models/mirror-warranty-product';
import { BaseLine, linesTable, ConfigLineKinds } from './baseLine';

export interface MirrorWarrantyLineData {
  code?: string;
}

export class MirrorWarrantyLine extends BaseLine<MirrorWarrantyLineData> {
  kind = ConfigLineKinds.MirrorWarranty;
  category = 'Options';
  name = 'Warranty';
  order = 120;
  editState = 'configuration.tab.options.mirror-warranty';
  product: MirrorWarrantyProduct;
  scrollToElementId = 'mirror-warrant-option-scroll-to';

  get valid() { return !!this.product; }

  deserialize(data?: MirrorWarrantyLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return MirrorWarrantyProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: MirrorWarrantyProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.MirrorWarranty] = MirrorWarrantyLine;
