// import { includes } from 'lodash/includes';
import FinishController from './finish.controller';
import { FinishProduct } from '../../../../api-shopify/product-models/finish-product';
import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { FrameProduct } from '../../../../api-shopify/product-models/frame-product';
import { RightBarService } from '../../../rightBar/rightBar.service';
import { ICategory } from '../../../../ui/categorizedScroller/categorizedScroller.directive';
import jquery from 'jquery';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import angular from 'angular';
import { CartService } from '~/configurator/cart/cart.service';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';
import { SwymWishListService } from '../../../services/swymWishList.service';
import { AddSampleToCartDialogController } from './addSampleToCartDialog/addSampleToCartDialog.controller';
import { DesignTypes } from '~/configurator/design/designTypes';
import { CssAnimationService } from '~/configurator/services/css-animation.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import { SaveDesignTimerService } from '../../save-design/save-design-timer.service';
import { RushProduct } from '~/api-shopify/product-models/rush-product';

/**
 * Describes a line shown on the virtual scrolling frames list
 */
interface Line {
  text?: string;
  description?: string;
  styles?: (FrameProduct | any[])[];
  lineNumber?: number;
}

interface Category extends ICategory {
  items?: FrameProduct[];
  code?: string;
  description?: string;
  collectionId?: string;
}

interface FiltersCategory {
  label: string;
  filters: FilterEntry[];
  htmlClass: string;
}
interface FilterEntry {
  name: string;
  imageUrl: string;
  filterValue: string;
  selected: boolean;
  count: number;
  collectionId?: string;
}
@app.inject(
  '$mdSidenav',
  '$scope',
  '$rootScope',
  '$state',
  'designConfiguration',
  RightBarService,
  '$timeout',
  '$http',
  '$window',
  '$location',
  '$anchorScroll',
  CartService,
  SwymWishListService,
  '$mdDialog',
  CssAnimationService,
  LoadingIndicatorService,
  SaveDesignTimerService,
  '$document',
  '$element',
  '$mdMedia'
).controller

export default class FrameController {

  public categoryButtons: Category[] = [
    {
      label: 'Gallery Collection',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-categories/Standard.jpg',
      buttonLabel: 'View Collection',
      anchor: 'standard',
      description: '<p>Browse this collection of nearly 100 styles of premium frames spanning traditional to contemporary decors.&nbsp;</p>' +
        '<p>All frames in this collection will have a black welded aluminum side behind the frame to conceal the side view of the TV.</p>' +
        '<p>Price Tier: $-$$</p>' +
        '<p>Frame IDs: M1000-M2999 &amp;&nbsp;M5000-M7000</p>',
      collectionId: '34806530103',
      code: 'FRAME-S'
    },
    {
      label: 'Metro',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-categories/Metro.jpg',
      buttonLabel: 'View Collection',
      anchor: 'metro',
      description: '<p>This collection offers a sleek minimalist look that&#39;s ideal for contemporary and urban decors where less is more.&nbsp;</p>' +
        '<p>Choose from over 40&nbsp;different finishes.&nbsp;</p>' +
        '<p>Price Tier: $</p>' +
        '<p>Frame IDs: M3000-M3100</p>',
      collectionId: '35354378295',
      code: 'FRAME-M'
    },

    /* removing HW
    {
      label: 'Premium Hardwood',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-categories/Hardwood.jpg',
      buttonLabel: 'View Collection',
      anchor: 'premium',
      description: '<p>The premium hardwood collection is made completely of solid premium hardwoods (cherry, mahogany, ash, red oak, maple and exotic woods) available in 30 standard finishes or custom finishes if needed. &nbsp;</p>' +
        '<p>First choose the profile, then choose the finish. This collection features a wood face to the frame along with matching wood sides in various depths. Ideal for TV&#39;s mounted on the surface of the wall.&nbsp;</p>' +
        '<p>Price Tier: $$-$$$</p>',
      collectionId: '35355197495',
      code: 'FRAME-H'
    },
    */
    {
      label: 'Artisan',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-categories/Artisan.jpg',
      buttonLabel: 'View Collection',
      anchor: 'artisan',
      description: '<p>This collection of museum quality finished corner frames are all hand gilded in 22k&nbsp;and 12k&nbsp;gold represent our finest frames for the true art collector. This international collection features over 30 styles of frames to include French, Italian, Dutch, Contemporary, and American style frames.&nbsp;</p>' +
        '<p>Price Tier: $$$$</p>' +
        '<p>Frame ID: M8000-M8100</p>',
      collectionId: '35353493559',
      code: 'FRAME-A'
    }
  ];

  public filterCategories: FiltersCategory[] = [
    {
      label: 'Color',
      htmlClass: 'color',
      filters: [
        { name: 'Silver', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/silver_50x.png', filterValue: 'silver', selected: false, count: 0 },
        { name: 'Silver & Black', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/silver-black_50x.png', filterValue: 'Silver & Black', selected: false, count: 0 },
        { name: 'Gold', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/gold_50x.png', filterValue: 'gold', selected: false, count: 0 },
        { name: 'Gold & Black', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/gold-black_50x.png', filterValue: 'Gold & Black', selected: false, count: 0 },
        { name: 'Bronze', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/bronze_50x.png', filterValue: 'bronze', selected: false, count: 0 },
        { name: 'Brown', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/brown_50x.png', filterValue: 'brown', selected: false, count: 0 },
        { name: 'Black', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/black_50x.png', filterValue: 'black', selected: false, count: 0 },
        { name: 'Grey', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/grey_50x.png', filterValue: 'grey', selected: false, count: 0 },
        { name: 'White', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/white_50x.png', filterValue: 'white', selected: false, count: 0 },
        { name: 'Dark Wood', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/dark-wood_50x.png', filterValue: 'Dark Wood', selected: false, count: 0 },
        { name: 'Light Wood', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/light-wood_50x.png', filterValue: 'Light Wood', selected: false, count: 0 },
        { name: 'Red', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/red_50x.png', filterValue: 'red', selected: false, count: 0 },
        { name: 'HighGloss', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/frame-filters/high-gloss_50x.png', filterValue: 'High Gloss', selected: false, count: 0 },
      ],
    },
    {
      label: 'Style',
      htmlClass: 'category',
      filters: [
        { name: 'Contemporary', imageUrl: '', filterValue: 'contemporary', selected: false, count: 0 },
        { name: 'Traditional', imageUrl: '', filterValue: 'traditional', selected: false, count: 0 },
        { name: 'Ornate', imageUrl: '', filterValue: 'ornate', selected: false, count: 0 },
        { name: 'Rustic / Distressed ', imageUrl: '', filterValue: 'rustic / distressed', selected: false, count: 0 },
        { name: 'Wood', imageUrl: '', filterValue: 'wood', selected: false, count: 0 },
        { name: 'Cottage', imageUrl: '', filterValue: 'cottage', selected: false, count: 0 },
        { name: 'Bold', imageUrl: '', filterValue: 'bold', selected: false, count: 0 },
        { name: 'Art Deco', imageUrl: '', filterValue: 'art deco', selected: false, count: 0 },
      ],
    },
    {
      label: 'Width',
      htmlClass: 'category',
      filters: [
        { name: '1" - 2.9"', imageUrl: '', filterValue: '1-2.9', selected: false, count: 0 },
        { name: '3" - 3.9"', imageUrl: '', filterValue: '3-3.9', selected: false, count: 0 },
        { name: '4" - 4.9"', imageUrl: '', filterValue: '4-4.9', selected: false, count: 0 },
        { name: '5" +', imageUrl: '', filterValue: '5', selected: false, count: 0 },
      ],
    },
    {
      label: 'Collection',
      htmlClass: 'category',
      filters: [
        { name: 'Gallery', imageUrl: '', filterValue: 'standard collection', selected: false, count: 0, collectionId: '34806530103', },
       // { name: 'Premium Hardwood', imageUrl: '', filterValue: 'hardwood collection', selected: false, count: 0, collectionId: '35355197495', },
        { name: 'Artisan', imageUrl: '', filterValue: 'artisan collection', selected: false, count: 0, collectionId: '35353493559', },
        // { name: 'Standard Collection', imageUrl: '', filterValue: 'standard collection', selected: false, count: 0 },
        { name: 'Metro', imageUrl: '', filterValue: 'metro collection', selected: false, count: 0, collectionId: '35354378295', },
      ],
    },
  ];

  foundItems: number = 0;
  lines: Line[];
  preview: FrameProduct;
  previousSelection: FrameProduct;
  selection: FrameProduct;
  selectedIndex: number;
  selectedLine: Line;
  selectedFilterPane: number;

  dropDownImageUrl: string[] = []; // the main image shown in the drop down

  finish: FinishProduct;
  hideHelp = true;
  categoryPreview: ICategory;
  // pages: Pages;

  frameLoadError: boolean;

  showFilters = true;
  filter: FilterEntry = null;
  filterBox: string;
  sort = 'caption';
  defaultFrameImage: string;
  detailPlaceholder: string;
  loaded = false;
  _toggleFilterMenuOpen = false;
  _showFilterMenu = false;
  _showFavorites = false;
  wishlistItems: any = [];
  wishlistFrameProducts: FrameProduct[] = [];
  mediaSize: string = '?';
  private areImagesLoaded = false;
  private frameColumnsPerLine = 4;
  private detailViewRowSpans = 2;
  private userSelectedColumns = -1;

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private $scope: ng.IScope,
    protected $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService,
    private configuration: DesignConfiguration,
    private $ftvRightBar: RightBarService,
    private $timeout: ng.ITimeoutService,
    private $http: ng.IHttpService,
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private $anchorScroll: ng.IAnchorScrollService,
    private cartService: CartService,
    private swymWishListService: SwymWishListService,
    private $mdDialog: ng.material.IDialogService,
    private $cssAnimationService: CssAnimationService,
    private $loadingIndicator: LoadingIndicatorService,
    private saveDesignTimerService: SaveDesignTimerService,
    private $document: ng.IDocumentService,
    private $element: ng.IAugmentedJQuery,
    private $mdMedia: ng.material.IMedia,

  ) {

    this.saveDesignTimerService.pauseTimer();
    this.selectedIndex = -1;
    $scope.$watch(() => document.readyState, () => {
      if (document.readyState === 'complete') {
        //  this.lazyLoadImages();
        this.addSwym();
      }
    });
    // xs	'(max-width: 599px)'
    this.$scope.$watch(() => this.$mdMedia('xs'), media => {

        if (media) {
          if ( this.userSelectedColumns === -1 ) {
            this.frameColumnsPerLine = 2;
            this.detailViewRowSpans = 4;
          } else {
            this.frameColumnsPerLine = this.userSelectedColumns;
          }

          this.mediaSize = 'xs';
          this.buildLines();
          this.lazyLoadDetailImages( this.selection );
        }

    });

    // '(min-width: 600px) and (max-width: 959px)'
    this.$scope.$watch(() => this.$mdMedia('sm'), media => {

        if (media) {
          if ( this.userSelectedColumns === -1 ) {
            this.frameColumnsPerLine = 3;
            this.detailViewRowSpans = 4;
          } else {
            this.frameColumnsPerLine = this.userSelectedColumns;
          }
          this.mediaSize = 'sm';
          this.buildLines();
          this.lazyLoadDetailImages( this.selection );
        }
    });
    // '(min-width: 960px) and (max-width: 1279px)'
    this.$scope.$watch(() => this.$mdMedia('md'), media => {

        if (media) {
          if ( this.userSelectedColumns === -1 ) {
            this.frameColumnsPerLine = 4;
            this.detailViewRowSpans = 4;
          } else {
            this.frameColumnsPerLine = this.userSelectedColumns;
          }
          this.mediaSize = 'md';
          this.buildLines();
          this.lazyLoadDetailImages( this.selection );
        }

    });
    // gt-md	'(min-width: 1280px)'
    this.$scope.$watch(() => this.$mdMedia('gt-md'), media => {

        if (media) {
          if ( this.userSelectedColumns === -1 ) {
            this.frameColumnsPerLine = 5;
            this.detailViewRowSpans = 2;
          } else {
            this.frameColumnsPerLine = this.userSelectedColumns;
            this.detailViewRowSpans = 1;
          }
          this.mediaSize = 'gt-md';
          this.buildLines();
          this.lazyLoadDetailImages( this.selection );
        }

    });
    // this.lazyLoadImages();
    this.defaultFrameImage = $window.fmtvAssetsRoot + 'assets/configurator/frame-placeholder.jpg';
    this.detailPlaceholder = $window.fmtvAssetsRoot + 'assets/configurator/detail-placeholder.jpg';
    if (document.readyState !== 'loading') {
      //      this.lazyLoadImages();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        // this.lazyLoadImages();
      });
    }

    // categoriesApi.subCategories(CommonCategories.Frames).then(categories => {
    FrameProduct.list().then(frames => {
      // if metro show it first
      let sortType: string = null;
      if (this.configuration.type === DesignTypes.MetroArt || this.configuration.type === DesignTypes.MetroMirror) {
        sortType = 'FRAME-M';
      }
      if (sortType) {
        // sort the category buttons in the order the collections should be displayed (easiest/fastest sort)
        let data = this.categoryButtons;
        let pos = data.findIndex(f => f.code === sortType);
        if (pos) {
          let top = data[pos];
          data.splice(pos, 1);
          data.unshift(top);
        }
      }
      const groups = groupBy(frames, f => f.collectionId);
      forEach(this.categoryButtons, b => {
        if (b.collectionId) {
          // const category = find(this.categoryButtons, c => c.collectionId === b.collectionId);
          b.items = groups[b.collectionId].sort((f1, f2) => {
            const x = f1.sku.toLowerCase();
            const y = f2.sku.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          b.description = b.description; // 'some frame description'; // find(category.properties, p => p.name === 'FullReview').values[0].value;
        }
      });
      this.buildLines();
      this.loaded = true;
    });

    $scope.$on('$destroy', () => {
      const itemsContainer = jquery('.items-container');
      itemsContainer.off('scroll', this.scrolled);
    });
    // $scope.$watch(() => this.filter, () => this.buildLines());
    $scope.$watch(() => this.filterBox, () => this.filterContents());
    $scope.$watch(() => this._showFavorites, () => this.getFrameProductsForWishList());
    $scope.$watchCollection(() => this.wishlistItems, () => this.getFrameProductsForWishList());
    $timeout(this.init, 100);
    //  $timeout( this.lazyLoadImages, 0);
  }

  getNumberOfColumns() {
    if ( this.userSelectedColumns !== -1 ) {
      return this.userSelectedColumns;
    } else {
      const isXs = this.$mdMedia('xs');
      const isSm = this.$mdMedia('sm');
      const isMd = this.$mdMedia('md');
      const isGtMd = this.$mdMedia('gt-md');

      // md-cols-xs="2" md-cols-sm="3" md-cols-md="4" md-cols-gt-md="5" md-cols="5"
      if ( isXs ){
        return 2;
      } else if ( isSm ) {
        return 3;
      } else if ( isMd ) {
        return 4;
      } else if ( isGtMd ){
        return 5;
      } else {
        return 5;
      }
    }
  }
  $onInit() {

  }

  addItemEvery(arr: any, every: number): any[][] {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      a.push(arr[i]);
      if ((i + 1) % every === 0) {
        a.push([i]);
        i++;
        if (arr[i]) {
          a.push(arr[i]);
        }
      }
    }
    return a;
  }

  setFramesPerRow( columns: number ) {
    // if the value equals what is already set, toggle it off
    if ( this.userSelectedColumns === columns ) {
      this.userSelectedColumns = -1;
    } else {
      this.userSelectedColumns = columns;
      this.frameColumnsPerLine  = this.userSelectedColumns;

      const isXs = this.$mdMedia('xs');
      const isSm = this.$mdMedia('sm');
      const isMd = this.$mdMedia('md');
      const isGtMd = this.$mdMedia('gt-md');

      // md-cols-xs="2" md-cols-sm="3" md-cols-md="4" md-cols-gt-md="5" md-cols="5"
      if ( isXs ){

      } else if ( isSm ) {  // 3,4 default
        if ( columns === 1) {
          this.detailViewRowSpans = 1;
        } else if ( columns === 2  ) {
          this.detailViewRowSpans = 3;
        } else if ( columns === 3  ) {
          this.detailViewRowSpans = 4;
        } else if ( columns === 4 ) {
          this.detailViewRowSpans = 4;
        } else if ( columns === 5 ) {
          this.detailViewRowSpans = 6;
        }
      } else if ( isMd ) {
        if ( columns === 1) {
          this.detailViewRowSpans = 1;
        } else if ( columns === 2  ) {
          this.detailViewRowSpans = 2;
        } else if ( columns === 3 ) {
          this.detailViewRowSpans = 3;
        } else if ( columns === 4 ) {
          this.detailViewRowSpans = 4;
        } else if ( columns === 5 ) {
          this.detailViewRowSpans = 8;
        }
      } else if ( isGtMd ){
        if ( columns === 5  ) {
          this.detailViewRowSpans = 2;
        } else if ( columns === 4  ) {
          this.detailViewRowSpans = 2;
        } else {
          this.detailViewRowSpans = 1;
        }
      }

      this.buildLines();
      this.lazyLoadDetailImages( this.selection );
    }

  }

  getColSpan(item: any) {
    // if its an empty [] then its a detail row placeholder or a ['filler'] row to fill the last missing items in the grid
    if ( (item && item.hasOwnProperty('length') )  && ( ( item[0] !== 'filler') || ( item[0] === 'last') )) {
      return this.frameColumnsPerLine;
    } else {
      return 1;
    }
  }
  getRowSpan(item: any) {
    // rows  - if it is an array (has length) its a place holder row for opening
    if ( (item && item.hasOwnProperty('length') )  && ( ( item[0] !== 'filler') || ( item[0] === 'last') )) {
      return this.detailViewRowSpans;
    } else {
      return 1; // frame preview image
    }
  }

  isDetailsRow(item: any) {
    // rows  - if it is an array (has length) its a place holder row for opening
    if ( (item && item.hasOwnProperty('length') )  && ( ( item[0] !== 'filler') || ( item[0] === 'last') )) {
      return true;
    } else {
      return false;
    }
  }

  chunkLines(styles: any) {
    const newArray = this.addItemEvery(styles, this.frameColumnsPerLine);
    if ( (styles.length + 1 % this.frameColumnsPerLine ) !== 0 ) {
      const missingRows =  ( Math.ceil(styles.length / this.frameColumnsPerLine ) * this.frameColumnsPerLine ) - styles.length + 1;
      for (let i = 0; i < missingRows; i ++) {
        if ( i === missingRows - 1 ) {
          newArray.push(['last']);
        } else {
          newArray.push(['filler']);
        }
      }
    }
    return newArray;
    // const result = styles.slice( index, 6 );
    /*
    var index = cacheInputs.indexOf(input);
    if (index !== -1) {
        return cacheResults[index];
    }
    var result = [];
    for (i = 0; i < input.length; i += columns) {
        result.push(input.slice(i, i + columns));
    }
    cacheInputs.push(input);
    cacheResults.push(result);
       return result;
    */

  }

  addSwym() {
    this.swymWishListService.getWishListItems().then((items: any) => {
      items.forEach((item: any) => {
        if (item.cprops && item.cprops.sku && item.cprops.sku.startsWith('FRAME-')) {
          this.wishlistItems[item.epi] = item;
        }
      });
    });
  }

  //  implement getItemAtIndex and getLength.
  /*
        getItemAtIndex = (index: number) => {
          let itemCount = 0;
          let item = null;
          for ( let curCategory of this.lines) {
            itemCount += 1;
            if ( itemCount === index) {
              let j = 0;
              item = curCategory.styles[j];
              break;
            }
            for ( let curCatStyle of  curCategory.styles ) {
              itemCount += 1;
              if ( itemCount === index) {
                item = curCatStyle;
                break;
              }
            }
          }
          return item;
        }

        // Required.
        getLength = () => {
          let length = this.lines.length;
          for ( let curCategory of this.lines) {
            length += curCategory.styles.length;
          }
          return length;
        }
  */
  flatten(arr: any) {
    return arr.reduce(  (flat: any, toFlatten: any) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }
  getFilterRelatedKeywords(){
      const  allKeywords = this.selection.getKeywordsForDisplay();
      const  allSplit = allKeywords.split(',').map( e => e.trim());
      const filteredKeywords = this.filterCategories.map( x => x.filters.filter( f => allSplit.find( all => all.toLowerCase().indexOf(  f.name.toLowerCase() ) > -1 )));
     // want to reduce this to a single array with item
      let temp = this.flatten( filteredKeywords );
      return temp;
  }

  selectFilterfromKeyWord( key: any ) {
    let y = key;
  }

  getFilterHtmlClass(filtercat: FiltersCategory) {
    return 'cat-' + filtercat.htmlClass;
  }
  getFrameProductsForWishList() {
    if (this._showFavorites) {
      FrameProduct.list().then((frames: FrameProduct[]) => {
        this.wishlistFrameProducts = frames.filter(f => {
          if (f.product._id in this.wishlistItems) {
            return f;
          } else {
            return null;
          }
        });
      }).then(() => {
        this.foundItems += this.wishlistFrameProducts.length;
        this.lines = [];
        this.lines.push({ text: 'Favorites', description: '', lineNumber: this.wishlistFrameProducts.length, styles: this.wishlistFrameProducts });
      });
    }
  }
  toggleShowFavorites() {
    this._showFavorites = !this._showFavorites;
  }
  showFavorites() {
    return this._showFavorites;
  }
  mouseOverThumbnail(style: FrameProduct) {
    if (this.selection) {
      return;
    }
    style.guaranteedPrice().then(() => {
      this.preview = style;
    });
  }

  mouseOutThumbnail() {
    if (this.selection) {
      return;
    }
    this.preview = null;
  }
  scrollTo(id: string) {
    this.$location.hash(id);
    this.$anchorScroll();
  }

  addSampleToCart($event: any, sample: SampleProduct) {
    const redirectToCart = false;
    let self = $event.target;
    this.cartService.addSampleProduct(sample, redirectToCart, false).then(() => {
      this.addToSywmWishlist(this.selection);
      const elementToAnimate = $(self).parent();
      this.$cssAnimationService.addElementToCart(elementToAnimate, this.$rootScope.$emit('force-cart-count-update'));
    }).catch((err: any) => {
      alert(err);
    });
  }
  selectSideImage( idx: number) {
    //  swap the selected side image with the preview image
    setTimeout(() => {
      const previewImage = angular.element(document.getElementsByClassName('preview--image'))[0];
      const imgs = angular.element(document.getElementsByClassName('image--side'));
      let selectedSrc = ( imgs[idx] as any).src;
      ( imgs[idx] as any).src = (previewImage as any).src;
      (previewImage as any).src = selectedSrc;
    }, 0);
  }
  getImageSrcForProductLabelAppImages(imageAsset: string) {
    return window.fmtvAssetsRoot + 'assets/configurator/product-labels/' + imageAsset;
  }
  showPane(pane: any) {
    this.selectedFilterPane = 3;
  }

  expandCallback(index: number, id: string) {
    this.selectedFilterPane = index;
    // this.$scope.accordionA.toggle(index);
    // this.$accordion.pane[index].collapse();
  }

  filterContents() {
    this.buildLines();
  }

  selectCategoryFilter(category: Category) {
    const filterEntry = this.filterCategories.find(c => c.label === category.description);
    let x = filterEntry.filters.find(f => f.collectionId === category.collectionId);
    this.selectFilter(x);
  }

  getNumber(numb: number) {
    return new Array(numb);
  }

  // open the details view of the frame when one item on the row is selected
  isRowSelected(idx: number, category: Line) {
    if (this.selectedIndex > -1) {
      return (this.selectedIndex < idx && this.selectedIndex >= idx - this.frameColumnsPerLine && this.isCategorySelected( category ));
    } else {
      return false;
    }
  }

  isPreviewCell(rowIdx: number, category: any, frame: any) {
    if ( frame && frame.length  && frame[0] === 'filler' ) {
      return false;
    }
    const roundup = this.roundUp(rowIdx, this.frameColumnsPerLine + 1);
    const result = (rowIdx + 1 !== roundup);
    return result;
  }

  /* shows all preview rows in the grid and hides/shows the detail view row based on selection */
  showGridTile(style: any, idx: number, category: Line) {
    if ( style && style.length  && style[0] === 'filler' ) {
      return true;
    }
    const isDetailsRow = this.isDetailsRow(style);
    if ( isDetailsRow ) {
      const isRowSelected = this.isRowSelected(idx, category);
      const result = isRowSelected;
      return result;
    } else {
      return true;
    }
  }

  roundUp(numToRound: number, multiple: number) {
    numToRound = numToRound === 0 ? 1 : numToRound;
    let up = Math.ceil(numToRound / multiple);
    return up * multiple;
  }

  isCategorySelected( cat: any ) {
    if ( this.selectedLine ) {
      return this.selectedLine.text === cat.text;
    }
    return false;
  }

  // say rows with 0 to 5 selected and #4 is selected
  // then the 'detail row' is 6 and should show #4
  // open the details view of the frame when one item on the row is selected
  isDetailSelected(idx: number, category: any ) {
    if (this.isRowSelected(idx, category)) {
      let selectedDetailIdx = this.roundUp(idx + 1, this.frameColumnsPerLine + 1);
      return selectedDetailIdx === idx + 1;
    }
    return false;
  }

  showFrameDetails(idx: number) {
    if (this.selectedIndex > -1) {
      return (this.selectedIndex < idx && this.selectedIndex > idx - this.frameColumnsPerLine);
    } else {
      return false;
    }
  }

  selectThumbnail(style: FrameProduct, category: any) {
    // if same element is clicked, do not close -- need to use the X
    if (!style) {
      return;
    }

    this.finish = null;
    const selectedGallery = this.lines.filter(l => {
      // remove an [][] entries
      if (l.styles) {
        return l.styles.find(e => (e as any).code === style.code);
      }
    });

    if (selectedGallery && selectedGallery[0] && selectedGallery[0].styles) {
      this.selectedIndex = selectedGallery[0].styles.findIndex(((e: any) => (e as any).code === style.code));
    } else {
      this.selectedIndex = -1;
    }

    if (selectedGallery) {
      this.selectedLine = selectedGallery[0];
    }

    // if already selected, clicking again closes it
    if (this.selection && this.selection.code === style.code) {
      this.previousSelection = null;
      this.selection = null;
      this.preview = null;
      this.selectedIndex = -1;
      this.selectedLine = null;

    } else {
      this.previousSelection = this.selection;
      this.selection = style;
      this.preview = style;
      this.lazyLoadDetailImages( this.selection );

    }
    if (this.dropDownImageUrl[0]) {
      /*
      this.$loadingIndicator.show();
      const img = document.createElement('img');
      img.src = this.dropDownImageUrl[0];
      (img as any).hideLoader = this.$loadingIndicator.hide();
      img.onload = () => {
        this.$loadingIndicator.hide();
        this.$scope.$apply();
      };
      img.onerror = () => {
        (img as any).hideLoader();
      };
    } else {
      this.$loadingIndicator.hide();
       */
    }
    // this.scrollTo(`expand-jump-${style.sku}`);

  }

  lazyLoadDetailImages(style: any){
    setTimeout(() => {
      this.dropDownImageUrl = style.images.map( ( image: any ) => {
        if (image.indexOf('.jpg') > -1) {
          return image.replace('.jpg', '_x400.jpg');
        }
      });
      const previewImage = angular.element(document.getElementsByClassName('preview--image'));
      (previewImage as any)[0].src  = this.dropDownImageUrl[0];
      const imgs = angular.element(document.getElementsByClassName('image--side'));
      for (let i = 0; i < imgs.length; i++ ) {
        (imgs[i] as any).src = this.dropDownImageUrl[ i + 1 ];
      }
    }, 500);
  }
  close() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.$mdSidenav('frame').close();
  }

  select() {
    // hack here
    // if an artisan frame is selected, the only rush option is 'standard'
    // and this is enforced in the expediate options (when selecting)
    // however when a non-artisan frame is selected AND a non-standard rush is picked
    // changing to an artisan frame needs to reset the selection back to 'standard'
    // Doing that here since there is no easy place to catch frame type changes
    // ALSO SEE SELECT() IN THE FRAME-BAR.CONTROLLER.TS
    if ( this.selection && this.selection.isArtisanNonPromise()) {
      RushProduct.list().then( options => {
        this.configuration.rush.setProduct(options[0]);
      });
    }
    if (this.selection.finishes) {
      this.saveDesignTimerService.unpauseTimerOnDialogClose();
      this.configuration.frame.setFrameProduct(this.selection, this.finish);
      this.$mdSidenav('frame').close();
      this.addToSywmWishlist(this.selection);

      /*
      this.$mdSidenav('frame').close();
      this.$ftvRightBar.show(<any>{
        controller: FinishController,
        templateUrl: 'configurator/configuration/design/frame/finish.html',
        id: 'finish',
        backButton: false,
        title: 'Select a Finish',
        className: 'finish',
        data: {
          style: this.selection,
          finish: this.finish,
          configuration: this.configuration
        }
      });
      */
    }
    else {
      this.saveDesignTimerService.unpauseTimerOnDialogClose();
      this.configuration.frame.setFrameProduct(this.selection, null);
      this.$mdSidenav('frame').close();
    }
  }

  toggleMobileMenuClicked() {
    this._toggleFilterMenuOpen = !this._toggleFilterMenuOpen;
  }

  showFiltersMenu() {
    // a media rule will make the menu toggle visible on its own and only then will it be clickable
    // so then the filters should auto-collapse
    const el = angular.element('#toggle-menu-mobile');
    // if the menu toggle is invisible then the filters should always been seen
    if ((el.css('visibility') === 'visible')) {
      this._showFilterMenu = this._toggleFilterMenuOpen;
    } else {
      this._showFilterMenu = true;
    }
    return this._showFilterMenu;
  }
  selected() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.$mdSidenav('frame').close();
  }

  toggleFilter() {
    this.showFilters = !this.showFilters;
  }

  selectOneFilter( filterEntry: FilterEntry ) {
    const cat = this.filterCategories.map(item => item.filters.map( i => i.selected = false ) );
    this.selectFilter( filterEntry );
    this.lazyLoadDetailImages( this.selection);
  }

  selectFilter(filterEntry: FilterEntry) {
    filterEntry.selected = !filterEntry.selected;
    this.filter = filterEntry;
    // this.filterAccordian[this.selectedFilterPane].collapse();
    // force a rebuild because the filter watch doesn't detect unselection
    this.buildLines();
  }
  clearFilter(filterLabel: string) {
    const cat = this.filterCategories.find(item => item.label === filterLabel);
    cat.filters.forEach(item => item.selected = false);
    this.buildLines();
  }
  showClearFilter(filterLabel: string) {
    const cat = this.filterCategories.find(item => item.label === filterLabel);
    let selectedFilters = cat.filters.filter(item => item.selected === true);
    return selectedFilters.length;
  }
  ensureFilterScroll() {
    const itemsContainer = jquery('.items-container');
    if (itemsContainer.scrollTop() < itemsContainer.height()) {
      itemsContainer.scrollTop(itemsContainer.height());
    }
  }
  isFrameInWishList(style: FrameProduct) {
    return (style.id in this.wishlistItems);
  }

  addToSywmWishlist(style: FrameProduct) {
    if (this.isFrameInWishList(style)) {
      this.swymWishListService.removeFromWishList(this.wishlistItems[style.id]).then(() => {
        delete this.wishlistItems[style.id];
        const tempItems: string[] = [];
        Object.keys(this.wishlistItems).forEach((key, index) => {
          if (this.wishlistItems[key].cprops && this.wishlistItems[key].cprops.sku) {
            tempItems.push(this.wishlistItems[key].cprops.sku);
          }
        });
        this.swymWishListService.broadcastWishlistIsDirty(tempItems);
      });
    } else {
      // this.swymWishListService.addCollection(this.swymWishListService.FRAME_COLLECTION, style.productId).then(() => {
      this.swymWishListService.addToWishList(style.productId, style.id, style.thumbnailUrl, `https://framemytv.com/products/${style.name}`, style.price, { sku: style.sku }, null).then(item => {
        this.wishlistItems[style.id] = item;
        const tempItems: string[] = [];
        Object.keys(this.wishlistItems).forEach((key: any, index: any, array: any) => {
          if (this.wishlistItems[key].cprops && this.wishlistItems[key].cprops.sku) {
            tempItems.push(this.wishlistItems[key].cprops.sku);
          }
        });
        this.swymWishListService.broadcastWishlistIsDirty(tempItems);
      });
      // });
    }
  }
  private init = () => {
    const sideNav = this.$mdSidenav('frame');
    sideNav.open().then(() => {
      this.$scope.$watch(() => sideNav.isOpen(), (open: boolean) => {
        if (!open) {
          this.$timeout(() => this.$state.go('^'), 300);
        }
      });
    });
    const itemsContainer = jquery('.items-container');
    itemsContainer.on('scroll', this.scrolled);
  }

  private scrolled = (e: JQueryMouseEventObject) => {
    if (this.hideHelp && e.target.scrollTop >= e.target.clientHeight / 2) {
      this.$scope.$apply(() => this.hideHelp = false);
    }
    if (!this.hideHelp && e.target.scrollTop < e.target.clientHeight / 2) {
      this.$scope.$apply(() => this.hideHelp = true);
    }
  }

  private getSamplesQty(sample: any) {
    //  return this.cartService.getItemInventoryQuantity( sample.id).then( qty => {
    //   return qty > 0;
    // });
    return true;
  }
  /**
   * Builds an array of lines to be shown in the virtual scrolling.
   *
   * Each category header is on a line, then frame styles are 4 to a line.
   * This allows the virtual scrolling directive to work with fixed height lines.
   */
  private buildLines() {
    this.lines = [];

    let lineNumber = 0;

    // filter by collection other collections are excluded
    // however if filtering by others (color, size, etc), the filter is applied to all items
    // in all collections
    let collectionFilters = new Array<FilterEntry>();
    let colorFilters = new Array<FilterEntry>();
    let styleFilters = new Array<FilterEntry>();
    let widthFilters = new Array<FilterEntry>();
    let curFilter = new Array<FilterEntry>();
    for (let category of this.filterCategories) {
      if (category.label === 'Collection') {
        curFilter = collectionFilters;
      } else if (category.label === 'Color') {
        curFilter = colorFilters;
      } else if (category.label === 'Width') {
        curFilter = widthFilters;
      } else if (category.label === 'Style') {
        curFilter = styleFilters;
      }
      for (let filterEntry of category.filters) {
        filterEntry.count = 0;
        if (filterEntry.selected === true) {
          curFilter.push(filterEntry);
        }
      }
    }
    this.foundItems = 0;
    // for each collection type, loop and filter
    this.categoryButtons.forEach(b => {
      let foundItems = 0;

      for (let category of this.filterCategories) {
        for (let filterEntry of category.filters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          b.items.map(a => {
            if (category.label === 'Width') {
              let width = Number.parseFloat(a.product.width);
              if (filterEntry.filterValue === '5') {
                let found = width >= 5;
                if (found) {
                  filterEntry.count += 1;
                }
              } else {
                let startWidth = Number.parseFloat(filterEntry.filterValue.split('-')[0]);
                let endsWidth = Number.parseFloat(filterEntry.filterValue.split('-')[1]);
                let found = (startWidth >= width && width <= endsWidth);
                if (found) {
                  filterEntry.count += 1;
                }
              }
            } else {
              let keys = a.product.getKeywords().toLowerCase();
              let found = keys.toLowerCase().indexOf(filterval) > -1;
              if (found) {
                filterEntry.count += 1;
              }
            }
          });
        }
      }

      lineNumber += 1;
      b.scrollPos = 150 * this.lines.length;
      let isFiltered: boolean = false;
      let filteredItems: FrameProduct[] = new Array<FrameProduct>();

      let collectionItems: FrameProduct[] = [];
      if (collectionFilters.length > 0) {
        let match = collectionFilters.filter(f => f.collectionId === b.collectionId);
        if (match.length === 0) {
          collectionItems = [];
        } else {
          collectionItems = b.items;
        }
      } else {
        // include all items in the current collection and filter from there
        collectionItems = b.items;
      }

      let colorItems: FrameProduct[] = [];
      if (colorFilters.length === 0) {
        colorItems = collectionItems;
      } else {
        for (let filterEntry of colorFilters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          const foundItemss = collectionItems.filter(a => {
            let keys = a.product.getKeywords().toLowerCase();
            let found = keys.indexOf(filterEntry.filterValue.toLowerCase()) > -1;
            return found;
          });
          // i.e. red union blue
          colorItems = colorItems.concat(foundItemss);
        }
      }
      let styleItems: FrameProduct[] = [];
      if (styleFilters.length === 0) {
        styleItems = colorItems;
      } else {
        for (let filterEntry of styleFilters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          const foundItemsss = colorItems.filter(a => {
            let keys = a.product.getKeywords().toLowerCase();
            const found = keys.indexOf(filterEntry.filterValue) > -1;
            return found;
          });
          styleItems = styleItems.concat(foundItemsss);
        }
      }

      let widthItems: FrameProduct[] = [];
      if (widthFilters.length === 0) {
        widthItems = styleItems;
      } else {
        for (let filterEntry of widthFilters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          const foundItemssss = styleItems.filter(a => {
            let width = Number.parseFloat(a.product.width);
            if (filterEntry.filterValue === '5') {
              let foundWidth = width >= 5;
              if (foundWidth) {
                return foundWidth;
              }
            }
            let startWidth = Number.parseFloat(filterEntry.filterValue.split('-')[0]);
            let endsWidth = Number.parseFloat(filterEntry.filterValue.split('-')[1]);
            let found = (startWidth >= width && width <= endsWidth);
            return found;
          });
          widthItems = widthItems.concat(foundItemssss);
        }
      }

      filteredItems = sortBy(filteredItems, this.sort);

      if (this.filterBox && this.filterBox.length > 0) {
        // artists, or title or sku, keywords
        const filterTrimmed = this.filterBox.trim().toLowerCase();
        widthItems = widthItems.filter(item => item.name.toLowerCase().includes(filterTrimmed) || item.sku.toLowerCase().includes(filterTrimmed) || item.getKeywords().includes(filterTrimmed));

      }
      this.foundItems += widthItems.length;
      this.lines.push({ text: b.label + (this.lines.length ? ' Collection' : ''), description: '', lineNumber, styles: widthItems });

      // this.lines.push.apply(this.lines, chunk(art).map(c => ({ styles: c })));
    });
    for (let collection of this.lines) {
      collection.styles = this.chunkLines(collection.styles) as any;
    }
  }

  private zeroPadPrice(price: number) {
    if (!price) {
      return -1;
    }
    let value = price.toString();
    const res = price.toString().split('.');
    if (res.length === 1 || res[1].length < 3) {
      value = Number(value).toFixed(2);
    }
    return value;
  }

  /*
    private buildLines() {
      this.lines = [];
      const regex = new RegExp(this.filter, 'i');
      let lineNumber = 0;
      this.categoryButtons.forEach(b => {
        lineNumber += 1;
        b.scrollPos = 150 * this.lines.length;
        const art = sortBy(filter(b.items, f => regex.test(f.product.name) || regex.test(f.product.code)), this.sort);
        this.lines.push({ text: b.label + (this.lines.length ? ' Collection' : ''), description: '', lineNumber, styles: art });
      });
    }
    */

}
