import { DesignConfiguration } from '../../design/designConfiguration';
import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { RushProduct } from '../../../api-shopify/product-models/rush-product';
import app from '../../app';
import { SaveDesignTimerService } from '../save-design/save-design-timer.service';

@app.inject('$mdDialog', DesignConfigurationService, SaveDesignTimerService).controller
export default class RushController {

  options: RushProduct[];
  configuration: DesignConfiguration;
  isArtisanFrame: boolean = false;
  imageA = window.fmtvAssetsRoot + 'assets/configurator/Calendar_a.jpg';
  imageB = window.fmtvAssetsRoot + 'assets/configurator/Calendar_b.jpg';

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private designConfigurationService: DesignConfigurationService,
    private saveDesignTimerService: SaveDesignTimerService,

  ) {
    this.configuration = designConfigurationService.designConfiguration;
    RushProduct.list().then(r => {
      this.options = r;
       // if the frame is artisan then only 'standard' shipping is available
      if ( this.configuration.frame && this.configuration.frame.frameProduct ) {
        if ( this.configuration.frame.frameProduct.isArtisanNonPromise() ) {
          // reset to 'standard' in case another option was already selected before an artisan frame was picked
          // note: this doesn't catch the case when the frame type is changed in the same design.. only when the expedite is opened
          this.configuration.rush.setProduct(this.options[0]);
        }
      }
    });

    this.saveDesignTimerService.pauseTimer();
    if ( this.configuration.frame && this.configuration.frame.frameProduct ) {
      this.isArtisanFrame = this.configuration.frame.frameProduct.isArtisanNonPromise();
    }
  }

  isOptionAvailable( index: number ) {
    if ( this.isArtisanFrame && index > 0 ){
      return false;
    } else {
      return true;
    }
  }

  $onInit() {

  }

  select(option: RushProduct) {
    this.designConfigurationService.designConfiguration.rush.setProduct(option);
    this.$mdDialog.hide();
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
  }

  cancel() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.$mdDialog.cancel();
  }

}
