import app from '../app';

@app.service
export class LoadingIndicatorService {
  visible = false;
  messageLines = ['Please wait...'];

  private visibleCount = 0;
  // allow custom message or use the default above
  show( msg?: string[]) {
    if ( msg ) {
      this.messageLines = msg;
    }
    this.visibleCount++;
    this.visible = true;
  }

  hide() {
    this.visibleCount--;
    if (this.visibleCount < 0) {
      this.visibleCount = 0;
    }
    this.visible = false; // this.visibleCount !== 0;
  }
}
