import { TvControlProduct } from '../../../api-shopify/product-models/tv-control-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface OldTvControlLineData {
  base?: string;
  caption?: string;
  factor?: string;
  name?: string;
  priority?: string;
  thumburl?: string;
  tvcontrolid?: string;
  url?: string;
}

export interface TvControlLineData {
  code?: string;
}

export class TvControlLine extends BaseLine<OldTvControlLineData & TvControlLineData> {
  kind = ConfigLineKinds.TvControl;
  category = 'Options';
  name = 'TV Control';
  order = 60;
  editState = 'configuration.tab.options.tv';
  product: TvControlProduct;
  scrollToElementId = 'header-tv';

  get valid() { return !!this.product; }

  deserialize(data?: OldTvControlLineData & TvControlLineData) {
    this.data = data;

    if (this.data && this.data.code) {
      return TvControlProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    else {
      return this.q.resolve();
    }
  }

  setProduct(product: TvControlProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code,
      name: product.name
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.TvControl] = TvControlLine;
