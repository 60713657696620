import { Product } from './product';
import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
// import * as Categories from '../apiCategories.service';
import sortBy from 'lodash/sortBy';
import { LightBoxInfo } from './light-box-info';

let listPromise: ng.IPromise<InstallationMethodProduct[]>;

export class InstallationMethodProduct extends Product {

  static list() {
    if (listPromise) {
      return listPromise;
    }
    return listPromise = InstallationMethodProduct.productsService.getAllInstallationMethodProduct()
      .then( ( products: any ) => sortBy(products, p => p.sortPosition).map((p: any) => new InstallationMethodProduct(p)));
  }

  static get(code: string) {
    return InstallationMethodProduct.productsService.getInstallationMethodBySku(code).then((p: any) => new InstallationMethodProduct(p));
  }

  @lib.inject(ProductsApi).property
  private static readonly productsService: ProductsApi;

  public readonly mounting: string[];
  public readonly mountType: string[];
  public readonly wallType: string[];
  public readonly productType: string[];
  public lightBoxInfo: LightBoxInfo;
  private _factor: number;
  private _manualPriceAdjustment = 1.06; // adjustment to the shopify price .. should be done in shopify but done here for now

  // thumbnailUrl moved to super

  get factor() {
    return this._factor || 0;
  }

  constructor(
    public data: any
  ) {

    super(data);
    this.mounting = data.mounting;
    this.mountType = data.mountType;
    this.wallType = data.wallType;
    this.productType = data.productType;
    this._factor = data.factor;
    this.lightBoxInfo = data.lightBoxInfo;
  }

  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    return this.basePrice().then(b => Math.ceil( b + this.factor * tvSize ));
  }
}
