import { MirrorProduct } from '../../../api-shopify/product-models/mirror-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface OldMirrorLineData {
  base?: string;
  caption?: string;
  factor?: string;
  mirrorid?: string;
  name?: string;
  priority?: string;
  thumburl?: string;
  url?: string;
  detailsKey?: string;
}

export interface MirrorLineData {
  code?: string;
}

export class MirrorLine extends BaseLine<OldMirrorLineData & MirrorLineData> {
  kind = ConfigLineKinds.Mirror;
  category = 'Design';
  name = 'Mirror';
  order = 40;
  editState = 'configuration.tab.design.mirror';
  scrollToElementId = 'mirror-option-scroll-to';

  mirrorProduct: MirrorProduct;
  product: MirrorProduct;

  get valid() { return !!this.mirrorProduct; }

  deserialize(data?: OldMirrorLineData & MirrorLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return MirrorProduct.get(this.data.code).then(m => this.setProduct(m, true));
    }
    else {
      return this.q.resolve();
    }
  }

  setProduct(product: MirrorProduct, noTrigger = false) {
    this.product = this.mirrorProduct = product;
    this.description = product.name;
    this.data = {
      code: product.code,
      name: product.name,
      thumburl: product.thumbnailUrl
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice() {
    if (this.mirrorProduct) {
      return this.mirrorProduct.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.Mirror] = MirrorLine;
