import { Analytics } from '../../analytics';
import app from '../../app';
import './tiles/frameTypeTile.component';
import './tiles/externalLinkTypeTile.component';
import './tiles/helpTile.component';
import './tiles/quoteTile.component';
import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { BrandingInfo } from '../../services/brandingInfo';
import { Tile, tiles } from './tiles';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';
import angular from 'angular';

@app.inject(
  DesignConfigurationService,
  '$state',
  '$mdMedia',
  'brandingInfo',
  '$scope',
  '$mdDialog',
  '$location',
  '$analytics',
  '$window'
  ).controller
export class FrameTypeController {

  tiles: Tile[];
  activeProductTiles: Tile[];
  availableTiles: Tile[];
  preview: string;
  previewedTile: Tile;
  previewMargin: string;

  showDiscover: boolean;
  discoverMargin: string;

  constructor(
    private designConfiguration: DesignConfigurationService,
    private $state: ng.ui.IStateService,
    private $mdMedia: ng.material.IMedia,
    private brandingInfo: BrandingInfo,
    private scope: ng.IScope,
    private $mdDialog: ng.material.IDialogService,
    private $location: ng.ILocationService,
    private $analytics: any,
    private $window: ng.IWindowService,
  ) {
  }

  $onInit() {
    this.designConfiguration.reset();
    const features = intersection(['frame', 'art', 'samsungDeco', 'mirror', 'liner'], keys(this.$location.search()));

    this.availableTiles = filter(tiles, t => t.kind !== 'frameType' &&  t.kind !== 'externalLink' || intersection(t.features, features).length === features.length);
    //  the title of a product (when changing product type ) is dependant on finding it in the splash screen tile list so needed to add
    // a discontinued flag to the tile list and filter them out for display (while maintaining them in the actual list)
    this.activeProductTiles = tiles.filter( t => {
      if ( t.kind === 'frameType' && t.discontinued === true ) {
        return;
      } else {
        return t;
      }
    });

    if (filter(this.availableTiles, t => !!t.features).length === 0) {
      this.availableTiles = tiles;
    }
    this.scope.$watch(() => this.$mdMedia('sm'), small => this.reflowTiles(small));
    this.scope.$watch(() => this.$mdMedia('xs'), small => this.reflowTiles(small));
    this.reflowTiles(this.$mdMedia('xs') || this.$mdMedia('sm'));

    try {
      sessionStorage.setItem('test', 'foo');
    }
    catch (e) {
      this.$mdDialog.show(
        this.$mdDialog.alert()
          .parent(angular.element('#fmtv-app'))
          .clickOutsideToClose(true)
          .title('Private Browsing not supported')
          .textContent(`It appears you're using Private Browsing. Please note that this feature will limit some of the functionality of our Design Center. For the best experience, we recommend disabling private browsing while on our site.`)
          .ariaLabel('Private browsing not supported')
          .ok('Ok')
      );
    }

    if (/(MSIE|Trident)/.test(window.navigator.userAgent)) {
      this.$mdDialog.show(
        this.$mdDialog.alert()
          .parent(angular.element('#fmtv-app'))
          .clickOutsideToClose(true)
          .title('Internet Explorer not supported')
          .textContent(`We have detected that you are using Internet Explorer to browse the web. Our Design Studio uses more advanced technology than your current browser is capable of and many features will not work as designed. We suggest using Chrome or another browser.`)
          .ariaLabel('Internet Explorer not supported')
          .ok('Ok')
      );
    }
    // Disabled for now because it pops up even when the configurator is closed
    // else if (this.$mdMedia('xs') || this.$mdMedia('sm')) {
    //   this.$mdDialog.show(
    //     this.$mdDialog.alert()
    //       .parent(angular.element('#fmtv-app'))
    //       .clickOutsideToClose(true)
    //       .textContent(`While our configurator does work via mobile, you may have a better experience through larger desktop or tablet browser.`)
    //       .ariaLabel('Small size detected')
    //       .ok('Ok')
    //   );
    // }
  }

  setPreview(preview: string) {
    if (this.preview === preview) {
      return;
    }
    this.preview = preview;
    this.previewedTile = preview ? find(this.tiles, t => t.frameType as any === preview) : null;
    this.previewMargin = '0';
    if (includes(['frame with art', 'frame with mirror', 'frameless mirror'], preview)) {
      this.previewMargin = '50%';
    }
  }

  clearPreview(preview: string) {
    if (!this.showDiscover && this.preview === preview) {
      this.setPreview(null);
    }
  }

  select(id: string) {
    this.designConfiguration
    .newDesign(id, this.$state.params)
    .then(() => {
      this.$analytics.eventTrack(Analytics.actions.designCreated, { category: Analytics.category, label: id });
      this.$state.go('configuration.tab.design', this.$state.current.params, {
        reload: true
      });
    });
  }

  openExternalLink( url: string ) {
    this.$window.open(url, '_self');
  }

  discover(id: string) {
    this.showDiscover = true;
    this.discoverMargin = '50%';
    if (includes(['frame with art', 'frame with mirror', 'frameless mirror'], id)) {
      this.discoverMargin = '0';
    }
  }

  clearDiscover() {
    this.showDiscover = false;
    this.clearPreview(this.preview);
  }

  private reflowTiles(small: boolean) {
    const total = tiles.length - (filter(this.availableTiles, t => t.kind === 'frameType').length - filter(this.brandingInfo.settings.frameTypes, v => v).length);

    if (small) {
      // this.tiles = filter(this.availableTiles, t => t.kind !== 'quote' && (t.kind !== 'frameType' || this.brandingInfo.settings.frameTypes[t.frameType]));
      if ( this.$mdMedia('xs') ) {
        this.availableTiles = filter(this.availableTiles, t => t.kind !== 'quote' && (t.kind !== 'frameType' || this.brandingInfo.settings.frameTypes[t.frameType]));
        this.availableTiles.splice( 0, 1); // remove initial 'help tile
      }
    }
    else {
      this.tiles = filter(this.availableTiles, t => t.kind !== 'frameType' || this.brandingInfo.settings.frameTypes[t.frameType]);
      if (total % 2 !== 0) {
        const lastQuote = findLast(this.availableTiles, t => t.kind === 'quote');
       // remove(this.tiles, t => t === lastQuote);
      }
    }
  }

}
