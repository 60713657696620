// The template file is generated by the gulp build
import Module from '@rightscale/ui-angular-decorators';
// import '~/virto/virto.module';
// import '~/api/api.module';
import '~/api-shopify/api-shopify.module';
import '~/ui/';

import '../configurator.images';
import '../configurator.templates';

const app = new Module('Designer', [
  'configurator.images',
  'configurator.templates',
  'ngMessages',
  'ngAnimate',
  'ui.router',
  'ngMaterial',
  'validation.match',
  'vAccordion',
  'ngSanitize',
  'angulartics',
  'angulartics.google.analytics',
  'angulartics.google.tagmanager',
  'ngCookies',
  'ui.router.title',
  'mdColorPicker',
  'vs-repeat',
  'angular-clipboard',
  'angular-intro',
  // 'Virto',
  // 'Api',
  'ApiShopify',
  'ftv.ui',
  'hc.marked',
  'ftv.lambdas'
], { prefix: 'ftv' });

export default app;
