import { CutGlassProduct } from '../../../../api-shopify/product-models/cut-glass-product';
import { CutGlassLineData } from '../../../design/lines';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { DesignConfigurationService } from '../../../design/designConfiguration.service';
import { Fraction, MeasurementsService } from '../../../services/measurements.service';
import app from '../../../app';
import defaults from 'lodash/defaults';
import filter from 'lodash/filter';
import some from 'lodash/some';

const settingsKey = 'CutGlassIntro';

interface Dimensions {
  system: 'inches' | 'mm';
  width: number;
  widthFraction: Fraction;
  height: number;
  heightFraction: Fraction;
}

@app.inject(
  MeasurementsService,
  DesignConfigurationService,
  '$scope',
  '$q',
  'ngIntroService',
  '$timeout').component({
  templateUrl: 'configurator/configuration/design/cut-glass/cutGlass.html'
})
export class CutGlass {
  dimensions: Dimensions = {
    system: 'inches',
    width: null,
    widthFraction: null,
    height: null,
    heightFraction: null
  };

  configuration: DesignConfiguration;

  products: CutGlassProduct[];

  widthFractionLabel = 'Fraction';
  heightFractionLabel = 'Fraction';
  maxSize = CutGlassProduct.maxSize();

  constructor(
    private measurementsService: MeasurementsService,
    designConfigurationService: DesignConfigurationService,
    private $scope: ng.IScope,
    private $q: ng.IQService,
    private introService: any,
    private $timeout: ng.ITimeoutService
  ) {
    this.configuration = designConfigurationService.designConfiguration;
  }

  $onInit() {
    if (this.configuration.cutGlass && this.configuration.cutGlass.data) {
      let d = this.configuration.cutGlass.data;
      if (d.system) {
        this.dimensions.system = d.system;
      }

      this.dimensions.widthFraction = this.measurementsService.getFraction(d.width);
      this.dimensions.width = this.dimensions.widthFraction ? Math.floor(d.width) : d.width;
      this.dimensions.heightFraction = this.measurementsService.getFraction(d.height);
      this.dimensions.height = this.dimensions.heightFraction ? Math.floor(d.height) : d.height;

      if (this.dimensions.system === 'inches') {
        this.dimensions.widthFraction = this.measurementsService.getFraction(d.width);
        this.dimensions.heightFraction = this.measurementsService.getFraction(d.height);
      }
    }

    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      $('[name=width]').focus();
    }, 400);

    this.$scope.$watch(() => this.dimensions, d => {
      let line = this.configuration.cutGlass;

      let data = defaults({
        width: d.width ? d.width + (d.widthFraction ? d.widthFraction.value : 0) : null,
        height: d.height ? d.height + (d.heightFraction ? d.heightFraction.value : 0) : null,
        system: d.system
      }, line.data);

      if (isFinite(d.width) && d.width % 1) {
        this.widthFractionLabel = 'N/A';
        this.dimensions.widthFraction = null;
      }
      else {
        this.widthFractionLabel = 'Fraction';
      }

      if (isFinite(d.height) && d.height % 1) {
        this.heightFractionLabel = 'N/A';
        this.dimensions.heightFraction = null;
      }
      else {
        this.heightFractionLabel = 'Fraction';
      }

      line.update(data);

      if (this.configuration.cutGlass.product && this.cannotSelect(this.configuration.cutGlass.product)) {
        this.configuration.cutGlass.setProduct(null);
      }

    }, true);

    CutGlassProduct.list().then(p => this.products = p);

    // if (localStorage.getItem(settingsKey) !== 'true') {
    if (
      this.dimensions.width === null &&
      this.dimensions.height === null &&
      this.dimensions.widthFraction === null &&
      this.dimensions.heightFraction === null
    ) {
      // this.setIntro();
    }
    // }
  }

  select(product: CutGlassProduct) {
    this.configuration.cutGlass.setProduct(product);
  }

  fractions(searchText: string) {
    if (searchText) {
      return this.$q.resolve(filter(this.measurementsService.fractions, f => f.label.indexOf(searchText) >= 0));
    }
    return this.$q.resolve(this.measurementsService.fractions);
  }

  systemChanged() {
    this.dimensions.width = null;
    this.dimensions.height = null;
    this.dimensions.heightFraction = null;
    this.dimensions.widthFraction = null;
  }

  cannotSelect(product: CutGlassProduct) {
    let d = this.configuration.cutGlass.data;

    let width = d.width;
    let height = d.height;

    if (d.system === 'mm') {
      width = this.measurementsService.mmToInches(width);
      height = this.measurementsService.mmToInches(height);
    }

    return (some(product.maxOversize, s => s < height) && some(product.maxOversize, s => s < width)) ||
      some([width, height], dim => dim > Math.max(product.maxOversize[0], product.maxOversize[1]));
  }

  overMax() {
    let d = this.configuration.cutGlass.data;

    let width = d.width;
    let height = d.height;

    return (some(this.maxSize, s => s < height) && some(this.maxSize, s => s < width)) ||
      some([width, height], dim => dim > Math.max(this.maxSize[0], this.maxSize[1]));
  }

  clearIntro() {
    this.introService.exit();
  }

  private setIntro() {
    this.introService.setOptions({
      doneLabel: 'Ok',
      showBullets: false,
      overlayOpacity: 0.3,
      exitOnEsc: true,
      exitOnOverlayClick: true,
      showButtons: false,
      steps: [{
        element: '#cut-glass-dimensions',
        intro: 'To get started, use these fields to specify the dimensions of your mirror.'
        //   element: '#cut-glass-width',
        //   intro: 'Choose the dimensions of your mirror'
        // }, {
        //   element: '#cut-glass-width-fraction',
        //   intro: 'You can enter fractions of inches there',
        //   position: 'left'
        // }, {
        //   element: '#cut-glass-unit',
        //   intro: 'You can change from inches to millimeters',
        //   position: 'left'
        // }, {
        //   element: '#cut-glass-buttons',
        //   intro: 'Then pick the mirror type'
      }]
    });

    this.$timeout(() => {
      this.introService.start();
    }, 1000);

    this.introService.onComplete(this.introCompleted);
    this.introService.onExit(this.introCompleted);
  }

  private introCompleted = () => {
    localStorage.setItem(settingsKey, 'true');
  }
}
