// import swiper from '~/angular-swiper/angular-swiper.module';

import { SwiperContainerController, SwiperContainerScope } from './swiper-container.controller';
import app from '../../app';
import angular from 'angular';
import { Swiper, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';

@app.directive()
export class SwiperContainer {
    definition(
    ): ng.IDirective {
        return {
          restrict: 'E',
          transclude: true,
          controller:  ['$scope', '$element', '$attrs', '$timeout', (
            $scope: SwiperContainerScope,
            $element: ng.IAugmentedJQuery,
            $attrs: ng.IAttributes,
            $timeout: ng.ITimeoutService
          ) => {

            function createUUID() {
                // http://www.ietf.org/rfc/rfc4122.txt
                const s: string []= [];
                const hexDigits = '0123456789abcdef';
                for (let i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
                const x = parseInt( s[19] );
                /* tslint:disable:no-bitwise */
                s[19] = hexDigits.substr(( x & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = '-';

                const newuuid = s.join('');
                return newuuid;
            }
            const uuid = createUUID();
            $scope.swiper_uuid = uuid;

            // directive defaults
            let params = {
                slidesPerView: $scope.slidesPerView || 1,
                slidesPerColumn: $scope.slidesPerColumn || 1,
                spaceBetween: $scope.spaceBetween || 0,
                direction: $scope.direction || 'horizontal',
                loop: $scope.loop || false,
                initialSlide: $scope.initialSlide || 0,
                showNavButtons: false,
                nextButton: '',
                prevButton: '',
                scrollbar: '',
                centeredSlides: true,
                fadeEffect: {
                    crossFade: true
                }
            };

            if (!angular.isUndefined($scope.autoplay) && typeof $scope.autoplay === 'number') {
                params = angular.extend({}, params, {
                    autoplay: $scope.autoplay
                });
            }

            if ($scope.paginationIsActive === true) {
                // original lib has old values
                params = angular.extend({}, params, {
                    // version 6 params
                    pagination : {
                        el: '.swiper-pagination',
                        type: 'bullets', // there are other types but just use bullets
                        clickable: $scope.paginationClickable || true,
                    }
                });
            }

            //    renderBullet
            /*
                        if ($scope.paginationIsActive === true) {
                            params = angular.extend({}, params, {
                                paginationClickable: $scope.paginationClickable || true,
                                pagination:
                                '#paginator-' + $scope.swiper_uuid
                            });
                        }
            */
            if ($scope.showNavButtons === true) {
                // version 6 params
                params = angular.extend({}, params, {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                });
            }

            if ($scope.showScrollBar === true) {
                params.scrollbar = '#scrollBar-' + $scope.swiper_uuid;
            }

            if ($scope.overrideParameters) {
                params = angular.extend({}, params, $scope.overrideParameters);
            }

            $timeout( () => {
                let swiperInstance = null;

                if (angular.isObject($scope.swiper)) {
                    // $scope.swiper = new Swiper( ( $element[0].firstChild as HTMLElement), params as any);
                    Swiper.use([Pagination, Mousewheel, Navigation, Autoplay, EffectFade]);
                    $scope.swiper = new Swiper( ($element[0].firstChild as HTMLElement), params as any);
                    /* ('.swiper-container', {
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    });
*/
                    swiperInstance = $scope.swiper;
                } else {
                    Swiper.use([Pagination, Mousewheel, Navigation, Autoplay, EffectFade]);
                    swiperInstance = new Swiper( ($element[0].firstChild as HTMLElement), params as any);
                }

                // If specified, calls this function when the swiper object is available
                if (!angular.isUndefined($scope.onReady)) {
                    $scope.onReady({
                        swiper: swiperInstance // json short for swiper: swiper
                    });
                }

            }, 100, true,
            $element,
            params);

      }],
          scope: {
            onReady: '&',
            slidesPerView: '=',
            slidesPerColumn: '=',
            spaceBetween: '=',
            parallax: '=',
            parallaxTransition: '@',
            paginationIsActive: '=',
            paginationClickable: '=',
            showNavButtons: '=',
            showScrollBar: '=',
            loop: '=',
            autoplay: '=',
            initialSlide: '=',
            containerCls: '@',
            wrapperCls: '@',
            paginationCls: '@',
            slideCls: '@',
            direction: '@',
            swiper: '=',
            overrideParameters: '='
        },
        link: ( scope: SwiperContainerScope, element) => {
            const uuid = scope.swiper_uuid;
            const paginatorId = 'paginator-' + uuid;
            const prevButtonId = 'prevButton-' + uuid;
            const nextButtonId = 'nextButton-' + uuid;
            const scrollBarId = 'scrollBar-' + uuid;

            const containerElement = element[0];

            angular.element(containerElement.querySelector('.swiper-pagination'))
                .attr('id', paginatorId);

            angular.element(containerElement.querySelector('.swiper-button-next'))
                .attr('id', nextButtonId);

            angular.element(containerElement.querySelector('.swiper-button-prev'))
                .attr('id', prevButtonId);

            angular.element(element[0].querySelector('.swiper-scrollbar'))
                .attr('id', scrollBarId);
        }, // end link

        template: '<div class="swiper-container {{containerCls}}">' +
            '<div class="parallax-bg" data-swiper-parallax="{{parallaxTransition}}" ng-show="parallax"></div>' +
            '<div class="swiper-wrapper {{wrapperCls}}" ng-transclude></div>' +
            '<div class="swiper-pagination {{paginationCls}}"></div>' +
            '<div class="swiper-button-next" ng-show="showNavButtons"></div>' +
            '<div class="swiper-button-prev" ng-show="showNavButtons"></div>' +
            '<div class="swiper-scrollbar" ng-show="showScrollBar"></div>' +
            '</div>'
        }; // end return
    }
}
