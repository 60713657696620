import app from '../../app';

import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';

import { DesignConfigurationService } from '../../design/designConfiguration.service';
import { optionPanes } from './options.routes';

@app.inject(DesignConfigurationService, '$state', '$timeout').service
export class OptionsService {
  constructor(
    private designConfigurationService: DesignConfigurationService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService
  ) { }

  /**
   * Takes the user to the next option pane after a given delay.
   * If the current pane is the last one, goes to the summary.
   */
  nextOption() {
    this.$timeout(() => this.moveToNext(), 2000);
  }

  private moveToNext() {
    this.$state.go('^');

    // The code below will move to the next available options pane.

    // const configuration = this.designConfigurationService.designConfiguration;
    // const id = this.$state.current.name.replace('configuration.tab.options.', '');
    // const index = findIndex(optionPanes, p => p.id === id);
    // let state = 'configuration.tab.summary';
    // for (let i = index + 1; i < optionPanes.length; i++) {
    //   if (!includes(optionPanes[i].hide, configuration.type)) {
    //     state = 'configuration.tab.options.' + optionPanes[i].id;
    //     break;
    //   }
    // }
    // this.$state.go(state);
  }
}
