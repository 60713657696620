import { Product } from './product';

export class SampleProduct extends Product {

 constructor(data: any) {
  super(data);

 }

 /**
  * URLs of images to be shown in detail views
  *
  * @readonly
  */
 get imagesForDetails() {
   return this.thumbnailUrl;
 }

 get displayTitle() {
  return this.name;
 }
 // used so sample.service.ts will compile .. think this is used with virto only and
 // therefore no longer used if ever
 get catalogId(){
   return -1;
 }
 // used so sample.service.ts will compile .. think this is used with virto only and
 // therefore no longer used if ever
 get imgSrc(){
   return '';
 }

 guaranteedPrice(): ng.IPromise<number> {
  return this.basePrice();
 }

}
