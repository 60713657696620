import app from '../../app';

@app.service
export class SaveDesignStep2Service {
  visible = false;
  initCount = 0;
  /* passes form entries from last screen to component */
  email: string;
  firstname: string;
  lastname: string;

  private visibleCount = 0;

  show( email: string, firstname: string, lastname: string ) {
    this.email = email;
    this.firstname = firstname;
    this.lastname = lastname;

    this.visibleCount++;
    this.visible = true;
  }

  hide() {
    this.initCount = 0;
    this.visible = false;
  }
}
