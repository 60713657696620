import { EdgeFinishProduct } from '../../../api-shopify/product-models/edge-finish-product';
import { BaseLine, linesTable, ConfigLineKinds } from './baseLine';

export interface EdgeFinishLineData {
  code?: string;
}

export class EdgeFinishLine extends BaseLine<EdgeFinishLineData> {
  kind = ConfigLineKinds.EdgeFinish;
  category = 'Options';
  name = 'Edge Finishing';
  order = 10;
  editState = 'configuration.tab.options.edge-finishing';
  product: EdgeFinishProduct;
  scrollToElementId = 'edge-finish-option-scroll-to';

  get valid() { return !!this.product; }

  deserialize(data?: EdgeFinishLineData) {
    this.data = data;
    if (this.data && this.data.code) {
      return EdgeFinishProduct.get(this.data.code).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: EdgeFinishProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.EdgeFinish] = EdgeFinishLine;
