import app from '../../app';
import FrameController from './frame/frame.controller';
import ArtController from './art/art.controller';
import LinerController from './liner/liner.controller';
import MirrorController from './mirror/mirror.controller';

app.inject(
  '$stateProvider'
).config((
  $stateProvider: ng.ui.IStateProvider
) => {

  $stateProvider
    .state('configuration.tab.design.frame', {
      url: window.fmtvApp.standalone && '/frame',
      views: {
        'right-bar@layout': {
          templateUrl: 'configurator/configuration/design/frame/frame.html',
          controller: FrameController,
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        $title: () => 'Frame Style'
      }
    })
    .state('configuration.tab.design.art', {
      url: window.fmtvApp.standalone && '/art',
      params: {
        category: null,
      },
      views: {
        'right-bar@layout': {
          templateUrl: 'configurator/configuration/design/art/art.html',
          controller: ArtController,
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        $title: () => 'Artwork'
      }
    })
    .state('configuration.tab.design.liner', {
      url: window.fmtvApp.standalone && '/liner',
      views: {
        'right-bar@layout': {
          templateUrl: 'configurator/configuration/design/liner/liner.html',
          controller: LinerController,
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        $title: () => 'Liner Color'
      }
    })
    .state('configuration.tab.design.mirror', {
      url: window.fmtvApp.standalone && '/mirror',
      views: {
        'right-bar@layout': {
          templateUrl: 'configurator/configuration/design/mirror/mirror.html',
          controller: MirrorController,
          controllerAs: '$ctrl'
        }
      },
      resolve: {
        $title: () => 'Mirror'
      }
    });
});
