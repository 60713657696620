import app from '../../../app';
import { InstallationLineData } from '../../../design/lines';

@app.inject('$mdDialog', '$state', '$scope', 'installation').controller
export default class RecessController {

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $state: ng.ui.IStateService,
    $scope: ng.IScope,
    public installation: InstallationLineData
  ) {
    $scope.$on('$stateChangeStart', () => {
      $mdDialog.hide();
    });
  }

  $onInit() { }

  close() {
    this.$mdDialog.hide();
  }

}
