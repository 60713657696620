import { MeasurementsService } from '../../services/measurements.service';
import { CutGlassProduct } from '../../../api-shopify/product-models/cut-glass-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import some from 'lodash/some';
import find from 'lodash/find';
import app from '../../app';

export interface CutGlassLineData {
  code?: string;
  system?: 'inches' | 'mm';
  width?: number;
  height?: number;
}

export class CutGlassLine extends BaseLine<CutGlassLineData> {
  kind = ConfigLineKinds.CutGlass;
  category = 'Design';
  name = 'Cut Glass';
  order = 1; // changed this from 90  so that the  'design' section comes before the 'options' section in the product config
  editState = 'configuration.tab.design';
  scrollToElementId = 'cut-glass-option-scroll-to';

  product: CutGlassProduct;

  surface: number;
  pricePerSqft: number;

  @app.inject(MeasurementsService).property
  private measurementsService: MeasurementsService;

  get valid() { return this.product && isFinite(this.data.width) && isFinite(this.data.height); }

  deserialize(data?: CutGlassLineData) {
    this.data = data || {};

    if (data && data.code) {
      CutGlassProduct.get(data.code).then(p => this.setProduct(p, true));
    }

    return this.q.resolve();
  }

  setProduct(product: CutGlassProduct, noTrigger = false) {
    if (!this.data) {
      this.data = {};
    }

    this.product = product;
    this.data.code = product && product.code;

    this.preview = {
      // this is a bit of a hack to set the glass image for all cutglass products

      // image: product.previewUrl,
      image :  'https://cdn.shopify.com/s/files/1/0011/6208/4407/products/opticlear_mirror-sample_2000x_2048x2048_e6c84ef2-5747-450d-b436-a83ee6868f7a_grande.jpg?v=1560382720',
      index: 2
    };

    return this.refreshPrice().then(() => {
      this.setDescription();
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  update(data: CutGlassLineData) {
    this.data = data;
    this.refreshPrice().then(() => {
      this.setDescription();
      this.triggerUpdate();
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product && isFinite(this.data.width) && isFinite(this.data.height)) {
      return this.product.guaranteedPrice().then(p => {
        let width = this.data.width;
        let height = this.data.height;
        if (this.data.system === 'mm') {
          width = this.measurementsService.mmToInches(width);
          height = this.measurementsService.mmToInches(height);
        }
        if ((some(this.product.maxSize, s => s < height) && some(this.product.maxSize, s => s < width)) ||
          some([width, height], dim => dim > Math.max(this.product.maxSize[0], this.product.maxSize[1]))) {
          p = p * 1.25;
        }
        this.pricePerSqft = p;

        width = this.measurementsService.inchesToFoot(width);
        height = this.measurementsService.inchesToFoot(height);
        this.surface = Math.ceil(width * height);

        this.price = this.pricePerSqft * Math.max(this.surface, 5);
      });
    }
    this.surface = 0;
    this.price = null;
    return this.q.resolve();
  }

  serialize() {
    let line = super.serialize();
    line.data = this.data;
    return line;
  }

  private setDescription() {
    if (!this.valid) {
      this.description = '';
      return;
    }

    let t = `Glass: ` + this.product.name;

    if (this.data.system === 'mm') {
      t += `<br>Width: ${this.data.width}mm, Height: ${this.data.height}mm`;
      t += `<br>or ${this.measurementsService.formatInches(this.measurementsService.mmToInches(this.data.width))} x ${this.measurementsService.formatInches(this.measurementsService.mmToInches(this.data.height))}`;
    }
    else {
      t += `<br>Width: ${this.measurementsService.formatInches(this.data.width)}, Height: ${this.measurementsService.formatInches(this.data.height)}`;
    }

    t += `<br>${this.surface} sqft at $${this.pricePerSqft}`;
    if (this.surface < 5) {
      t += ' (min 5 sqft)';
    }

    this.description = t;
  }
}

linesTable[ConfigLineKinds.CutGlass] = CutGlassLine;
