import angular from 'angular';
import find from 'lodash/find';
import app from '../app';
import { tiles } from '../configuration/frameType/tiles';
import RushController from '../configuration/summary/rush.controller';
import { DesignConfiguration } from '../design/designConfiguration';
import { DesignConfigurationService } from '../design/designConfiguration.service';
import { DesignTypes } from '../design/designTypes';
import { BrandingService } from '../services/branding.service';
import { BrandingInfo } from '../services/brandingInfo';
import { LoadingIndicatorService } from '../loadingIndicator/loadingIndicator.service';
import isEmpty from 'lodash/isEmpty';
import { SwymWishListService } from '../services/swymWishList.service';
import { CartService } from '../../configurator/cart/cart.service';
import { SaveDesignService } from '../configuration/save-design/save-design.service';
import SummaryController from '../configuration/summary/summary.controller';
import { PasswordProtectGlassDialogController } from './password-protect-glass-dialog.controller';
@app.inject(
  BrandingService,
  DesignConfigurationService,
  '$element',
  '$mdMedia',
  '$mdDialog',
  '$state',
  '$rootScope',
  LoadingIndicatorService,
  '$scope',
  'SwymWishListService',
  CartService,
  SaveDesignService,
  '$filter',
  '$location'
).component({
  templateUrl: 'configurator/summary-bar/summary-bar.html'
})
export class SummaryBar {
  showFinancingBanner = false;
  brandingInfo: BrandingInfo;
  configuration: DesignConfiguration;
  typeDropdown = false;
  cartItemCounter: string = '0';
  designsCounter: string = '0';
  designTypes = [
    {
      type: DesignTypes.FrameWithArt,
      label: 'tv ART',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_1.jpg'
    },
    {
      type: 'section-header',
      label: 'Legacy (Discontinued)',
      image: '',
    },
    {
      type: DesignTypes.FrameWithMirror,
      label: 'tv MIRROR',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_3.jpg'
    },
    {
      type: DesignTypes.MetroMirror,
      label: 'METRO tv MIRROR',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_7.jpg'
    },
    {
      type: DesignTypes.FrameOnly,
      label: 'tv FRAME',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_2.jpg'
    },
    {
      type: DesignTypes.FramelessMirror,
      label: 'FLOATING tv MIRROR',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_9.jpg'
    },
    {
      type: DesignTypes.CutGlass,
      label: 'tv MIRROR glass',
      image: window.fmtvAssetsRoot + 'assets/configurator/frameTypes/image_8.jpg'
    }
  ];
  private _showExpedite: boolean;
  private configToSearch: string;

  get disabled() { return this.$state.current.name === 'configuration.frameType'; }

  constructor(
    private brandingService: BrandingService,
    private configurationService: DesignConfigurationService,
    private $element: ng.IAugmentedJQuery,
    private $mdMedia: ng.material.IMedia,
    private $mdDialog: ng.material.IDialogService,
    private $state: ng.ui.IStateService,
    private $rootScope: ng.IRootScopeService,
    private loadingIndicator: LoadingIndicatorService,
    private $scope: ng.IScope,
    private swymWishListService: SwymWishListService,
    private cartService: CartService,
    private saveDesignService: SaveDesignService,
    private $filter: ng.IFilterService,
    private $location: ng.ILocationService
  ) {
    brandingService.infoPromise.then(info => this.brandingInfo = info);
    $rootScope.$watch(() => this.configurationService.designConfiguration.type, (configType, oldVal) => {
      if (!isEmpty(configType)) {
        // Only display the financing banner across the top for Framed TV Mirrors and TV Art Cover product types
        if ( configType === DesignTypes.FrameWithArt || configType === DesignTypes.FrameWithMirror ) {
          this.showFinancingBanner = true;
        } else {
          this.showFinancingBanner = false;
        }
        this._showExpedite = this.configurationService.designConfiguration.type !== DesignTypes.CutGlass;
      }
    }, true);

    $rootScope.$on('wishlistIsDirty', (e, newSkuList) => {
      this.getDesignsCount();
    });

    this.forceCartCountUpdate();
    this.getDesignsCount();
    // forces a count update when the app triggers an add to count
    $rootScope.$on('force-cart-count-update',  () => {
      this.forceCartCountUpdate();
    });
  }

  $onInit() {
    this.configuration = this.configurationService.designConfiguration;
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.$element.find('.trustpilot-widget').get(0));
    }
  }
  shipDateSpan() {
    const monthsString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // 14-20 weeks
    const now = new Date();
    //                          14 weeks in minutes
    const ms14 = now.getTime() + (141120 * 60 * 1000);
                      // 20 weeeks in minutes
    const ms20 = now.getTime() + (201600 * 60 * 1000);
    const startingIdx =  new Date(ms14 ).getMonth();
    const endingIdx = new Date(ms20).getMonth();
    const dateString  = monthsString[startingIdx] + '-' + monthsString[endingIdx];
    return dateString;
  }

  getDateEndOfCurMonth(){
    const now = new Date();
    const endOfMonth =  new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    const endOfMonthDate = new Date(now.getFullYear(), now.getMonth(), endOfMonth);
    const dateString  = this.$filter('date')(endOfMonthDate, 'MMM dd yyyy');
    return dateString;
  }

  typeName(code: string) {
    return find(tiles, t => (t.frameType as any) === code).title || 'Select type';
  }

  newDesign() {
    this.configurationService.reset();
    this.$state.go('configuration.frameType', { configurationId: 'new' }, { reload: true });
  }

  save(e: MouseEvent) {
    this.configurationService.showSaveDialog(e);
  }

  link(e: MouseEvent) {
    if (!this.configuration.isNew) {
      if ( this.configuration.dirty ) {
        this.$mdDialog.show(
          this.$mdDialog.confirm()
            .parent(angular.element('#fmtv-app'))
            .title('Save Changes')
            .textContent(`Do you wish to save the changes before copying the link? If you answer 'No' the link will load the last saved configuration.`)
            .ok('Yes')
            .cancel('No')
        ).then(() => {
          this.loadingIndicator.show();
          this.configurationService.save().then(() => {
            this.loadingIndicator.hide();
            this.configurationService.showLink(e);
          });
        }, ()  => {
          // show the link for an already saved config
          this.configurationService.showLink(e);
        });
      }  else {
        this.configurationService.showLink(e);
      }
    }
    else {
      this.loadingIndicator.show();
      this.configurationService.save().then(() => {
        this.loadingIndicator.hide();
        this.configurationService.showLink(e);
      });
    }
  }

  myDesigns() {
    if (!window.parent.postEventMessage) {
      alert('This only works from an iframe');
    }
    else {
      window.parent.postEventMessage({ message: 'openMyContent' });
    }
  }
  showSaveDesign(){
    this.saveDesignService.show();
  }

  setRush(ev: MouseEvent) {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    this.$mdDialog.show({
      controller: RushController,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/configuration/summary/rush.html',
      parent: angular.element('#fmtv-app'),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: useFullScreen
    });
  }
  showExpedite() {
    // hide for cut glass
    return this._showExpedite;
  }
  switchType(type: DesignTypes | string) {
    if ( type === 'section-header' ) {
      return;
    }
    if (  type === DesignTypes.MetroMirror ||
          type === DesignTypes.FrameWithMirror ||
          type === DesignTypes.FramelessMirror ||
          type === DesignTypes.CutGlass ||
          type === DesignTypes.FrameOnly ){
      this.typeDropdown = false;
      this.$mdDialog.show({
        templateUrl: 'configurator/summary-bar/password-protect-glass-dialog.html',
        controller: PasswordProtectGlassDialogController,
        controllerAs: '$ctrl',
        parent: angular.element('#fmtv-app'),
        clickOutsideToClose: true,
        fullscreen: true
      }).then((result: string) => {

          if (result === 'password_confirmed') {
            this.configuration.switchType(type);
          }
      });
    } else {
      this.typeDropdown = false;

      this.$mdDialog.show(
        this.$mdDialog.confirm()
          .parent(angular.element('#fmtv-app'))
          .title('Confirm action')
          .textContent(`Are you sure you wish to switch the product category?`)
          .ok('Yes, continue')
          .cancel('No')
      ).then(() => {
        this.configuration.switchType(type);

      });
    }
  }

  displaySaveDesign(){
    if ( this.configuration.dirty ){
      /*
      const element = angular.element(document.getElementById('btn-save-design'));
      element.addClass('heartbeat');
      setTimeout( () => {
        element.removeClass('heartbeat');
      }, 3000); // animation length
      */
      return true;
    } else {
      return false;
    }
  }

  financingPrice(price: number) {
    return (price * 1.1) / 36;
  }

  showMyDesigns(){
    this.swymWishListService.showFavoritesPopup();
  }
  getDesignsCount(){
    this.swymWishListService.getDesignsCount().then( count => {
      this.designsCounter = count;
    });
  }
  openMiniCart(){
    this.$rootScope.$broadcast('toggle-mini-cart');
  }
  openShopifyDesign( designId: string ){
    // https://framemytv.com/products/s201110-6496
    if ( this.configuration.number && this.configuration.number !== 'new' ) {
      window.open(`https://framemytv.com/products/${this.configuration.number}`,'_blank');
    }
  }
  openDesignSearchDialog() {
    this.$mdDialog.show({
      controller: SummaryBar,
      controllerAs: '$ctrl',
      templateUrl: 'configurator/summary-bar/search-config-dialog.html',
      parent: angular.element('#fmtv-app'),
      // targetEvent: e,
      clickOutsideToClose: false,
    }
    ).then((xx) => {

    });
  }
  closeDesignSearchDialog(){
    this.$mdDialog.hide();
  }
  openConfigId(){
    if ( this.configToSearch ) {
      const url =  this.$state.href('configuration', { configurationId: this.configToSearch });
      window.location.href = url + '/design?t=' + new Date().getTime();
    }
  }
  disableSelection( selection: any ) {
    if (
          selection.type === DesignTypes.MetroMirror ||
          selection.type === DesignTypes.CutGlass ||
          selection.type === DesignTypes.FramelessMirror ||
          selection.type === DesignTypes.FrameOnly ) {
      return true;
    }
    return false;
  }
  private forceCartCountUpdate(){
    this.cartService.getCartCount().then( (count: string) => {
      this.cartItemCounter = count;
    });
  }
}
