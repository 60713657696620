import app from '~/configurator/app';

@app.component({
  templateUrl: 'configurator/overlay-tab/overlay-tab.component.html'
})
export class OverlayTab {
  get href() {
    return /framemytv\.com(?!\/apps\/designer)/.test(document.referrer) ? document.referrer : 'https://framemytv.com';
  }
}
