import app from '../../../app';

@app.inject('$mdDialog').controller
export class ConfirmTvModel {
  constructor(
    private $mdDialog: ng.material.IDialogService
  ) {
  }

  $onInit() { }

  specify() {
    this.$mdDialog.hide(true);
  }

  later() {
    this.$mdDialog.hide(false);
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
