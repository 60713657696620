import { BrandingInfo } from '../../services/brandingInfo';
import app from '../../app';
import { BrandingService } from '../../services/branding.service';
import { AuthenticationService } from '../authentication.service';

const pages = {
  login: 'configurator/user/login/_login.html',
  register: 'configurator/user/login/_register.html',
  forgotPassword: 'configurator/user/login/_forgotPassword.html',
  emailSent: 'configurator/user/login/_emailSent.html'
};

@app.inject(
  '$mdDialog',
  '$mdToast',
  AuthenticationService,
  BrandingService,
  '$http',
  'message'
).controller
export default class LoginController {

  page = pages.login;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;

  vendor = true;

  error: string;

  loading = false;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdToast: ng.material.IToastService,
    private authentication: AuthenticationService,
    brandingService: BrandingService,
    private $http: ng.IHttpService,
    public message: string
  ) {
    brandingService.infoPromise.then(info => this.vendor = info.id !== '0');
  }

  $onInit() { }

  toggleRegister() {
    this.page = pages.register;
  }

  toggleForgotPassword() {
    this.page = pages.forgotPassword;
  }

  sendEmail() {
    this.loading = true;
    this.$http.post('/account/forgotpassword', {
      Email: this.userName
    }).then(() => {
      this.loading = false;
      this.page = pages.emailSent;
    }, () => {
      this.loading = false;
      this.error = 'An unexpected error has occurred. Please try again later.';
    });
  }

  login(hideToast = false) {
    this.error = null;
    this.loading = true;
    this.authentication.login(this.userName, this.password).then(() => {
      this.$mdDialog.hide();
      if (!hideToast) {
        this.$mdToast.showSimple('Login successful');
      }
    }, (error: any) => {
      this.loading = false;
      this.error = 'The email or password provided is invalid. Please verify your credentials and try again.';
    });
  }

  register() {
    this.loading = true;
   /* this.authentication.register({
      email: this.email,
      password: this.password,
      phone: this.phone,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company
    }).then(() => {
      this.$mdDialog.hide();
      this.$mdToast.showSimple('Registration successful');
    }, (error: any) => {
      this.loading = false;
      this.error = 'An unexpected error has occurred. Please try again in a few minutes and contact us if the problem persists.';
    });
    */
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
