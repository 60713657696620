import app from '../app';
// import { PricingService } from '../services/pricing.service';

// tslint:disable-next-line:only-arrow-functions
// app.inject(PricingService, '$filter').filter(function price(
app.inject( '$filter').filter(function price(
  // $ftvPricing: PricingService,
  $filter: ng.IFilterService
) {
  let fn = (input: string, fractionSize?: number): string | number => {
  let p = parseInt(input);
  let adjustment = p; // $ftvPricing.adjustPrice(p);
  // if (adjustment === '') {
  //  return 'Call for price';
  // }
  return $filter('currency')(<number>adjustment, '$', fractionSize);
  // return '$999999';
  };

  fn.$stateful = true;

  return fn;
});
