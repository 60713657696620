import app from '../../app';
import { SaveDesignTimerService } from '../../configuration/save-design/save-design-timer.service';
import { IDesignConfiguration } from '~/api-shopify/product-models/product';
import { DesignConfigurationService } from '../designConfiguration.service';
import { DesignTypes } from '../designTypes';
import { config } from 'process';
import ConfigurationLibraryController from '~/configurator/configurationLibrary/configurationLibrary.controller';
import LayoutController from '~/configurator/layout/layout.controller';

/**
 * Identifies the type of item on a configuration line
 */
export enum ConfigLineKinds {
  TvModel,
  Frame,
  Artwork,
  Liner,
  ArtCover,
  Mirror,
  TvControl,
  ArtControl,
  SideDepth,
  SpeakerBar,
  Installation,
  Rush,
  Custom,
  Shipping,
  CutGlass,
  GlassOptions,
  EdgeFinish,
  BackingOption,
  MountingOption,
  CutGlassAccessory,
  ArtWarranty,
  MirrorWarranty
}

export let linesTable: { [kind: string]: any } = {};
export type UpdateCallback = (line: BaseLine<any>) => void;

export interface Line {
  /**
   * arbitrary value to uniquely identify the line
   */
  id: string;

  /**
   * Used for grouping the lines
   */
  category: string;

  /**
   * Generic name for the line
   */
  name: string;

  /**
   * Description of the line based on the user's choice
   */
  description: string;

  /**
   * Price of the line
   */
  price?: number;

  /**
   * Design preview information
   */
  preview?: {
    /**
     * URL of the image to show as preview
     */
    image?: string

    /**
     * z-index of the image, higher numbers are on top
     */
    index?: number
  };

  /**
   * Used to sort the items
   */
  order: number;

  /**
   * Type of data on this line
   */
  kind: ConfigLineKinds;

  /**
   * Custom data specific to the line's kind
   */
  data: any;

  editPath?: any;

  update?(newData: any): void;

  onUpdate?(callback: UpdateCallback): void;

  refreshPrice?(): ng.IPromise<any>;

  serialize?(): Line;
}

export abstract class BaseLine<T> implements Line {

  // this is set by the designConfiguration when 'changeType' is called
  public static updatesToCompleteTypeChange: number = 0;
  public static totalChanges: number = 0;
  public static saveDesignWasShown: boolean = false;
  public static lastDesignType: DesignTypes;

  id = 'TMP' + Date.now() + Math.floor(Math.random() * 3000 + 1000).toString();
  category: string;
  name: string;
  description: string;
  price?: number;
  preview?: {
    image?: string
    index?: number
  };
  order: number;
  kind: ConfigLineKinds;
  data: T;
  scrollToElementId: string; // used to scroll to a particular section in the UI when a config option is incomplete
  /**
   * Router state that will let the user edit this line
   */
  editState?: string;

  protected updated: UpdateCallback;

  @app.inject('$q').property
  protected q: ng.IQService;

  @(app.inject('DesignConfigurationService').property )
  protected designService: DesignConfigurationService;

  @(app.inject(SaveDesignTimerService).property)
  private saveDesignTimerService: SaveDesignTimerService;

  abstract get valid(): boolean;

  abstract refreshPrice(): ng.IPromise<any>;
  abstract deserialize(data: T): ng.IPromise<void>;

  /*
   *
   * FrameOnly = <any>'frame only',
   * FrameWithMirror = <any>'frame with mirror',
   * FrameWithArt = <any>'frame with art',
   * FramelessMirror = <any>'frameless mirror',
   * MetroMirror = <any>'metro mirror',
   * MetroArt = <any>'metro art',
   * CutGlass = <any>'cut glass',
   */
  triggerUpdate() {
    if (this.updated) {
      const changesSoFar = BaseLine.totalChanges;

      // total changes registered when changing config type
      // the defaults et make these changes
      // NOTE total changes are not the same when 'changing config type' versus 'new config type'

      // new config type
      // frame with art  = 6 when config changed
      // frame with mirror  = 6
      // frameless mirror 3
      // frame only 4
      // metro mirror 5
      // cut glass 1
      let frameChanged = false;
      let artChanged = false;
      let linerChanged = false;
      let mirrorChanged = false;

      let configuration = this.designService.designConfiguration;
      const configType = configuration.type;
     // if ( BaseLine.lastDesignType !== configType) {
     //   BaseLine.lastDesignType = configType;
     //   BaseLine.designWasSaved = false;
     // }

      if ( configuration.isNew || (!configuration.isNew && configuration.dirty )) {
        // if frame
        if (configType === DesignTypes.FrameWithArt || configType === DesignTypes.FrameOnly || configType === DesignTypes.FrameWithMirror
            || configType === DesignTypes.FrameWithMirror || configType === DesignTypes.MetroMirror || configType === DesignTypes.MetroArt) {
          let defaultSku = 'FRAME-M1011';
          if ( configType === DesignTypes.MetroMirror || configType === DesignTypes.MetroArt ) {
            defaultSku = 'FRAME-M3012';
          }

          if (configType === DesignTypes.FrameOnly || configType === DesignTypes.FrameWithMirror ){
            defaultSku = 'FRAME-M4101';
          }
          if ( configuration.frame && configuration.frame.frameProduct && configuration.frame.frameProduct.sku !== defaultSku) {
            frameChanged = true;
          }
        }
        // if art
        if (configType === DesignTypes.FrameWithArt || configType === DesignTypes.MetroArt ) {
          if ( configuration.artwork && configuration.artwork.product && configuration.artwork.product.sku !== 'ART-8175'){
            artChanged = true;
          }
        }
        // if liner
        if ( configuration.liner && configuration.liner.product && configuration.liner.product.sku ) {
            if (configType === DesignTypes.FrameWithArt  ) {
                if ( configuration.frame && configuration.frame.product && !configuration.frame.product.isHardwood()){
                  if ( configuration.liner.product.sku !== 'LINER-3'){
                    linerChanged = true;
                  }
                } else if ( configuration.frame && configuration.frame.product && configuration.frame.product.isHardwood() && configuration.liner.product.sku !== 'LINER-5'){ // no liner
                    linerChanged = true;
                }
            } else if (configType === DesignTypes.FrameWithMirror  ) {
                if ( configuration.liner.product.sku !== 'LINER-3'){
                  linerChanged = true;
                }
            }
        }

        // if mirror
        if (configType === DesignTypes.FrameWithMirror || configType === DesignTypes.FramelessMirror || configType === DesignTypes.MetroMirror ) {
          if ( configuration.mirror && configuration.mirror.product && configuration.mirror.product.sku !== 'MIRROR-OPTICLEAR-ULTRA'){
              mirrorChanged = true;
          }
        }
      }

      if ( (configuration.isNew && !BaseLine.saveDesignWasShown) || (!configuration.isNew && configuration.dirty && !BaseLine.saveDesignWasShown)) {
        if ( configType === DesignTypes.FrameWithArt ) {
          if ( ( artChanged && frameChanged && linerChanged ) ||
             ( !artChanged && frameChanged && linerChanged ) ||
             ( artChanged && !frameChanged && linerChanged ) ||
             ( artChanged && frameChanged && !linerChanged ) ) {
            this.saveDesignTimerService.starTimer();
          }
        } else if ( configType === DesignTypes.FrameWithMirror ) {
          // likely could use default glass so don't track
          if ( ( frameChanged && linerChanged && mirrorChanged ) ||
             ( !frameChanged && linerChanged && mirrorChanged ) ||
             ( frameChanged && !linerChanged && !mirrorChanged ) ||
             ( frameChanged && linerChanged && !mirrorChanged ) ) {
            this.saveDesignTimerService.starTimer();
          }
        } else if ( configType === DesignTypes.MetroMirror  ) {
          if ( frameChanged || mirrorChanged ) {
            this.saveDesignTimerService.starTimer();
          }
        } else if ( configType === DesignTypes.FramelessMirror ) {
          if ( mirrorChanged || ( this.name === 'Installation Method' && (this.data as any).code ) ) {
            this.saveDesignTimerService.starTimer();
          }
        } else if ( configType === DesignTypes.FrameOnly ) {
          if ( frameChanged || linerChanged ) {
            this.saveDesignTimerService.starTimer();
          }
        } else if ( configType === DesignTypes.CutGlass ) {
          if ( this.name === 'Cut Glass') {
            if ( this.data && (this.data as any).width && ( this.data as any).height ) {
              this.saveDesignTimerService.starTimer();
            }
          }
        } else {
          this.saveDesignTimerService.resetOpenTimer();
        }
      }

/*
      BaseLine.totalChanges += 1;
      if ( BaseLine.totalChanges <= 1 ) {
          this.saveDesignTimerService.stopTimer();
      } else if (BaseLine.totalChanges > 1 ){
        this.saveDesignTimerService.starTimer();
      }
*/
      // so can track the required lines from the designConfiguration.ts

      const requiredLines = this.designService.designConfiguration.lineTypesForCategory[configType];

      if ( this.kind === ConfigLineKinds.TvModel) {

      }
     // this.saveDesignTimerService.resetOpenTimer();

      // user reverted to a saved item
      //  this.saveDesignTimerService.stopTimerAndClose();
      this.updated(this);
    }
  }

  onUpdate(callback: UpdateCallback) {
    this.updated = callback;
  }

  serialize(): Line {
    return {
      id: this.id,
      category: this.category,
      name: this.name,
      description: this.description,
      price: this.price,
      preview: this.preview,
      order: this.order,
      kind: this.kind,
      data: this.data
    };
  }
}
