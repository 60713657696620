import lib from '~/ui/ui.module';

@lib.inject('$element', '$scope', '$animate').component({
  templateUrl: 'ui/popup/popup.html',
  transclude: true,
  bindings: {
    show: '='
  }
})
export class Popup {

  show: boolean;

  constructor(
    private $element: ng.IRootElementService,
    private $scope: ng.IScope,
    private $animate: any
  ) {}

  $onInit() {
    let parent = this.$element.parent();
    // this.$element.appendTo(document.body);

    this.$animate.pin(this.$element, parent);

    // this.$scope.$on('$destroy', () => {
    //   this.$element.remove();
    // });

    this.$scope.$watch(() => this.show, show => {
      if (show) {
        $(document.body).addClass('pb-noscroll');
      }
      else {
        $(document.body).removeClass('pb-noscroll');
      }
    });
  }

  close() {
    this.show = false;
  }
}
