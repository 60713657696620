import { SideDepthProduct } from '../../../api-shopify/product-models/side-depth-product';
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';

export interface SideDepthLineData {
  code?: string;
  id?: string;
  name?: string;
}

export class SideDepthLine extends BaseLine<SideDepthLineData> {
  kind = ConfigLineKinds.SideDepth;
  category = 'Options';
  name = 'Side Depth';
  order = 80;
  editState = 'configuration.tab.options.depth';
  product: SideDepthProduct;
  scrollToElementId = 'side-depth-option-scroll-to';

  get valid() { return !!this.product; }

  deserialize(data?: SideDepthLineData) {
    this.data = data;
    if (this.data && (this.data.code || this.data.id)) {
      return SideDepthProduct.get(this.data.code || this.data.id).then(p => this.setProduct(p, true));
    }
    return this.q.resolve();
  }

  setProduct(product: SideDepthProduct, noTrigger = false) {
    this.description = product.name;
    this.product = product;
    this.data = {
      code: product.code,
      name: product.name
    };
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => this.price = p);
    }
    return this.q.resolve();
  }
}

linesTable[ConfigLineKinds.SideDepth] = SideDepthLine;
