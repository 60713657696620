import app from '../app';

@app.inject('$mdDialog').controller
export class PasswordProtectGlassDialogController {
  password: string;
  constructor(
    private $mdDialog: ng.material.IDialogService
  ) { }

  $onInit() { }

  cancel() {
    this.$mdDialog.cancel();
  }

  ok() {
    if ( this.password === 'FMTV') {
      this.$mdDialog.hide('password_confirmed');
    } else {
      this.$mdDialog.cancel();
    }
  }
}
