import app from '../app';
import angular from 'angular';

@app.inject('$window', '$timeout').directive('hScroll')
export class FixToTop {
  definition(
    $window: ng.IWindowService,
    $timeout: ng.ITimeoutService
  ) {
    let $win = angular.element($window); // wrap window object as jQuery object
    return {
      restrict: 'A',
      link: (scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) => {
        let offset = attrs['hScroll'];     // get initial scroll length until it can be determined from loaded images
        let scrollContainer = element[0].querySelector('.thumb-slider');
        let width = 100;
        setTimeout(() => {
          let images = element[0].querySelectorAll('.thumb-img');
          let selectedIdx = -1;
          for (let i = 0; i < images.length; i++) {
            if (images[i].className === 'thumb-img') {
              width = (images[i] as any).width;
            } else if (images[i].className === 'thumb-img selected' || images[i].className === 'thumb-img liner selected') {
              selectedIdx = i;
              break;
            }
          }

          if (selectedIdx) {
            (scrollContainer as any).scrollLeft += Number(width * selectedIdx);
          }
        }, 1500);

        (scope as any).canScrollRight = () => {
          const result = Math.abs( (scrollContainer as any).scrollWidth -  (scrollContainer as any).clientWidth ) - 10 >  (scrollContainer as any).scrollLeft;
          return result;
        },
          (scope as any).canScrollLeft = () => {
            return (scrollContainer as any).scrollLeft > 0;
          },
          (scope as any).scrollbarShowing = () => {
            return (scrollContainer as any).scrollWidth > (scrollContainer as any).clientWidth;
          },
          (scope as any).scrollToRight = () => {
            (scrollContainer as any).scrollLeft += Number(width);
            // if right at the end, scroll to the end
            if ((scrollContainer as any).scrollLeft > Math.abs( (scrollContainer as any).scrollWidth -  (scrollContainer as any).clientWidth ) - width ) {
              (scrollContainer as any).scrollLeft = Math.abs( (scrollContainer as any).scrollWidth -  (scrollContainer as any).clientWidth );
            }
          },
          (scope as any).scrollToLeft = () => {
            (scrollContainer as any).scrollLeft -= Number(width);
          };
      }
    };
  }
}
