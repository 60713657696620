import { AuthenticationService } from '../../../user/authentication.service';
import { FinishProduct } from '../../../../api-shopify/product-models/finish-product';
import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { FrameProduct } from '../../../../api-shopify/product-models/frame-product';

@app.inject(
  '$http',
  AuthenticationService,
  '$scope'
).component({
  bindings: {
    preview: '=',
    frameStyle: '=',
    finish: '<',
    configuration: '=',
    onSelected: '&'
  },
  templateUrl: 'configurator/configuration/design/frame/frameDetails.html'
})
export class FrameDetails {
  preview: boolean;
  frameStyle: FrameProduct;
  configuration: DesignConfiguration;
  onSelected: () => void;
  finish: FinishProduct;

  constructor(
    private $http: ng.IHttpService,
    private authenticationService: AuthenticationService,
    $scope: ng.IScope
  ) {
  }

  $onInit() { }

  showSamples() {
    return !this.authenticationService.restricted();
  }

  select() {
    // for hardwood the frame + style
    // so if there is a this.finish, it includes the sku of the framestyle and the 'framestyle' is a preselected
    // sku used to make an image
    if ( this.finish) {
      const sku = this.finish.sku;
      FrameProduct.get( this.finish.sku ).then( (frame: FrameProduct) => {
        this.frameStyle = frame;
        // this.finish = null;
        this.configuration.frame.setFrameProduct(this.frameStyle, this.finish);
        this.onSelected();
      });
    } else {
      this.configuration.frame.setFrameProduct(this.frameStyle, this.finish);
      this.onSelected();
    }
  }
}
