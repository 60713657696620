import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { RightBarService } from '../../../rightBar/rightBar.service';
import { ICategory } from '../../../../ui/categorizedScroller/categorizedScroller.directive';
import { ArtProduct } from '~/api-shopify/product-models/art-product';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
// import { CatalogShopifyModuleApi } from '~/shopify/api/CatalogShopifyModuleApi';
import { ApiShopifyCategoriesService } from '~/api-shopify/categories.service';
import { Dictionary } from 'lodash';
import { Z_NEED_DICT } from 'zlib';
import lib from '~/api-shopify/api-shopify.module';
import { SampleProduct } from '~/api-shopify/product-models/sample-product';
import { SwymWishListService } from '../../../services/swymWishList.service';
import angular, { IWindowService } from 'angular';
import { CategorySelector } from '~/product-browser/browser/product-list/category-selector/category-selector.component';
import { SaveDesignTimerService } from '../../save-design/save-design-timer.service';

/**
 * Describes a line shown on the virtual scrolling frames list
 */
interface Line {
  text?: string;
  description?: string;
  styles?: ArtProduct[];
  categoryId?: string;

  lineNumber?: number;
}

interface Category extends ICategory {
  items?: ArtProduct[];
  code?: string;
  collectionId?: string; // for shopify
  description?: string;
  source?: string; // used while transitioning from shopify to vitro to track where the data is loaded from
  filterValue?: string;
}

interface FiltersCategory {
  label: string;
  filters: FilterEntry[];
  htmlClass: string;
  autoExpand: boolean;
}

interface FilterEntry {
  name: string;
  imageUrl: string;
  filterValue: string;
  selected: boolean;
  count: number;
  collectionId?: string;
}

@app.inject(
  '$stateParams',
  '$mdSidenav',
  RightBarService,
  SaveDesignTimerService,
  'designConfiguration',
  '$scope',
  '$state',
  '$timeout',
  '$mdMedia',
   SwymWishListService,
   '$window',
).controller

export default class ArtController {
  // Kevin - make order =  My Favorites, Category, Color, Subject Matter
  public filterCategories: FiltersCategory[] = [
    {
      label: 'Category',
      htmlClass: 'category',
      filters: [], // will dynamically create this based on the button categories
      autoExpand: false
    },
    {
      label: 'Color',
      htmlClass: 'color',
      filters: [
        { name: 'red', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/red_50x.png', filterValue: 'red', selected: false, count: 0 },
        { name: 'blue', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/blue_50x.png', filterValue: 'blue', selected: false, count: 0 },
        { name: 'white', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/white_50x.png', filterValue: 'white', selected: false, count: 0 },
        { name: 'black', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/black_50x.png', filterValue: 'black', selected: false, count: 0 },
        { name: 'brown', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/brown_50x.png', filterValue: 'brown', selected: false, count: 0 },
        { name: 'yellow', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/yellow_50x.png', filterValue: 'yellow', selected: false, count: 0 },
        { name: 'green', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/green_50x.png', filterValue: 'green', selected: false, count: 0 },
        { name: 'purple', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/purple_50x.png', filterValue: 'purple', selected: false, count: 0 },
        { name: 'orange', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/orange_50x.png', filterValue: 'orange', selected: false, count: 0 },
        { name: 'cool', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/cool_50x.png', filterValue: 'cool', selected: false, count: 0 },
        { name: 'warm', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/warm_50x.png', filterValue: 'warm', selected: false, count: 0 },
        { name: 'dark', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/dark_50x.png', filterValue: 'dark', selected: false, count: 0 },
        { name: 'earthtones', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/earthtones_50x.png', filterValue: 'earthtones', selected: false, count: 0 },
        { name: 'dreary', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/dreary_50x.png', filterValue: 'dreary', selected: false, count: 0 },
        { name: 'vibrant', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/vibrant_50x.png', filterValue: 'vibrant', selected: false, count: 0 },
        { name: 'colorful', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/colorful_50x.png', filterValue: 'colorful', selected: false, count: 0 },
        { name: 'neutral', imageUrl: window.fmtvAssetsRoot + 'assets/configurator/art-filters/neutral_50x.png', filterValue: 'neutral', selected: false, count: 0 },
      ],
      autoExpand: true
    },
    {
      label: 'Subject Matter',
      htmlClass: 'subject-matter',
      filters: [
        { name: 'animal', imageUrl: '', filterValue: 'animal', selected: false, count: 0 },
        { name: 'boat', imageUrl: '', filterValue: 'boat', selected: false, count: 0 },
        { name: 'beach', imageUrl: '', filterValue: 'beach', selected: false, count: 0 },
        { name: 'bird', imageUrl: '', filterValue: 'bird', selected: false, count: 0 },
        { name: 'cactus', imageUrl: '', filterValue: 'cactus', selected: false, count: 0 },
        { name: 'city', imageUrl: '', filterValue: 'city', selected: false, count: 0 },
        { name: 'clouds', imageUrl: '', filterValue: 'clouds', selected: false, count: 0 },
        { name: 'coastal', imageUrl: '', filterValue: 'coastal', selected: false, count: 0 },
        { name: 'desert art', imageUrl: '', filterValue: 'desert-art', selected: false, count: 0 },
        { name: 'dog', imageUrl: '', filterValue: 'dog', selected: false, count: 0 },
        { name: 'flowers', imageUrl: '', filterValue: 'flowers', selected: false, count: 0 },
        { name: 'food', imageUrl: '', filterValue: 'food', selected: false, count: 0 },
        { name: 'forest', imageUrl: '', filterValue: 'forest', selected: false, count: 0 },
        { name: 'horizon', imageUrl: '', filterValue: 'horizon', selected: false, count: 0 },
        { name: 'horse', imageUrl: '', filterValue: 'horse', selected: false, count: 0 },
        { name: 'landscape', imageUrl: '', filterValue: 'landscape', selected: false, count: 0 },
        { name: 'mountain', imageUrl: '', filterValue: 'mountain', selected: false, count: 0 },
        { name: 'ocean', imageUrl: '', filterValue: 'ocean', selected: false, count: 0 },
        { name: 'outdoor', imageUrl: '', filterValue: 'outdoor', selected: false, count: 0 },
        { name: 'people', imageUrl: '', filterValue: 'people', selected: false, count: 0 },
        { name: 'tree', imageUrl: '', filterValue: 'tree', selected: false, count: 0 },
        { name: 'tropical', imageUrl: '', filterValue: 'tropical', selected: false, count: 0 },
        { name: 'sailboat', imageUrl: '', filterValue: 'sailboat', selected: false, count: 0 },
        { name: 'southwest', imageUrl: '', filterValue: 'southwest', selected: false, count: 0 },
        { name: 'wine', imageUrl: '', filterValue: 'wine', selected: false, count: 0 },
        { name: 'water', imageUrl: '', filterValue: 'water', selected: false, count: 0 },
      ],
      autoExpand: false
    },
  ];
  categoryFilters: FilterEntry[] = [];
  categoryButtons: Category[] = [
    {
      label: 'Expressionist',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Expressionist.jpg?v=1565872707',
      buttonLabel: 'View Collection',
      anchor: 'expressionist',
      description: 'Expressionist Collection',
      collectionId: '75779604535',
      code: 'ART-EXPRESSIONIST'
    },
    {
      label: 'Architectural Abstracts',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Architectural_Abstracts.jpg?v=1565874713',
      buttonLabel: 'View Collection',
      anchor: 'archabstract',
      description: 'Architectural Abstracts Collection',
      collectionId: '75780915255',
      code: 'ART-ARCHITECTABSTRACT'
    },
    {
      label: 'Skylines',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/CityScapes_Skylines.jpg?v=1565872470',
      buttonLabel: 'View Collection',
      anchor: 'skylines',
      description: 'Skylines Collection',
      collectionId: '75780161591',
      code: 'ART-SKYLINES'
    },
    {
      label: 'Oceans',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Oceans.jpg?v=1565873259',
      buttonLabel: 'View Collection',
      anchor: 'oceans',
      description: 'Oceans Collection',
      collectionId: '75779964983',
      code: 'ART-OCEANS',
      filterValue: 'ocean'
    },
    {
      label: 'Landscapes',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Landscapes.jpg?v=1565872858',
      buttonLabel: 'View Collection',
      anchor: 'landscapes',
      description: 'Landscapes Collection',
      collectionId: '75779768375',
      code: 'ART-LANDSCAPES'
    },
    {
      label: 'Flowers',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Flowers.jpg?v=1565872782',
      buttonLabel: 'View Collection',
      anchor: 'flowers',
      description: 'Flowers Collection',
      collectionId: '75779670071',
      code: 'ART-FLOWERS',
      filterValue: 'flowers'
    },
    {
      label: 'Still Life',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/StillLife.jpg?v=1565874410',
      buttonLabel: 'View Collection',
      anchor: 'stilllife',
      description: 'Still Life Collection',
      collectionId: '75780751415',
      code: 'ART-STILLLIFE',
      filterValue: 'flowers'
    },
    {
      label: 'Abstract',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Abstract.jpg?v=1565872364',
      buttonLabel: 'View Collection',
      anchor: 'abstract',
      description: 'Abstract Collection',
      collectionId: '75779375159',
      code: 'ART-ABSTRACT'
    },
    {
      label: 'Religion',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Religion.jpg?v=1565873421',
      buttonLabel: 'View Collection',
      anchor: 'religion',
      description: 'Religion Collection',
      collectionId: '75780063287',
      code: 'ART-RELIGION'
    },
    {
      label: 'Photography',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Photography.jpg?v=1565873352',
      buttonLabel: 'View Collection',
      anchor: 'photography',
      description: 'Photography Collection',
      collectionId: '75779997751',
      code: 'ART-PHOTOGRAPHY'
    },
    {
      label: 'Cityscapes',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/CityScapes_Skylines.jpg?v=1565872470',
      buttonLabel: 'View Collection',
      anchor: 'cityscapes',
      description: 'Cityscapes Collection',
      collectionId: '75779473463',
      code: 'ART-CITYSCAPES'
    },
    {
      label: 'Speakeasy',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Speakeasy.jpg?v=1565873659',
      buttonLabel: 'View Collection',
      anchor: 'speakeasy',
      description: 'Speakeasy Collection',
      collectionId: '75780227127',
      code: 'ART-SPEAKEASY'
    },
    {
      label: 'Nature & Wild Life',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Wildnerness_Wildlife.jpg?v=1565873092',
      buttonLabel: 'View Collection',
      anchor: 'naturewildlife',
      description: 'Nature & Wild Life Collection',
      collectionId: '75779932215',
      code: 'ART-NATUREWILDLIFE'
    },
    {
      label: 'Maritime',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Maritime.jpg?v=1565872953',
      buttonLabel: 'View Collection',
      anchor: 'maritime',
      description: 'Maritime Collection',
      collectionId: '75779866679',
      code: 'ART-MARITIME'
    },
    {
      label: 'Villa View',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/3136.jpg?v=1565874589',
      buttonLabel: 'View Collection',
      anchor: 'villaview',
      description: 'Villa View Collection',
      collectionId: '75780882487',
      code: 'ART-VILLAVIEW'
    },
    {
      label: 'Impressionist',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/70302_Cropped.jpg?v=1565872204',
      buttonLabel: 'View Collection',
      anchor: 'impressionist',
      description: 'Impressionist Collection',
      collectionId: '40838987831',
      code: 'ART-IMPRESSIONIST'
    },
    {
      label: 'Destinations',
      thumbUrl: 'https://cdn.shopify.com/s/files/1/0011/6208/4407/collections/Destinations.jpg?v=1565872626',
      buttonLabel: 'View Collection',
      anchor: 'destinations',
      description: 'Destinations Collection',
      collectionId: '75779538999',
      code: 'ART-DESTINATIONS'
    },
    {
      label: 'Custom',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/art-categories/ArtCustom_500.jpg',
      buttonLabel: 'Select',
      anchor: 'custom',
      description: '',
      collectionId: '35456188471',
      code: 'ART-CUST',
    },
    {
      label: 'Commissioned',
      thumbUrl: window.fmtvAssetsRoot + 'assets/configurator/art-categories/ArtCommissioned_500.jpg',
      buttonLabel: 'Select',
      anchor: 'commissioned',
      description: '',
      collectionId: '61202726967',
      code: 'ART-C',
    }
  ];

  foundItems: number = 0;
  lines: Line[];
  preview: ArtProduct;
  previousSelection: ArtProduct;
  selection: ArtProduct;
  selectedIndex: number;
  selectedLine: Line;
  selectedFilterPane: number;

  dropDownImageUrl: string[] = []; // the main image shown in the drop down

  categoryPreview: ICategory;
  preselectedCategory: string; // selects a category on open
  selectedCategory: string;
  hideHelp = true;
  showFilters = true;
  filter: FilterEntry = null;
  filterBox: string;

  sort = 'product.code';

  loaded = false;
  _toggleFilterMenuOpen = false;
  _showFilterMenu = false;
  _showFavorites = false;

  wishlistItems: any = []; // wishlist items
  wishlistArtProducts: ArtProduct[]; // the product associated with items in the wishlist

  defaultFrameImage: string;

  @lib.inject('$q').property
  protected q: ng.IQService;

  private stylePerLine = 4;

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private $mdSidenav: ng.material.ISidenavService,
    private $ftvRightBar: RightBarService,
    private saveDesignTimerService: SaveDesignTimerService,
    private configuration: DesignConfiguration,
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    $mdMedia: ng.material.IMedia,
    private swymWishListService: SwymWishListService,
    private $window: ng.IWindowService,
  ) {
    this.preselectedCategory = $stateParams.category;
    this.defaultFrameImage = $window.fmtvAssetsRoot + 'assets/configurator/default_art_180x100.jpg';
    this.saveDesignTimerService.pauseTimer();
    swymWishListService.getWishListItems().then((items: any ) => {
        items.forEach(( item: any ) => {
          if ( item.cprops && item.cprops.sku && item.cprops.sku.startsWith('ART-') ){
              this.wishlistItems[item.epi] = item;
          }
        });
    });

    let artList: ArtProduct[] = [];

    let categoryFilter = {
      label: 'Category',
      filters: ([] as FilterEntry[]),
    };

    // dynamically build the filter entries for the categories based on the categoryButtons
    for (const i of this.categoryButtons) {
      let selected = false;
      categoryFilter.filters.push( { name: i.label, imageUrl: i.thumbUrl, filterValue: i.label, selected,count: 0, collectionId: i.collectionId});
    }
    let cat = this.filterCategories.find(c => c.label === 'Category');
    cat.filters = categoryFilter.filters;
    this.categoryFilters = categoryFilter.filters;

    ArtProduct.list().then(art => {
      artList = art;
      const groups = groupBy(art, (a: ArtProduct) => a.collectionId);
      forEach(this.categoryButtons, b => {
        if (b.collectionId) {
          b.items = groups[b.collectionId];
          if (b.items) {
            b.items.sort((f1, f2) => {
              const x = f1.sku.toLowerCase();
              const y = f2.sku.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            });
          }
          b.description = b.description; // yes redundant but the description should come from shopify not be hardcoded and would be set here
        }
      });

      this.buildLines();
      this.loaded = true;

    });

    /*
    $scope.$watch(() => $mdMedia('xs') || $mdMedia('sm'), (small: boolean) => {
      this.stylePerLine = small ? 3 : 5;
      this.buildLines();
    });
    */
    $scope.$on('$destroy', () => {
      const itemsContainer = $('.items-container');
      // itemsContainer.off('scroll', this.scrolled);
    });

    $scope.$watch(() => this.filterBox, () => this.filterContents());
    $scope.$watch(() => this._showFavorites, () => this.getArtProductsForWishList());
    $scope.$watchCollection(() => this.wishlistItems, () => this.getArtProductsForWishList());
    $timeout(this.init, 1000);
  }

  $onInit() {
    if ( this.preselectedCategory ) {
      if ( this.preselectedCategory === 'ART-CUST' ) {
        const cat = {
          description: 'Category',
          collectionId: '35456188471'
        } as Category;
        this.selectCategoryFilter( cat );
      }
    }
  }

  getArtProductsForWishList(){
    if ( this._showFavorites ) {

      ArtProduct.list().then( (art: ArtProduct[]) => {
        this.wishlistArtProducts = art.filter( a => {
          if ( a.product._id  in this.wishlistItems ) {
            return a;
          } else {
            return null;
          }
        });
      }).then(() => {
        this.foundItems += this.wishlistArtProducts.length;
        this.lines = [];
        this.lines.push({ text: 'Favorites', description: '', lineNumber: this.wishlistArtProducts.length, styles: this.wishlistArtProducts });
      });
    }
  }
  toggleShowFavorites(){
    this._showFavorites = !this._showFavorites;
  }

  showFavorites() {
    return this._showFavorites;
  }

  getFilterHtmlClass( filtercat: FiltersCategory ){
    return 'cat-' + filtercat.htmlClass;
  }
  mouseOverCategory(category: ICategory) {
    if (!this.selectedCategory) {
      this.categoryPreview = category;
    }
  }

  mouseOutCategory(category: ICategory) {
    if (!this.selectedCategory) {
      this.categoryPreview = null;
    }
  }

  selectCategory(category: ICategory) {
    if (this.selectedCategory === category.anchor) {
      this.selectedCategory = null;
      this.categoryPreview = null;
    }
    else if (category.items.length === 1) {
      this.selectThumbnail(category.items[0]);
      this.selectedCategory = category.anchor;
    }
  }

  mouseOverThumbnail(art: ArtProduct) {
    if (this.selection) {
      return;
    }
    this.preview = art;
  }

  mouseOutThumbnail() {
    if (this.selection) {
      return;
    }
    this.preview = null;
  }

  // TO DO
  addSampleToCart(sample: SampleProduct) {
    const redirectToCart = false;
    /*
    this.cartService.addSampleProduct( sample, redirectToCart ).then ( () => {
      // const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
      this.$mdDialog.show({
        templateUrl: 'configurator/configuration/design/frame/addSampleToCartDialog/addSampleToCartDialog.html',
        controller: AddSampleToCartDialogController,
        controllerAs: '$ctrl',
        parent: angular.element('#fmtv-app'),
        clickOutsideToClose: true,
        fullscreen: false,
        locals: {
          frameSku: this.selection.name,
          sample
        }
      });
    });
    */
  }

  selectCategoryFilter( category: Category) {
    const filterEntry = this.filterCategories.find( c => c.label === category.description);
    let x = filterEntry.filters.find( f => f.collectionId === category.collectionId);
    this.selectFilter( x );
  }
  selectFilter(filterEntry: FilterEntry) {
    filterEntry.selected = !filterEntry.selected;
    this.filter = filterEntry;
    // this.filterAccordian[this.selectedFilterPane].collapse();
    // force a rebuild because the filter watch doesn't detect unselection
    this.buildLines();
  }

  clearFilter(filterLabel: string) {
    const cat = this.filterCategories.find(item => item.label === filterLabel);
    cat.filters.forEach(item => item.selected = false);
    this.buildLines();
  }
  showClearFilter(filterLabel: string) {
    const cat = this.filterCategories.find(item => item.label === filterLabel);
    let selectedFilters = cat.filters.filter(item => item.selected === true);
    return selectedFilters.length;
  }

  close() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.$mdSidenav('art').close();
  }
  isArtInWishList(style: ArtProduct) {
    return (style.id in this.wishlistItems);
  }
  addToSywmWishlist(style: ArtProduct) {
    if (this.isArtInWishList(style)) {
      this.swymWishListService.removeFromWishList(this.wishlistItems[style.id]).then( () => {
        delete this.wishlistItems[style.id];
        const tempItems: string[] = [];
        Object.keys(this.wishlistItems).forEach( (key, index) => {
          if ( this.wishlistItems[key].cprops && this.wishlistItems[key].cprops.sku ) {
            tempItems.push( this.wishlistItems[key].cprops.sku );
          }
        });
        this.swymWishListService.broadcastWishlistIsDirty( tempItems );
      });
    } else {
      // this.swymWishListService.addCollection(this.swymWishListService.ART_COLLECTION, style.productId ).then( () => {
          this.swymWishListService.addToWishList(style.productId, style.id, style.thumbnailUrl, `https://framemytv.com/products/${style.name}`, style.price, { sku: style.sku }, null).then(item => {
          this.wishlistItems[style.id] = item;
          const tempItems: string[] = [];
          Object.keys(this.wishlistItems).forEach(  (key: any, index: any , array: any)  => {
            if ( this.wishlistItems[key].cprops && this.wishlistItems[key].cprops.sku ) {
              tempItems.push(this.wishlistItems[key].cprops.sku );
            }
          });
          this.swymWishListService.broadcastWishlistIsDirty( tempItems );
        });
      // });
    }
  }

  select() {
    if (this.selection) {
      this.configuration.artwork.setProduct(this.selection);
      this.addToSywmWishlist(this.selection);
      this.close();
    }
  }

  selectSideImage(idx: number) {
    let temp = this.dropDownImageUrl[0];
    this.dropDownImageUrl[0] = this.dropDownImageUrl[idx];
    this.dropDownImageUrl[idx] = temp;
  }
  getPreviewImageForSelected() {
    return this.selection.previewUrl;
  }
  getImageSrcForProductLabelAppImages(imageAsset: string) {
    return window.fmtvAssetsRoot + 'assets/configurator/product-labels/' + imageAsset;
  }
  showPane(pane: any) {
    let x = 1;
    x = x + 1;
    // this.selectedFilterPane = 3;
  }

  filterContents() {
    this.buildLines();
  }

  /*
  selectThumbnail(art: ArtProduct) {
    if (!art) {
      return;
    }
    this.selectedCategory = null;
    this.categoryPreview = null;

    if (this.selection && this.selection.product.code === art.product.code) {
      this.selection = null;
      this.preview = null;
    }
    else {
      this.selection = art;
      this.preview = art;
    }
  }
  */
  selectThumbnail(style: ArtProduct) {
    // if same element is clicked, do not close -- need to use the X
    if (!style) {
      return;
    }
    const line = this.lines.filter(l => {
      return l.styles.find(e => e.code === style.code);
    });
    if (line) {
      this.selectedLine = line[0];
    }

    if (this.selection && this.selection.code === style.code) {
      this.previousSelection = null;
      this.selection = null;
      this.preview = null;

    } else {
      /*this.dropDownImageUrl[0] = style.previewUrl || '';
      this.dropDownImageUrl[1] = style.thumbnailUrl || '';
      this.dropDownImageUrl[2] = style.previewWithoutLiner || '';
      this.dropDownImageUrl[3] = style.previewUrl || '';*/
      this.dropDownImageUrl = [];
      /*this.dropDownImageUrl = style..images.map( image => {
        if ( image.indexOf( '.jpg') > -1 ) {
          return image.replace( '.jpg', '_x400.jpg');
        }
      });
      */
      this.previousSelection = this.selection;
      this.selection = style;
      this.preview = style;
    }
    // this.scrollTo(`expand-jump-${style.sku}`);

  }

  showFiltersMenu() {
    // a media rule will make the menu toggle visible on its own and only then will it be clickable
    // so then the filters should auto-collapse
    const el = angular.element('#toggle-menu-mobile');
    // if the menu toggle is invisible then the filters should always been seen
    if ((el.css('visibility') === 'visible')) {
      this._showFilterMenu = this._toggleFilterMenuOpen;
    } else {
      this._showFilterMenu = true;
    }
    return this._showFilterMenu;
  }

  isElementSelected(style: any) {
    if (this.previousSelection === style.code) {
      return 'is-collapsed';
    }

    if (!this.selection) {
      return 'is-collapsed';
    }
    if (style && style.code) {
      // {{selected: $ctrl.isElementSelected( style ), 'is-expanded': $ctrl.isElementSelected(style), 'is-collapsed': !$ctrl.isElementSelected(style)}
      if (this.selection.code === style.code) {
        return 'frame-expanded';
      } else {
        return 'frame-collapsed';
      }
    }
  }
  canSelectArt() {
    return this.preview || this.selectedCategory === 'custom' || this.selectedCategory === 'commissioned';
  }

  toggleFilter() {
    this.showFilters = !this.showFilters;
  }

  ensureFilterScroll() {
    const itemsContainer = $('.items-container');
    if (itemsContainer.scrollTop() < itemsContainer.height()) {
      itemsContainer.scrollTop(itemsContainer.height());
    }
  }

  showCategories() {
    let isfiltered = false;
    if (this.filterBox && this.filterBox.length > 0) {
      return false;
    }
    this.filterCategories.forEach( cat => {
      let isCatFiltered = cat.filters.find( f => f.selected === true);
      if ( isCatFiltered) {
          isfiltered = true;
      }
    });
    if ( this._showFavorites ){
      return false;
    }
    if (!isfiltered) return true;

    return false;
  }

  toggleMobileMenuClicked() {
    this._toggleFilterMenuOpen = !this._toggleFilterMenuOpen;
  }
  private init = () => {
    const sideNav = this.$mdSidenav('art');
    sideNav.open().then(() => {
      this.$scope.$watch(() => sideNav.isOpen(), (open: boolean) => {
        if (!open) {
          this.$timeout(() => this.$state.go('^'), 300);
        }
      });
    });

    const itemsContainer = $('.items-container');
    itemsContainer.on('scroll', this.scrolled);
  }

  private scrolled = (e: JQueryMouseEventObject) => {
    if (this.hideHelp && e.target.scrollTop >= e.target.clientHeight / 2) {
      this.$scope.$apply(() => this.hideHelp = false);
    }
    if (!this.hideHelp && e.target.scrollTop < e.target.clientHeight / 2) {
      this.$scope.$apply(() => this.hideHelp = true);
    }
  }

  private isArtProduct(pet: number | ArtProduct): pet is ArtProduct {
    return (<ArtProduct>pet).product !== undefined;
  }

  private buildLines() {
    this.lines = [];

    let lineNumber = 0;

    // filter by collection other collections are excluded
    // however if filtering by others (color, size, etc), the filter is applied to all items
    // in all collections
    let favoritesFilters = new Array<FilterEntry>();
    let colorFilters = new Array<FilterEntry>();
    let categoryFilters = new Array<FilterEntry>();
    let subjectMatterFilters = new Array<FilterEntry>();
    let curFilter: FilterEntry[];
    for (let category of this.filterCategories) {
      if (category.label === 'My Favorites') {
        curFilter = favoritesFilters;
      } else if (category.label === 'Color') {
        curFilter = colorFilters;
      } else if (category.label === 'Category') { // filters by collectin id
        curFilter = categoryFilters;
      } else if (category.label === 'Subject Matter') {
        curFilter = subjectMatterFilters;
      }
      for (let filterEntry of category.filters) {
        filterEntry.count = 0;
        if (filterEntry.selected === true) {
          curFilter.push(filterEntry);
        }
      }
    }

    this.foundItems = 0;
    // for each collection type, loop and filter
    this.categoryButtons.forEach( b => {

      let filteredItems: ArtProduct[] = new Array<ArtProduct>();
      let foundItems = 0;

      // count the number of items in each filter for display purposes
      for (let category of this.filterCategories) {
        for (let filterEntry of category.filters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          if ( b.items ) {
              b.items.map(a => {
              if (filterEntry.collectionId && (filterEntry.collectionId === a.collectionId)) {
                filterEntry.count += 1;
              } else {
                let keys = a.product.getKeywords().toLowerCase();
                let found = keys.toLowerCase().indexOf(filterval) > -1;
                if (found) {
                  filterEntry.count += 1;
                }
              }
            });
          }
        }
      }

      // Kevin - make order =  My Favorites, Category, Color, Subject Matter
      // subjectMatterFilters/collection filter
      let categoryItems: ArtProduct[] = [];
      if (categoryFilters.length > 0) {
        let match = categoryFilters.filter(f => f.collectionId === b.collectionId);
        if (match.length === 0) {
          categoryItems = [];
        } else {
          categoryItems = b.items;
        }
      } else {
        // include all items in the current collection and filter from there
        categoryItems = b.items;
      }

      lineNumber += 1;
      b.scrollPos = 150 * this.lines.length;
      let isFiltered: boolean = false;

      // color filter
      let colorItems: ArtProduct[] = new Array<ArtProduct>();
      if (colorFilters.length === 0) {
        colorItems = categoryItems;
      } else {
        for (let filterEntry of colorFilters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          const foundItemss = categoryItems.filter(a => {
            let keys = a.product.getKeywords().toLowerCase();
            let found = keys.indexOf(filterEntry.filterValue.toLowerCase()) > -1;
            return found;
          });
          // i.e. red union blue
          colorItems = colorItems.concat(foundItemss);
        }
      }

      // category filter
      let subjectMatterItems: ArtProduct[] = [];
      if (subjectMatterFilters.length === 0) {
        subjectMatterItems = colorItems || [];
      } else {
        for (let filterEntry of subjectMatterFilters) {
          const filterval = filterEntry.filterValue.replace('-', ' ').toLowerCase();
          const foundItemsss = colorItems.filter(a => {
            let keys = a.product.getKeywords().toLowerCase();
            const found = keys.indexOf(filterEntry.filterValue) > -1;
            return found;
          });
          subjectMatterItems = subjectMatterItems.concat(foundItemsss);
        }
      }

      filteredItems = sortBy(filteredItems, this.sort);

      let searchBoxItems: ArtProduct[] = [];
      if (this.filterBox && this.filterBox.length > 0) {
        // artists, or title or sku, keywords
        const filterTrimmed = this.filterBox.trim().toLowerCase();
        // this filters the items when combined with a selected filter

        searchBoxItems = subjectMatterItems.filter(item => item.name.toLowerCase().includes(filterTrimmed) || item.sku.toLowerCase().includes(filterTrimmed) || item.getKeywords().includes(filterTrimmed));
        subjectMatterItems = searchBoxItems;// subjectMatterItems.concat(searchBoxItems);
      }

      this.foundItems += subjectMatterItems.length;
      this.lines.push({ text: b.label + (this.lines.length ? ' Collection' : ''), description: '', lineNumber, styles: subjectMatterItems });
      // this.lines.push.apply(this.lines, chunk(art).map(c => ({ styles: c })));

    }); // end of this.categoryButtons.forEach( b => {
  }

}
