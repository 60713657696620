import app from '../app';

@app.inject('$state').component({
  templateUrl: 'configurator/dev/devButton.html'
})
export class DevButton {
  devMode = false;

  constructor(
    private $state: ng.ui.IStateService
  ) { }

  $onInit() {
    this.devMode = localStorage.getItem('configurator:dev') === 'true';
  }

  clearDev() {
    localStorage.removeItem('configurator:dev');
    window.parent.location.href = window.parent.location.href.replace(/env=dev\&?/, '');
  }
}
