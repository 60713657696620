
import map from 'lodash/map';
import lib from '~/api-shopify/api-shopify.module';
import { ProductsApi } from '~/api-shopify/products-api';
import { IDesignConfiguration } from '~/api-shopify/product-models/product';
import { FinishProduct } from './finish-product';
import { ProductWithSamples } from './product-with-samples';
import { SampleProduct } from './sample-product';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';
import app from '~/configurator/app';
import { TvModelLine } from '~/configurator/design/lines';

let listPromise: ng.IPromise<FrameProduct[]>;

let defaultsPromise: ng.IPromise<FrameProduct[]>;

export enum FrameCategories {
  Standard = <any>'FRAME-S',
  Metro = <any>'FRAME-M',
  // removing HW
  // Hardwood = <any>'FRAME-H',
  Artisan = <any>'FRAME-A'
}

export class FrameProduct extends ProductWithSamples {
  public static readonly defaults = ['FRAME-M1009', 'FRAME-M1011', 'FRAME-M4101', 'FRAME-M4130', 'FRAME-M4165'];
  public static readonly defaultsMetro = ['FRAME-M3000', 'FRAME-M3002', 'FRAME-M3012', 'FRAME-M3017', 'FRAME-M3027'];

  static list() {
    if (listPromise) {
      return listPromise;
    }
    /* REMOVE CACHING
    let cachedData = sessionStorage.getItem(FrameProduct.key);

    let q: ng.IPromise<any>;
    let isVirtoData = false;
    if (cachedData) {
      const json = JSON.parse(cachedData);
      if ( json[0].associations) { // hack check if its old virto data
        isVirtoData = true;
        cachedData = null;
      } else {
        q = FrameProduct.$q.resolve(JSON.parse(cachedData));
      }
    }
    if ( isVirtoData || !cachedData ) {
        q = FrameProduct.productsApi.getFrameCollections().then(p => {
          const stripped = p.map( (prod: any) => prod);
          try {
            sessionStorage.setItem(FrameProduct.key, JSON.stringify(stripped));
          }
          catch {}
          return stripped;
        });
    }
    */
    let q: ng.IPromise<any> = FrameProduct.productsApi.getFrameCollections();
    return listPromise = q.then(products => map(products, p => {
        const temp = new FrameProduct(p);
        // hack around to optimize the image sizes for display from shopify using shopify url to resize images.
        if ( temp.thumbnailUrl.indexOf( 'x.jpg') === -1 ){
          temp.thumbnailUrl = temp.thumbnailUrl.replace( '.jpg', '_x200.jpg');
        }
        // temp.thumbnailUrl = temp.thumbnailUrl.replace( 'front_corner.jpg', 'front_corner_x200.jpg');
        // temp.thumbnailUrl = temp.thumbnailUrl.replace( '1600.jpg', '1600_x200.jpg');
        // temp.thumbnailUrl = temp.thumbnailUrl.replace( 'M8020.jpg', 'M8020_x200.jpg');
        // temp.thumbnailUrl = temp.thumbnailUrl.replace( 'M8019.jpg', 'M8019_x200.jpg');
        return temp;
    }));
  }

  static loadDefaults() {
    if (defaultsPromise) {
      return defaultsPromise;
    }
    const defaultFrames = this.defaults.concat( this.defaultsMetro );
    let q: ng.IPromise<any> = FrameProduct.productsApi.getFramesBySkuList( defaultFrames );
    return defaultsPromise = q.then(products => map(products, p => {
        const temp = new FrameProduct(p);
        // hack around to optimize the image sizes for display from shopify using shopify url to resize images.
        if ( temp.thumbnailUrl.indexOf( 'x.jpg') === -1 ){
          temp.thumbnailUrl = temp.thumbnailUrl.replace( '.jpg', '_x200.jpg');
        }
        return temp;
    }));
  }

  // this does not appear to be used anywhere... need to verify
  static forCategory(category: string) {
    // is this right? category may need to be aligned across product types (liners etc ) so the same product in the same category
    /// can be selected.... see the liner.controller.ts
    return FrameProduct.productsApi.getFrameCollectionById(category).then((p: any) => new FrameProduct(p));
    // return FrameProduct.productsApi.GET(category.code).then(products => products.map(p => new FrameProduct(p)));
  }

  /**
   * Get a single frame product by sku
   * @param code
   */
  static get(code: string) {
    return FrameProduct.productsApi.getFrameBySku(code).then((p: any) => new FrameProduct(p));
  }

  static getFinishes(baseFrameSku: string) {
    return FrameProduct.productsApi.getFrameFinishesByFrameBaseSku(baseFrameSku).then((result: any) => result.map((i: any) => new FinishProduct(i)));
  }
  private static key = 'frame-cache';
  @lib.inject(ProductsApi).property
  private static readonly productsApi: ProductsApi;

  @lib.inject('$q').property
  private static readonly $q: ng.IQService;

  public readonly layerUrl: string; // layer to be shown when building the frame + liner + art view
  public readonly previewWithoutLiner: string;
  public readonly width: string;
  public readonly factor: number;
  public readonly finish: string;
  public readonly product: FrameProduct;

  //  ???????????????????
  public readonly code: string; // TODO -- I *THINK* CODE IS the same as category

  public readonly images: string[];
   // this relates to the 'product label' app in shopify and is the tags are filters on the cache server
   // to create these values
  public readonly frameLabels: { tag: string; imageUrl: string; }[];

  @lib.inject('DesignConfigurationService').property
  protected designService: IDesignConfiguration;

  @(app.inject('DesignConfigurationService').property )
  protected designConfigService: DesignConfigurationService;

  private _finishesPromise: ng.IPromise<FinishProduct[]>;
  private _finishes: FinishProduct[];

  get finishes() {
    // mocking up the finishes in the cache server to make this compatible for now;
    if (this.finish && !this._finishes) {
      this._finishes = [];
      // in this versions, a frame + finish is its own sku
      // in the old version a frame was a sku and it was given a finish
      // so mocking this version to look like the old vesion at least temporarily
      FrameProduct.getFinishes(this._baseSkuHardwood).then(result => { this._finishes = result; });
    }
    return this._finishes;
  }

  private _priceCheckSize: number;
  private _pricePromise: ng.IPromise<number>;
  private _baseSkuHardwood: string;
  private _manualPriceAdjustment: number  = 1.16607; // this should be set in shopify but temporarily set here - was 1.06 but going 10% more

    constructor(data: any) {
    super(data);
    // TODO: explicityly definied in original but does not appear to be in use
    // however leaving it for reference in case it is in a view
    // this.imagesForDetails = data.layerUrl;
    this.images = data.images;
    this.width = data.faceWidth;
    this.factor = data.factor;
    this.frameLabels = data.frameLabels || [];
    if (this._basePrice === 0 ){
      console.log( this._basePrice);
    }
    // hardwood frames have two preview images
    this.previewWithoutLiner = data.layerWithoutLiner || null;
    this.layerUrl = data.layerUrl;
    // this is a self reference to make it compatible with the virto frame product
    this.product = this;
    this.finish = data.finish;
    this._baseSkuHardwood = data.baseSkuHardwood || '';
    try {
      this.addSample(new SampleProduct(data.cornerSample));
      this.addSample(new SampleProduct(data.chipSample));
    } catch (e) {
      // console.log(`missing sample data in ${this.sku}`);
    }

  }

  categoryCode() {
    return this.category;
    // return FrameProduct.categoriesService.categories().then(cats => find(cats, c => c.id === this.product.categoryId).code);
  }

  isMetro() {
    return this.category === 'FRAME-M';
  }

  isArtisan() {
    // this is a promise for legacy purposes
    return this.q.resolve(this.category === 'FRAME-A');
  }
  isArtisanNonPromise() {
    return this.category === 'FRAME-A';
  }
  isHardwood() {
    return this.category === 'FRAME-H';
  }
  isHardwoodBaroque(){
    return  this.category === 'FRAME-H' && this.name.toLowerCase().includes( 'baroque');
  }

  isHardwoodTraditional(){
    return  this.category === 'FRAME-H' && this.name.toLowerCase().includes( 'traditional');
  }

  isDistressedFrame(){
    return (this.getKeywords().toLowerCase().indexOf( 'distressed') > -1);
  }
  /**
   * Is 'guaranteed' to return a price
   * To keep compatibllity with legacy (virto) code,
   * this must return a promise.
   * In virto, the price was looked up via the api
   * however here it is included with the loaded data so no need for a secondary lookup
   * TODO: need to review why it was looked up.. perhaps if this data is cached
   * client side (which it currently is not) the lookup is used to override the cache'd price???
   */
  guaranteedPrice() {
    let config = this.designService.designConfiguration;
    if (!config || !config.tvModel || !config.tvModel.sizeForPrice) {
      return this.q.resolve(null);
    }
    let tvSize = config.tvModel.sizeForPrice;
    if (!this._pricePromise || !this._priceCheckSize || this._priceCheckSize !== tvSize) {
      this._priceCheckSize = tvSize;

      let factor = this.factor;
      if (tvSize >= 75) {
        factor += 8;
      }
      else if (tvSize >= 52) {
        factor += 4;
      }

      this._pricePromise = this.basePrice().then(b => {
        let total = Math.ceil( (b + factor * tvSize) * this._manualPriceAdjustment);
        if ( (config.tvModel as TvModelLine).isCustomSize ) {
          total = Math.ceil(total *  (config.tvModel as TvModelLine).customTVSizePriceAdjustmentOnNonFixedPricedItems); // add 35% price increase on custom sized tvs
        }
        this._price = total;
        return total;
      });
  }
    return this._pricePromise;
  }
}
