import app from '../../app';

@app.service
export class ProgressBarService {
  visible = false;

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
