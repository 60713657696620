import app from '../../app';

import angular from 'angular';

import { BrandingService } from '../../services/branding.service';
import { CaptureFormDialogController } from './dialog/captureFormDialog.controller';
import { BrandingInfo } from '../../services/brandingInfo';

@app.inject(
  BrandingService,
  '$state',
  '$mdDialog',
  '$mdMedia'
).service
export class CaptureFormService {
  vendor: boolean;
  private brandingInfo: BrandingInfo;

  private _visible = false;
  get visible() { return this._visible; }
  set visible(value: boolean) {
    if (value && !this.vendor) {
      // HACK: when not a vendor, hijack the capture form and show a modal instead...
      this.showDialog();
    }
    else {
      this._visible = value;
    }
  }

  get hideCaptureForm() { return localStorage['hideCaptureForm']; }
  set hideCaptureForm(value: boolean) { localStorage['hideCaptureForm'] = value; }

  constructor(
    brandingService: BrandingService,
    private $state: ng.ui.IStateService,
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia
  ) {
    brandingService.infoPromise.then(info => {
      this.vendor = info.id && info.id !== '0';
      this.brandingInfo = info;
    });
  }

  private showDialog() {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    this.$mdDialog.show({
      templateUrl: 'configurator/configuration/captureForm/dialog/captureFormDialog.html',
      controller: CaptureFormDialogController,
      controllerAs: '$ctrl',
      parent: angular.element('#fmtv-app'),
      clickOutsideToClose: true,
      fullscreen: useFullScreen
    }).catch(() => {
        this.hideCaptureForm = true;
        if (this.brandingInfo.settings.optionsTab) {
          this.$state.go('configuration.tab.options');
        }
        else {
          this.$state.go('configuration.tab.summary');
        }
    });
  }
}
