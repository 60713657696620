import { MiniLoadingIndicatorService } from './mini-loading-indicator.service';
import app from '../app';

@app.inject(MiniLoadingIndicatorService).component({
  templateUrl: 'configurator/mini-loading-indicator/mini-loading-indicator.html',
})
export class MiniLoadingIndicator {
  constructor(
    public miniLoadingIndicatorService: MiniLoadingIndicatorService
  ) {
  }

  $onInit() { }
}
