import { MeasurementsService } from '../../../services/measurements.service';
import { DesignConfiguration } from '../../../design/designConfiguration';
import app from '../../../app';
import angular from 'angular';
import _ from 'lodash';
import { CutGlassLineData } from '~/configurator/design/lines/cutGlassLine';
import { TvModelLine } from '~/configurator/design/lines';
import { WebworkerService } from '~/configurator/web-worker/web-worker.service';
import { MiniLoadingIndicatorService } from '~/configurator/mini-loading-indicator/mini-loading-indicator.service';
import { LoaderTimeoutController } from './loader-timeout.controller';

@app.inject(
  MeasurementsService,
  '$scope',
  '$sce',
  '$q',
  '$rootScope',
  '$mdMedia',
  '$timeout',
  '$mdDialog',
  WebworkerService,
  MiniLoadingIndicatorService,
  '$mdToast'
).component({
  templateUrl: 'configurator/configuration/directives/preview/preview.html',
  bindings: {
    configuration: '<'
  }
})
export class Preview {
  configuration: DesignConfiguration;
  glassWidth: number;
  glassHeight: number;
  previewImage: any;
  zoomPreview: boolean = false;
  shadowImage = window.fmtvAssetsRoot + 'assets/configurator/shadow.png';
  previewBackgroundPlaceholder = window.fmtvAssetsRoot + 'assets/configurator/preview_placeholder.png';
  previewCutGlass = window.fmtvAssetsRoot + 'assets/configurator/mirror_placeholder.jpg';
  private scaleFactor: number = 1;
  private isMobile = false;
  private loaderTimer: NodeJS.Timer = null;
  private distressedNotificationWasClosed: boolean = false;
  constructor(
    private measurementsService: MeasurementsService,
    private $scope: ng.IScope,
    private $sce: ng.ISCEService,
    private q: ng.IQService,
    private $rootScope: ng.IRootScopeService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $mdDialog: ng.material.IDialogService,
    private webworkerService: WebworkerService,
    private miniLoadingIndicator: MiniLoadingIndicatorService,
    private $mdToast: ng.material.IToastService,
  ) {

    $scope.$on('zoom-preview', () => {
      this.toggleZoom();
    });
    $(window).resize(() => {
      this.resizeGlass();
    });
  }
  $post() {
    this.resizeGlass();
  }
  $onInit() {
    this.$scope.$watch(() => this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku, () => this.pauseOnChange());
    this.$scope.$watch(() => this.configuration.artwork && this.configuration.artwork.product && this.configuration.artwork.product.sku, () => this.pauseOnChange());
    this.$scope.$watch(() => this.configuration.liner && this.configuration.liner.product && this.configuration.liner.product.sku, () => this.pauseOnChange());
    this.$scope.$watch(() => this.$mdMedia('xs'), (isXS) => {
      this.isMobile = isXS;
    });
    if (this.webworkerService.self) {
      this.webworkerService.self.postMessage('pause_worker');
    }
    this.$scope.$watch(() => this.configuration.frame && this.configuration.frame.frameProduct && this.configuration.frame.frameProduct.sku,
      () => this.showToastDistressedMessageOnMobile()
    );

    this.$timeout(() => {
      if (this.configuration.cutGlass && this.configuration.cutGlass.data) {
        this.setSize(this.configuration.cutGlass.data);
      }
    });
    if (this.configuration.cutGlass) {
      this.$scope.$watch(() => this.configuration.cutGlass && this.configuration.cutGlass.data, d => {
        this.setSize(d);
      }, true);
    }
  }
  pauseOnChange() {
    this.miniLoadingIndicator.show();
    // reset so the message is shown when a frame or other options is changed
    this.distressedNotificationWasClosed = false;
    /*
    if ( this.loaderTimer ) {
      clearTimeout(this.loaderTimer);
    }
    // if the loader is displayed for too long assume the image loading has failed and show a dialog
    this.loaderTimer = setTimeout( () => {
      this.showLoaderTimeoutDialog();
    }, 7000 );
    */
    if (this.webworkerService.self) {
      this.webworkerService.self.postMessage('pause_worker');
    }
  }

  formatWidth() {
    if (this.configuration.cutGlass.data && this.configuration.cutGlass.data.width) {
      if (this.configuration.cutGlass.data.system === 'inches') {
        return this.measurementsService.formatInches(this.configuration.cutGlass.data.width);
      }
      else {
        return `${this.configuration.cutGlass.data.width}mm`;
      }
    }

    return '?';
  }

  formatHeight() {
    if (this.configuration.cutGlass.data && this.configuration.cutGlass.data.height) {
      if (this.configuration.cutGlass.data.system === 'inches') {
        return this.measurementsService.formatInches(this.configuration.cutGlass.data.height);
      }
      else {
        return `${this.configuration.cutGlass.data.height}mm`;
      }
    }
    return '?';
  }

  toggleZoom() {
    if (!this.$mdMedia('xs')) {
      this.zoomPreview = false;
      return;
    }
    this.zoomPreview = !this.zoomPreview;
  }

  showDistressedNoficiation() {
    return (!this.distressedNotificationWasClosed && this.configuration.frame && this.configuration.frame.product && this.configuration.frame.product.isDistressedFrame());
  }

  showToastDistressedMessageOnMobile() {
    if (this.isMobile) {
      if (this.configuration.frame && this.configuration.frame.product && this.configuration.frame.product.isDistressedFrame()) {
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .position('top right')
            .toastClass('distress-message-toast')
            .textContent('Please note: The image below is a simulated mockup. The chosen frame style has a “distressed” finish and there may be visible knots or other variations in the final product.')
            .action('Ok')
            .hideDelay(1000000)

        ).then(  (response) => {
          if (response === 'ok') {
            this.$mdToast.hide();
          }
        });
      }
    }
  }

  closeDistressedNotification() {
    this.distressedNotificationWasClosed = true;
  }
  private showLoaderTimeoutDialog() {
    const useFullScreen = this.$mdMedia('sm') || this.$mdMedia('xs');
    this.$mdDialog.show({
      templateUrl: 'configurator/configuration/directives/preview/loader-timeout-dialog.html',
      controller: LoaderTimeoutController,
      controllerAs: '$ctrl',
      parent: angular.element('#fmtv-app'),
      clickOutsideToClose: true,
      fullscreen: useFullScreen
    }).catch(() => {
      // this.hideCaptureForm = true;
    });
  }
  private setSize(d: CutGlassLineData) {
    if (d && d.width && d.height) {
      if (d.width > d.height) {
        this.glassWidth = d.width;
        this.glassHeight = d.height;
      }
      else {
        this.glassWidth = d.width;
        this.glassHeight = d.height;
      }
    }
    else {
      // defaults when nothing yet input
      this.glassWidth = 100;
      this.glassHeight = 100;
    }
    this.resizeGlass();
  }

  // fill the available space as much as possible for any given glass dimesions
  private resizeGlass() {
    // this works with css media rules to resize the css glass and fonts
    // const glass = angular.element(document.getElementById('glass-scalable-wrapper'));
    const container = angular.element(document.getElementById('cut-glass-preview'));

    if (container && container[0] && container[0].clientHeight && container[0].clientWidth) {
      // if not viewing cutglass this will have no height/width
      const containerH: number = container[0].clientHeight;
      const containerW: number = container[0].clientWidth;
      let scaleW = containerW / (this.glassWidth + 50);
      let scaleH = containerH / (this.glassHeight + 50);
      this.scaleFactor = scaleW < scaleH ? scaleW : scaleH;
    } else {
      this.scaleFactor = 1;
    }
  }

  private imageLoaded = (lineKind: any) => {
    this.miniLoadingIndicator.hide();
    if (this.loaderTimer) {
      clearTimeout(this.loaderTimer);
    }
    if (this.webworkerService.self) {
      this.webworkerService.self.postMessage('resume_worker');
    }
  }
  /**
   * reduce preview image sizes if on mobile
   * NOTE: cannot modify these in the underlying data
   *       because the urls are used to create a high-res pdf server side
   * NOTE: this will be called on resize so no need to watch the media query
   */
  private imageLoading() {
    alert('loading ');
  }

  private imageLoadingError() {
    this.miniLoadingIndicator.hide();
    // alert('loading error ');
  }

  private reduceImageSizeIfMobile(line: any) {
    // TODO can get large images for medium (like tablet)
    const isMobile = this.$mdMedia('xs') || this.$mdMedia('sm');
    if (!isMobile) {
      return line.preview.image;
    } else {
      // these need to be scaled down based on original size
      // frame 2000x1777 == 310x276  ( 0.8885 )
      // art 1200x1066 = 310x276   ( 0.888333333333 )
      // liner -- same saize as frame
      let url: string = line.preview.image;
      const mobileResize = '_400X355';
      let resizeUrl = '';
      // https://cdn.shopify.com/s/files/1/0011/6208/4407/products/TVM01F_blacked_out_2000x.png?v=1597359591
      if (url === TvModelLine.tvBackgroundImage) {
        resizeUrl = url.replace('2000x', mobileResize);
      } else if (url) {
        if (line) {
          resizeUrl = url.substring(0, url.lastIndexOf('.')) + mobileResize + url.substring(url.lastIndexOf('.'));
        }
        return resizeUrl;
      }
    }
  }

  /*
    loadImage(src: string) {
      return $q((resolve: any, reject: any) => {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.onerror = (e) => {
          // reject(e);
          resolve(image);
        };
        image.src = src;
      });
    }
    */
}
