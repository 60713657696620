import { DesignConfigurationService } from '../design/designConfiguration.service';
import { VirtoCommerceProductConfigurationModuleWebModelProductConfigurationRequest } from '../../api-virto-models/models';
import { LoginService } from '../user/login/login.service';
import { AuthenticationService } from '../user/authentication.service';
import app from '../app';

type SavedConfig = VirtoCommerceProductConfigurationModuleWebModelProductConfigurationRequest;

@app.inject(
  '$state',
  '$window',
  LoginService,
  AuthenticationService,
  DesignConfigurationService
).controller
export default class ConfigurationLibraryController {

  configurations: SavedConfig[];

  constructor(
    private $state: ng.ui.IStateService,
    private $window: ng.IWindowService,
    loginService: LoginService,
    private authentication: AuthenticationService,
    private designConfigurationService: DesignConfigurationService
  ) {
    if (!authentication.loggedIn) {
      loginService.login().then(() => {
        this.refresh();
      }, () => {
        $state.go('configuration.frameType', { configurationId: 'new' });
      });
    }
    else {
      this.refresh();
    }
  }

  $onInit() { }

  parseDate(date: string) {
    return new Date(Date.parse(date));
  }

  open(configuration: SavedConfig) {
    this.designConfigurationService.emptyCache();
    this.$state.go('configuration.tab.design', { configurationId: configuration.number }, { reload: true });
  }

  back() {
    this.$window.history.back();
  }

  link(e: MouseEvent, configuration: SavedConfig) {
    // this.designConfigurationService.showLink(configuration.number, e);
  }

  email(configuration: SavedConfig) {
    this.designConfigurationService.email(configuration.number);
  }

  print(configuration: SavedConfig) {
    this.$state.go('configuration.print', { configurationId: configuration.number });
  }

  impersonatedName() {
    return this.authentication.impersonatedName;
  }

  private refresh() {
    // this.apiConfigurations.listByUser().then(data => {
    //   this.configurations = data.productConfigurationRequests;
    // });
  }

  // We're not deleting configs for now
  // delete(e: MouseEvent, configuration: ConfigurationMetadata) {
  //   var confirm = this.$mdDialog.confirm()
  //     .title('Remove this saved design?')
  //     .textContent('This will design will be permanently deleted.')
  //     .ariaLabel('Confirm design deletion')
  //     .targetEvent(e)
  //     .ok('Yes, delete it')
  //     .cancel('Cancel');

  //   this.$mdDialog.show(confirm).then(() => {
  //     this.$ftvApi.deleteConfiguration(configuration.Configuration_ID)
  //       .then(() => {
  //         this.refresh();
  //       });
  //   });
  // }

  // pdf(configuration: ConfigurationMetadata) {
  //   // a bit hackish but we have to first make the site load the correct configuration before
  //   // going to the pdf page because it picks up the config from the session...
  //   var win = window.open(`http://framemytv.com/configuration/${configuration.Configuration_ID}`, '_blank');
  //   this.$timeout(() => {
  //     win.location.href = 'http://framemytv.com/index.cfm?fuseaction=product.pdf'
  //   }, 500)
  // }
}
