import { MirrorProduct } from '../../../../api-shopify/product-models/mirror-product';
import app from '../../../app';
import { RightBarService } from '../../../rightBar/rightBar.service';

@app.inject('$stateParams').controller
export class MirrorDetailsBarController {
  mirror: MirrorProduct;

  constructor(
    $stateParams: ng.ui.IStateParamsService
  ) {
    MirrorProduct.get($stateParams['mirrorId']).then(p => {
      this.mirror = p;
    });
  }
  $onInit() { }
}

// tslint:disable-next-line:max-classes-per-file
@app.inject('$state', RightBarService).controller
export default class MirrorDetailsController {

  constructor(
    $state: ng.ui.IStateService,
    $ftvRightBar: RightBarService
  ) {
    $ftvRightBar.show(<any>{
      controller: MirrorDetailsBarController,
      templateUrl: 'configurator/configuration/design/mirror/details.html',
      id: 'mirrorDetails',
      title: 'Details',
      className: 'mirror-details',
      closeButton: true,
      closed: () => {
        $state.go('^');
      }
    });
  }

}
