import app from '../../../app';
import { MirrorProduct } from '../../../../api-shopify/product-models/mirror-product';
import { DesignConfigurationService } from '../../../design/designConfiguration.service';
import { DesignConfiguration } from '../../../design/designConfiguration';
import * as Lines from '../../../design/lines';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import { SaveDesignTimerService } from '../../save-design/save-design-timer.service';

@app.inject(
  '$mdSidenav',
  '$state',
  '$scope',
  '$timeout',
  '$mdMedia',
  'designConfiguration',
  DesignConfigurationService,
  SaveDesignTimerService
).controller
export default class MirrorController {

  mirror: MirrorProduct;
  mirrors: MirrorProduct[];
  loaded = false;

  private basePrice = 0;

  constructor(
    private $mdSidenav: ng.material.ISidenavService,
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $mdMedia: ng.material.IMedia,
    public designConfiguration: DesignConfiguration,
    private designConfigurationService: DesignConfigurationService,
    private saveDesignTimerService: SaveDesignTimerService
  ) {
    MirrorProduct.list().then(mirrors => {
      this.mirrors = mirrors; // sortBy(mirrors, m => m.product.priority);
      this.loaded = true;
    });
    this.saveDesignTimerService.pauseTimer();
    $timeout(this.init, 100);
  }

  $onInit() {}

  close() {
    this.saveDesignTimerService.unpauseTimerOnDialogClose();
    this.$mdSidenav('mirror').close();
  }

  select(mirror: MirrorProduct) {
    if (this.$mdMedia('xs') || !mirror) {
      mirror = mirror || this.mirror;
      this.designConfiguration.mirror.setProduct(mirror);
      this.close();
    }
    else {
      this.mirror = mirror;
      // this.samples = this.$ftvSamples.mirrorSamples(mirror);
    }
  }

  details(e: MouseEvent, mirror: MirrorProduct) {
    e.preventDefault();
    e.stopPropagation();
    this.$state.go('configuration.tab.design.mirror.details', { mirrorId: mirror.code });
  }

  selected(mirror: MirrorProduct) {
    if (this.mirror) {
      return this.mirror.code === mirror.code;
    }
    else if (this.designConfiguration.mirror.data) {
      return this.designConfiguration.mirror.data.code === mirror.code;
    }
    return false;
  }

  canSelect(mirror: MirrorProduct) {
    if (includes(['MIRROR-6', 'MIRROR-4'], mirror.code) && this.designConfiguration.tvModel.data.diagscreensize > 75) {
      return false;
    }
    return true;
  }

  private init = () => {
    const sideNav = this.$mdSidenav('mirror');
    sideNav.open().then(() => {
      this.$scope.$watch(() => sideNav.isOpen(), (open: boolean) => {
        if (!open) {
          this.$timeout(() => this.$state.go('^'), 300);
        }
      });
    });
  }
}
