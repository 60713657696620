import { ISO_8601 } from 'moment';
import tinycolor from 'tinycolor2';
import app from '../app';
import { BrandingService } from '../services/branding.service';
import { BrandingInfo } from '../services/brandingInfo';
import { AuthenticationService } from '../user/authentication.service';
import includes from 'lodash/includes';
import defaults from 'lodash/defaults';
import isEmpty from 'lodash/isEmpty';

@app.inject(
  BrandingService,
  '$state',
  '$scope',
  '$location',
  AuthenticationService
).controller
export default class AccountSettingsController {

  account: BrandingInfo;
  customer: any;
  storeLink: string;

  priceAdjustment: string;

  buttonSizes = ['Small', 'Medium', 'Large'];
  buttonCode: string;
  tabCode: string;

  backgroundColor: string;
  textColor: string;

  embedHeight = '750';
  embedHeightUnit = 'px';
  embedWidth = '100';
  embedWidthUnit = '%';
  embedShadow = true;
  embedCode: string;

  address: any;

  private iframeSizes = [
    { width: 142, height: 25 },
    { width: 212, height: 35 },
    { width: 275, height: 50 }
  ];

  constructor(
    private brandingService: BrandingService,
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    private location: ng.ILocationService,
    authentication: AuthenticationService
  ) {
    $scope.$watch(() => $state.params['store'], (accountId: string) => {
      if (accountId) {
        this.brandingService.load($state.params['store']).then(
          b => {
            if (!authentication.isAdmin &&
              !includes(b.emails, authentication.email)) {
              $state.go('configuration.frameType');
            }
            this.account = b;
            this.defaultEmbedValues();
            this.backgroundColor = b.settings.storeColor;
            this.storeLink = `https://framemytv.com/apps/designer/configuration/new/frame-type?store=${accountId}`;
            this.setupWatches();
            this.priceAdjustment = b.settings.priceAdjustment.toString();
            if (b.addresses.length) {
              this.address = b.addresses[0];
            }
          }
        );

        this.buttonCode = this.buildCode(accountId, 'button');
        this.tabCode = this.buildCode(accountId, 'tab');
      }
    });

    $scope.$watch(() => this.priceAdjustment, p => {
      if (p) {
        this.account.settings.priceAdjustment = parseInt(p);
        if (p === '0') {
          this.account.settings.showMsrp = false;
        }
      }
    });

    $scope.$watch(() => this.backgroundColor, c => {
      if (c) {
        this.textColor = this.getTextColor(c);
      }
    });

    $scope.$watchCollection(() => [
      this.embedHeight,
      this.embedHeightUnit,
      this.embedWidth,
      this.embedWidthUnit,
      this.storeLink,
      this.embedShadow
    ], () => {
      const shadow = this.embedShadow ? 'box-shadow: 0 4px 12px -2px #000;' : '';
      this.embedCode = `<iframe style="width:${this.embedWidth}${this.embedWidthUnit};height=${this.embedHeight}${this.embedHeightUnit};border:solid 2px #777;min-height:500px;${shadow}" src="${this.storeLink}"></iframe>`;
    });
  }

  $onInit() {}

  keepColor() {
    if (this.account.settings.button.backgroundColor === this.account.settings.storeColor) {
      this.account.settings.button.backgroundColor = this.backgroundColor;
    }

    if (this.account.settings.tab.backgroundColor === this.account.settings.storeColor) {
      this.account.settings.tab.backgroundColor = this.backgroundColor;
    }

    this.account.settings.storeColor = this.backgroundColor;
  }

  discardColor() {
    this.backgroundColor = this.account.settings.storeColor;
  }

  close() {
    this.brandingService.setStore(this.$state.params['store']).then(() => {
      history.back();
    });
  }

  private getTextColor(bg: string) {
    return tinycolor.mostReadable(tinycolor(bg), [tinycolor('#000'), tinycolor('#fff')]).toHexString();
  }

  private defaultEmbedValues() {
    this.account.settings.button = defaults(this.account.settings.button || {}, {
      backgroundColor: this.account.settings.storeColor,
      textColor: this.getTextColor(this.account.settings.storeColor),
      label: 'Hide My TV',
      size: 2
    });

    this.account.settings.tab = defaults(this.account.settings.tab || {}, {
      backgroundColor: this.account.settings.storeColor,
      textColor: this.getTextColor(this.account.settings.storeColor),
      edge: 'left',
      location: 'center',
      label: 'Hide My TV',
      size: 2
    });
  }

  private setupWatches() {
    // Button
    this.$scope.$watch(() => this.account.settings.button.backgroundColor, b => {
      if (b) {
        this.account.settings.button.textColor = this.getTextColor(b);
      }
    });

    // Tab
    this.$scope.$watch(() => this.account.settings.tab.backgroundColor, b => {
      if (b) {
        this.account.settings.tab.textColor = this.getTextColor(b);
      }
    });

    this.$scope.$watch(() => this.account.settings, s => {
      if (!isEmpty(s)) {
        this.brandingService.saveSettings(s);
      }
    }, true);
  }

  private buildCode(accountId: string, type: string) {
    const urlParts = this.location.absUrl().split('/app');

    return `<script${type === 'button' ? ' class="fmtv-button"' : ''}>
(function(f,r,a,m,e,my,t,v){my=r.getElementsByTagName('script');t=r.currentScript||my[my.length-1];
f[a]=f[a]||function(){(f[a].q=f[a].q||[]).push(arguments)};if(my[0].src.indexOf(m+'/embed.js')<0){
v=r.createElement('script');v.src=m+'/embed.js';v.async=true;my[0].parentNode.insertBefore(v,my[0]);
}f[a](e,t,m);f['FrameMyTvObject']=a;})(window,document,'ftv','${urlParts[0].replace(/http(s)?:/, '')}/vendor',{type:'${type}',storeId:${accountId}});
</script>`;
  }
}
