import { DesignConfiguration } from '../../design/designConfiguration';
import { Line } from '../../design/lines/baseLine';
import { BrandingInfo } from '../../services/brandingInfo';
import { AuthenticationService } from '../../user/authentication.service';
import app from '../../app';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { LambdasService } from '~/lambdas/lambdas.service';
import { LoadingIndicatorService } from '~/configurator/loadingIndicator/loadingIndicator.service';
import angular from 'angular';
import { DesignConfigurationService } from '~/configurator/design/designConfiguration.service';

@app
  .inject(
    '$window',
    AuthenticationService,
    'brandingInfo',
    'designConfiguration',
    '$state',
    'PreviousState',
    'LambdasService',
    '$scope',
    '$sce',
    LoadingIndicatorService,
    'DesignConfigurationService'
  )
  .controller
export default class PrintController {

  lines: { [key: string]: Line[] };
  categories = ['Design', 'Options'];
  showPrices = true;
  pdfContent: any;
  pdfName: string;
  pdfUrl: string;
  fileURL: string;

  constructor(
    private $window: ng.IWindowService,
    private authenticationService: AuthenticationService,
    public brandingInfo: BrandingInfo,
    public configuration: DesignConfiguration,
    private $state: ng.ui.IStateService,
    private previousState: any,
    private lambdaService: LambdasService,
    private $scope: ng.IScope,
    private $sce: ng.ISCEService,
    private loadingIndicator: LoadingIndicatorService,
    private designConfigurationService: DesignConfigurationService

  ) {
    // this is used by the original html print
    //   const lines = this.configuration.lines;
    //   this.lines = groupBy(sortBy(lines, l => l.order), l => l.category);
  }

  $onInit() {
    this.downloadPdfForDisplay();
   }
   displayPrices(){
    this.downloadPdfForDisplay();
   }
   downloadPdfForDisplay() {
      if ( this.configuration.dirty || this.configuration.isNew ) {
          this.loadingIndicator.show(['Saving configuration...', '(Step 1 of 2)']);
          this.configuration.save().then( saved => {
          this.designConfigurationService.designUpdated();
          // go back to the print this time with the sku in the url
          this.$state.go('configuration.print', {configurationId: saved.sku});
        });
      } else {
        this.loadingIndicator.show( ['Generating pdf...', '(Step 2 of 2)']);
        const config = this.configuration.toProductInfo();
        let pdfFile =  this.lambdaService.downloadPdfFile( config, this.showPrices).then ( (response) => {
          this.fileURL = URL.createObjectURL(response);
          this.pdfContent = this.$sce.trustAsResourceUrl(this.fileURL);
          this.loadingIndicator.hide();
        });
      }
   }

   downloadPdf () {
      const config = this.configuration.toProductInfo();
      let skuName = config.sku;
      if ( config.sku && config.sku === 'new') {
        skuName = 'framemytv-unsaved-config-' + this.timeStamp();
      }
      const fileUrl = document.createElement('a');
      fileUrl.href = this.fileURL;
      fileUrl.setAttribute('download', skuName);
      fileUrl.click();
   }

  showSummaryTab() {
    return this.brandingInfo.settings.summaryTab || !this.authenticationService.restricted();
  }

  back() {
    // this.$state.go(this.previousState.name, this.previousState.params);
    let x = this.configuration;
    this.$state.go( 'configuration.tab.design', {configurationId: this.configuration.number} );
  }

  print() {
    // give time for impages to load otherwise only partially downloaded images are shown
    return this.lambdaService.downloadPdf( this.configuration.toProductInfo());
    /*
    setTimeout( () => {
      this.$window.print();
    }, 3000);
    */
  }
  private timeStamp() {
    const now = new Date();

    let date = [ now.getMonth() + 1, now.getDate(), now.getFullYear() ];
    let time: any = [ now.getHours(), now.getMinutes(), now.getSeconds() ];

  // Determine AM or PM suffix based on the hour
    const suffix = ( time[0] < 12 ) ? 'AM' : 'PM';

  // Convert hour from military time
    time[0] = ( time[0] < 12 ) ? time[0] : time[0] - 12;

  // If hour is 0, set it to 12
    time[0] = time[0] || 12;

  // If seconds and minutes are less than 10, add a zero
    for ( let i = 1; i < 3; i++ ) {
      if ( time[i] < 10 ) {
        time[i] = '0' + time[i];
      }
    }

  // Return the formatted string
    return date.join('-') + '-' + time.join('-') + '-' + suffix;
  }
}
