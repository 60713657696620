import app from '../../../app';
import { DesignConfiguration } from '../../../design/designConfiguration';

@app
  .inject('$element', '$scope')
  .component({
    bindings: {
      configuration: '<'
    }
  })
export class UnsavedDot {
  configuration: DesignConfiguration;

  constructor(
    private $element: ng.IRootElementService,
    private $scope: ng.IScope
  ) { }

  $onInit() {
    this.$element.parent().css({ position: 'relative', overflow: 'visible' });
    this.$scope.$watch(() => this.configuration.dirty, dirty => {
      this.$element.toggleClass('dirty', dirty);
    });
  }
}
