import app from '../../../app';

import { TvControlProduct } from '../../../../api-shopify/product-models/tv-control-product';
import { DesignConfiguration } from '../../../design/designConfiguration';
import { OptionsService } from '../options.service';

@app.inject('designConfiguration', OptionsService).controller
export default class OptionsTvController {
  tvControls: TvControlProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private optionsService: OptionsService
  ) {
    TvControlProduct.list().then(c => this.tvControls = c);
  }

  $onInit() { }

  select(tvControl: TvControlProduct) {
    this.configuration.tvControl.setProduct(tvControl);
    this.optionsService.nextOption();
  }
}
