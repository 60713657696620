import { IDesignConfiguration } from './product';
import { Product } from './product';
import find from 'lodash/find';
import lib from '~/api-shopify/api-shopify.module';
import moment from 'moment';
import { DesignTypes } from '../../configurator/design/designTypes';

export class RushProduct extends Product {

  static list(): ng.IPromise<RushProduct[]> {
    let config = RushProduct.designService.designConfiguration;
    if (!config) {
      return RushProduct.q.resolve([]);
    }
    if (config.type === <any>DesignTypes.CutGlass) {
      return RushProduct.cutGlass();
    }
    else {
      return RushProduct.standard();
    }
  }

  static get(code: string) {
    return RushProduct.list().then(products => find(products, p => p.code === code));
  }

  get delay() {
    return Math.ceil(moment(this.readyDate).diff(moment(), 'days') / 7) + ' weeks';
  }

  @lib.inject('$q').property
  private static readonly q: ng.IQService;

  @lib.inject('DesignConfigurationService').property
  private static readonly designService: IDesignConfiguration;

  private static fromToday(days: number) {
    let d = new Date();
    d.setDate(d.getDate() + Math.ceil(days));
    return d;
  }

  private static standard() {
    let config = RushProduct.designService.designConfiguration;
    let base: ng.IPromise<number>;
    let suffix = 'ALL';

    if (config && config.artwork) {
      suffix = 'ART';
      if ( config && config.frame && config.frame.frameProduct ){
        // extend artisan frame lead time to 8 weeks
        base = RushProduct.q.resolve( config.frame.frameProduct.isArtisan().then( b => b ? 56 : 42 ));
      } else {
        base = RushProduct.q.resolve(42);
      }
    }
    else if (config && config.frame && config.frame.frameProduct) {
      // tslint:disable-next-line:whitespace
      base = RushProduct.q.resolve( config.frame.frameProduct.isArtisan().then( b=> b ? 56 : 28 ));
    }
    else {
      base = RushProduct.q.resolve(28);
    }

    let result =  base.then(b => ([
      new RushProduct(<any>{
        code: '1',
        sku: `SVC-STD-${suffix}`,
        name: 'Standard',
        minDay: b,
        maxDay: b + 7,
        readyDate: RushProduct.fromToday(b),
        latestDate: RushProduct.fromToday(b + 7),
        price: () => 0
      }),
      new RushProduct(<any>{
        code: '2',
        sku: `SVC-GRT-${suffix}`,
        name: 'Guaranteed',
        maxDay: b,
        readyDate: RushProduct.fromToday(b),
        price: () => RushProduct.designService.designConfiguration.total * 0.03
      }),
      new RushProduct(<any>{
        code: '3',
        sku: `SVC-PRT-${suffix}`,
        name: 'Partial Rush',
        maxDay: Math.ceil(b * 0.75),
        readyDate: RushProduct.fromToday(b * 0.75),
        price: () => RushProduct.designService.designConfiguration.total * 0.07
      }),
      new RushProduct(<any>{
        code: '4',
        sku: `SVC-RSH-${suffix}`,
        name: 'Standard Rush',
        maxDay: Math.ceil(b * 0.5),
        readyDate: RushProduct.fromToday(b * 0.5),
        price: () => RushProduct.designService.designConfiguration.total * 0.15
      }),
      new RushProduct(<any>{
        code: '5',
        sku: `SVC-PRI-${suffix}`,
        name: 'Priority Rush',
        maxDay: Math.ceil(b * 0.25),
        readyDate: RushProduct.fromToday(b * 0.25),
        price: () => RushProduct.designService.designConfiguration.total * 0.5
      })]));

    return result;
  }

  private static cutGlass() {
    let config: any = RushProduct.designService.designConfiguration;

    let min = 0;
    let max = 0;

    if (config && config.cutGlass && config.cutGlass.data) {
      min = Math.min(config.cutGlass.data.height, config.cutGlass.data.width);
      max = Math.max(config.cutGlass.data.height, config.cutGlass.data.width);
    }

    if ( config.backingOption && config.backingOption.product && config.backingOption.product.sku === 'BLACKOUT-BACK-PAINTING') {
      return RushProduct.q.resolve([
       new RushProduct(<any>{
         code: '1',
         sku: 'SVC-STD-GLS',
         name: 'Standard',
         maxDay: 14,
         readyDate: RushProduct.fromToday(14),
         price: () => 0
       })
     ]);
   } else if (min > 60 || max > 72) {
      return RushProduct.q.resolve([
        new RushProduct(<any>{
          code: '1',
          sku: 'SVC-STD-GLS',
          name: 'Standard',
          maxDay: 14,
          readyDate: RushProduct.fromToday(14),
          price: () => 0
        })
      ]);
    }
    else {
      return RushProduct.q.resolve([
        new RushProduct(<any>{
          code: '1',
          sku: 'SVC-STD-GLS',
          name: 'Standard',
          minDay: 3,
          maxDay: 5,
          readyDate: RushProduct.fromToday(3),
          latestDate: RushProduct.fromToday(5),
          price: () => 0
        }),
        new RushProduct(<any>{
          code: '6',
          sku: 'SVC-RSH-GLS',
          name: 'Rush',
          maxDay:1,
          readyDate: RushProduct.fromToday(1),
          price: () => Math.max(RushProduct.designService.designConfiguration.total * 0.10, 100)
        })
      ]);
    }
  }

  public readyDate: number;
  public maxDay: number;
  public minDay: number;
  public priceFunction: () => number;
  private _code: string; // override super since code and sku are normally the same
  constructor( data: any ){
    super(data);
    this._code = data.code;
    this.maxDay = data.maxDay;
    this.minDay = data.minDay;
    this.readyDate = data.readyDate;
    this.priceFunction = data.price;
  }
  get code(){
    return this._code;
  }

  get price(){
    return this.priceFunction();
  }

  guaranteedPrice(): ng.IPromise<number> {
    let config = this.designService.designConfiguration;
    if (!config) {
      return this.q.resolve(0);
    }
    return this.q.resolve(  this.price);
  }
}
