import app from '../../../app';

import { DesignConfiguration } from '../../../design/designConfiguration';
import { SideDepthProduct } from '../../../../api-shopify/product-models/side-depth-product';
import { RightBarService } from '../../../rightBar/rightBar.service';
import { OptionsService } from '../options.service';

@app.inject(
  'designConfiguration',
  RightBarService,
  '$sce',
  OptionsService
).controller
export default class OptionsDepthController {

  depths: SideDepthProduct[];

  constructor(
    public configuration: DesignConfiguration,
    private $ftvRightBar: RightBarService,
    private $sce: ng.ISCEService,
    private optionsService: OptionsService
  ) {
    SideDepthProduct.list().then(s => {
      this.depths = s;
    });
  }

  $onInit() { }

  select(depth: SideDepthProduct) {
    this.configuration.sideDepth.setProduct(depth);
    // after putting all options in the same UI - need to save to get a product id
    // since the UI steps that would otherwise do it, no longer exist
    if ( this.configuration.canOrder ) {
     // this.configuration.save();
    }
    this.optionsService.nextOption();
  }

  details(e: MouseEvent, depth: SideDepthProduct) {
    e.preventDefault();
    e.stopPropagation();

    this.$ftvRightBar.show({
      templateUrl: 'configurator/configuration/options/depth/details.html',
      id: 'depthDetails',
      backButton: true,
      title: depth.name + ' Side Depth',
      data: this.$sce.trustAsHtml((depth as any).description)
    });
  }
}
