
import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import { ArtProduct} from '~/api-shopify/product-models/art-product';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import app from '../../app';
import angular from 'angular';

export interface OldArtLineData {
  byline?: string;
  caption?: string;
  category?: string;
  credit?: string;
  filename?: string;
  headline?: string;
  keywords?: string;
  name?: string;
  rCount?: string;
  rownum?: string;
  thumburl?: string;
  url?: string;
}

export interface NewArtLineData {
  code?: string;
  product?: ArtProduct;
}

export class ArtLine extends BaseLine<OldArtLineData & NewArtLineData> {

  kind = ConfigLineKinds.Artwork;
  category = 'Design';
  name = 'Artwork';
  order = 30;
  editState = 'configuration.tab.design.art';

  product: ArtProduct ;
  scrollToElementId = 'artwork-option-scroll-to';

  @app.inject('$mdDialog').property
  $mdDialog: ng.material.IDialogService;

  get valid() { return !!this.product; }

  deserialize(data?: OldArtLineData & NewArtLineData) {
    this.data = data;

    if (this.data && this.data.code) {
        return ArtProduct.get(this.data.code).then( (a: any) => this.setProduct( a, true));
    }

    return this.q.resolve();
  }

  setProduct(product: ArtProduct, noTrigger = false) {
    this.product = product;
    this.data = {
      code: product.code,
      product,
      byline: product.artist,
      name: product.name,
      thumburl: product.thumbnailUrl
    };

    this.preview = {};
    this.preview.index = 2;

    this.preview.image = product.layerUrl;
      // this.preview = {
      //  image: find(product.product.images, i => i.name.indexOf('_med') >= 0).url,
      //  index: 2
      //  };
    this.applyProduct();
    return this.refreshPrice().then(() => {
      if (!noTrigger) {
        this.triggerUpdate();
      }
    });
  }

  refreshPrice(): ng.IPromise<any> {
    if (this.product) {
      return this.product.guaranteedPrice().then(p => {
        this.price = p;
      });
    }
    this.price = null;
    return this.q.resolve();
  }

  serialize() {
    let line = super.serialize();
    line.data = {
      code: this.data && this.data.code
    };
    return line;
  }

  private applyProduct() {
    let t = `${this.data.product.code}: ${this.data.product.name}`;
    let artist = this.data.product.artist;
    if (artist) {
      t += ` by ${artist}`;
    }

    if (this.data.headline === 'Commissioned') {
      t += ' (price varies)';
    }
    this.description = t;
  }
}

linesTable[ConfigLineKinds.Artwork] = ArtLine;
