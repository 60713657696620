// import { CustomerManagementModuleApi } from '../../virto/api/CustomerManagementModuleApi';
import { BrandingInfo, StoreSettings } from './brandingInfo';
import app from '../app';
import tinycolor from 'tinycolor2';
import jquery from 'jquery';
import find from 'lodash/find';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';

let defaultBranding: BrandingInfo = {
  id: '0',
  title: 'Design Center',
  logo: window.fmtvAssetsRoot + 'assets/configurator/logo.png',
  siteUrl: 'http://framemytv.com',
  theme: '',
  logoPrint: window.fmtvAssetsRoot + 'assets/configurator/logo-black.png',
  prettyPhone: '888.TV.FRAME',
  phone: '18888837263',
  liveChat: true,
  settings: {
    priceAdjustment: 0,
    summaryTab: true,
    optionsTab: true,
    captureForm: true,
    showPrices: true,
    showTitle: false,
    showLogo: true,
    showPhone: true,
    estimateShipping: true,
    showMsrp: true,
    frameTypes: {
      'frame only': true,
      'frame with art': true,
      'frame with mirror': true,
      'frameless mirror': true,
      'metro mirror': true,
      'cut glass': true
    }
  }
};

@app.inject(
  '$window',
  // CustomerManagementModuleApi,
  '$q',
  '$mdMedia',
  '$rootScope'
).service
export class BrandingService {

  infoPromise: ng.IPromise<BrandingInfo>;

  private info: BrandingInfo = {};
  private styleSheet: HTMLStyleElement;
  private vendor: any;
  private q: ng.IDeferred<BrandingInfo>;

  constructor(
    private $window: ng.IWindowService,
   // private customerApi: CustomerManagementModuleApi,
    private $q: ng.IQService,
    $mdMedia: ng.material.IMedia,
    $rootScope: ng.IRootScopeService
  ) {
    this.q = $q.defer();
    this.infoPromise = this.q.promise;

    $rootScope.$watch(() => $mdMedia('xs') || $mdMedia('sm'), mobile => {
      if (mobile || (this.info && this.info.id !== '0')) {
        jquery('.trustpilot-widget').hide();
      }
      else {
        jquery('.trustpilot-widget').show();
      }
    });
  }

  load(id: string): ng.IPromise<BrandingInfo> {
    throw new Error(`Vendor not foudn`);
    /*return this.customerApi.customerModuleSearchVendors({ take: 99999 })
      .then( (r: any) => {
        const vendor = find(r.data.vendors, (v: any) => v.vendorNumber === id);
        if (!vendor) {
          throw new Error(`Vendor not found [${id}]`);
        }
        return vendor.id;
      })
      .then((vendorId: string) => this.customerApi.customerModuleGetMemberById(vendorId))
      .then( (r: any) => {
        this.vendor = r.data;
        if (!this.vendor) {
          return this.$q.reject();
        }

        let settings: StoreSettings = this.vendor.configuratorSettings ? JSON.parse(this.vendor.configuratorSettings) : {};
        if (typeof (settings) === 'string') {
          settings = {};
        }

        // Load the old style settings if the new style is missing
        if (typeof settings.captureForm === 'undefined') {
          settings.captureForm = this.vendor.CaptureForm === '1';
        }
        if (typeof settings.optionsTab === 'undefined') {
          settings.optionsTab = this.vendor.OptionsTab === '1';
        }
        if (typeof settings.showPrices === 'undefined') {
          settings.showPrices = this.vendor.ShowPrices === '1';
        }
        if (typeof settings.showTitle === 'undefined') {
          settings.showTitle = this.vendor.ShowTitle === '1';
        }
        if (typeof settings.summaryTab === 'undefined') {
          settings.summaryTab = this.vendor.SummaryTab === '1';
        }
        if (typeof settings.priceAdjustment === 'undefined') {
          settings.priceAdjustment = parseInt(this.vendor.PriceAdjustment);
        }
        if (typeof settings.storeColor === 'undefined') {
          settings.storeColor = this.vendor.AcctField10;
        }
        if (typeof settings.frameTypes === 'undefined') {
          settings.frameTypes = {
            'frame only': true,
            'frame with art': true,
            'frame with mirror': true,
            'frameless mirror': true,
            'metro mirror': true
          };
        }

        let branding: BrandingInfo = {
          id,
          title: this.vendor.Account_name,
          logo: this.vendor.logoUrl,
          logoPrint: this.vendor.logo,
          siteUrl: this.vendor.web_url,
          theme: this.vendor.AcctField9,
          prettyPhone: this.vendor.phone,
          phone: this.vendor.phone,
          liveChat: this.vendor.LiveChat === '1',
          contactName: this.vendor.contactName,
          contactEmail: this.vendor.contactEmail,
          contactId: parseInt(this.vendor.USERID),
          address1: this.vendor.Address1,
          address2: this.vendor.Address2,
          city: this.vendor.City,
          state: this.vendor.State,
          zip: this.vendor.Zip,
          settings,
          customerId: this.vendor.Customer_ID,
          addresses: this.vendor.addresses,
          emails: this.vendor.emails
        };

        return merge({}, defaultBranding, branding);
      });
      */
  }

  saveSettings(settings: StoreSettings) {
    this.vendor.configuratorSettings = JSON.stringify(settings);
    // this.customerApi.customerModuleUpdateMember(this.vendor);
  }

  setStore(storeId: string) {
    if (storeId) {
      this.load(storeId).then(s => {
        this.replace(s);
        this.buildStyles(s.settings.storeColor || '#000');
        this.q.resolve(this.info);
      }, () => {
        this.badStore();
        this.q.reject();
      });
    }
    else {
      this.buildStyles('#272928');
      this.replace(cloneDeep(defaultBranding));
      this.q.resolve(this.info);
    }
    return this.infoPromise;
  }

  private replace(info: BrandingInfo) {
    keys(this.info).forEach((k) => {
      delete this.info[k];
    });

    merge(this.info, info);
  }

  private buildStyles(color: string) {
    if (!this.styleSheet) {
      this.styleSheet = document.createElement('style');
      document.head.insertBefore(this.styleSheet, document.head.firstChild);
    }

    let bgColor = tinycolor(color);
    let textColor = tinycolor.mostReadable(bgColor, [tinycolor('#000'), tinycolor('#fff')]);
    let lightBgColor = tinycolor(color).lighten(20);
    let lightTextColor = tinycolor.mostReadable(lightBgColor, [tinycolor('#000'), tinycolor('#fff')]);

    this.styleSheet.innerHTML = `
.store-branding {
  background-color: ${ color} !important;
  color: ${ textColor.toHexString()} !important;
  fill: ${ textColor.toHexString()} !important;
}

.store-branding.light {
  background-color: ${ lightBgColor.toHexString()} !important;
  color: ${ lightTextColor.toHexString()} !important;
  fill: ${ lightTextColor.toHexString()} !important;
}
`;
  }

  private badStore() {
    this.$window.location.replace(this.$window.location.href.replace(/store=\d+&?/, '').replace(/\?$/, ''));
  }
}
