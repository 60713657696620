import app from '../../../app';
import MirrorDetailsController from './details.controller';

app.inject('$stateProvider').config((
  $stateProvider: ng.ui.IStateProvider
) => {

  $stateProvider.state('configuration.tab.design.mirror.details', {
    url: '/:mirrorId',
    views: {
      'right-bar@layout': {
        templateUrl: 'configurator/configuration/design/mirror/details.html',
        controller: MirrorDetailsController,
        controllerAs: '$ctrl'
      }
    }
  });
});
