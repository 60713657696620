import app from '../app';
import { IPromise, IHttpResponse } from 'angular';

@app.inject(
  '$timeout',
  '$q',
  '$http',
  '$cookies'
).service

/**
 * This service is for submitting values to existing hubspot forms without iframing the form on the page (i.e. form recreated in the app)
 */
export class HubspotJSONFormService {

  constructor(
    private timeout: ng.ITimeoutService,
    private q: ng.IQService,
    private $http: ng.IHttpService,
    private $cookies: ng.cookies.ICookiesService
  ) {
  }
  /**
   * @param id the form id in hubspot
   * @param fields an array of form values to sumbmit in the format [
   *     {
   *       "name": "email", <== form value in hubspot
   *       "value": "example@example.com" <== the value in the apps form
   *     },
   */
  submitHubSpotForm(id: string, fields: any, pageURI: string, pageName: string): IPromise<IHttpResponse<any>> {
    const hutk = this.$cookies.get('hubspotutk');
    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/2302681/' + id;
    let ip = '';

    // ping external ip free service to get client ip for hubspot submission
    return this.$http.get('https://api.ipify.org?format=json').then( (response) => {
      ip = (response.data as any).ip;
    })
    .catch( (error: any) => { console.error(error); })
    .then(() => {
      const data = {
        'submittedAt': Date.now(),
        'fields': fields,
        'context': {
          'hutk': hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          'pageUri': pageURI,
          'pageName': pageName,
        },
      };
      if ( ip !== '' ) {
        (data as any).context.ipAddress = ip;
      }
      const jsonData = JSON.stringify(data);
      // TODO verify this is posted as json
      return this.$http.post<any>(url, jsonData);
      // status === 200 success, 400 === rejected, 403 === portal not allowed to post, 404 formGuid not found
    });
  }
}
