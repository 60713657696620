import app from '../../../app';
import { CutGlassController } from './details.controller';

app.inject('$stateProvider').config((
  $stateProvider: ng.ui.IStateProvider
) => {

  $stateProvider.state('configuration.tab.design.cutGlass', {
    url: '/cut-glass',
    views: {
      'right-bar@layout': {
        templateUrl: 'configurator/configuration/design/cut-glass/details.html',
        controller: CutGlassController,
        controllerAs: '$ctrl'
      }
    }
  });
});
