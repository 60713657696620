import app from '../app';
import find from 'lodash/find';

export interface Fraction { value: number; label: string; fraction: string; }

let fractions: Fraction[] = [
  { value: 0.0625, label: '1/16 (0.0625)', fraction: '1/16' },
  { value: 0.125, label: '1/8 (0.125)', fraction: '1/8' },
  { value: 0.1875, label: '3/16 (0.1875)', fraction: '3/16' },
  { value: 0.25, label: '1/4 (0.25)', fraction: '1/4' },
  { value: 0.3125, label: '5/16 (0.3125)', fraction: '5/16' },
  { value: 0.375, label: '3/8 (0.375)', fraction: '3/8' },
  { value: 0.4375, label: '7/16 (0.4375)', fraction: '7/16' },
  { value: 0.5, label: '1/2 (0.5)', fraction: '1/2' },
  { value: 0.5625, label: '9/16 (0.5625)', fraction: '9/16' },
  { value: 0.625, label: '5/8 (0.625)', fraction: '5/8' },
  { value: 0.6875, label: '11/16 (0.6875)', fraction: '11/16' },
  { value: 0.75, label: '3/4 (0.75)', fraction: '3/4' },
  { value: 0.8125, label: '13/16 (0.8125)', fraction: '13/16' },
  { value: 0.875, label: '7/8 (0.875)', fraction: '7/8' },
  { value: 0.9375, label: '15/16 (0.9375)', fraction: '15/16' }
];

@app.service
export class MeasurementsService {
  get fractions() { return fractions; }

  formatInches(value: number) {
    let floor = Math.floor(value);
    let dec = value - floor;

    let fraction = find(fractions, f => f.value === dec);
    if (fraction) {
      return `${floor}" ${fraction.fraction}`;
    }

    // if there is no fraction, we don't do anything to the value
    return `${Math.round(value * 100) / 100}"`;
  }

  getFraction(value: number) {
    let floor = Math.floor(value);
    let dec = value - floor;

    return find(fractions, f => f.value === dec);
  }

  mmToInches(value: number) {
    return value * 0.03937007874;
  }

  inchesToFoot(value: number) {
    return value / 12;
  }
}
