import { BaseLine, ConfigLineKinds, linesTable } from './baseLine';
import clone from 'lodash/clone';

export interface OldSpeakerBarLineData {
  hasspeakerbar?: boolean;
  makemodel?: string;
  mounted?: string;
  gap?: number;
  instructions?: string;
}
export interface SpeakerBarLineData { }

export class SpeakerBarLine extends BaseLine<OldSpeakerBarLineData & SpeakerBarLineData> {
  kind = ConfigLineKinds.SpeakerBar;
  category = 'Options';
  name = 'Speaker Bar Accomodation';
  order = 90;
  editState = 'configuration.tab.options.speaker';
  scrollToElementId = 'speaker-bar-option-scroll-to';

  get valid() { return true; }

  deserialize(data?: OldSpeakerBarLineData & SpeakerBarLineData) {
    this.data = data;
    this.setDescription(this.data);
    return this.q.resolve();
  }

  update(data?: OldSpeakerBarLineData & SpeakerBarLineData) {
    this.setDescription(data);
    this.price = data.hasspeakerbar ? 450 : 0;
    this.data = clone(data);
    this.triggerUpdate();
  }

  refreshPrice() {
    return this.q.resolve();
  }

  private setDescription(speaker: OldSpeakerBarLineData) {
    if (!speaker) {
      this.description = null;
    }
    else if (!speaker.hasspeakerbar) {
      this.description = 'None';
    }
    else {
      let t = `Model: ${speaker.makemodel}<br>Mounted: ${speaker.mounted}<br>Gap: ${speaker.gap} in.`;
      if (speaker.instructions) {
        t += '<br>' + speaker.instructions;
      }
      t += '<br>NOTE: Speaker bar specified and supplied by client.';
      this.description = t;
    }
  }
}

linesTable[ConfigLineKinds.SpeakerBar] = SpeakerBarLine;
